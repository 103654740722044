import { TranslocoModule } from '@ngneat/transloco';
import { NgModule } from "@angular/core";
import { HttpClientModule } from "@angular/common/http";
import { RouterModule } from "@angular/router";
import { MatButtonModule } from "@angular/material/button";
import { MatDividerModule } from "@angular/material/divider";
import { MatIconModule } from "@angular/material/icon";
import { MatMenuModule } from "@angular/material/menu";
import { MatDialogModule } from "@angular/material/dialog";
import { MatSelectModule } from "@angular/material/select";
import { FuseFullscreenModule } from "@fuse/components/fullscreen";
import { FuseLoadingBarModule } from "@fuse/components/loading-bar";
import { FuseNavigationModule } from "@fuse/components/navigation";
import { LanguagesModule } from "app/layout/common/languages/languages.module";
import { MessagesModule } from "app/layout/common/messages/messages.module";
import { NotificationsModule } from "app/layout/common/notifications/notifications.module";
import { QuickChatModule } from "app/layout/common/quick-chat/quick-chat.module";
import { SearchModule } from "app/layout/common/search/search.module";
import { ShortcutsModule } from "app/layout/common/shortcuts/shortcuts.module";
import { UserModule } from "app/layout/common/user/user.module";
import { SharedModule } from "app/shared/shared.module";
import { ModernLayoutComponent } from "app/layout/layouts/horizontal/modern/modern.component";
import { FuseDrawerModule } from "@fuse/components/drawer";

@NgModule({
	declarations: [ModernLayoutComponent],
	imports: [
		HttpClientModule,
		RouterModule,
		MatButtonModule,
		MatDividerModule,
		MatIconModule,
		MatMenuModule,
		FuseFullscreenModule,
		FuseLoadingBarModule,
		FuseNavigationModule,
		FuseDrawerModule,
		LanguagesModule,
		MessagesModule,
		NotificationsModule,
		QuickChatModule,
		SearchModule,
		ShortcutsModule,
		UserModule,
		MatDialogModule,
		MatSelectModule,
		SharedModule,
		TranslocoModule
	],
	exports: [ModernLayoutComponent],
})
export class ModernLayoutModule {}
