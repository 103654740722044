import { Injectable } from "@angular/core";
// import { timesFont } from "./fonts/times.ttf.Base64.encoded";

import * as pdfMake from 'pdfmake/build/pdfmake';
import * as timesFonts from './fonts/times';

pdfMake.vfs = timesFonts.pdfMake.vfs;
pdfMake.fonts = {
	TimesNewRoman: {
		normal: 'Times.ttf',
		bold: 'Timesbold.otf',
		italics: 'Timesitalic.ttf',
		bolditalics: 'Timesbolditalic.ttf'
	},
	Roboto: {
		normal: 'Roboto-Regular.ttf',
		bold: 'Roboto-Medium.ttf',
		italics: 'Roboto-Italic.ttf',
		bolditalics: 'Roboto-MediumItalic.ttf'
	}
};




@Injectable({
	providedIn: "root",
})
export class PdfService {
	// pdfMake: any;

	constructor() {}



	async generatePdf(data: any) {
		const pdf = pdfMake.createPdf(data).open();
	}

	async downloadPdf(data: any) {
		pdfMake.createPdf(data).download('documento.pdf');
	}

	async downloadEpigrafePdf(data: any, name: any) {
		const pdf = await pdfMake.createPdf(data).getBlob(blob => {
		  const link = document.createElement('a');
		  link.href = URL.createObjectURL(blob);
		  link.download = `FICHA-${name}.pdf`;
		  link.click();
		});
	}

}
