<div class="settings-cog fixed flex items-center justify-center left-0 w-10 h-10 shadow-lg rounded-r-md z-90 cursor-pointer bg-[#111927] bg-opacity-90 print:hidden"
    [class.lg:right-0]="config.layout === 'centered' || config.layout === 'material'"
    [class.lg:right-16]="config.layout !== 'centered' && config.layout !== 'material'" style="top: 100px"
    (click)="settingsDrawer.toggle()" *ngIf="!isScreenSmall">
    <mat-icon class="icon-size-5 text-white animate-spin-slow" [svgIcon]="'heroicons_solid:cog'"></mat-icon>
</div>

<fuse-drawer class="w-screen min-w-screen sm:w-100 sm:min-w-100 z-999" fixed [mode]="'over'" [name]="'settingsDrawer'"
    [position]="'left'" #settingsDrawer  [opened]="smallMenuOpen">

    <div class="flex flex-col w-full overflow-auto bg-[#111927]">
        <div class="flex flex-row items-center mt-10 mb-5 px-6 h-20 min-h-20 text-white">
            <!-- <mat-icon class="icon-size-7 text-current" [svgIcon]="'heroicons_solid:cog'"></mat-icon>
            <div class="ml-3 text-2xl font-semibold tracking-tight">Settings</div> -->
            <div class="flex items-center h-48 pt-6 px-8">
                <img class="w-50" src="assets/images/logo/logo_colores.png" alt="Logo image">

            </div>
            <button class="ml-auto" mat-icon-button (click)="settingsDrawer.close()">
                <mat-icon class="text-current" [svgIcon]="'heroicons_outline:x'"></mat-icon>
            </button>
        </div>

        <div class="flex flex-col gap-10 p-6 text-white font-bold text-lg leading-5 justify-between h-full">

            <div>
                <div class="flex items-center gap-3 pl-4 cursor-pointer" routerLink="app" *ngIf="userInfo.rol != 'TECNICO'">
                    <img class="h-5 w-5" src="assets/icons/home.png" alt="icon-home">
                    {{ "drawer.inicio" | transloco }}
                </div>
                <ng-container *ngIf="userInfo.rol != 'TECNICO'">
                    <div class="text-lg leading-5 text-[#BBCFF9] my-6">{{ "drawer.inventarios.name" | transloco }}</div>
                    <div class="flex flex-col pl-4 gap-8">
                        <div class="flex items-center gap-3 cursor-pointer" (click)="onOpenModalSubmenuInventarioBienes()">
                            <img class="h-5 w-5" src="assets/icons/inventario_bienes.png" alt="icon-inventario_bienes">
                            {{ "drawer.inventarios.bienes" | transloco }}
                        </div>
                        <div class="flex items-center gap-3 cursor-pointer" (click)="onOpenModalPatrimonioSuelo()">
                            <img class="h-5 w-5" src="assets/icons/inventario_patrimonio.png"
                                alt="icon-inventario_patrimonio">
                                {{ "drawer.inventarios.patrimonio" | transloco }}
                        </div>
                        <!-- <div class="flex items-center gap-3 cursor-pointer" routerLink="/app/arbolado-urbano">
                            <img class="h-5 w-5" src="assets/icons/inventario_arbolado.png" alt="icon-inventario_arbolado">
                            Inventario arbolado Urbano
                        </div> -->
                    </div>
                </ng-container>
                
                <ng-container *ngIf="['SUPER ADMIN', 'SECRETARIO', 'TECNICO', 'GESTOR'].includes(userInfo?.rol)">
                    <div class="text-lg leading-5 text-[#BBCFF9] my-6" >{{ "drawer.configuracion.name" | transloco }}</div>
                    <div class="flex flex-col pl-4 gap-8">
                        <div class="flex items-center gap-3 cursor-pointer" routerLink="app/notificaciones" *ngIf="userInfo.rol != 'TECNICO'">
                            <img class="h-5 w-5" src="assets/icons/notifications.png" alt="icon-notification">
                            {{ "drawer.configuracion.notificaciones" | transloco }}
                        </div>
                        <div class="flex items-center gap-3 cursor-pointer" (click)="onOpenModalCertificadosConfiguracion()" *ngIf="!['TECNICO', 'GESTOR'].includes(userInfo.rol)">
                            <img class="h-5 w-5" src="assets/icons/configuracion_certificados.png"
                                alt="icon-configuracion_certificados">
                                {{ "drawer.configuracion.certificados" | transloco }}
                        </div>
                        <div class="flex items-center gap-3 cursor-pointer" (click)="onOpenModalDatosCatastro()" *ngIf="!['SECRETARIO', 'TECNICO', 'GESTOR'].includes(userInfo.rol)">
                            <img class="h-5 w-5" src="assets/icons/obtener_catastro.png" alt="icon-obtener-catastro">
                            {{ "drawer.configuracion.obtenerCatastro" | transloco }}
                        </div>
                        <div class="flex items-center gap-3 cursor-pointer" routerLink="/app/scraping/totales" *ngIf="!['SECRETARIO', 'GESTOR'].includes(userInfo.rol)">
                            <img class="h-5 w-5" src="assets/icons/listado_datos.png" alt="icon-listado-datos">
                            {{ "drawer.configuracion.listadoCatastro" | transloco }}
                        </div>
                        <div class="flex items-center gap-3 cursor-pointer" routerLink="app/control-usuarios" *ngIf="!['SECRETARIO', 'TECNICO', 'GESTOR'].includes(userInfo.rol)">
                            <img class="h-5 w-5" src="assets/icons/configuracion_accesos.png"
                                alt="icon-configuracion_accesos">
                                {{ "drawer.configuracion.usuarios" | transloco }}
                        </div>
                        <!-- <div class="flex items-center gap-3 cursor-pointer" (click)="onOpenModalCargarDatos()" *ngIf="['ADMIN', 'SUPER ADMIN'].includes(userInfo.rol)">
                            <img class="h-5 w-5" src="assets/icons/obtener_catastro.png"
                                alt="icon-configuracion_accesos">
                                {{ "drawer.configuracion.carga" | transloco }}
                        </div> -->
                    </div>
                    <ng-container *ngIf="['SUPER ADMIN', 'TECNICO'].includes(userInfo.rol)">
                        <div class="text-lg leading-5 text-[#BBCFF9] my-6">{{ "drawer.informes.name" | transloco }}</div>
                        <div class="flex flex-col pl-4 gap-8">
                            <div class="flex items-center gap-3 cursor-pointer" (click)="showModalResultados()">
                                <img class="h-5 w-5" src="assets/icons/informe_resultados.png" alt="icon-informe-resultados">
                                {{ "drawer.informes.resultados" | transloco }}
                            </div>
                            <div class="flex items-center gap-3 cursor-pointer" (click)="showModalInventarioBienes()">
                                <img class="h-5 w-5" src="assets/icons/inv_bienes_drawer.png"
                                    alt="icon-inventario-bienes-drawer">
                                    {{ "drawer.informes.inventario" | transloco }}
                            </div>
                        </div>
                    </ng-container>
                </ng-container>
            </div>
            <a routerLink="/sign-out"
                class="rounded-xl bg-[#791515] font-bold text-xl leading-5 flex gap-2 items-center py-3 justify-center">
                <mat-icon>logout</mat-icon>
                {{ "drawer.cerrarSesion" | transloco }}
            </a>

            <ng-template #content>
                <h2 mat-dialog-title>Título del modal</h2>
                <mat-dialog-content>
                  Contenido del modal
                </mat-dialog-content>
                <mat-dialog-actions>
                  <button mat-button (click)="onClose()">Cerrar</button>
                </mat-dialog-actions>
            </ng-template>
        </div>

    </div>

</fuse-drawer>