import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "environments/environment";
import { BehaviorSubject, Observable, tap, switchMap, of } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ProjectService {
  private _data: BehaviorSubject<any> = new BehaviorSubject(null);
  urlApi = environment.urlApi2;
  urlApiOld = environment.urlApi;
  // urlApi = "http://82.223.217.238:3000/api/";

  /**
   * Constructor
   */
  constructor(private _httpClient: HttpClient) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Getter for data
   */
  get data$(): Observable<any> {
    return this._data.asObservable();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Get data
   */
  getData(): Observable<any> {
    return this._httpClient.get("api/dashboards/project").pipe(
      tap((response: any) => {
        this._data.next(response);
      })
    );
  }

  getResumen(municipioId: number) {
    return this._httpClient
      .get(`${this.urlApi}resumenes?municipioId=${municipioId}`)
      .pipe(
        switchMap((response: any) => {
          console.log(response);
          return of(response);
        })
      );
  }

  getResumenGeneralDerechos(municipioId: number) {
    return this._httpClient
      .get(`${this.urlApi}resumenes/bienes?municipioId=${municipioId}`)
      .pipe(
        switchMap((response: any) => {
          console.log(response);
          return of(response);
        })
      );
  }

  getResumenPendientes(municipioId: number) {
    return this._httpClient
      .get(
        `${this.urlApi}resumenes/bienes-pendientes?municipioId=${municipioId}`
      )
      .pipe(
        switchMap((response: any) => {
          console.log(response);
          return of(response);
        })
      );
  }
  // getBienesEpigrafes() {
  // 	return this._httpClient
  // 		.get(`${this.urlApi}generico/bienesEpigrafe`)
  // 		.pipe(
  // 			switchMap((response: any) => {
  // 				return of(response);
  // 			})
  // 		);
  // }

  getValoracion(municipioId: number) {
    return this._httpClient
      .get(`${this.urlApi}informes/valoracion?municipioId=${municipioId}`)
      .pipe(
        switchMap((response: any) => {
          console.log(response);
          return of(response);
        })
      );
  }
  getValoracionComparativa(municipioId: number, year: number) {
    return this._httpClient
      .get(
        `${this.urlApi}informes/valoracion?municipioId=${municipioId}&division=${year}`
      )
      .pipe(
        switchMap((response: any) => {
          console.log(response);
          return of(response);
        })
      );
  }
}
