import { TranslocoCoreModule } from "./core/transloco/transloco.module";
import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ExtraOptions, PreloadAllModules, RouterModule } from "@angular/router";
import { MarkdownModule } from "ngx-markdown";
import { FuseModule } from "@fuse";
import { FuseConfigModule } from "@fuse/services/config";
import { FuseMockApiModule } from "@fuse/lib/mock-api";
import { CoreModule } from "app/core/core.module";
import { appConfig } from "app/core/config/app.config";
import { mockApiServices } from "app/mock-api";
import { LayoutModule } from "app/layout/layout.module";
import { AppComponent } from "app/app.component";
import { appRoutes } from "app/app.routing";
import { LoadingModalComponent } from "./modules/components/loading-modal/loading-modal.component";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";


const routerConfig: ExtraOptions = {
	preloadingStrategy: PreloadAllModules,
	scrollPositionRestoration: "enabled",
};

@NgModule({
	declarations: [AppComponent, LoadingModalComponent],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		MatProgressSpinnerModule,
		RouterModule.forRoot(appRoutes, routerConfig),

		// Fuse, FuseConfig & FuseMockAPI
		FuseModule,
		FuseConfigModule.forRoot(appConfig),
		FuseMockApiModule.forRoot(mockApiServices),

		// Core module of your application
		CoreModule,

		// Layout module of your application
		LayoutModule,

		// 3rd party modules that require global configuration via forRoot
		MarkdownModule.forRoot({}),
		TranslocoCoreModule,
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
