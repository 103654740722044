import { Injectable } from "@angular/core";
import { PdfService } from "./pdf.service";
import { style } from "@angular/animations";
import { CurrencySpPipe } from "app/pipes/currency.pipe";
import { DateSpPipe } from "app/pipes/date.pipe";
import { ExportDocumentosPdfService } from "./export-pdf.service";

enum estilosPdf {
	TITULO = "titulo",
	BOLDCURSIVA = "boldCursiva",
	BOLD = "bold",
}

@Injectable({
	providedIn: "root",
})
export class FichasDatosPdfService {
	constructor(
		private exportPdfService: ExportDocumentosPdfService,
		private DateSpPipe: DateSpPipe,
		private currencySpPipe: CurrencySpPipe,
	) {}

	async fichaDatosMueblesHistoricos(data: any,  epigrafe:any = {id: ''},  info: any  = null, entidad_local?:any) {

		const encabezado = await this.exportPdfService.encabezadoFicha({data, codigo: epigrafe.codigo, epigrafe: true, info},entidad_local);

		const tablaBody = [
			[
				...this.exportPdfService.columnBuild({
					texto: "TITULO",
					colSpan: 4,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
					rowSpan: null,
					borders: this.exportPdfService.bordersType.noBottom,
				}),
				...this.exportPdfService.columnBuild({
					texto: "DIMENSIONES",
					colSpan: 4,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
					rowSpan: null,
					borders: this.exportPdfService.bordersType.noBottom,
				}),
			],
			[
				...this.exportPdfService.columnBuild({
					texto:  data.titulo_de_propiedad ?? "NO CONSTA",
					colSpan: 4,
					rowSpan: null,
					borders: this.exportPdfService.bordersType.noTop,
					bold: true
				}),
				...this.exportPdfService.columnBuild({
					texto: data.medidas ?? "NO CONSTA",
					colSpan: 4,
					rowSpan: null,
					borders: this.exportPdfService.bordersType.noTop,
					bold: true
				}),
			],
			[
				...this.exportPdfService.columnBuild({
					texto: "DESCRIPCIÓN DEL BIEN",
					colSpan: 8,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
					rowSpan: null,
					borders: this.exportPdfService.bordersType.noBottom,
				}),
			],
			[
				...this.exportPdfService.columnBuild({
					texto: data.descripcion ?? "NO CONSTA",
					colSpan: 8,
					rowSpan: null,
					borders: this.exportPdfService.bordersType.noTop,
					style: estilosPdf.TITULO,
				}),
			],

			[
				...this.exportPdfService.columnBuild({
					texto: "UBICACIÓN DEL BIEN",
					colSpan: 1,
					rowSpan: 2,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
					borders: this.exportPdfService.bordersType.noBottom,
					margin: [0, 10 ,0,0]
				}),
				...this.exportPdfService.columnBuild({
					texto: "VÍA PÚBLICA AL FRENTE",
					colSpan: 1,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
					rowSpan: null,
					borders: this.exportPdfService.bordersType.none,
				}),
				...this.exportPdfService.columnBuild({
					texto: data.tipoVia ? data.tipoVia.abreviatura : "NC",
					colSpan: 1,
					rowSpan: null,
					borders: this.exportPdfService.bordersType.none,
				}),
				...this.exportPdfService.columnBuild({
					texto: data.via_publica ?? "NO CONSTA",
					colSpan: 2,
					rowSpan: null,
					borders: this.exportPdfService.bordersType.none,
				}),
				"",
				...this.exportPdfService.columnBuild({
					texto: "NÚMERO",
					colSpan: 1,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
					rowSpan: null,
					borders: this.exportPdfService.bordersType.none,
				}),
				...this.exportPdfService.columnBuild({
					texto: data.num_ord ?? "NO CONSTA",
					colSpan: 1,
					rowSpan: null,
					borders: this.exportPdfService.bordersType.right,
				}),
			],
			[
				...this.exportPdfService.columnBuild({
					texto: "",
					colSpan: 1,
					rowSpan: 2,
					borders: this.exportPdfService.bordersType.noBottom,
				}),
				...this.exportPdfService.columnBuild({
					texto: "EDIFICIO",
					colSpan: 1,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
					rowSpan: null,
					borders: this.exportPdfService.bordersType.none,
				}),
				...this.exportPdfService.columnBuild({
					texto: data.edificio ?? "NO CONSTA",
					colSpan: 2,
					rowSpan: null,
					borders: this.exportPdfService.bordersType.none,
				}),
				...this.exportPdfService.columnBuild({
					texto: "PLANTA",
					colSpan: 1,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
					rowSpan: null,
					borders: this.exportPdfService.bordersType.none,
				}),
				...this.exportPdfService.columnBuild({
					texto: data.planta ?? "NO CONSTA",
					colSpan: 1,
					rowSpan: null,
					borders: this.exportPdfService.bordersType.none,
				}),
				...this.exportPdfService.columnBuild({
					texto: "DEPENDENCIA",
					colSpan: 1,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
					rowSpan: null,
					borders: this.exportPdfService.bordersType.none,
				}),
				...this.exportPdfService.columnBuild({
					texto: data.dependencia ?? "",
					colSpan: 1,
					rowSpan: null,
					borders: this.exportPdfService.bordersType.right,
				}),
			],
			[
				...this.exportPdfService.columnBuild({
					texto: "AUTOR",
					colSpan: 4,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
					rowSpan: null,
					borders: this.exportPdfService.bordersType.noBottom,
				}),
				...this.exportPdfService.columnBuild({
					texto: "SOPORTE",
					colSpan: 4,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
					rowSpan: null,
					borders: this.exportPdfService.bordersType.noBottom,
				}),
			],
			[
				...this.exportPdfService.columnBuild({
					texto: data.autor ?? "NO CONSTA",
					colSpan: 4,
					rowSpan: null,
					borders: this.exportPdfService.bordersType.noTop,
					style: estilosPdf.TITULO,
				}),
				...this.exportPdfService.columnBuild({
					texto: data.soporte ?? "NO CONSTA",
					colSpan: 4,
					rowSpan: null,
					borders: this.exportPdfService.bordersType.noTop,
					style: estilosPdf.TITULO,
				}),
			],
			[
				...this.exportPdfService.columnBuild({
					texto: "ESTADO DE CONSERVACIÓN",
					colSpan: 4,
					rowSpan: null,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
					borders: this.exportPdfService.bordersType.noBottom,
				}),
				...this.exportPdfService.columnBuild({
					texto: "TECNICA",
					colSpan: 4,
					rowSpan: null,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
					borders: this.exportPdfService.bordersType.noBottom,
				}),
			],
			[
				...this.exportPdfService.columnBuild({
					texto: data.estadoConservacionCodigo ?? "NO CONSTA"					,
					colSpan: 4,
					rowSpan: null,
					borders:this.exportPdfService.bordersType.noTop,
				}),
				...this.exportPdfService.columnBuild({
					texto: data.tecnica ?? "NO CONSTA",
					colSpan: 4,
					rowSpan: null,
					borders:this.exportPdfService.bordersType.noTop,
				}),
			],
			[
				...this.exportPdfService.columnBuild({
					texto: "TITULO DE ADQUISICIÓN",
					colSpan: 4,
					rowSpan: null,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
					borders: this.exportPdfService.bordersType.noBottom,
				}),
				...this.exportPdfService.columnBuild({
					texto: "FECHA DE ADQUISICIÓN",
					colSpan: 4,
					rowSpan: null,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
					borders: this.exportPdfService.bordersType.noBottom,
				}),
			],
			[
				...this.exportPdfService.columnBuild({
					texto: data.titulo_adquisicion ?? "NO CONSTA",
					colSpan: 4,
					rowSpan: null,
					borders: this.exportPdfService.bordersType.noTop,
				}),
				...this.exportPdfService.columnBuild({
					texto: data.fecha_adquisicion ?? "NO CONSTA",
					colSpan: 4,
					rowSpan: null,
					borders: this.exportPdfService.bordersType.noTop,
				}),
			],
			[
				{
					rowSpan: 3,
					colSpan: 2,
					text: "VALORACIONES",
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
					alignment: "center",
					border: this.exportPdfService.bordersType.noBottom,
					margin: [0, 35, 0 ,0]
				},
				"",
				...this.exportPdfService.columnBuild({
					texto: "COSTO DE ADQUISICIÓN (€)",
					colSpan: 3,
					rowSpan: null,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
					borders: this.exportPdfService.bordersType.top,
				}),
				...this.exportPdfService.columnBuild({
					texto: data.costo_adquisicion ?? "NO CONSTA",
					colSpan: 3,
					rowSpan: null,
					borders: this.exportPdfService.bordersType.topRight,
					style: estilosPdf.TITULO,
				}),
			],
			[
				...this.exportPdfService.columnBuild({
					texto: "Nº CUENTA (PGCP)",
					colSpan: 3,
					prevCol: 2,
					rowSpan: null,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
					borders: this.exportPdfService.bordersType.none,
				}),
				...this.exportPdfService.columnBuild({
					texto: data.contable.codigo ?? "NO CONSTA",
					colSpan: 3,
					rowSpan: null,
					borders: this.exportPdfService.bordersType.right,
					style: estilosPdf.TITULO,
				}),
			],
			[
				...this.exportPdfService.columnBuild({
					texto: "VALORACION TOTAL (€) ",
					colSpan: 3,
					prevCol: 2,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
					borders: this.exportPdfService.bordersType.bottom,
				}),
				...this.exportPdfService.columnBuild({
					texto: `${this.currencySpPipe.transform(
						data.valor
					)}`,
					colSpan: 3,
					borders: this.exportPdfService.bordersType.right,
					style: estilosPdf.TITULO,
				}),
			],
			[
				...this.exportPdfService.columnBuild({
					texto: "",
					colSpan: 2,
					borders: this.exportPdfService.bordersType.horizontal,
				}),
				...this.exportPdfService.columnBuild({
					texto: "CANTIDAD",
					colSpan: 4,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
					borders: this.exportPdfService.bordersType.topRight,
				}),
				...this.exportPdfService.columnBuild({
					texto: "VALOR TOTAL (€)",
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
					colSpan: 2,
					borders: this.exportPdfService.bordersType.topRight,
				}),
			],
			[
				...this.exportPdfService.columnBuild({
					texto: "",
					colSpan: 2,
					borders: this.exportPdfService.bordersType.noTop,
				}),
				...this.exportPdfService.columnBuild({
					texto: data.cantidad ?? "NO CONSTA",
					colSpan: 4,
					borders: this.exportPdfService.bordersType.noTop,
				}),
				...this.exportPdfService.columnBuild({
					texto: `${this.currencySpPipe.transform(
						data.valor_total
					)}`,
					colSpan: 2,
					borders: this.exportPdfService.bordersType.noTop,
				}),
			],
			[
				{
					colSpan: 8,
					text: "",
					border: this.exportPdfService.bordersType.top,
				},
			],
		];
		
		const content = [
			...encabezado,
			{
				style: "tableExample",
				fontSize: 9,
				margin: [0, 10, 0, 0],
				table: {
					widths: [55, 55, 55, 55, 50, 40, 70, 60],
					body: tablaBody,
					layout: 'fillBody',
				},
				layout: {
					defaultBorder: false,
				},
				defaultStyle: {
					italics: true,
					
				},
			},
		];

		let informe = {
			content,
			styles: {
				titulo: {
					// fontSize: 10,
					bold: true,
					italics: false,
					// margin: [0, 10, 0, 5],
				},
				cursiva: {
					// fontSize: 10,
					italics: true,
					// margin: [0, 10, 0, 5],
				},
				boldCursiva: {
					// fontSize: 10,
					italics: true,
					bold: true,
					// margin: [0, 10, 0, 5],
				},
			},
			defaultStyle: {
				italics: true,
				
			},
		};
		return informe;
		// this.pdfService.generatePdf(informe);
	}

	async fichaDatosBienesRevertibles(data: any,  epigrafe:any = {id: ''}, info: any = null,entidad_local?:any) {
		const encabezado = await this.exportPdfService.encabezadoFicha({data, codigo: epigrafe.codigo, epigrafe: true, info},entidad_local);

		const tablaBody = [
			[
				...this.exportPdfService.columnBuild({
					texto: "DESCRIPCIÓN DEL BIEN",
					colSpan: 8,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
					rowSpan: null,
					borders: this.exportPdfService.bordersType.noBottom,
				}),
			],
			[
				...this.exportPdfService.columnBuild({
					texto: data.descripcion ?? "NO CONSTA",
					colSpan: 8,
					rowSpan: null,
					borders: this.exportPdfService.bordersType.noTop,
					style: estilosPdf.TITULO,
				}),
			],
			[
				...this.exportPdfService.columnBuild({
					texto: "FECHA DE CESIÓN",
					colSpan: 4,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
					rowSpan: null,
					borders: this.exportPdfService.bordersType.noBottom,
				}),
				...this.exportPdfService.columnBuild({
					texto: "FECHA DE REVERSIÓN",
					colSpan: 4,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
					rowSpan: null,
					borders: this.exportPdfService.bordersType.noBottom,
				}),
			],
			[
				...this.exportPdfService.columnBuild({
					texto: data.fecha_cesion,
					colSpan: 4,
					rowSpan: null,
					borders: this.exportPdfService.bordersType.noTop,
					style: estilosPdf.TITULO,
				}),
				...this.exportPdfService.columnBuild({
					texto: data.fecha_reversion,
					colSpan: 4,
					rowSpan: null,
					borders: this.exportPdfService.bordersType.noTop,
					style: estilosPdf.TITULO,
				}),
			],




			[
				...this.exportPdfService.columnBuild({
					texto: "INMUEBLE SOBRE EL QUE RECAYERE",
					colSpan: 1,
					rowSpan: 2,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
					borders: this.exportPdfService.bordersType.noBottom,
					margin: [0, 13 ,0,0]
				}),
				...this.exportPdfService.columnBuild({
					texto: "VÍA PÚBLICA AL FRENTE",
					colSpan: 1,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
					rowSpan: null,
					borders: this.exportPdfService.bordersType.none,
				}),
				...this.exportPdfService.columnBuild({
					texto: data.via_publica ?? "NC",
					colSpan: 2,
					rowSpan: null,
					borders: this.exportPdfService.bordersType.none,
				}),
				...this.exportPdfService.columnBuild({
					texto: "",
					colSpan: 2,
					rowSpan: null,
					borders: this.exportPdfService.bordersType.none,
				}),
				...this.exportPdfService.columnBuild({
					texto: "NÚMERO",
					colSpan: 1,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
					rowSpan: null,
					borders: this.exportPdfService.bordersType.none,
				}),
				...this.exportPdfService.columnBuild({
					texto: data.num_ord ?? "NC",
					colSpan: 1,
					rowSpan: null,
					borders: this.exportPdfService.bordersType.right,
				}),
			],
			[
				...this.exportPdfService.columnBuild({
					texto: "",
					colSpan: 1,
					rowSpan: 2,
					borders: this.exportPdfService.bordersType.noBottom,
				}),
				...this.exportPdfService.columnBuild({
					texto: "REF. CATASTRAL",
					colSpan: 1,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
					rowSpan: null,
					borders: this.exportPdfService.bordersType.none,
				}),
				...this.exportPdfService.columnBuild({
					texto: data.referencia_catastral ?? "NC",
					colSpan: 1,
					rowSpan: null,
					borders: this.exportPdfService.bordersType.none,
				}),
				...this.exportPdfService.columnBuild({
					texto: "POLÍGONO",
					colSpan: 1,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
					rowSpan: null,
					borders: this.exportPdfService.bordersType.none,
				}),
				...this.exportPdfService.columnBuild({
					texto: data.poligono ?? "NC",
					colSpan: 1,
					rowSpan: null,
					borders: this.exportPdfService.bordersType.none,
				}),
				...this.exportPdfService.columnBuild({
					texto: "PARCELA",
					colSpan: 2,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
					rowSpan: null,
					borders: this.exportPdfService.bordersType.none,
				}),
				...this.exportPdfService.columnBuild({
					texto: data.parcela ?? "NC",
					colSpan: 1,
					rowSpan: null,
					borders: this.exportPdfService.bordersType.right,
				}),
			],
			[
				...this.exportPdfService.columnBuild({
					texto: "CONDICIÓN",
					colSpan: 1,
					rowSpan: null,
					borders: this.exportPdfService.bordersType.noBottom,
					italics: true,
					font: 'TimesNewRoman',
					bold: false
				}),
				...this.exportPdfService.columnBuild({
					texto: "DATOS JURÍDICOS",
					colSpan: 7,
					rowSpan: null,
					borders: this.exportPdfService.bordersType.noBottom,
					italics: true,
					font: 'TimesNewRoman',
					bold: false
				}),
			],
			[
				...this.exportPdfService.columnBuild({
					texto: data.condicion ?? "NO CONSTA",
					colSpan: 1,
					rowSpan: 2,
					borders: [true, false, true, false],
					bold: true
				}),
				{
					text: "Registro",
					border: this.exportPdfService.bordersType.none,
					italics: true,
					font: 'TimesNewRoman',
					width: 50
				},
				{
					text: "Tomo",
					border: this.exportPdfService.bordersType.none,
					italics: true,
					font: 'TimesNewRoman',
					width: 50
				},
				{
					text: "Libro",
					border: this.exportPdfService.bordersType.none,
					italics: true,
					font: 'TimesNewRoman',
					width: 50
				},
				{
					text: "Folio",
					border: this.exportPdfService.bordersType.none,
					italics: true,
					font: 'TimesNewRoman',
					width: 50
				},
				{
					text: "Finca",
					border: this.exportPdfService.bordersType.none,
					italics: true,
					font: 'TimesNewRoman',
					width: 50
				},
				{
					text: "Inscrip",
					border: this.exportPdfService.bordersType.none,
					italics: true,
					font: 'TimesNewRoman',
					width: 50
				},
				{
					text: "",
					border: this.exportPdfService.bordersType.right,
					italics: true,
					font: 'TimesNewRoman',
					width: 50
				},
			],
			[
				...this.exportPdfService.columnBuild({
					texto: data.registro_propiedad ?? "NC",
					colSpan: 1,
					prevCol: 1,
					rowSpan: null,
					borders: this.exportPdfService.bordersType.bottom,
					bold: true
				}),
				{
					text: data.tomo ?? "NC",
					bold: true
				},
				{
					text: `${data.libro ?? "NC"}`,
					bold: true
				},
				{
					text: `${data.folio ?? "NC"}`,
					bold: true
				},
				{
					text: data.finca ?? "NC",
					bold: true
				},
				{
					text: data.inscripcion_registral ?? "NC",
					border: this.exportPdfService.bordersType.none,
					bold: true
				},
				{
					text: "",
					border: this.exportPdfService.bordersType.right,
					italics: true,
					font: 'TimesNewRoman',
				},
			],
			[
				...this.exportPdfService.columnBuild({
					texto: "TITULO DE ADQUISICIÓN",
					colSpan: 4,
					rowSpan: null,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
					borders: this.exportPdfService.bordersType.noBottom,
				}),
				...this.exportPdfService.columnBuild({
					texto: "FECHA DE ADQUISICIÓN",
					colSpan: 4,
					rowSpan: null,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
					borders: this.exportPdfService.bordersType.noBottom,
				}),
			],
			[
				...this.exportPdfService.columnBuild({
					texto: data.titular ?? "NO CONSTA",
					colSpan: 4,
					rowSpan: null,
					borders: this.exportPdfService.bordersType.noTop,
				}),
				...this.exportPdfService.columnBuild({
					texto: data.fecha_adquisicion,
					colSpan: 4,
					rowSpan: null,
					borders: this.exportPdfService.bordersType.noTop,
				}),
			],


			[
				{
					rowSpan: 3,
					colSpan: 2,
					text: "COSTO",
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
					alignment: "center",
					border: this.exportPdfService.bordersType.all,
					margin: [0, 20, 0 ,0]
				},
				"",
				...this.exportPdfService.columnBuild({
					texto: "COSTO DE ADQUISICIÓN",
					colSpan: 3,
					rowSpan: null,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
					borders: this.exportPdfService.bordersType.top,
				}),
				...this.exportPdfService.columnBuild({
					texto: data.costo_adquisicion ?? "NO CONSTA",
					colSpan: 3,
					rowSpan: null,
					borders: this.exportPdfService.bordersType.topRight,
					style: estilosPdf.TITULO,
				}),
			],
			[
				...this.exportPdfService.columnBuild({
					texto: "Nº CUENTA (PGCP)",
					colSpan: 3,
					prevCol: 2,
					rowSpan: null,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
					borders: this.exportPdfService.bordersType.none,
				}),
				...this.exportPdfService.columnBuild({
					texto: data.contable.codigo ?? "NO CONSTA",
					colSpan: 3,
					rowSpan: null,
					borders: this.exportPdfService.bordersType.right,
					style: estilosPdf.TITULO,
				}),
			],
			[
				...this.exportPdfService.columnBuild({
					texto: "VALORACION TOTAL (€) ",
					colSpan: 3,
					prevCol: 2,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
					borders: this.exportPdfService.bordersType.bottom,
				}),
				...this.exportPdfService.columnBuild({
					texto: `${this.currencySpPipe.transform(
						data.valor_total
					)}`,
					colSpan: 3,
					borders: this.exportPdfService.bordersType.right,
					style: estilosPdf.TITULO,
				}),
			],

			[
				{
					rowSpan: 2,
					colSpan: 2,
					text: "FRUTOS Y RENTAS",
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
					alignment: "center",
					border: this.exportPdfService.bordersType.all,
					margin: [0, 10, 0 ,0]
				},
				"",
				...this.exportPdfService.columnBuild({
					texto: "CONCEPTO",
					colSpan: 4,
					rowSpan: null,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
					borders: this.exportPdfService.bordersType.noBottom,
				}),
				...this.exportPdfService.columnBuild({
					texto: "VALOR (€)",
					colSpan: 2,
					rowSpan: null,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
					borders: this.exportPdfService.bordersType.noBottom,
				}),
			],
			[
				...this.exportPdfService.columnBuild({
					texto: data.concepto ?? "NO CONSTA",
					colSpan: 4,
					prevCol: 2,
					rowSpan: null,
					borders: this.exportPdfService.bordersType.noTop,
				}),
				...this.exportPdfService.columnBuild({
					texto: `${this.currencySpPipe.transform(
						data.valor
					)}`,
					colSpan: 2,
					rowSpan: null,
					borders: this.exportPdfService.bordersType.noTop,
					style: estilosPdf.TITULO,
				}),
			],

			[
				{
					colSpan: 8,
					text: "",
					border: this.exportPdfService.bordersType.top,
				},
			],
		];
		
		const content = [
			...encabezado,
			{
				style: "tableExample",
				fontSize: 9,
				margin: [0, 10, 0, 0],
				table: {
					widths: [100, 50, 50, 50, 50, 50, 50, 50],
					body: tablaBody,
				},
				layout: {
					defaultBorder: false,
				},
				defaultStyle: {
					italics: true,
					
				},
			},
		];

		let informe = {
			content,
			styles: {
				titulo: {
					// fontSize: 10,
					bold: true,
					italics: false,
					// margin: [0, 10, 0, 5],
				},
				cursiva: {
					// fontSize: 10,
					italics: true,
					// margin: [0, 10, 0, 5],
				},
				boldCursiva: {
					// fontSize: 10,
					italics: true,
					bold: true,
					// margin: [0, 10, 0, 5],
				},
			},
			defaultStyle: {
				italics: true,
				
			},
		};
		return informe;
		// this.pdfService.generatePdf(informe);
	}

    async fichaDatosVehiculos(data: any,  epigrafe:any = {id: ''}, info: any  = null, entidad_local?:any) {
		const tablaBody = [

            [
				...this.exportPdfService.columnBuild({
					texto: "TIPO DE VEHÍCULO",
					colSpan: 6,
					rowSpan: null,
					borders: this.exportPdfService.bordersType.noBottom,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
				}),
				...this.exportPdfService.columnBuild({
					texto: "MATRÍCULA",
					colSpan: 2,
					rowSpan: null,
					borders: this.exportPdfService.bordersType.noBottom,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
				}),
			],
            [
				...this.exportPdfService.columnBuild({
					texto: data.tipo_vehiculo ?? "NC",
					colSpan: 6,
					rowSpan: null,
					borders: this.exportPdfService.bordersType.noTop,
				}),
				...this.exportPdfService.columnBuild({
					texto: data.matricula ?? "",
					colSpan: 2,
					rowSpan: null,
					borders: this.exportPdfService.bordersType.noTop,
				}),
			],
			[
				...this.exportPdfService.columnBuild({
					texto: "FECHA ADQUISICIÓN",
					colSpan: 2,
					rowSpan: null,
					borders: this.exportPdfService.bordersType.noBottom,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
				}),
				...this.exportPdfService.columnBuild({
					texto: "TÍTULAR DE ADQUISICIÓN",
					colSpan: 3,
					rowSpan: null,
					borders: this.exportPdfService.bordersType.noBottom,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
				}),
                ...this.exportPdfService.columnBuild({
					texto: "DESTINO",
					colSpan: 3,
					rowSpan: null,
					borders: this.exportPdfService.bordersType.noBottom,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
				}),
			],
			[
				...this.exportPdfService.columnBuild({
					texto: `${this.DateSpPipe.transform(data.fecha_adquisicion)}` ?? "NC/NC/NC",
					colSpan: 2,
					rowSpan: null,
					borders: this.exportPdfService.bordersType.noTop,
				}),
				...this.exportPdfService.columnBuild({
					texto: data.titulo_adquisicion ?? "NO CONSTA",
					colSpan: 3,
					rowSpan: null,
					borders: this.exportPdfService.bordersType.noTop,
				}),
                ...this.exportPdfService.columnBuild({
					texto: data.destino ?? "NO CONSTA",
					colSpan: 3,
					rowSpan: null,
					borders: this.exportPdfService.bordersType.noTop,
				}),
			],
			

			///
			[
				{
					rowSpan: 3,
					colSpan: 2,
					text: "COSTO",
					border: this.exportPdfService.bordersType.all,
					margin: [0,20,0,0],
					font: 'TimesNewRoman',
					alignment: "center",
					bold: false,
					italics: true,
				},
				"",
				...this.exportPdfService.columnBuild({
					texto: "COSTO DE ADQUISICIÓN (€)",
					colSpan: 3,
					rowSpan: null,
					borders: this.exportPdfService.bordersType.top,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
				}),
				...this.exportPdfService.columnBuild({
					texto: data.costo_adquisicion ?? "NO CONSTA",
					colSpan: 3,
					rowSpan: null,
					borders: this.exportPdfService.bordersType.topRight,
					style: estilosPdf.TITULO,
				}),
			],
			[
				...this.exportPdfService.columnBuild({
					texto: "Nº CUENTA (PGCP)",
					colSpan: 3,
					prevCol: 2,
					rowSpan: null,
					borders: this.exportPdfService.bordersType.none,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
				}),
				...this.exportPdfService.columnBuild({
					texto: data.contable.codigo ?? "NO CONSTA",
					colSpan: 3,
					rowSpan: null,
					borders: this.exportPdfService.bordersType.right,
					style: estilosPdf.TITULO,
				}),
			],
			[
				...this.exportPdfService.columnBuild({
					texto: "VALORACIÓN (€) ",
					colSpan: 3,
					prevCol: 2,
					borders: this.exportPdfService.bordersType.bottom,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
				}),
				...this.exportPdfService.columnBuild({
					texto: `${this.currencySpPipe.transform(
						data.valor_total
					)}`,
					colSpan: 3,
					borders: this.exportPdfService.bordersType.right,
					style: estilosPdf.TITULO,
				}),
			],
			//NUEVO NNUEVO
			
			
			[
				{
					colSpan: 8,
					text: "",
					border: this.exportPdfService.bordersType.top,
				},
			],
		];
		const encabezado = await this.exportPdfService.encabezadoFicha({data, codigo: epigrafe.codigo, epigrafe: true, info},entidad_local);
		// const hor = await this.exportPdfService.historialFichaEpigrafe(data);
		const content = [
			...encabezado,
			{
				style: "tableExample",
				fontSize: 9,
				margin: [0, 10, 0, 0],
				table: {
					widths: "*",
					body: tablaBody,
				},
				layout: {
					defaultBorder: false,
				},
				defaultStyle: {
					italics: true,
					
				},
			},

			// ...hor.content,
		];

		let informe = {
			content,
			styles: {
				titulo: {
					// fontSize: 10,
					bold: true,
					italics: false,
					// margin: [0, 10, 0, 5],
				},
				cursiva: {
					// fontSize: 10,
					italics: true,
					// margin: [0, 10, 0, 5],
				},
				boldCursiva: {
					// fontSize: 10,
					italics: true,
					bold: true,
					// margin: [0, 10, 0, 5],
				},
			},
			defaultStyle: {
				italics: true,
				
			},
		};
		return informe;
		// this.pdfService.generatePdf(informe);
	}

	async fichaDatosCreditos(data: any,  epigrafe:any = {id: ''}, info: any = null,entidad_local?:any) {
		const tablaBody = [

            [
				...this.exportPdfService.columnBuild({
					texto: "CONCEPTO",
					colSpan: 9,
					borders: this.exportPdfService.bordersType.noBottom,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
				}),
			],
            [
				...this.exportPdfService.columnBuild({
					texto: data.concepto ?? "NO CONSTA",
					colSpan: 9,
					borders: this.exportPdfService.bordersType.noTop,
				}),
			],
			[
				...this.exportPdfService.columnBuild({
					texto: "NOMBRE DEL DEUDOR",
					borders: this.exportPdfService.bordersType.noBottom,
					font: 'TimesNewRoman',
					bold: false,
					colSpan: 9,
					italics: true,
				}),
			],
            [
				...this.exportPdfService.columnBuild({
					texto: data.nombre_deudor ?? "NO CONSTA",
					colSpan: 9,
					borders: this.exportPdfService.bordersType.noTop,
				}),
			],
			[
				...this.exportPdfService.columnBuild({
					texto: "TÍTULO DE ADQUISICIÓN",
					colSpan: 3,
					rowSpan: null,
					borders: this.exportPdfService.bordersType.noBottom,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
				}),
				...this.exportPdfService.columnBuild({
					texto: "FECHA DE ADQUISICIÓN",
					colSpan: 3,
					rowSpan: null,
					borders: this.exportPdfService.bordersType.noBottom,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
				}),
                ...this.exportPdfService.columnBuild({
					texto: "FECHA DE VENCIMIENTO",
					colSpan: 3,
					rowSpan: null,
					borders: this.exportPdfService.bordersType.noBottom,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
				}),
			],
			[
				...this.exportPdfService.columnBuild({
					texto: data.titulo_adquisicion ?? "NO CONSTA",
					colSpan: 3,
					rowSpan: null,
					borders: this.exportPdfService.bordersType.noTop,
				}),
				...this.exportPdfService.columnBuild({
					texto: `${this.DateSpPipe.transform(data.fecha_adquisicion)}` ?? "NC/NC/NC",
					colSpan: 3,
					rowSpan: null,
					borders: this.exportPdfService.bordersType.noTop,
				}),
				...this.exportPdfService.columnBuild({
					texto: `${this.DateSpPipe.transform(data.fecha_vencimiento)}` ?? "NC/NC/NC",
					colSpan: 3,
					rowSpan: null,
					borders: this.exportPdfService.bordersType.noTop,
				}),
			],
			[
				...this.exportPdfService.columnBuild({
					texto: "VALOR EFECTIVO",
					colSpan: 4,
					rowSpan: null,
					borders: this.exportPdfService.bordersType.noBottom,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
				}),
				...this.exportPdfService.columnBuild({
					texto: "Nº CUENTA (PGCP)",
					colSpan: 5,
					rowSpan: null,
					borders: this.exportPdfService.bordersType.noBottom,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
				}),
			],
			[
				...this.exportPdfService.columnBuild({
					texto: `${this.currencySpPipe.transform(
						data.valor
					)}`,
					colSpan: 4,
					rowSpan: null,
					borders: this.exportPdfService.bordersType.noTop,
				}),
				...this.exportPdfService.columnBuild({
					texto: data.contable.codigo ?? "NO CONSTA",
					colSpan: 5,
					rowSpan: null,
					borders: this.exportPdfService.bordersType.noTop,
				}),
			],
			
			
			[
				{
					colSpan: 9,
					text: "",
					border: this.exportPdfService.bordersType.top,
				},
			],
		];
		const encabezado = await this.exportPdfService.encabezadoFicha({data, codigo: epigrafe.codigo, epigrafe: true, info},entidad_local);
		// const hor = await this.exportPdfService.historialFichaEpigrafe(data);
		const content = [
			...encabezado,
			{
				style: "tableExample",
				fontSize: 9,
				margin: [0, 10, 0, 0],
				table: {
					widths: "*",
					body: tablaBody,
				},
				layout: {
					defaultBorder: false,
				},
				defaultStyle: {
					italics: true,
					
				},
			},

			// ...hor.content,
		];

		let informe = {
			content,
			styles: {
				titulo: {
					// fontSize: 10,
					bold: true,
					italics: false,
					// margin: [0, 10, 0, 5],
				},
				cursiva: {
					// fontSize: 10,
					italics: true,
					// margin: [0, 10, 0, 5],
				},
				boldCursiva: {
					// fontSize: 10,
					italics: true,
					bold: true,
					// margin: [0, 10, 0, 5],
				},
			},
			defaultStyle: {
				italics: true,
				
			},
		};
		return informe;
		// this.pdfService.generatePdf(informe);
	}

	async fichaDatosValoresInmobiliarios(data: any,  epigrafe:any = {id: ''}, info: any = null,entidad_local?:any) {
		const tablaBody = [

            [
				...this.exportPdfService.columnBuild({
					texto: "NÚMEROS DE LOS TÍTULOS",
					colSpan: 7,
					borders: this.exportPdfService.bordersType.noBottom,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
				}),
				...this.exportPdfService.columnBuild({
					texto: "CLASE",
					colSpan: 2,
					borders: this.exportPdfService.bordersType.noBottom,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
				}),
			],
            [
				...this.exportPdfService.columnBuild({
					texto: data.num_titulos ?? "NO CONSTA",
					colSpan: 7,
					borders: this.exportPdfService.bordersType.noTop,
				}),
				...this.exportPdfService.columnBuild({
					texto: data.clase ?? "NO CONSTA",
					colSpan: 2,
					borders: this.exportPdfService.bordersType.noTop,
				}),
			],
			[
				...this.exportPdfService.columnBuild({
					texto: "ORGANISMO O ENTIDAD EMISORA",
					borders: this.exportPdfService.bordersType.noBottom,
					font: 'TimesNewRoman',
					bold: false,
					colSpan: 9,
					italics: true,
				}),
			],
            [
				...this.exportPdfService.columnBuild({
					texto: data.org_entidad_emi ?? "NO CONSTA",
					colSpan: 9,
					borders: this.exportPdfService.bordersType.noTop,
				}),
			],
			[
				...this.exportPdfService.columnBuild({
					texto: "SERIE Y NUMERACIÓN",
					colSpan: 5,
					rowSpan: null,
					borders: this.exportPdfService.bordersType.noBottom,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
				}),
				...this.exportPdfService.columnBuild({
					texto: "FECHA DE ADQUISICIÓN",
					colSpan: 4,
					rowSpan: null,
					borders: this.exportPdfService.bordersType.noBottom,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
				}),
			],
			[
				...this.exportPdfService.columnBuild({
					texto: data.serie_num ?? "NO CONSTA",
					colSpan: 5,
					rowSpan: null,
					borders: this.exportPdfService.bordersType.noTop,
				}),
				...this.exportPdfService.columnBuild({
					texto: `${this.DateSpPipe.transform(data.fecha_adquisicion)}` ?? "NC/NC/NC",
					colSpan: 4,
					rowSpan: null,
					borders: this.exportPdfService.bordersType.noTop,
				}),
			],
			[
				...this.exportPdfService.columnBuild({
					texto: "LUGAR EN EL QUE SE ENCUENTRA DEPOSITADO",
					borders: this.exportPdfService.bordersType.noBottom,
					font: 'TimesNewRoman',
					bold: false,
					colSpan: 9,
					italics: true,
				}),
			],
            [
				...this.exportPdfService.columnBuild({
					texto: data.lugar_depositado ?? "NO CONSTA",
					colSpan: 9,
					borders: this.exportPdfService.bordersType.noTop,
				}),
			],

			[
				...this.exportPdfService.columnBuild({
					texto: "COSTO",
					colSpan: 2,
					rowSpan: 4,
					borders: this.exportPdfService.bordersType.all,
					margin: [0, 25, 0, 0],
					italics: true,
					alignment: "center",
					font: "TimesNewRoman",
					bold: false,
				}),
				...this.exportPdfService.columnBuild({
					texto: "COSTO DE ADQUISICIÓN",
					colSpan: 3,
					rowSpan: null,
					borders: this.exportPdfService.bordersType.top,
					italics: true,
					font: "TimesNewRoman",
					bold: false,
				}),
				...this.exportPdfService.columnBuild({
					texto: data.costo_adquisicion ? `${data.costo_adquisicion} ${data.moneda}` : "NO CONSTA",
					colSpan: 4,
					rowSpan: null,
					borders: this.exportPdfService.bordersType.topRight,
					style: estilosPdf.TITULO,
				}),
			],
			[
				...this.exportPdfService.columnBuild({
				texto: "CAPITAL NOMINAL",
				colSpan: 3,
				prevCol: 2,
				borders: this.exportPdfService.bordersType.none,
				italics: true,
				font: "TimesNewRoman",
				bold: false,
				}),
				...this.exportPdfService.columnBuild({
					texto: `${this.currencySpPipe.transform(
						data.capital_nominal
					)}`,
					colSpan: 4,
					borders: this.exportPdfService.bordersType.right,
					style: estilosPdf.TITULO,
				}),
			],
			[
				...this.exportPdfService.columnBuild({
				texto: "Nº CUENTA (PGCP)",
				colSpan: 3,
				prevCol: 2,
				rowSpan: null,
				borders: this.exportPdfService.bordersType.none,
				italics: true,
				font: "TimesNewRoman",
				bold: false,
				}),
				...this.exportPdfService.columnBuild({
				texto: data.contable.codigo ?? "NO CONSTA",
				colSpan: 4,
				rowSpan: null,
				borders: this.exportPdfService.bordersType.right,
				style: estilosPdf.TITULO,
				}),
			],
			[
				...this.exportPdfService.columnBuild({
				texto: "VALORACIÓN TOTAL (€)",
				colSpan: 3,
				prevCol: 2,
				borders: this.exportPdfService.bordersType.bottom,
				italics: true,
				font: "TimesNewRoman",
				bold: false,
				}),
				...this.exportPdfService.columnBuild({
					texto: `${this.currencySpPipe.transform(
						data.valor_total
					)}`,
					colSpan: 4,
					borders: this.exportPdfService.bordersType.right,
					style: estilosPdf.TITULO,
				}),
			],

			[ 
				...this.exportPdfService.columnBuild({
					texto: "FRUTOS Y RENTAS",
					colSpan: 2,
					rowSpan: 2,
					borders: this.exportPdfService.bordersType.all,
					italics: true,
					font: "TimesNewRoman",
					bold: false,
					margin: [0, 8, 0, 0],
					alignment: "center",
				}),
				...this.exportPdfService.columnBuild({
					texto: "CONCEPTO",
					colSpan: 4,
					borders: this.exportPdfService.bordersType.topRight,
					italics: true,
					font: "TimesNewRoman",
					bold: false,
				}),
				...this.exportPdfService.columnBuild({
					texto: "VALOR (€)",
					colSpan: 3,
					borders: this.exportPdfService.bordersType.topRight,
					italics: true,
					font: "TimesNewRoman",
					bold: false,
				}),
			],
			[
				...this.exportPdfService.columnBuild({
					texto: data.concepto ?? "NO CONSTA",
					colSpan: 4,
					prevCol: 2,
					rowSpan: null,
					borders: this.exportPdfService.bordersType.right,
					style: estilosPdf.TITULO,
				}),
				...this.exportPdfService.columnBuild({
					texto: `${this.currencySpPipe.transform(data.valor)}`,
					colSpan: 3,
					rowSpan: null,
					borders: this.exportPdfService.bordersType.right,
					style: estilosPdf.TITULO,
				}),
			],

			
			
			[
				{
					colSpan: 9,
					text: "",
					border: this.exportPdfService.bordersType.top,
				},
			],
		];
		const encabezado = await this.exportPdfService.encabezadoFicha({data, codigo: epigrafe.codigo, info},entidad_local);
		// const hor = await this.exportPdfService.historialFichaEpigrafe(data);
		const content = [
			...encabezado,
			{
				style: "tableExample",
				fontSize: 9,
				margin: [0, 10, 0, 0],
				table: {
					widths: "*",
					body: tablaBody,
				},
				layout: {
					defaultBorder: false,
				},
				defaultStyle: {
					italics: true,
					
				},
			},

			// ...hor.content,
		];

		let informe = {
			content,
			styles: {
				titulo: {
					// fontSize: 10,
					bold: true,
					italics: false,
					// margin: [0, 10, 0, 5],
				},
				cursiva: {
					// fontSize: 10,
					italics: true,
					// margin: [0, 10, 0, 5],
				},
				boldCursiva: {
					// fontSize: 10,
					italics: true,
					bold: true,
					// margin: [0, 10, 0, 5],
				},
			},
			defaultStyle: {
				italics: true,
				
			},
		};
		return informe;
		// this.pdfService.generatePdf(informe);
	}

	async fichaDerechosReales(data: any,  epigrafe:any = {id: ''}, info: any = null, entidad_local?:any) {
		const tablaBody = [

            [
				...this.exportPdfService.columnBuild({
					texto: "CONTENIDO DEL DERECHO",
					colSpan: 6,
					borders: this.exportPdfService.bordersType.noBottom,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
				}),
				...this.exportPdfService.columnBuild({
					texto: "NATURALEZA DE DOMINIO ",
					colSpan: 3,
					borders: this.exportPdfService.bordersType.noBottom,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
				}),
			],
            [
				...this.exportPdfService.columnBuild({
					texto: data.contenido ?? "NO CONSTA",
					colSpan: 6,
					borders: this.exportPdfService.bordersType.noTop,
				}),
				...this.exportPdfService.columnBuild({
					texto: data.juridica.nombre ?? "NO CONSTA",
					colSpan: 3,
					borders: this.exportPdfService.bordersType.noTop,
				}),
			],
			[
				...this.exportPdfService.columnBuild({
					texto: "INMUEBLE SOBRE EL QUE RECAYERE",
					colSpan: 1,
					rowSpan: 2,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
					borders: this.exportPdfService.bordersType.noBottom,
					margin: [0, 5 ,0,5]
				}),
				...this.exportPdfService.columnBuild({
					texto: "VÍA PÚBLICA AL FRENTE",
					colSpan: 3,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
					rowSpan: null,
					borders: this.exportPdfService.bordersType.none,
				}),
				...this.exportPdfService.columnBuild({
					texto: data.via_publica ?? "NC",
					colSpan: 3,
					rowSpan: null,
					borders: this.exportPdfService.bordersType.none,
				}),
				...this.exportPdfService.columnBuild({
					texto: "NÚMERO",
					colSpan: 1,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
					rowSpan: null,
					borders: this.exportPdfService.bordersType.none,
				}),
				...this.exportPdfService.columnBuild({
					texto: data.numero ?? "NC",
					colSpan: 1,
					rowSpan: null,
					borders: this.exportPdfService.bordersType.right,
				}),
			],
			[
				...this.exportPdfService.columnBuild({
					texto: "",
					colSpan: 1,
					rowSpan: 2,
					borders: this.exportPdfService.bordersType.noBottom,
				}),
				...this.exportPdfService.columnBuild({
					texto: "REF. CATASTRAL",
					colSpan: 2,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
					rowSpan: null,
					borders: this.exportPdfService.bordersType.none,
				}),
				...this.exportPdfService.columnBuild({
					texto: data.referencia_catastral ?? "NC",
					colSpan: 2,
					rowSpan: null,
					borders: this.exportPdfService.bordersType.none,
				}),
				...this.exportPdfService.columnBuild({
					texto: "POLÍGONO",
					colSpan: 1,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
					rowSpan: null,
					borders: this.exportPdfService.bordersType.none,
				}),
				...this.exportPdfService.columnBuild({
					texto: data.poligono ?? "NC",
					colSpan: 1,
					rowSpan: null,
					borders: this.exportPdfService.bordersType.none,
				}),
				...this.exportPdfService.columnBuild({
					texto: "PARCELA",
					colSpan: 1,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
					rowSpan: null,
					borders: this.exportPdfService.bordersType.none,
				}),
				...this.exportPdfService.columnBuild({
					texto: data.parcela ?? "NC",
					colSpan: 1,
					rowSpan: null,
					borders: this.exportPdfService.bordersType.right,
				}),
			],
			[
				...this.exportPdfService.columnBuild({
					texto: "FECHA DE ADQUISICIÓN",
					colSpan: 1,
					borders: this.exportPdfService.bordersType.noBottom,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
				}),
				...this.exportPdfService.columnBuild({
					texto: "TITULO DE ADQUISICIÓN",
					colSpan: 2,
					borders: this.exportPdfService.bordersType.noBottom,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
				}),
				...this.exportPdfService.columnBuild({
					texto: "INSCRIPCIÓN REGISTRAL",
					colSpan: 6,
					borders: this.exportPdfService.bordersType.noBottom,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
				}),

			],
			[
				...this.exportPdfService.columnBuild({
					texto: `${this.DateSpPipe.transform(data.fecha_adquisicion)}` ?? "NO CONSTA",
					colSpan: 1,
					borders: this.exportPdfService.bordersType.left,
				}),
				...this.exportPdfService.columnBuild({
					texto: data.titulo_propiedad ?? "NO CONSTA",
					colSpan: 2,
					borders: this.exportPdfService.bordersType.left,
				}),
				...this.exportPdfService.columnBuild({
					texto: "Registro",
					colSpan: 1,
					borders: this.exportPdfService.bordersType.left,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
				}),
				...this.exportPdfService.columnBuild({
					texto: "Tomo",
					colSpan: 1,
					borders: this.exportPdfService.bordersType.none,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
				}),
				...this.exportPdfService.columnBuild({
					texto: "Libro",
					colSpan: 1,
					borders: this.exportPdfService.bordersType.none,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
				}),
				...this.exportPdfService.columnBuild({
					texto: "Folio",
					colSpan: 1,
					borders: this.exportPdfService.bordersType.none,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
				}),
				...this.exportPdfService.columnBuild({
					texto: "Finca",
					colSpan: 1,
					borders: this.exportPdfService.bordersType.none,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
				}),
				...this.exportPdfService.columnBuild({
					texto: "Inscrip.",
					colSpan: 1,
					borders: this.exportPdfService.bordersType.right,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
				}),

			],
			[
				...this.exportPdfService.columnBuild({
					texto: "",
					colSpan: 1,
					borders: this.exportPdfService.bordersType.noTop,
				}),
				...this.exportPdfService.columnBuild({
					texto: "",
					colSpan: 2,
					borders: this.exportPdfService.bordersType.noTop,
				}),
				...this.exportPdfService.columnBuild({
					texto: data.registro_propiedad ?? "NC",
					colSpan: 1,
					borders: this.exportPdfService.bordersType.left,

				}),
				...this.exportPdfService.columnBuild({
					texto: data.tomo ?? "NC",
					colSpan: 1,
					borders: this.exportPdfService.bordersType.none,
				}),
				...this.exportPdfService.columnBuild({
					texto: data.libro ?? "NC",
					colSpan: 1,
					borders: this.exportPdfService.bordersType.none,
				}),
				...this.exportPdfService.columnBuild({
					texto: data.folio ?? "NC",
					colSpan: 1,
					borders: this.exportPdfService.bordersType.none,
				}),
				...this.exportPdfService.columnBuild({
					texto: data.finca ?? "NC",
					colSpan: 1,
					borders: this.exportPdfService.bordersType.none,
				}),
				...this.exportPdfService.columnBuild({
					texto: data.inscripcion_registral ?? "NC",
					colSpan: 1,
					borders: this.exportPdfService.bordersType.right,
				}),

			],

			[
				...this.exportPdfService.columnBuild({
					texto: "COSTO",
					colSpan: 1,
					rowSpan: 3,
					borders: this.exportPdfService.bordersType.all,
					margin: [0, 15, 0, 0],
					italics: true,
					alignment: "center",
					font: "TimesNewRoman",
					bold: false,
				}),
				...this.exportPdfService.columnBuild({
					texto: "COSTO DE ADQUISICIÓN",
					colSpan: 4,
					rowSpan: null,
					borders: this.exportPdfService.bordersType.top,
					italics: true,
					font: "TimesNewRoman",
					bold: false,
				}),
				...this.exportPdfService.columnBuild({
					texto: data.costo_adquisicion ? `${data.costo_adquisicion} ${data.moneda}` : "NO CONSTA",
					colSpan: 4,
					rowSpan: null,
					borders: this.exportPdfService.bordersType.topRight,
					style: estilosPdf.TITULO,
				}),
			],
			[
				...this.exportPdfService.columnBuild({
				texto: "Nº CUENTA (PGCP)",
				colSpan: 4,
				prevCol: 1,
				rowSpan: null,
				borders: this.exportPdfService.bordersType.none,
				italics: true,
				font: "TimesNewRoman",
				bold: false,
				}),
				...this.exportPdfService.columnBuild({
				texto: data.contable.codigo ?? "NO CONSTA",
				colSpan: 4,
				rowSpan: null,
				borders: this.exportPdfService.bordersType.right,
				style: estilosPdf.TITULO,
				}),
			],
			[
				...this.exportPdfService.columnBuild({
				texto: "VALORACIÓN TOTAL (€)",
				colSpan: 4,
				prevCol: 1,
				borders: this.exportPdfService.bordersType.bottom,
				italics: true,
				font: "TimesNewRoman",
				bold: false,
				}),
				...this.exportPdfService.columnBuild({
					texto: `${this.currencySpPipe.transform(
						data.valor_total
					)}`,
					colSpan: 4,
					borders: this.exportPdfService.bordersType.right,
					style: estilosPdf.TITULO,
				}),
			],
			[ 
				{
					rowSpan: 2,
					colSpan: 1,
					text: "FRUTOS Y RENTAS",
					border: [true, true, true, true],
					italics: true,
					font: "TimesNewRoman",
					bold: false,
					alignment: "center",
					margin: [0, 8, 0, 0],
				},
				...this.exportPdfService.columnBuild({
					texto: "CONCEPTO",
					colSpan: 4,
					borders: this.exportPdfService.bordersType.topRight,
					italics: true,
					font: "TimesNewRoman",
					bold: false,
				}),
				...this.exportPdfService.columnBuild({
					texto: "VALOR (€)",
					colSpan: 4,
					borders: this.exportPdfService.bordersType.topRight,
					italics: true,
					font: "TimesNewRoman",
					bold: false,
				}),
			],
			[
				...this.exportPdfService.columnBuild({
					texto: data.concepto ?? "NO CONSTA",
					colSpan: 4,
					prevCol: 1,
					rowSpan: null,
					borders: this.exportPdfService.bordersType.right,
					style: estilosPdf.TITULO,
				}),
				...this.exportPdfService.columnBuild({
					texto: `${this.currencySpPipe.transform(data.valor)}`,
					colSpan: 4,
					rowSpan: null,
					borders: this.exportPdfService.bordersType.right,
					style: estilosPdf.TITULO,
				}),
			],

			[
				{
					colSpan: 9,
					text: "",
					border: this.exportPdfService.bordersType.top,
				},
			],
		];
		const encabezado = await this.exportPdfService.encabezadoFicha({data, codigo: epigrafe.codigo, epigrafe: true,  info},entidad_local);
		// const hor = await this.exportPdfService.historialFichaEpigrafe(data);
		const content = [
			...encabezado,
			{
				style: "tableExample",
				fontSize: 9,
				margin: [0, 10, 0, 0],
				table: {
					widths: [90, 45, 30, 45, 45, 60, 45, 40, 40],
					heights: [15, 70],
					body: tablaBody,
				},
				layout: {
					defaultBorder: false,
				},
				defaultStyle: {
					italics: true,
					
				},
			},

			// ...hor.content,
		];

		let informe = {
			content,
			styles: {
				titulo: {
					// fontSize: 10,
					bold: true,
					italics: false,
					// margin: [0, 10, 0, 5],
				},
				cursiva: {
					// fontSize: 10,
					italics: true,
					// margin: [0, 10, 0, 5],
				},
				boldCursiva: {
					// fontSize: 10,
					italics: true,
					bold: true,
					// margin: [0, 10, 0, 5],
				},
			},
			defaultStyle: {
				italics: true,
				
			},
		};
		return informe;
		// this.pdfService.generatePdf(informe);
	}

	async fichaSemovientes(data: any,  epigrafe:any = {id: ''}, info: any = null, entidad_local?:any) {
		const tablaBody = [

            [
				...this.exportPdfService.columnBuild({
					texto: "ESPECIE",
					colSpan: 3,
					borders: this.exportPdfService.bordersType.noBottom,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
				}),
				...this.exportPdfService.columnBuild({
					texto: "NÚMERO DE CABEZAS",
					colSpan: 3,
					borders: this.exportPdfService.bordersType.noBottom,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
				}),
				...this.exportPdfService.columnBuild({
					texto: "RAZA",
					colSpan: 3,
					borders: this.exportPdfService.bordersType.noBottom,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
				}),
			],
			[
				...this.exportPdfService.columnBuild({
					texto: data.especie ?? "NO CONSTA",
					colSpan: 3,
					borders: this.exportPdfService.bordersType.noTop,
				}),
				...this.exportPdfService.columnBuild({
					texto: data.num_cabezas ?? "NO CONSTA",
					colSpan: 3,
					borders: this.exportPdfService.bordersType.noTop,
				}),
				...this.exportPdfService.columnBuild({
					texto: data.raza ?? "NO CONSTA",
					colSpan: 3,
					borders: this.exportPdfService.bordersType.noTop,
				}),
			],
			[
				...this.exportPdfService.columnBuild({
					texto: "PERSONA ENCARGARA DE SU CUSTODIA",
					colSpan: 9,
					borders: this.exportPdfService.bordersType.noBottom,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
				}),
			],
			[
				...this.exportPdfService.columnBuild({
					texto: data.persona_enc ?? "NO CONSTA",
					colSpan: 9,
					borders: this.exportPdfService.bordersType.noTop,
				}),
			],
			[
				...this.exportPdfService.columnBuild({
					texto: "TÍTULO DE ADQUISICIÓN",
					colSpan: 5,
					borders: this.exportPdfService.bordersType.noBottom,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
				}),
				...this.exportPdfService.columnBuild({
					texto: "FECHA DE ADQUISICIÓN",
					colSpan: 4,
					borders: this.exportPdfService.bordersType.noBottom,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
				}),
			],
			[
				...this.exportPdfService.columnBuild({
					texto: data.titulo_adquisicion ?? "NO CONSTA",
					colSpan: 5,
					borders: this.exportPdfService.bordersType.noTop,
				}),
				...this.exportPdfService.columnBuild({
					texto: `${this.DateSpPipe.transform(data.fecha_adquisicion)}` ?? "NO CONSTA",
					colSpan: 4,
					borders: this.exportPdfService.bordersType.noTop,
				}),
			],
			
			[
				...this.exportPdfService.columnBuild({
					texto: "COSTO",
					colSpan: 1,
					rowSpan: 3,
					borders: this.exportPdfService.bordersType.all,
					margin: [0, 15, 0, 0],
					italics: true,
					alignment: "center",
					font: "TimesNewRoman",
					bold: false,
				}),
				...this.exportPdfService.columnBuild({
					texto: "COSTO DE ADQUISICIÓN",
					colSpan: 4,
					rowSpan: null,
					borders: this.exportPdfService.bordersType.top,
					italics: true,
					font: "TimesNewRoman",
					bold: false,
				}),
				...this.exportPdfService.columnBuild({
					texto: data.costo_adquisicion ? `${data.costo_adquisicion} ${data.moneda}` : "NO CONSTA",
					colSpan: 4,
					rowSpan: null,
					borders: this.exportPdfService.bordersType.topRight,
					style: estilosPdf.TITULO,
				}),
			],
			[
				...this.exportPdfService.columnBuild({
				texto: "Nº CUENTA (PGCP)",
				colSpan: 4,
				prevCol: 1,
				rowSpan: null,
				borders: this.exportPdfService.bordersType.none,
				italics: true,
				font: "TimesNewRoman",
				bold: false,
				}),
				...this.exportPdfService.columnBuild({
				texto: data.contable.codigo ?? "NO CONSTA",
				colSpan: 4,
				rowSpan: null,
				borders: this.exportPdfService.bordersType.right,
				style: estilosPdf.TITULO,
				}),
			],
			[
				...this.exportPdfService.columnBuild({
				texto: "VALORACIÓN (€)",
				colSpan: 4,
				prevCol: 1,
				borders: this.exportPdfService.bordersType.bottom,
				italics: true,
				font: "TimesNewRoman",
				bold: false,
				}),
				...this.exportPdfService.columnBuild({
					texto: `${this.currencySpPipe.transform(
						data.valor_total
					)}`,
					colSpan: 4,
					borders: [false, false, true, true],
					style: estilosPdf.TITULO,
				}),
			],



		];
		const encabezado = await this.exportPdfService.encabezadoFicha({data, codigo: epigrafe.codigo, epigrafe: true, info},entidad_local);
		// const hor = await this.exportPdfService.historialFichaEpigrafe(data);
		const content = [
			...encabezado,
			{
				style: "tableExample",
				fontSize: 9,
				margin: [0, 10, 0, 0],
				table: {
					widths: [90, 45, 30, 45, 45, 60, 45, 40, 40],
					body: tablaBody,
				},
				layout: {
					defaultBorder: false,
				},
				defaultStyle: {
					italics: true,
					
				},
			},

			// ...hor.content,
		];

		let informe = {
			content,
			styles: {
				titulo: {
					// fontSize: 10,
					bold: true,
					italics: false,
					// margin: [0, 10, 0, 5],
				},
				cursiva: {
					// fontSize: 10,
					italics: true,
					// margin: [0, 10, 0, 5],
				},
				boldCursiva: {
					// fontSize: 10,
					italics: true,
					bold: true,
					// margin: [0, 10, 0, 5],
				},
			},
			defaultStyle: {
				italics: true,
				
			},
		};
		return informe;
		// this.pdfService.generatePdf(informe);
	}

	async fichaArbolado(data: any,  epigrafe:any = {id: ''}, info: any = null,entidad_local?:any) {
		const tablaBody = [

            [
				...this.exportPdfService.columnBuild({
					texto: "ESPECIE",
					colSpan: 7,
					borders: this.exportPdfService.bordersType.noBottom,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
				}),
				...this.exportPdfService.columnBuild({
					texto: "NÚMERO DE PIES",
					colSpan: 2,
					borders: this.exportPdfService.bordersType.noBottom,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
				}),
			],
			[
				...this.exportPdfService.columnBuild({
					texto: data.especie ?? "NO CONSTA",
					colSpan: 7,
					borders: this.exportPdfService.bordersType.noTop,
				}),
				...this.exportPdfService.columnBuild({
					texto: data.n_pies ?? "NO CONSTA",
					colSpan: 2,
					borders: this.exportPdfService.bordersType.noTop,
				}),
			],

			[
				...this.exportPdfService.columnBuild({
					texto: "SITUACION",
					colSpan: 1,
					rowSpan:  2,
					borders: this.exportPdfService.bordersType.all,
					font: 'TimesNewRoman',
					margin: [0,20,0,0],
					bold: false,
					italics: true,
				}),
				...this.exportPdfService.columnBuild({
					texto: "LOCALIZACION",
					colSpan: 2,
					borders: this.exportPdfService.bordersType.none,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
				}),
				...this.exportPdfService.columnBuild({
					texto: data.localizacion ?? "NO CONSTA",
					colSpan: 3,
					borders: this.exportPdfService.bordersType.none,
				}),
				...this.exportPdfService.columnBuild({
					texto: "N ORD INV",
					colSpan: 1,
					borders: this.exportPdfService.bordersType.none,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
				}),
				...this.exportPdfService.columnBuild({
					texto: data.n_ord_inv_gral ?? "NO CONSTA",
					colSpan: 2,
					borders: this.exportPdfService.bordersType.right,
				}),
			],
			[
				...this.exportPdfService.columnBuild({
					texto: "TRAMO/AGRUPACIÓN",
					colSpan: 2,
					prevCol: 1,
					borders: this.exportPdfService.bordersType.none,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
				}),
				...this.exportPdfService.columnBuild({
					texto: data.tramo_agrupacion ?? "NO CONSTA",
					colSpan: 1,
					borders: this.exportPdfService.bordersType.none,
				}),
				...this.exportPdfService.columnBuild({
					texto: "ALINEACIÓN",
					colSpan: 1,
					borders: this.exportPdfService.bordersType.none,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
				}),
				...this.exportPdfService.columnBuild({
					texto: data.alineacion ?? "NO CONSTA",
					colSpan: 1,
					borders: this.exportPdfService.bordersType.none,
				}),
				...this.exportPdfService.columnBuild({
					texto: "DISTANCIA ENTRE PIES",
					colSpan: 2,
					borders: this.exportPdfService.bordersType.none,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
				}),
				...this.exportPdfService.columnBuild({
					texto: data.dist_entre_pies ?? "NO CONSTA",
					colSpan: 1,
					borders: this.exportPdfService.bordersType.right,
				}),
			],

			[
				...this.exportPdfService.columnBuild({
					texto: "ESTADO GENERAL TRAMO/AGRUPACIÓN",
					colSpan: 1,
					borders: this.exportPdfService.bordersType.all,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
				}),
				...this.exportPdfService.columnBuild({
					texto: "PIES VIVOS",
					colSpan: 2,
					borders: this.exportPdfService.bordersType.top,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
				}),
				...this.exportPdfService.columnBuild({
					texto: data.pies_vivos ?? "NO CONSTA",
					colSpan: 1,
					borders: this.exportPdfService.bordersType.top,
				}),
				...this.exportPdfService.columnBuild({
					texto: "PIES MUERTOS",
					colSpan: 2,
					borders: this.exportPdfService.bordersType.top,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
				}),
				...this.exportPdfService.columnBuild({
					texto: data.pies_muertos ?? "NO CONSTA",
					colSpan: 1,
					borders: this.exportPdfService.bordersType.top,
				}),
				...this.exportPdfService.columnBuild({
					texto: "HUECOS",
					colSpan: 1,
					borders: this.exportPdfService.bordersType.top,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
				}),
				...this.exportPdfService.columnBuild({
					texto: data.huecos ?? "NO CONSTA",
					colSpan: 1,
					borders: this.exportPdfService.bordersType.topRight,
				}),
			],

			[
				...this.exportPdfService.columnBuild({
					texto: "CARACTERÍSTICAS DENDOMÉTRICAS",
					colSpan: 1,
					rowSpan: 2,
					margin: [0,10,0,0],
					borders: this.exportPdfService.bordersType.all,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
				}),
				...this.exportPdfService.columnBuild({
					texto: "DIÁMETRO MEDIO",
					colSpan: 2,
					borders: this.exportPdfService.bordersType.top,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
				}),
				...this.exportPdfService.columnBuild({
					texto: data.diametro_medio ?? "NO CONSTA",
					colSpan: 1,
					borders: this.exportPdfService.bordersType.top,
				}),
				...this.exportPdfService.columnBuild({
					texto: "ALTURA MEDIA",
					colSpan: 1,
					borders: this.exportPdfService.bordersType.top,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
				}),
				...this.exportPdfService.columnBuild({
					texto: data.altura_media ?? "NO CONSTA",
					colSpan: 1,
					borders: this.exportPdfService.bordersType.top,
				}),
				...this.exportPdfService.columnBuild({
					texto: "ALTURA BIFURCACIÓN",
					colSpan: 2,
					borders: this.exportPdfService.bordersType.top,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
				}),
				...this.exportPdfService.columnBuild({
					texto: data.altura_bifurcacion ?? "NO CONSTA",
					colSpan: 1,
					borders: this.exportPdfService.bordersType.topRight,
				}),
			],
			[
				...this.exportPdfService.columnBuild({
					texto: "PORTE Y FORMA DE COPA",
					colSpan: 4,
					prevCol: 1,
					borders: this.exportPdfService.bordersType.bottom,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
				}),
				...this.exportPdfService.columnBuild({
					texto: data.porte_f_copa ?? "NO CONSTA",
					colSpan: 4,
					borders: this.exportPdfService.bordersType.right,
				}),
			],
			[
				...this.exportPdfService.columnBuild({
					texto: "CARACTERÍSTICAS BIOMECÁNICAS",
					colSpan: 1,
					rowSpan: 10,
					margin: [0,70,0,0],
					borders: this.exportPdfService.bordersType.all,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
				}),
				...this.exportPdfService.columnBuild({
					texto: "ESTADO SANITARIO",
					colSpan: 4,
					borders: this.exportPdfService.bordersType.top,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
				}),
				...this.exportPdfService.columnBuild({
					texto: data.estado_general ?? "NO CONSTA",
					colSpan: 4,
					borders: this.exportPdfService.bordersType.topRight,
				}),
			],
			[
				...this.exportPdfService.columnBuild({
					texto: "Enfermedad",
					colSpan: 4,
					prevCol: 1,
					borders: this.exportPdfService.bordersType.noBottom,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
				}),
				...this.exportPdfService.columnBuild({
					texto: "Plagas",
					colSpan: 4,
					borders: this.exportPdfService.bordersType.noBottom,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
				}),
			],
			[
				...this.exportPdfService.columnBuild({
					texto: data.efermedades ?? "NO CONSTA",
					prevCol: 1,
					colSpan: 4,
					borders: this.exportPdfService.bordersType.right,
				}),
				...this.exportPdfService.columnBuild({
					texto: data.plagas ?? "NO CONSTA",
					colSpan: 4,
					borders: this.exportPdfService.bordersType.right,
				}),
			],
			[
				...this.exportPdfService.columnBuild({
					texto: "Daños abióticos",
					colSpan: 4,
					prevCol: 1,
					borders: this.exportPdfService.bordersType.noBottom,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
				}),
				...this.exportPdfService.columnBuild({
					texto: "Pudriciones",
					colSpan: 4,
					borders: this.exportPdfService.bordersType.noBottom,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
				}),
			],
			[
				...this.exportPdfService.columnBuild({
					texto: data.danos_abioticos ?? "NO CONSTA",
					prevCol: 1,
					colSpan: 4,
					borders: this.exportPdfService.bordersType.right,
				}),
				...this.exportPdfService.columnBuild({
					texto: data.pudriciones ?? "NO CONSTA",
					colSpan: 4,
					borders: this.exportPdfService.bordersType.right,
				}),
			],
			[
				...this.exportPdfService.columnBuild({
					texto: "Estado general",
					colSpan: 8,
					prevCol: 1,
					borders: this.exportPdfService.bordersType.noBottom,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
				}),
			],
			[
				...this.exportPdfService.columnBuild({
					texto: data.estado_general ?? "NO CONSTA",
					prevCol: 1,
					colSpan: 8,
					borders: this.exportPdfService.bordersType.right,
				}),
			],

			[
				...this.exportPdfService.columnBuild({
					texto: "ÍNDICE DE PELIGROSIDAD",
					colSpan: 4,
					prevCol: 1,
					borders: this.exportPdfService.bordersType.noBottom,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
				}),
				...this.exportPdfService.columnBuild({
					texto: "EDAD FISIOLÓGICA",
					colSpan: 2,
					borders: this.exportPdfService.bordersType.noBottom,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
				}),
				...this.exportPdfService.columnBuild({
					texto: "ESPERANZA DE VIDA ÚTIL",
					colSpan: 2,
					borders: this.exportPdfService.bordersType.noBottom,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
				}),
			],
			[
				...this.exportPdfService.columnBuild({
					texto: "Tronco",
					colSpan: 2,
					prevCol: 1,
					borders: this.exportPdfService.bordersType.none,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
				}),
				...this.exportPdfService.columnBuild({
					texto: "Rama",
					colSpan: 2,
					borders: this.exportPdfService.bordersType.right,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
				}),
				...this.exportPdfService.columnBuild({
					texto: "",
					colSpan: 2,
					borders: this.exportPdfService.bordersType.right,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
				}),
				...this.exportPdfService.columnBuild({
					texto: "",
					colSpan: 2,
					borders: this.exportPdfService.bordersType.right,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
				}),
			],
			[
				...this.exportPdfService.columnBuild({
					texto: data.tronco ?? "NO CONSTA",
					prevCol: 1,
					colSpan: 2,
					borders: this.exportPdfService.bordersType.bottom,
				}),
				...this.exportPdfService.columnBuild({
					texto: data.rama ?? "NO CONSTA",
					colSpan: 2,
					borders: this.exportPdfService.bordersType.right,
				}),
				...this.exportPdfService.columnBuild({
					texto: data.edad_fisiologica ?? "NO CONSTA",
					colSpan: 2,
					borders: this.exportPdfService.bordersType.right,
				}),
				...this.exportPdfService.columnBuild({
					texto: data.vida_util ?? "NO CONSTA",
					colSpan: 2,
					borders: this.exportPdfService.bordersType.right,
				}),
			],






			[
				...this.exportPdfService.columnBuild({
					texto: "TRATAMIENTO Y LABORES",
					colSpan: 1,
					rowSpan: 15,
					margin: [0,100,0,0],
					borders: this.exportPdfService.bordersType.all,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
				}),
				...this.exportPdfService.columnBuild({
					texto: "ACTUALES",
					colSpan: 8,
					borders: this.exportPdfService.bordersType.topRight,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
				}),
			],
			[
				...this.exportPdfService.columnBuild({
					texto: "Podas",
					colSpan: 4,
					prevCol: 1,
					borders: this.exportPdfService.bordersType.noBottom,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
				}),
				...this.exportPdfService.columnBuild({
					texto: "Riego",
					colSpan: 4,
					borders: this.exportPdfService.bordersType.noBottom,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
				}),
			],
			[
				...this.exportPdfService.columnBuild({
					texto: data.act_podas ?? "NO CONSTA",
					prevCol: 1,
					colSpan: 4,
					borders: this.exportPdfService.bordersType.right,
				}),
				...this.exportPdfService.columnBuild({
					texto: data.act_riegos ?? "NO CONSTA",
					colSpan: 4,
					borders: this.exportPdfService.bordersType.right,
				}),
			],
			[
				...this.exportPdfService.columnBuild({
					texto: "Tratamiento fitosanitarios",
					colSpan: 4,
					prevCol: 1,
					borders: this.exportPdfService.bordersType.noBottom,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
				}),
				...this.exportPdfService.columnBuild({
					texto: "Otros",
					colSpan: 4,
					borders: this.exportPdfService.bordersType.noBottom,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
				}),
			],
			[
				...this.exportPdfService.columnBuild({
					texto: data.act_tratamientos ?? "NO CONSTA",
					prevCol: 1,
					colSpan: 4,
					borders: this.exportPdfService.bordersType.right,
				}),
				...this.exportPdfService.columnBuild({
					texto: data.act_otros ?? "NO CONSTA",
					colSpan: 4,
					borders: this.exportPdfService.bordersType.right,
				}),
			],
			[
				...this.exportPdfService.columnBuild({
					texto: "FUTUROS",
					colSpan: 8,
					prevCol: 1,
					borders: this.exportPdfService.bordersType.noBottom,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
				}),
			],
			[
				...this.exportPdfService.columnBuild({
					texto: "Podas",
					colSpan: 4,
					prevCol: 1,
					borders: this.exportPdfService.bordersType.noBottom,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
				}),
				...this.exportPdfService.columnBuild({
					texto: "Riego",
					colSpan: 4,
					borders: this.exportPdfService.bordersType.noBottom,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
				}),
			],
			[
				...this.exportPdfService.columnBuild({
					texto: data.fut_podas ?? "NO CONSTA",
					prevCol: 1,
					colSpan: 4,
					borders: this.exportPdfService.bordersType.right,
				}),
				...this.exportPdfService.columnBuild({
					texto: data.fut_riegos ?? "NO CONSTA",
					colSpan: 4,
					borders: this.exportPdfService.bordersType.right,
				}),
			],
			[
				...this.exportPdfService.columnBuild({
					texto: "Tratamiento fitosanitarios",
					colSpan: 4,
					prevCol: 1,
					borders: this.exportPdfService.bordersType.noBottom,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
				}),
				...this.exportPdfService.columnBuild({
					texto: "Otros",
					colSpan: 4,
					borders: this.exportPdfService.bordersType.noBottom,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
				}),
			],
			[
				...this.exportPdfService.columnBuild({
					texto: data.fut_tratamientos ?? "NO CONSTA",
					prevCol: 1,
					colSpan: 4,
					borders: this.exportPdfService.bordersType.right,
				}),
				...this.exportPdfService.columnBuild({
					texto: data.fut_otros ?? "NO CONSTA",
					colSpan: 4,
					borders: this.exportPdfService.bordersType.right,
				}),
			],
			[
				...this.exportPdfService.columnBuild({
					texto: "RECOMENDACIONES",
					colSpan: 8,
					prevCol: 1,
					borders: this.exportPdfService.bordersType.noBottom,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
				}),
			],
			[
				...this.exportPdfService.columnBuild({
					texto: "En cuanto a mantenimiento",
					colSpan: 8,
					prevCol: 1,
					borders: this.exportPdfService.bordersType.noBottom,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
				}),
			],
			[
				...this.exportPdfService.columnBuild({
					texto: data.rec_mantenimiento ?? "NO CONSTA",
					prevCol: 1,
					colSpan: 8,
					borders: this.exportPdfService.bordersType.right,
				}),
			],
			[
				...this.exportPdfService.columnBuild({
					texto: "Actuaciones",
					colSpan: 8,
					prevCol: 1,
					borders: this.exportPdfService.bordersType.noBottom,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
				}),
			],
			[
				...this.exportPdfService.columnBuild({
					texto: "NO CONSTA",
					prevCol: 1,
					colSpan: 8,
					borders: this.exportPdfService.bordersType.noTop,
				}),
			],
			



		];
		const encabezado = await this.exportPdfService.encabezadoFicha({data, codigo: epigrafe.codigo, epigrafe: false, title: 'INVENTARIO DE ARBOLADO URBANO', info},entidad_local);
		// const hor = await this.exportPdfService.historialFichaEpigrafe(data);
		const content = [
			...encabezado,
			{
				style: "tableExample",
				fontSize: 9,
				margin: [0, 10, 0, 0],
				table: {
					widths: [90, 30, 35, 30, 60, 55, 40, 40, 50],
					heights: [12,12,12,12,12,12,12,12,12,12,12,12,12,12,12,12,12,12,12,12,12,12,12,12,12,12,12,12,12,30,12,30,],
					body: tablaBody,
				},
				layout: {
					defaultBorder: false,
				},
				defaultStyle: {
					italics: true,
					
				},
			},

			// ...hor.content,
		];

		let informe = {
			content,
			styles: {
				titulo: {
					// fontSize: 10,
					bold: true,
					italics: false,
					// margin: [0, 10, 0, 5],
				},
				cursiva: {
					// fontSize: 10,
					italics: true,
					// margin: [0, 10, 0, 5],
				},
				boldCursiva: {
					// fontSize: 10,
					italics: true,
					bold: true,
					// margin: [0, 10, 0, 5],
				},
			},
			defaultStyle: {
				italics: true,
				
			},
		};
		return informe;
		// this.pdfService.generatePdf(informe);
	}


	async fichaFondosMetalicos(data: any,  epigrafe:any = {id: ''}, info: any = null,entidad_local?:any) {
		const tablaBody = [

            [
				...this.exportPdfService.columnBuild({
					texto: "ENTIDAD",
					colSpan: 2,
					borders: [true, true, false, true],
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
				}),
				...this.exportPdfService.columnBuild({
					texto: data.entidad ?? "NO CONSTA",
					colSpan: 7,
					borders: this.exportPdfService.bordersType.topRight,
				}),
			],
			
            [
				...this.exportPdfService.columnBuild({
					texto: "NUMERO DE CUENTA",
					colSpan: 5,
					borders: this.exportPdfService.bordersType.noBottom,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
				}),
				...this.exportPdfService.columnBuild({
					texto: "SALDO ULTIMA ACTUALIZACIÓN",
					colSpan: 2,
					borders: this.exportPdfService.bordersType.noBottom,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
				}),
				...this.exportPdfService.columnBuild({
					texto: "FECHA ÚLTIMA ACTUALIZACIÓN",
					colSpan: 2,
					borders: this.exportPdfService.bordersType.noBottom,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
				}),
			],

			[
				...this.exportPdfService.columnBuild({
					texto: "Entidad",
					colSpan: 1,
					borders: this.exportPdfService.bordersType.left,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
				}),
				...this.exportPdfService.columnBuild({
					texto: "Oficina",
					colSpan: 1,
					borders: this.exportPdfService.bordersType.none,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
				}),
				...this.exportPdfService.columnBuild({
					texto: "D.C",
					colSpan: 1,
					borders: this.exportPdfService.bordersType.none,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
				}),
				...this.exportPdfService.columnBuild({
					texto: "Número de cuenta",
					colSpan: 2,
					borders: this.exportPdfService.bordersType.right,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
				}),
				...this.exportPdfService.columnBuild({
					texto: "",
					colSpan: 2,
					borders: this.exportPdfService.bordersType.right,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
				}),
				...this.exportPdfService.columnBuild({
					texto: "",
					colSpan: 2,
					borders: this.exportPdfService.bordersType.right,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
				}),
			],
			[
				...this.exportPdfService.columnBuild({
					texto: data.entidad ?? "NO CONSTA",
					colSpan: 1,
					borders: [true, false, false, true],
				}),
				...this.exportPdfService.columnBuild({
					texto: data.oficina ?? "NO CONSTA",
					colSpan: 1,
					borders: this.exportPdfService.bordersType.bottom,
				}),
				...this.exportPdfService.columnBuild({
					texto: data.dc ?? "NO CONSTA",
					colSpan: 1,
					borders: this.exportPdfService.bordersType.bottom,
				}),
				...this.exportPdfService.columnBuild({
					texto: data.contable.codigo ?? "NO CONSTA",
					colSpan: 2,
					borders: [false, false, true, true],
				}),
				...this.exportPdfService.columnBuild({
					texto: data.saldoAct ?? "NO CONSTA",
					colSpan: 2,
					borders: [true, false, true, true],
				}),
				...this.exportPdfService.columnBuild({
					texto: data.fechaAct ?? "NO CONSTA",
					colSpan: 2,
					borders: [false, false, true, true],
				}),
			],


			[
				...this.exportPdfService.columnBuild({
					texto: "",
					colSpan: 9,
					borders: this.exportPdfService.bordersType.none,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
				}),
			],

			[
				...this.exportPdfService.columnBuild({
					texto: "SALDO ACTUAL (€)",
					colSpan: 4,
					borders: this.exportPdfService.bordersType.noBottom,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
				}),
				...this.exportPdfService.columnBuild({
					texto: "Nº CUENTA (PGCP)",
					colSpan: 5,
					borders: this.exportPdfService.bordersType.noBottom,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
				}),
			],
			[
				...this.exportPdfService.columnBuild({
					texto: `${this.DateSpPipe.transform(data.fecha_adquisicion)}` ?? "NO CONSTA",
					colSpan: 4,
					borders: this.exportPdfService.bordersType.noTop,
				}),
				...this.exportPdfService.columnBuild({
					texto: data.titulo_adquisicion ?? "NO CONSTA",
					colSpan: 5,
					borders: this.exportPdfService.bordersType.noTop,
				}),
			],
			[
				...this.exportPdfService.columnBuild({
					texto: "",
					colSpan: 9,
					borders: this.exportPdfService.bordersType.none,
				}),
			],

			
			[
				...this.exportPdfService.columnBuild({
					texto: "CONCEPTO",
					colSpan: 3,
					borders: this.exportPdfService.bordersType.all,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
				}),
				...this.exportPdfService.columnBuild({
					texto: "REMITENTE",
					colSpan: 3,
					borders: this.exportPdfService.bordersType.all,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
				}),
				...this.exportPdfService.columnBuild({
					texto: "FECHA",
					colSpan: 1,
					borders: this.exportPdfService.bordersType.all,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
				}),
				...this.exportPdfService.columnBuild({
					texto: "IMPORTE",
					colSpan: 1,
					borders: this.exportPdfService.bordersType.all,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
				}),
				...this.exportPdfService.columnBuild({
					texto: "SALDO",
					colSpan: 1,
					borders: this.exportPdfService.bordersType.all,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
				}),
			],



		];
		const encabezado = await this.exportPdfService.encabezadoFicha({data, codigo: epigrafe.codigo, info},entidad_local);
		// const hor = await this.exportPdfService.historialFichaEpigrafe(data);
		const content = [
			...encabezado,
			{
				style: "tableExample",
				fontSize: 9,
				margin: [0, 10, 0, 0],
				table: {
					widths: [45, 45, 45, 45, 45, 45, 60, 40, 60],
					body: tablaBody,
				},
				layout: {
					defaultBorder: false,
				},
				defaultStyle: {
					italics: true,
					
				},
			},

			// ...hor.content,
		];

		let informe = {
			content,
			styles: {
				titulo: {
					// fontSize: 10,
					bold: true,
					italics: false,
					// margin: [0, 10, 0, 5],
				},
				cursiva: {
					// fontSize: 10,
					italics: true,
					// margin: [0, 10, 0, 5],
				},
				boldCursiva: {
					// fontSize: 10,
					italics: true,
					bold: true,
					// margin: [0, 10, 0, 5],
				},
			},
			defaultStyle: {
				italics: true,
				
			},
		};
		return informe;
		// this.pdfService.generatePdf(informe);
	}

	async fichaOtrosInmuebles(data: any,  epigrafe:any = {id: ''}, info: any = null,entidad_local?:any) {
		const tablaBody = [

            [
				...this.exportPdfService.columnBuild({
					texto: "DESCRIPCIÓN DE BIEN",
					colSpan: 6,
					borders: this.exportPdfService.bordersType.noBottom,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
				}),
				...this.exportPdfService.columnBuild({
					texto: "CANTIDAD ",
					colSpan: 3,
					borders: this.exportPdfService.bordersType.noBottom,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
				}),
			],
            [
				...this.exportPdfService.columnBuild({
					texto: data.descripcion ?? "NO CONSTA",
					colSpan: 6,
					borders: this.exportPdfService.bordersType.noTop,
				}),
				...this.exportPdfService.columnBuild({
					texto: data.cantidad ?? "NO CONSTA",
					colSpan: 3,
					borders: this.exportPdfService.bordersType.noTop,
				}),
			],

			[
				...this.exportPdfService.columnBuild({
					texto: "ESTIMACIÓN VÍA ÚTIL",
					colSpan: 5,
					borders: this.exportPdfService.bordersType.noBottom,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
				}),
				...this.exportPdfService.columnBuild({
					texto: "DESTINO",
					colSpan: 2,
					borders: this.exportPdfService.bordersType.noBottom,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
				}),
				...this.exportPdfService.columnBuild({
					texto: "PROVEEDOR ",
					colSpan: 2,
					borders: this.exportPdfService.bordersType.noBottom,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
				}),
			],
            [
				...this.exportPdfService.columnBuild({
					texto: data.estimacion ?? "NO CONSTA",
					colSpan: 5,
					borders: this.exportPdfService.bordersType.noTop,
				}),
				...this.exportPdfService.columnBuild({
					texto: data.destino ?? "NO CONSTA",
					colSpan: 2,
					borders: this.exportPdfService.bordersType.noTop,
				}),
				...this.exportPdfService.columnBuild({
					texto: data.proveedor ?? "NO CONSTA",
					colSpan: 2,
					borders: this.exportPdfService.bordersType.noTop,
				}),
			],

			[
				...this.exportPdfService.columnBuild({
					texto: "ESTADO DE CONSERVACIÓN",
					colSpan: 5,
					borders: this.exportPdfService.bordersType.noBottom,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
				}),
				...this.exportPdfService.columnBuild({
					texto: "TÍTULO DE ADQUISICIÓN",
					colSpan: 2,
					borders: this.exportPdfService.bordersType.noBottom,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
				}),
				...this.exportPdfService.columnBuild({
					texto: "FECHA DE ADQUISICIÓN",
					colSpan: 2,
					borders: this.exportPdfService.bordersType.noBottom,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
				}),
			],
            [
				...this.exportPdfService.columnBuild({
					texto: data.estado_conservacion ?? "NO CONSTA",
					colSpan: 5,
					borders: this.exportPdfService.bordersType.noTop,
				}),
				...this.exportPdfService.columnBuild({
					texto: data.titulo_adquisicion ?? "NO CONSTA",
					colSpan: 2,
					borders: this.exportPdfService.bordersType.noTop,
				}),
				...this.exportPdfService.columnBuild({
					texto: `${this.DateSpPipe.transform(data.fecha_adquisicion )}` ?? "NO CONSTA",
					colSpan: 2,
					borders: this.exportPdfService.bordersType.noTop,
				}),
			],

			[
				...this.exportPdfService.columnBuild({
					texto: "UBICACIÓN DEL BIEN",
					colSpan: 1,
					rowSpan: 2,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
					borders: this.exportPdfService.bordersType.noBottom,
					margin: [0, 5 ,0,5]
				}),
				...this.exportPdfService.columnBuild({
					texto: "VÍA PÚBLICA AL FRENTE",
					colSpan: 3,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
					rowSpan: null,
					borders: this.exportPdfService.bordersType.none,
				}),
				...this.exportPdfService.columnBuild({
					texto: data.via_publica ?? "NC",
					colSpan: 3,
					rowSpan: null,
					borders: this.exportPdfService.bordersType.none,
				}),
				...this.exportPdfService.columnBuild({
					texto: "NÚMERO",
					colSpan: 1,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
					rowSpan: null,
					borders: this.exportPdfService.bordersType.none,
				}),
				...this.exportPdfService.columnBuild({
					texto: data.numero ?? "NC",
					colSpan: 1,
					rowSpan: null,
					borders: this.exportPdfService.bordersType.right,
				}),
			],
			[
				...this.exportPdfService.columnBuild({
					texto: "",
					colSpan: 1,
					rowSpan: 2,
					borders: this.exportPdfService.bordersType.noBottom,
				}),
				...this.exportPdfService.columnBuild({
					texto: "EDIFICIO",
					colSpan: 2,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
					rowSpan: null,
					borders: this.exportPdfService.bordersType.none,
				}),
				...this.exportPdfService.columnBuild({
					texto: data.edificio ?? "NC",
					colSpan: 2,
					rowSpan: null,
					borders: this.exportPdfService.bordersType.none,
				}),
				...this.exportPdfService.columnBuild({
					texto: "PLANTA",
					colSpan: 1,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
					rowSpan: null,
					borders: this.exportPdfService.bordersType.none,
				}),
				...this.exportPdfService.columnBuild({
					texto: data.planta ?? "NC",
					colSpan: 1,
					rowSpan: null,
					borders: this.exportPdfService.bordersType.none,
				}),
				...this.exportPdfService.columnBuild({
					texto: "DEPENDENCIA",
					colSpan: 1,
					font: 'TimesNewRoman',
					bold: false,
					italics: true,
					rowSpan: null,
					borders: this.exportPdfService.bordersType.none,
				}),
				...this.exportPdfService.columnBuild({
					texto: data.dependencia ?? "NC",
					colSpan: 1,
					rowSpan: null,
					borders: this.exportPdfService.bordersType.right,
				}),
			],


			[
				...this.exportPdfService.columnBuild({
					texto: "COSTO",
					colSpan: 1,
					rowSpan: 2,
					borders: this.exportPdfService.bordersType.all,
					margin: [0, 15, 0, 0],
					italics: true,
					alignment: "center",
					font: "TimesNewRoman",
					bold: false,
				}),
				...this.exportPdfService.columnBuild({
					texto: "Nº CUENTA (PGCP)",
					colSpan: 4,
					rowSpan: null,
					borders: this.exportPdfService.bordersType.top,
					italics: true,
					font: "TimesNewRoman",
					bold: false,
				}),
				...this.exportPdfService.columnBuild({
					texto: data.n_cuenta ?? "NC",
					colSpan: 4,
					rowSpan: null,
					borders: this.exportPdfService.bordersType.topRight,
					style: estilosPdf.TITULO,
				}),
			],
			[
				...this.exportPdfService.columnBuild({
				texto: "VALORACIÓN TOTAL (€)",
				colSpan: 4,
				prevCol: 1,
				borders: this.exportPdfService.bordersType.bottom,
				italics: true,
				font: "TimesNewRoman",
				bold: false,
				}),
				...this.exportPdfService.columnBuild({
					texto: `${this.currencySpPipe.transform(
						data.valor_total
					)}`,
					colSpan: 4,
					borders: this.exportPdfService.bordersType.right,
					style: estilosPdf.TITULO,
				}),
			],

			[
				{
					colSpan: 9,
					text: "",
					border: this.exportPdfService.bordersType.top,
				},
			],
		];
		const encabezado = await this.exportPdfService.encabezadoFicha({data, codigo: epigrafe.codigo, epigrafe: true,  info},entidad_local);
		// const hor = await this.exportPdfService.historialFichaEpigrafe(data);
		const content = [
			...encabezado,
			{
				style: "tableExample",
				fontSize: 9,
				margin: [0, 10, 0, 0],
				table: {
					widths: [90, 45, 30, 45, 45, 60, 45, 40, 40],
					heights: [15, 70],
					body: tablaBody,
				},
				layout: {
					defaultBorder: false,
				},
				defaultStyle: {
					italics: true,
					
				},
			},

			// ...hor.content,
		];

		let informe = {
			content,
			styles: {
				titulo: {
					// fontSize: 10,
					bold: true,
					italics: false,
					// margin: [0, 10, 0, 5],
				},
				cursiva: {
					// fontSize: 10,
					italics: true,
					// margin: [0, 10, 0, 5],
				},
				boldCursiva: {
					// fontSize: 10,
					italics: true,
					bold: true,
					// margin: [0, 10, 0, 5],
				},
			},
			defaultStyle: {
				italics: true,
				
			},
		};
		return informe;
		// this.pdfService.generatePdf(informe);
	}



}
