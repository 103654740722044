<div class="flex flex-col flex-auto md:w-160 md:min-w-160 -m-6">
    <div class="flex flex-row sm:flex-row items-center justify-between bg-[#333B63] text-[#FBFCFD] py-4 px-9">
        <div class="text-xl font-bold tracking-tight leading-6 truncate">{{ "modales.amortizacion.title" | transloco }}</div>
        <button class="mt-3 sm:mt-0 sm:ml-2" (click)="onClose()">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <div class="grid grid-cols-1 grid-flow-row gap-6 w-full mt-4 sm:mt-4 px-8 py-4">

        <div class="flex flex-col">
            <div class="font-bold text-lg leading-5 text-[#333B63]">{{ "modales.amortizacion.text" | transloco }}</div>
            <div class="flex-auto grid grid-cols-1 sm:grid-cols-2 gap-4 mt-6 border-[#333B63] border px-3 pt-2 bg-[#F4F6F8]" style="color: rgba(0, 0, 0, 0.87);">
                <div>
                    <label>{{ "modales.amortizacion.lastDate" | transloco }}</label>
                    <mat-form-field appearance="fill" class="w-full mt-2">
                        <input matInput type="date">
                    </mat-form-field>
                </div>
                <div>
                    <label>{{ "modales.amortizacion.newDate" | transloco }}</label>
                    <mat-form-field appearance="fill" class="w-full mt-2 mb-0">
                        <input matInput type="date">
                    </mat-form-field>
                </div>
            </div>
            <div class="mt-6 flex justify-end gap-4">
                <button class="border-[#333B63] border rounded font-medium text-base leading-8 py-1 px-3" (click)="onClose()">{{ "modales.amortizacion.buttons.cancelar" | transloco | uppercase }}</button>
                <button class="bg-[#333B63] rounded font-medium text-base leading-8 text-white py-1 px-4">{{ "modales.amortizacion.buttons.aceptar" | transloco | uppercase }}</button>
            </div>
        </div>
    </div>
</div>