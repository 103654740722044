/* tslint:disable:max-line-length */
import { FuseNavigationItem } from "@fuse/components/navigation";

export const defaultNavigation: FuseNavigationItem[] = [
	{
		id: "example",
		title: "Inicio",
		type: "basic",
		icon: "heroicons_outline:chart-pie",
		link: "/dashboard",
	},
	{
		id: "consultas",
		title: "Inventarios",
		type: "group",
		icon: "heroicons_outline:clipboard",
		children: [
			{
				id: "admin.catalogos.calificacionUrbanistica",
				title: "Inventario de Bienes y Derechos",
				type: "basic",
				link: "/inicio/derechos-reales",
			},
			{
				id: "admin.catalogos.calificacionUrbanistica",
				title: "Inmuebles Rusticos",
				type: "basic",
				link: "/inicio/inmuebles-rusticos",
			},
			{
				id: "admin.catalogos.calificacionUrbanistica",
				title: "Inmuebles Urbanos",
				type: "basic",
				link: "/inicio/inmuebles-urbanos",
			},
			{
				id: "admin.catalogos.calificacionUrbanistica",
				title: "Otros Muebles",
				type: "basic",
				link: "/inicio/otros-muebles",
			},
			{
				id: "admin.catalogos.calificacionUrbanistica",
				title: "Semovientes",
				type: "basic",
				link: "/inicio/semovientes",
			},
			{
				id: "admin.catalogos.calificacionUrbanistica",
				title: "Valores Moviliarios",
				type: "basic",
				link: "/inicio/valores-moviliarios",
			},
			{
				id: "admin.catalogos.calificacionUrbanistica",
				title: "Vehiculos",
				type: "basic",
				link: "/inicio/vehiculos",
			},
		],
	},
	{
		id: "admin",
		title: "Administración",
		type: "group",
		icon: "heroicons_outline:chart-pie",
		subtitle: "Administrar sistema",
		children: [
			{
				id: "admin.catalogos",
				title: "Catálogos",
				type: "collapsable",
				icon: "heroicons_outline:clipboard-check",
				children: [
					{
						id: "admin.catalogos.funcional",
						title: "Funcional",
						type: "basic",
						link: "/admin/catalogos/funcional",
					},
					{
						id: "admin.catalogos.epigrafe",
						title: "Epigrafe",
						type: "basic",
						link: "/admin/catalogos/epigrafe",
					},
					{
						id: "admin.catalogos.calificacionUrbanistica",
						title: "Calificación urbanística",
						type: "basic",
						link: "/admin/catalogos/calificacionUrbanistica",
					},
					{
						id: "admin.catalogos.estadoConservacion",
						title: "Estado de conservación",
						type: "basic",
						link: "/admin/catalogos/estadoConservacion",
					},
					{
						id: "admin.catalogos.juridica",
						title: "Jurídica",
						type: "basic",
						link: "/admin/catalogos/juridica",
					},
					{
						id: "admin.catalogos.contable",
						title: "Contable",
						type: "basic",
						link: "/admin/catalogos/contable",
					},
				],
			},
		],
	},
];
export const compactNavigation: FuseNavigationItem[] = [
	{
		id: "example",
		title: "Example",
		type: "basic",
		icon: "heroicons_outline:chart-pie",
		link: "/example",
	},
];
export const futuristicNavigation: FuseNavigationItem[] = [
	{
		id: "example",
		title: "Example",
		type: "basic",
		icon: "heroicons_outline:chart-pie",
		link: "/example",
	},
];
export const horizontalNavigation: FuseNavigationItem[] = [
	{
		id: "example",
		title: "Example",
		type: "basic",
		icon: "heroicons_outline:chart-pie",
		link: "/example",
	},
];
