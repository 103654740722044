import { MatDialogRef } from "@angular/material/dialog";
import { Component, OnInit } from "@angular/core";
import { ConfiguracionCertificadosService } from "./configuracion-certificados.service";
import { FeaturesService } from "./../services/features.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { CertificadosPdfService } from "../services/certificados-pdf.service";

@Component({
	selector: "app-configuracion-certificados",
	templateUrl: "./configuracion-certificados.component.html",
	styles: [],
})
export class ConfiguracionCertificadosComponent implements OnInit {
	data: any;
	nombreAlcalde: any
	nombreSecretario: any
	fecha: any
	lugar: any
	loadingData = false;
	userInfo: any
	formatedDate: any
	fechaActual: any;
	fechaInventario: any;
	constructor(
		public dialogRef: MatDialogRef<ConfiguracionCertificadosComponent>,
		private configuracionService: ConfiguracionCertificadosService,
		private featuresService: FeaturesService,
		private _snackBar: MatSnackBar,
		private certificadoPdfService: CertificadosPdfService,
	) {
		this.userInfo = JSON.parse(localStorage.getItem("user"));
	}

	ngOnInit(): void {
		this.fechaActual = new Date();
		this.loadingData = true;
		this.configuracionService.getConfiguracion().subscribe(
			(data) => {
				this.data = data.result || {};
				// let date  = new Date(this.data.created_at);
				// this.formatedDate = `${date.getFullYear()}-${(date.getMonth()+1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
				this.formatedDate = this.data.fecha;

				this.nombreAlcalde = this.data.nombreAlcalde
				this.nombreSecretario = this.data.nombreSecretario
				this.fecha = this.formatedDate
				this.lugar =this.data.lugar
				this.fechaInventario = this.data.fechaInventario
				this.loadingData = false;
			},
			(err) => {
				this.loadingData = false;
			}
		);
	}

	onClose(): void {
		this.dialogRef.close();
	}
	saveData() {
		console.log(this.nombreAlcalde)
		console.log(this.nombreSecretario)
		console.log(this.lugar)
		console.log(this.fecha)
		console.log(this.userInfo)
		console.log(this.fechaInventario)
		let data = {
			secretario: this.nombreSecretario,
			lugar: this.lugar,
			alcalde: this.nombreAlcalde,
			usuarioId: this.userInfo.idUser,
			municipioId: this.userInfo.municipioId,
			fecha: this.fecha,
			fechaInventario: this.fechaInventario,
		}
		this.featuresService.setCertifiedData(data).subscribe({
			next: response => {
			  console.log(response);
			  this.dialogRef.close();
			  this._snackBar.open("Guardado correctamente!!", "Cerrar", {
				duration: 2000,
			  });
			  this.certificadoPdfService.updateDatosCertificado();
			},
			error: error => {
			  console.log(error);
			},
		  });
	}
}
