import { Injectable } from '@angular/core';
import { ImageBaseUrlService } from './Reportes-Servicios/image-base-url.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { NewFeaturesService } from "../services/newFeatures.service";
import { ExportDocumentosPdfService } from "./export-pdf.service";

@Injectable({
	providedIn: 'root'
})
export class CreateTableService {
	image = null;
	userInfo: any;
	imagenMunicipio = null;
	infoProvincia: any


	constructor(
		private imageBase: ImageBaseUrlService,
		private newFeaturesService: NewFeaturesService,
		private http: HttpClient, private sanitizer: DomSanitizer,
		private exportPdfService: ExportDocumentosPdfService,
	) {
		this.userInfo = JSON.parse(localStorage.getItem("user"));
		this.newFeaturesService.getInfoProvincia(this.userInfo.municipioId).subscribe({
			next: (res) => {
				this.infoProvincia = res.result
				this.newFeaturesService.getMunicipioImagen(this.infoProvincia.provincia.nombre, this.infoProvincia.nombre).subscribe({
					next: (res) => {
						const reader = new FileReader();
						reader.readAsDataURL(res);
						reader.onloadend = () => {
							this.imagenMunicipio = reader.result;
						};

					},
					error: (err) => console.log(err)
				});
			},
			error: (err) => console.log(err)
		});
	}

	//Definir Objeto de bordes de tabla
	bordersType = {
		none: [false, false, false, false],
		all: [true, true, true, true],
		left: [true, false, false, false],
		top: [false, true, false, false],
		right: [false, false, true, false],
		bottom: [false, false, false, true],
		vertical: [false, true, false, true],
		horizontal: [true, false, true, false],
		noLeft: [false, true, true, true],
		noTop: [true, false, true, true],
		noRight: [true, true, false, true],
		noBottom: [true, true, true, false],
		topRight: [false, true, true, false],
	};


	rowColumnsEmpy(col = 8, row = 1) {
		const resp = [];
		for (let i = 0; i < row; i++) {
			const er = [];
			for (let j = 0; j < col; j++) {
				er.push[""];
			}
			resp.push(er);
		}
		return resp;
	}

	rowBuild(texto, colSpan = 1) {
		let resp: any[] = [];
		resp.push({
			border: [true, false, true, true],
			text: texto,
			colSpan: colSpan,
		});
	}

	columnBuild({
		texto,
		colSpan = 0,
		rowSpan = 1,
		borders = [true, true, true, true],
		prevCol = 0,
		style = null,
		bold = true,
		italics = false,
		color = null,
		alignment = "left",
		margin = null
	}) {
		let resp: any[] = [];
		if (prevCol) {
			for (let i = 0; i < prevCol; i++) {
				resp.push("");
			}
		}
		const colSpanAdd = colSpan ? colSpan - 1 : 0;
		resp.push({
			rowSpan: rowSpan,
			colSpan: colSpan,
			text: texto,
			border: borders,
			style: style,
			bold: bold,
			italics: italics,
			color: color,
			alignment: alignment,
			margin: margin
		});
		if (colSpanAdd) {
			for (let i = 0; i < colSpanAdd; i++) {
				resp.push("");
			}
		}
		return resp;
	}



	async createTable(
		data: any,
		titulo = "",
		tituloColumnas = [],
		valoresColumnnas = [],
		widthColumns = [],
		title = null,
		info:any = null
	) {
		if(title == null) {
			title = 'INVENTARIO DE BIENES Y DERECHOS'
		}

		if(info == null) {
			info = {
				data: this.infoProvincia,
				image: this.imagenMunicipio
			}
		}


		const body = [];
		data.forEach((element) => {
			let row = []
			valoresColumnnas.forEach((key, idx) => {
				if(typeof key == 'object' ) {
					let str = ''
					key.forEach((el, sidx) => {
						if(el.split('.').length > 1) {
							if(element[el.split('.')[0]]) {
								let item = element[el.split('.')[0]][el.split('.')[1]] ?? 'NO PROCEDE'
								str += item + '\t'
							}
						} else {

							let item = element[el] ?? 'NO PROCEDE'
							if(sidx == 0) {
								item += "\t|"
							}
							str += item + '\t'
						}
					})
					let column = {
						text: str ?? "NO PROCEDE",
						border: this.bordersType.none,
						alignment: idx == 0 ? "center" : "",
					}
					row.push(column)
				} else {
					// console.log(element.epigrafeId)
					let column = {
						text: element[key] ?? "NO PROCEDE",
						border: this.bordersType.none,
						alignment: idx == 0 ? "center" : "",
					}
					if(element.epigrafeId == 7) {
						if(idx == 2|| idx == 3){
							column.alignment = 'center'
						}
					}
					if(element.epigrafeId == 13){
						// if(idx == 3|| idx == 4){
							column.alignment = 'center'
						// }
					}
					row.push(column)
				}
				
			})
			body.push(row)
		});

		const headers = []
		let blankSpace = []

		tituloColumnas.forEach(title => {
			headers.push({
				text: title,
				fillColor: "#b1b8c9",
				alignment: "center",
				fontSize: 10,
				italics: false,
				bold: true,
			})
			blankSpace.push({
				text: "",
				border: this.bordersType.none,
				margin: [0, 0, 0, 0],
				fillColor: "#FFFFFF",
			})
		})


		if(info.image == null){
			let prov = info.data.provincia.nombre
			let url = `${prov}/${prov}?file=${prov.toLowerCase()}_escudo.png`
			info.image = await this.exportPdfService.getImg(url)
		}

		let columnImage = {}
		if(info.image ==  null || info.image == 'not found') {
		  columnImage = {
			text: '',
			width: 50,
			margin: [0, 10, 0, 0],
		  }
		} else {
		  columnImage = {
			image: info.image,
			width: 50,
			margin: [0, 10, 0, 0],
		  }
		}

		const content = [
			{
				columns: [
					columnImage,
					{
						margin: [20, 35, 10, 0],
						alignment: "left",
						fontSize: 13,
						text: title,
						bold: true
					},
				],
			},
			{
				margin: [0, 5, 0, 0],
				table: {
					widths: ["*"],
					body: [
						[
							{
								text: `EPIGRAFE ${titulo}`,
								alignment: "center",
								fillColor: "#4a5269",
								color: "#fff",
								italics: false,
								fontSize: 10,
								bold: true,
							},
						],
					],
				},
			},
			{
				fontSize: 9,
				table: {
					widths: widthColumns,
					headerRows: widthColumns.length,

					body: [
						blankSpace,
						blankSpace,
						headers,
						blankSpace,
						blankSpace,
						...body,
					],
				},
				layout: {
					fillColor: function (rowIndex, node, columnIndex) {
						return rowIndex % 2 === 0 ? "#CCCCCC" : null;
					},
				},
			},
		];

		let table = {
			pageOrientation: "landscape",
			content,
			pageMargins: [40, 40, 40, 40],
			info: {
				title: `Inventario Bienes y Derechos`,
				author: "SerProfes",
			},
		};
		return table;
	}

	async getBase64ImageFromURL(url: string): Promise<string> {
		const response = await fetch(url);
		const blob = await response.blob();

		return new Promise<string>((resolve) => {
			const reader = new FileReader();
			reader.readAsDataURL(blob);
			reader.onloadend = () => {
				resolve(reader.result as string);
			};
		});
	}

	imageBase64: string;


	async createTableInmuebles(
		data: any,
		titulo = "",
		url = "",
		id,
		info: any = null
	) {


		if(info == null) {
			info = {
				data: this.infoProvincia,
				image: this.imagenMunicipio
			}
		}


		let widthColumns = ["10%", "15%", "40%", "10%", "25%"]

		const body = [];

		data.forEach((element) => {
			let row = []
			if (id == 11) {
				row = [
					{
						text: element.orden ?? element.n_orden ?? "",
						colSpan: 1,
						rowSpan: null,
						border: [false, false, false, false],
						alignment: "center"
					},
					{
						text: element.localidad ?? "",
						colSpan: 1,
						rowSpan: null,
						border: [false, false, false, false],
						alignment: "center"
					},
					{
						text: `${element.tipoVia && element.tipoVia != null ? element.tipoVia.abreviatura : ""} ${element.via_publica_al_frente ?? "NO PROCEDE"}`,
						colSpan: 1,
						rowSpan: null,
						border: [false, false, false, false],
					},
					{
						text: element.numero ?? "S/N",
						colSpan: 1,
						rowSpan: null,
						border: [false, false, false, false],
						alignment: "center"
					},
					{
						text: element.destino ?? "",
						colSpan: 1,
						rowSpan: null,
						border: [false, false, false, false],
						alignment: "center"
					},

				]
			} else if (id == 10) {
				row = [
					{
						text: element.orden ?? element.n_orden ?? "",
						colSpan: 1,
						rowSpan: null,
						border: [false, false, false, false],
						alignment: "center"
					},
					{
						text: element.localidad ?? "",
						colSpan: 1,
						rowSpan: null,
						border: [false, false, false, false],
						alignment: "center"
					},
					{
						text: `${element.tipoVia && element.tipoVia != null ? element.tipoVia.abreviatura : ""}\t\t${element.via_publica_al_frente ?? "NO PROCEDE"}`,
						colSpan: 1,
						rowSpan: null,
						border: [false, false, false, false],
					},
					{
						text: element.numero ?? "S/N",
						colSpan: 1,
						rowSpan: null,
						border: [false, false, false, false],
						alignment: "center"
					},
					{
						text: element.nombre ?? "",
						colSpan: 1,
						rowSpan: null,
						border: [false, false, false, false],
						alignment: "center"
					},

				]
			} else if (id == 17) {
				row = [
					{
						text: element.orden ?? element.n_orden ?? "",
						colSpan: 1,
						rowSpan: null,
						border: [false, false, false, false],
						alignment: "center"
					},
					{
						text: element.localidad ?? "",
						colSpan: 1,
						rowSpan: null,
						border: [false, false, false, false],
						alignment: "center"
					},
					{
						text: `${element.tipoVia && element.tipoVia != null ? element.tipoVia.abreviatura : ""}\t\t${element.nombre?? "NO PROCEDE"}`,
						colSpan: 1,
						rowSpan: null,
						border: [false, false, false, false],
					},
					{
						text: element.poligono ?? "NO PROCEDE",
						colSpan: 1,
						rowSpan: null,
						border: [false, false, false, false],
						alignment: "center"
					},
					{
						text: element.parcela ?? "NO PROCEDE",
						colSpan: 1,
						rowSpan: null,
						border: [false, false, false, false],
						alignment: "center"
					},

				]
			} else if (id == 18 || id == 19) {
				row = [
					{
						text: element.n_orden ?? "",
						border: [false, false, false, false],
						alignment: "center"
					},
					{
						text: element.localidad ?? "",
						border: [false, false, false, false],
						alignment: "center"
					},
					{
						text: `${element.tipoVia && element.tipoVia != null ? element.tipoVia.abreviatura : ""} ${element.via_publica_al_frente ?? "NO PROCEDE"}` ?? "",
						border: [false, false, false, false],
					},
					{
						text: element.origen ?? "NO PROCEDE",
						border: [false, false, false, false],
						alignment: "center"
					},
					{
						text: element.final ?? "NO PROCEDE",
						border: [false, false, false, false],
						alignment: "center"
					},

				]
			} else {
				row = [
					{
						text: element.n_orden ?? "",
						border: [false, false, false, false],
						alignment: "center"
					},
					{
						text: element.localidad ?? "",
						border: [false, false, false, false],
						alignment: "center"
					},
					{
						text: `${element.tipoVia && element.tipoVia != null ? element.tipoVia.abreviatura : ""} \t ${element.via_publica_al_frente ?? "NO PROCEDE"}`,
						border: [false, false, false, false],
					},
					{
						text: element.numero ?? "S/N",
						border: [false, false, false, false],
						alignment: "center"
					},
					{
						text: element.destino ?? "",
						border: [false, false, false, false],
						alignment: "center"
					},

				]
			}
			body.push(row)
		});


		let headers = []

		if (id == 17) {
			headers = [
				{
					text: "No ORD.",
					fillColor: "#b1b8c9",
					alignment: "center",
					fontSize: 10,
					italics: false,
					bold: true,
				},
				{
					text: "LOCALIDAD",
					fillColor: "#b1b8c9",
					alignment: "center",
					fontSize: 10,
					italics: false,
					bold: true,
				},
				{
					text: "NOMBRE",
					fillColor: "#b1b8c9",
					alignment: "center",
					fontSize: 10,
					italics: false,
					bold: true,
				},
				{
					text: "POLIGONO",
					fillColor: "#b1b8c9",
					alignment: "center",
					fontSize: 10,
					italics: false,
					bold: true,
				},
				{
					text: "PARCELA",
					fillColor: "#b1b8c9",
					alignment: "center",
					fontSize: 10,
					italics: false,
					bold: true,
				},
			]
		} else if (id == 10) {
			headers = [
				{
					text: "No ORD.",
					fillColor: "#b1b8c9",
					alignment: "center",
					fontSize: 10,
					italics: false,
					bold: true,
				},
				{
					text: "LOCALIDAD",
					fillColor: "#b1b8c9",
					alignment: "center",
					fontSize: 10,
					italics: false,
					bold: true,
				},
				{
					text: "NOMBRE VIA",
					fillColor: "#b1b8c9",
					alignment: "center",
					fontSize: 10,
					italics: false,
					bold: true,
				},
				{
					text: "NUM",
					fillColor: "#b1b8c9",
					alignment: "center",
					fontSize: 10,
					italics: false,
					bold: true,
				},
				{
					text: "NOMBRE",
					fillColor: "#b1b8c9",
					alignment: "center",
					fontSize: 10,
					italics: false,
					bold: true,
				},
			]
		} else if (id == 18) {
			headers = [
				{
					text: "No ORD.",
					fillColor: "#b1b8c9",
					alignment: "center",
					fontSize: 10,
					italics: false,
					bold: true,
				},
				{
					text: "LOCALIDAD",
					fillColor: "#b1b8c9",
					alignment: "center",
					fontSize: 10,
					italics: false,
					bold: true,
				},
				{
					text: "NOMBRE",
					fillColor: "#b1b8c9",
					alignment: "center",
					fontSize: 10,
					italics: false,
					bold: true,
				},
				{
					text: "ORIGEN",
					fillColor: "#b1b8c9",
					alignment: "center",
					fontSize: 10,
					italics: false,
					bold: true,
				},
				{
					text: "FINAL",
					fillColor: "#b1b8c9",
					alignment: "center",
					fontSize: 10,
					italics: false,
					bold: true,
				},
			]
		} else {
			headers = [
				{
					text: "No ORD.",
					fillColor: "#b1b8c9",
					alignment: "center",
					fontSize: 10,
					italics: false,
					bold: true,
				},
				{
					text: "LOCALIDAD",
					fillColor: "#b1b8c9",
					alignment: "center",
					fontSize: 10,
					italics: false,
					bold: true,
				},
				{
					text: "NOMBRE VIA",
					fillColor: "#b1b8c9",
					alignment: "center",
					fontSize: 10,
					italics: false,
					bold: true,
				},
				{
					text: "NUM",
					fillColor: "#b1b8c9",
					alignment: "center",
					fontSize: 10,
					italics: false,
					bold: true,
				},
				{
					text: "DESTINO",
					fillColor: "#b1b8c9",
					alignment: "center",
					fontSize: 10,
					italics: false,
					bold: true,
				},
			]
		}

		let blankSpace = []

		for (let i = 0, length = headers.length; i < length; i += 1) {
			blankSpace.push({
				text: "",
				border: this.bordersType.none,
				margin: [0, 0, 0, 0],
				fillColor: "#FFFFFF",
			})
		}

		if(info.image == null){
			let prov = info.data.provincia.nombre
			let url = `${prov}/${prov}?file=${prov.toLowerCase()}_escudo.png`
			info.image = await this.exportPdfService.getImg(url)
		}

		let columnImage = null;
		if(info.image) {
			columnImage = {
				image: info.image,
				width: 40,
				margin: [0, 0, 0, 0],
			}
		} else {
			columnImage = {
				text: '',
				width: 40,
				margin: [0, 0, 0, 0],
			}
		}


		const content = [
			{
				columns: [
					columnImage,
					{
						margin: [20, 35, 10, 0],
						alignment: "left",
						fontSize: 13,
						text: "INVENTARIO DE BIENES Y DERECHOS",
						bold: true
					},
				],
			},
			{
				margin: [0, 5, 0, 0],
				table: {
					widths: ["*"],
					body: [
						[
							{
								text: `EPIGRAFE ${titulo}`,
								alignment: "center",
								fillColor: "#4a5269",
								color: "#fff",
								italics: false,
								fontSize: 10,
								bold: true,
							},
						],
					],
				},
				layout: {
					pageOrientation: "landscape"
				},
			},
			{
				fontSize: 10,
				table: {
					widths: widthColumns,
					headerRows: widthColumns.length,

					body: [
						blankSpace,
						blankSpace,
						headers,
						blankSpace,
						blankSpace,
						...body,
					],
				},
				layout: {
					fillColor: function (rowIndex, node, columnIndex) {
						return rowIndex % 2 === 0 ? "#CCCCCC" : null;
					},
					pageOrientation: "landscape"
				},
			},
		];

		let table = {
			pageOrientation: "landscape",
			content,
			defaultStyle: {
				italics: false,
				bold: true,
			},
		};
		return table;
	}



	/**
	 * Migracion al Nuevo Backend
	 */


}
