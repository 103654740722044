import { SubmenuMobiliariosCreditosComponent } from './submenu-mobiliarios-creditos/submenu-mobiliarios-creditos.component';
import { Router } from "@angular/router";
import { SubmenuInmueblesComponent } from "./submenu-inmuebles/submenu-inmuebles.component";
import { MatDialogRef, MatDialog } from "@angular/material/dialog";
import { Component, OnInit } from "@angular/core";

@Component({
	selector: "app-submenu-alta-bien",
	templateUrl: "./submenu-alta-bien.component.html",
	styleUrls: ["./submenu-alta-bien.component.scss"],
})
export class SubmenuAltaBienComponent implements OnInit {
	lista: any[] = [
		{ name: "inmuebles", color: "#5D85A1" },
		{ name: "derechos_reales", color: "#4DAF4A", epigrafe: "2." },
		{
			name: "muebles_ha",
			color: "#9C3836",
			epigrafe: "3."
		},
		{ name: "val_creditos", color: "#FACC00", epigrafe: "4." },
		{ name: "vehiculos", color: "#CDCC00", epigrafe: "5." },
		{ name: "semovientes", color: "#61C0E9", epigrafe: "6." },
		{ name: "otros_muebles", color: "#70440F", epigrafe: "7." },
		{
			name: "bienes_revertibles",
			color: "#F78E10",
			epigrafe: "8.",
		},
		{ name: "arbolado_urbano", color: "#27ae60", epigrafe: "20." },
		{ name: "patrimonio_suelo_inmuebles", color: "#2c3e50", epigrafe: "21." },
		{ name: "patrimonio_suelo_fondos", color: "#2c3e50", epigrafe: "22." },
	];

	constructor(
		public dialogRef: MatDialogRef<SubmenuAltaBienComponent>,
		private _matDialog: MatDialog,
		private router: Router
	) // private _snackBar: MatSnackBar
	{}

	ngOnInit(): void {}

	onClose(): void {
		this.dialogRef.close();
	}

	onOpenSubmenu(item: any): void {
		if (item.name == "inmuebles") {
			this._matDialog.open(SubmenuInmueblesComponent, {
				autoFocus: false,
				data: {
					isAlta: true,
					ref: this.dialogRef,
				},
			});
			return;
		}
		if (item.name == "val_creditos") {
			this._matDialog.open(SubmenuMobiliariosCreditosComponent, {
				autoFocus: false,
				data: {
					isAlta: true,
					ref: this.dialogRef,
				},
			});
			return;
		}
		if (!item.epigrafe) {
			// this._snackBar.open("En Construcción", "Cerrar", {
			// 	duration: 2000,
			// });
			return;
		}
		let epigrafe = "";
		switch(item.name) {
			case "derechos_reales": 
				epigrafe = "derechos-reales";
				break;
			case "muebles_ha":
				epigrafe = "muebles-historicos";
				break;
			case "val_creditos":
				epigrafe = "mobiliarios-creditos";
				break;
			case "vehiculos":
				epigrafe = "vehiculos";
				break;
			case "semovientes":
				epigrafe = "semovientes";
				break;
			case "otros_muebles":
				epigrafe = "otros-muebles";
				break;
			case "bienes_revertibles":
				epigrafe = "bienes-revertibles";
				break;
			case "arbolado_urbano":
				epigrafe = "arbolado-urbano";
				break;
			case "patrimonio_suelo_inmuebles":
				epigrafe = "patrimonio-suelo-inmuebles";
				break;
			case "patrimonio_suelo_fondos":
				epigrafe = "patrimonio-suelo-fondos";
				break;
			default:
				break;
		}
		this.router
			.navigateByUrl(`/app/alta-bien/${epigrafe}`, {
				state: { name: item.name, epigrafe: item.epigrafe },
			})
			.then(() => this.dialogRef.close());
	}
}
