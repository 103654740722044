import { SubmenuInmueblesComponent } from './../submenu-inmuebles.component';
import { Router } from "@angular/router";
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { Component, OnInit, Inject } from "@angular/core";

@Component({
	selector: "app-submenu-inmuebles-urbanos",
	templateUrl: "./submenu-inmuebles-urbanos.component.html",
	styleUrls: ["./submenu-inmuebles-urbanos.component.scss"],
})
export class SubmenuInmueblesUrbanosComponent implements OnInit {
	lista: any[] = [
		{
			name: "bienInmuebles",
			color: "#5D85A1",
			epigrafe: "1.1.1.",
			status: true,
		},
		{
			name: "parques",
			color: "#4DAF4A",
			epigrafe: "1.1.2.",
			status: true,
		},
		{
			name: "solares",
			color: "#9C3836",
			epigrafe: "1.1.3.",
			status: true,
		},
		{
			name: "infraestructuras",
			color: "#FACA38",
			epigrafe: "1.1.4.",
			status: true,
		},
	];

	constructor(
		public dialogRef: MatDialogRef<SubmenuInmueblesUrbanosComponent>,
		private router: Router,
		private _matDialog: MatDialog,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {}

	ngOnInit(): void {}

	onClose(): void {
		this.dialogRef.close();
	}

	onClick(item: any): void {
		console.log(item);
		if (!item.status) {
			return;
		}
		let epigrafe = "";
		switch (item.name) {
			case "bienInmuebles":
				epigrafe = "inmuebles-urbanos";
				break;
			case "parques":
				epigrafe = "parques-jardines";
				break;
			case "solares":
				epigrafe = "solares";
				break;
			case "infraestructuras":
				epigrafe = "infraestructuras";
				break;
			default:
				break;
		}
		console.log(epigrafe);
		if(this.data.isAlta) {
			this.router
				.navigateByUrl(`/app/alta-bien/${epigrafe}`, {
					state: { name: item.name, epigrafe: item.epigrafe },
				})
				.then(() => {
					this.dialogRef.close();
					this.data.refs.forEach((ref: any) => ref.close());
				});
		} else {
			this.router
				.navigateByUrl(`/app/${epigrafe}`, {
					state: { name: item.name, epigrafe: item.epigrafe },
				})
				.then(() => {
					this.dialogRef.close();
					this.data.refs.forEach((ref: any) => ref.close());
				});
		}
	}

	onPrevSubmenu(): void {
		this.dialogRef.close();
		this._matDialog.open(SubmenuInmueblesComponent, {
			autoFocus: false,
			data: {
				refs: [this.data.ref, this.dialogRef],
				isAlta: this.data.isAlta
			},
		})
	}
}
