import { of, map } from 'rxjs';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'environments/environment';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ScrapingService {
  urlApi = environment.urlApi2;

  constructor(private httpClient: HttpClient) { }

  getScrapingByProvincia(provincias: string[], credentials: any): Observable<any> {
    return this.httpClient.post(`${this.urlApi}scrap?provincias=${provincias}`, { credentials }).pipe(
      map((resp) => {
        console.log(resp);
        return resp;
      })
    );
  }
    
}
