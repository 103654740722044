import { SubmenuAltaBienComponent } from './../submenu-alta-bien.component';
import { Router } from "@angular/router";
import { SubmenuInventariosComponent } from '../../submenu-inventarios/submenu-inventarios.component';
import { SubmenuInmueblesUrbanosComponent } from "./submenu-inmuebles-urbanos/submenu-inmuebles-urbanos.component";
import {
	MatDialog,
	MatDialogRef,
	MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { Component, OnInit, Inject } from "@angular/core";

@Component({
	selector: "app-submenu-inmuebles",
	templateUrl: "./submenu-inmuebles.component.html",
	styleUrls: ["./submenu-inmuebles.component.scss"],
})
export class SubmenuInmueblesComponent implements OnInit {
	lista: any[] = [
		{ name: "inmUrbanos", color: "#5D85A1" },
		{ 
			name: "fincas", 
			color: "#4DAF4A", 
			epigrafe: "1.2.",
		},
		{ name: "vias_urbanas", color: "#CDCC00", epigrafe: "1.3." },
		{ name: "vias_rusticas", color: "#61C0E9", epigrafe: "1.4." },
	];

	constructor(
		public dialogRef: MatDialogRef<SubmenuInmueblesComponent>,
		private _matDialog: MatDialog,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private router: Router // private _snackBar: MatSnackBar
	) {}

	ngOnInit(): void {}

	onClose(): void {
		this.dialogRef.close();
	}

	onOpenSubmenu(item: any): void {
	
		if (item.name == "inmUrbanos") {

			this._matDialog.open(SubmenuInmueblesUrbanosComponent, {
			
				autoFocus: false,
				data: {
					refs: [this.data.ref, this.dialogRef],
					isAlta: this.data.isAlta
				},
			});
		} else {
			let epigrafe = "";
			switch(item.name) {
				case "fincas": 
					epigrafe = "inmuebles-rusticos";
					break;
				case "vias_urbanas":
					epigrafe = "vias-publicas-urbanas";
					break;
				case "vias_rusticas":
					epigrafe = "vias-publicas-rusticas";
					break;
				default:
					break;
			}
			if(this.data.isAlta) {
				this.router
				.navigateByUrl(`/app/alta-bien/${epigrafe}`, {
					state: { name: item.name, epigrafe: item.epigrafe },
				})
				.then(() => {
					this.dialogRef.close();
					this.data.ref.close();
				});
			} else {
				this.router
				.navigateByUrl(`/app/${epigrafe}`, {
					state: { name: item.name, epigrafe: item.epigrafe },
				})
				.then(() => {
					this.dialogRef.close();
					this.data.ref.close();
				});
			} 
			// this.router
			// 	.navigateByUrl("/app/alta-bien", { state: { name } })
			// 	.then(() => {
			// 		this.dialogRef.close();
			// 		this.data.ref.close();
			// 	});
		}
	}

	onPrevSubmenu(): void {
		console.log(this.data);
		this.dialogRef.close();
		if(this.data.isAlta) {
		  this._matDialog.open(SubmenuAltaBienComponent, {
			autoFocus: false,
			data: {
				refs: [this.data.ref, this.dialogRef],
				isAlta: this.data.isAlta
			},
		  })
		} else {
		  this._matDialog.open(SubmenuInventariosComponent, {
			autoFocus: false,
			data: {
				refs: [this.data.ref, this.dialogRef],
				isAlta: this.data.isAlta
			},
		  })
		}
		}
}
