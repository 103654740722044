<h1 mat-dialog-title>{{'drawer.configuracion.carga'| transloco}}</h1>

<div *ngIf="forceMessage == true">
    <div mat-dialog-content>
        <h3 class="text-xl">{{'loading_model.mensa5'| transloco}}</h3>
        <h3 class="text-xl">{{'loading_model.mensa6'| transloco}}</h3>
    </div>
    <div mat-dialog-actions>
        <div class="mt-3 flex justify-end gap-4">
            <button class="border-[#333B63] border rounded font-medium text-base leading-8 py-1 px-3" (click)="onClose()" [disabled]="loading == true">{{ "usuarios.modales.create.buttons.cancelar" | transloco | uppercase }}</button>
            <button class="bg-[#333B63] rounded font-medium text-base text-white py-3 px-4" (click)="uploadFile(true)" [disabled]="loading == true">{{ "modales.certificados.buttons.aceptar" | transloco | uppercase }}</button>
        </div>
    </div>
</div>
<div *ngIf="forceMessage == false">
    <div mat-dialog-content>
        <form [formGroup]="form">
            <div class="sm:col-span-3">
                <label>{{ "usuarios.modales.create.provincia.label" | transloco }}</label>
                <mat-form-field appearance="fill" class="w-full mt-2">
                    <mat-select [placeholder]="'usuarios.modales.create.provincia.placeholder' | transloco" [formControlName]="'id_provincia'" (selectionChange)="onChangeProvincia($event)">
                        <mat-option *ngFor="let provincia of provincias" [value]="provincia.id">
                            {{provincia.nombre}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="sm:col-span-3">
                <label>{{ "usuarios.modales.create.municipio.label" | transloco }}</label>
                <mat-form-field appearance="fill" class="w-full mt-2">
                    <mat-select [placeholder]="'usuarios.modales.create.municipio.placeholder' | transloco" [formControlName]="'municipioId'">
                        <mat-option *ngFor="let municipio of municipios" [value]="municipio.id">
                            {{municipio.nombre}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
    
            <div>
                <div
                    #fileInputContainer
                    class="w-90 border-2 border-gray-300 border-dashed rounded-lg p-4 flex flex-col items-center justify-center cursor-pointer"
                    [class.has-file]="fileName"
                    (click)="handleFileInputClick()"
                >
                    <input
                    #fileInput
                    type="file"
                    accept=".xls,.xlsx,.csv"
                    (change)="handleFileInputChange($event)"
                    hidden
                    />
                    <ng-container *ngIf="!fileName">
                    <img class="h-20 w-20" src="assets/icons/upload_image.png" alt="icon-upload-file">
                    <div class="font-bold text-lg leading-5 text-[#5182EF] text-center">
                        {{ 'vistasSubepigrafes.subepigrafes.forms_common.otros_datos.seleccionar' | transloco }}
                    </div>
                    </ng-container>
                    <span *ngIf="fileName" class="file-name">{{ fileName }}</span>
                </div>
            </div>
        </form>
    </div>
    <div mat-dialog-actions>
        <div class="mt-3 flex justify-end gap-4">
            <button class="border-[#333B63] border rounded font-medium text-base leading-8 py-1 px-3" (click)="onClose()" [disabled]="loading == true">{{ "usuarios.modales.create.buttons.cancelar" | transloco | uppercase }}</button>
            <button class="bg-[#333B63] rounded font-medium text-base text-white py-3 px-4" (click)="uploadFile()" [disabled]="loading == true">{{ "modales.certificados.buttons.aceptar" | transloco | uppercase }}</button>
        </div>
    </div>
</div>
