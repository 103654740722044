import { DateTime } from "luxon";
import { Injectable, OnInit } from "@angular/core";
import { Utils } from "../../utils/utils";


@Injectable({
	providedIn: 'root'
})
export class CertificadoUtils implements OnInit {

	constructor() { }
	ngOnInit(): void {
	}


	// -----------------------------------------------------------------------------------------------------
	// @ Public methods
	// -----------------------------------------------------------------------------------------------------

	static certificadoRusticas() {
		return [];
	}

	static async generarCertificadoBienes(data: any, infoCertificado: any, imagen: any) {
		let fechaCertificado = infoCertificado.fecha ? new Date(infoCertificado.fecha) : new Date();
		if (infoCertificado.fecha) {
			fechaCertificado = new Date(infoCertificado.fecha.toString()+ 'T00:00:00Z');
			const offsetMinutos = fechaCertificado.getTimezoneOffset();
			fechaCertificado = new Date(fechaCertificado.getTime() + offsetMinutos * 60000);
		}
		const certificado = [];
		certificado.push(...(await this.cabeceraCertificado(infoCertificado, imagen)));
		certificado.push({
			text:
				"Que en el libro de Inventario de bienes de este Ayuntamiento, cuya última rectificación fue " +
				`aprobada por el Pleno de la Corporación en sesión del día ${fechaCertificado.toLocaleString("es-ES", DateTime.DATE_FULL)}`,
		});
		certificado.push({
			margin: [0, 10, 0, 0],
			text: `Figura con el núm. ${data.numero} el siguiente bien:`,
		});
		certificado.push({
			margin: [20, 15, 0, 0],
			text:
				`Edificio conocido por ${data.nombre || "N/A"} situada en ${data.via_publica_al_frente || "N/A"
				} n° ${data.numero ?? '-'} de ${data.s_sup_registral || "N/A"} m2 ` +
				`de superficie, y los siguientes linderos:`,
		});

		if (data.epigrafe != null && data.epigrafe.id == 19) {
			certificado.push({
				margin: [20, 10, 0, 0],
				style: "tableExample",
				table: {
					body: [
						["Orige:", { text: `${data.lindero_frente || "N/A"}` }],
						["Final:", `${data.lindero_fondo || "N/A"}`],
						["Izquierda:", `${data.lindero_izquierdo || "N/A"}`],
						["Derecha:", `${data.lindero_derecho || "N/A"}`],
					],
				},
				layout: "noBorders",
			});
		} else {
			certificado.push({
				margin: [20, 10, 0, 0],
				style: "tableExample",
				table: {
					body: [
						["Frente:", { text: `${data.lindero_frente || "N/A"}` }],
						["Fondo:", `${data.lindero_fondo || "N/A"}`],
						["Izquierda:", `${data.lindero_izquierdo || "N/A"}`],
						["Derecha:", `${data.lindero_derecho || "N/A"}`],
					],
				},
				layout: "noBorders",
			});
		}
		certificado.push({
			margin: [20, 10, 0, 0],
			text: `Que el expresado bien tiene una naturaleza jurídica de ${data.naturaleza || "N/A"
				}`,
		});
		certificado.push({
			margin: [20, 10, 0, 0],
			text:
				`Que el título en virtud del cual se atribuye la propiedad a esta Entidad es de ` +
				`${Utils.entidadByEpigrafe(data)
				} y se encuentra inscrito en el Registro De la propiedad de ${data.registro_propiedad || "N/A"
				} con ` +
				`signatura registral`,
		});
		certificado.push({
			margin: [20, 10, 0, 0],
			alignment: "justify",
			columns: [
				`Tomo ${data.tomo || "N/A"}`,
				`Libro ${data.libro || "N/A"}`,
				`Folio ${data.folio || "N/A"}`,
				`Finca ${data.finca || "N/A"}`,
				`Inscripc. ${data.inscripcion_registral || "N/A"}`,
			],
		});

		certificado.push(...this.pieCertificado(infoCertificado, fechaCertificado));
		return certificado;
	}

	static async generarCertificadoBienesPersonalizado(estructuraCertificado: any, data: any, infoCertificado: any, imagen: any, tipoInventario?:any) {
		const certificado = [];
		let fechaCertificado = infoCertificado.fechaInventario ? new Date(infoCertificado.fecha) : new Date();
		if (infoCertificado.fechaInventario) {
			fechaCertificado = new Date(infoCertificado.fechaInventario.toString()+ 'T00:00:00Z');
			const offsetMinutos = fechaCertificado.getTimezoneOffset();
			fechaCertificado = new Date(fechaCertificado.getTime() + offsetMinutos * 60000);
		}

		// Fecha al pie
		let fecha = infoCertificado.fecha ? new Date(infoCertificado.fecha) : new Date();
		if (infoCertificado.fecha) {
			fecha = new Date(infoCertificado.fecha.toString()+ 'T00:00:00Z');
			const offsetMinutos = fecha.getTimezoneOffset();
			fecha = new Date(fecha.getTime() + offsetMinutos * 60000);
		}

		certificado.push(...(await this.cabeceraCertificado(infoCertificado, imagen)));
		certificado.push({
			text:
			`En el libro del Inventario de ${tipoInventario} de este Ayuntamiento, cuya última rectificación fue ` +
				`aprobada por el Pleno de la Corporación en sesión del día ${fechaCertificado.toLocaleString("es-ES", DateTime.DATE_FULL)}`,
		});
		certificado.push({
			margin: [0, 10, 0, 0],
			text: estructuraCertificado.textoNumeroBien
		});
		certificado.push({
			margin: [20, 15, 0, 0],
			text: estructuraCertificado.datosGenerales

		});

		certificado.push({
			margin: [20, 10, 0, 0],
			style: "tableExample",
			table: {
				body: estructuraCertificado.cuerpo
			},
			layout: "noBorders",
		});


		certificado.push({
			margin: [20, 10, 0, 0],
			text: `Que el expresado bien tiene una naturaleza jurídica de ${data?.juridica?.nombre || "N/A"
				}`,
		});
		certificado.push({
			margin: [20, 10, 0, 0],
			text: estructuraCertificado.textoPie

		});
		certificado.push({
			margin: [20, 10, 0, 0],
			alignment: "justify",
			columns: estructuraCertificado.infoPie 
		});

		certificado.push(...this.pieCertificado(infoCertificado, fecha));
		return certificado;
	}

	static pieCertificado(info: any, fechaCertificado: any): any[] {
		const municipioData = JSON.parse(localStorage.getItem("muni"))
		const municipio = municipioData.nombre;
		const body = [];
		body.push({
			margin: [0, 10, 0, 0],
			text: "Y para que así conste y surta sus efectos en el expediente de su razón, expido la presente, con el Visto Bueno del Sr/Sra Alcalde.",
		});
		body.push({
			alignment: "justify",
			columns: [
				{
					text: `En ${municipio
						}, a ${fechaCertificado.toLocaleString("es-ES", DateTime.DATE_FULL)}`,
					margin: [0, 10, 0, 0],
				},
			],
		});
		body.push({
			alignment: "justify",
			margin: [0, 50, 0, 0],
			columns: [
				{ text: "Vº Bº Alcalde de la Entidad" },
				{ text: "Sr/Sra. Secretario/a" },
			],
		});
		return body;
	}

	static async cabeceraCertificadov1(info: any, imagen: any) {
		/*let c = await this.getBase64ImageFromURL(
			"./assets/images/municipios/arrecife.png"
		).then((e) => e);*/
		const body = []; ``
		body.push({
			margin: [100, 0, 0, 0],
			columns: [
				{ image: imagen, width: 50 },
				{ margin: [10, 30, 0, 0], text: "EXCMA. DIPUTACIÓN ARRECIFE" },
			],
		});
		body.push({
			margin: [0, 20, 0, 20],
			text: "CERTIFICACION DEL INVENTARIO DE BIENES",
			style: "titulo",
		});
		body.push({
			margin: [0, 0, 0, 10],
			text: `D/Dª ${info.nombreSecretario} Secretario/a de ${info.nombreAlcalde}`,
		});
		body.push({ text: "CERTIFICO" });
		return body;
	}

	static async cabeceraCertificado(info: any, imagen: any) {
		const municipioData = JSON.parse(localStorage.getItem("muni"))
		const entidadLocal = JSON.parse(localStorage.getItem("entidad_local"))
		const municipio = municipioData.nombre;
		/*let c = await this.getBase64ImageFromURL(
			"./assets/images/municipios/arrecife.png"
		).then((e) => e);*/
		const body = []; ``
		body.push({
			margin: [150, 0, 0, 0],
			columns: [
				// { image: imagen, width: 50 },
				{ margin: [-100, 35, 0, 0], text: `${entidadLocal.nombre}` , alignment: "center", },
			],
		});
		body.push({columns: [
			{ margin: [0, -50, 0, 0], image: imagen, width: 50 },
			{
			margin: [-20, 10, 0, 10],
			text: "CERTIFICACION DEL INVENTARIO DE BIENES",
			style: "titulo",
		}]});
		body.push({
			margin: [0, 0, 0, 0],
			text: `D/Dª ${info.nombreSecretario} Secretario/a de`,
		});
		body.push({margin: [0, 0, 0, 5], text: `${entidadLocal.nombre}` });
		body.push({text: "CERTIFICO" });
		return body;
	}

	static getBase64ImageFromURL(url: any) {
		console.log("URLLL");
		console.log(url);
		return new Promise((resolve, reject) => {
			var img = new Image();
			img.setAttribute("crossOrigin", "anonymous");

			img.onload = () => {
				var canvas = document.createElement("canvas");
				canvas.width = img?.width;
				canvas.height = img?.height;

				var ctx = canvas.getContext("2d");
				ctx?.drawImage(img, 0, 0);

				var dataURL = canvas.toDataURL("image/png");
				resolve(dataURL);
			};

			img.onerror = (error) => {
				reject(error);
			};

			img.src = url;
		});
	}
}
