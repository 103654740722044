import { MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';
import { FeaturesService } from '../services/features.service';
import { AuthService } from 'app/core/auth/auth.service';
import { environment } from 'environments/environment';
import { DomSanitizer } from '@angular/platform-browser';
import { finalize } from 'rxjs';
import * as JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { ThemePalette } from '@angular/material/core';
import { BienesRevertiblesService } from '../bienes-revertibles/bienes-revertibles.service';


export interface Task {
  name: string;
  label: string;
  completed: boolean;
  color: ThemePalette;
  subtasks?: Task[];
}

@Component({
  selector: 'app-exportar-ficheros',
  templateUrl: './exportar-ficheros.component.html',
  styleUrls: ['./exportar-ficheros.component.scss']
})
export class ExportarFicherosComponent implements OnInit {

  task: Task = {
    name: 'Todos',
    completed: false,
    label: '',
    color: 'primary',
    subtasks: [
      { name: 'Inmuebles Urbanos', label: 'Inmuebles Urbanos', completed: false, color: 'primary' },
      { name: 'Inmuebles Rústicos', label: 'Inmuebles Rusticos', completed: false, color: 'primary' },
      { name: 'Vías Públicas Urbanas', label: 'Vias Publicas Urbanas', completed: false, color: 'primary' },
      { name: 'Vías Públicas Rústicas', label: 'Vias Publicas Rusticas', completed: false, color: 'primary' },
      { name: 'Bienes Revertibles', label: 'Bienes Revertibles', completed: false, color: 'primary' },
      { name: 'Patrimonio Suelo', label: 'Patrimonio Suelo', completed: false, color: 'primary' },
    ],

  };

  allComplete: boolean = false;


  rootPath = "";
  initPath = "";
  loadingDirectory = false;
  userInfo: any;
  isAdmin = false;
  urlApiFiles = environment.urlApi;
  zip = new JSZip()
  totalFiles = 0;
  tamañoZip = 0;
  archivosDownload: any[] = [];
  nombre = "";
  bandera = false;
  banderaSpinner = false;
  banderaSpinnerDownload = false;
  banderaSpinnerDownloadDB=false;
  showSubMenuBaseD: boolean = false;
  showButtonDownloadBaseD = false;
  epigrafesSinData: string[] = [];


  fileUrl;
  treeFiles: any = {
    epigrafes: [
      {
        name: "Inventario de Bienes y Derechos",
        route: "inventario-bienes-derechos",
        subepigrafes: [],
      },
    ],
  };

  constructor(
    public dialogRef: MatDialogRef<ExportarFicherosComponent>,
    private _featureService: FeaturesService,
    private authService: AuthService,
    private sanitizer: DomSanitizer,
    private revertiblesService: BienesRevertiblesService
  ) {

  }

  ngOnInit(): void {
    this.userInfo = JSON.parse(localStorage.getItem("user"));
  }

  onClose(): void {

    this.dialogRef.close();
    this.bandera = false;
  }


  selectRadioOption(option: any) {
    this.totalFiles = 0;
    this.nombre = "";
    this.showSubMenuBaseD = false;
    this.bandera = false;

    this.zip = new JSZip();
    this.rootPath = this.authService.pathMunicipio;
    this.initPath = this.rootPath;
    let carpeta = "";
    if (option.value == "Documentacion") {
      carpeta = "docs";
    } else if (option.value == "Planos") {
      carpeta = "planos"
    } else if (option.value == "Fotografias") {
      carpeta = "fotografias";
    } else if (option.value == "Geometrias") {
      carpeta = "geometrias";
    } else if (option.value == "Base de datos") {
      this.showSubMenuBaseD = true;

    }

    console.log(carpeta);
    console.log(this.initPath + "/" + carpeta);


    if (carpeta != "") {
      this.banderaSpinner = true;
      this._featureService.getPathInfo(this.initPath + "/" + carpeta)
        .subscribe((data: any[]) => {
          this.banderaSpinner = false;
          this.bandera = true;
          this.totalFiles = data.length;
          this.archivosDownload = data;
          this.nombre = option.value;

        });
    }

  }


  descargarZip() {
    let nombreArchivo = this.nombre;
    let binaryData: Blob[] = [];
    this.banderaSpinnerDownload = true;
    this.archivosDownload.forEach(archivo => {
      this._featureService
        .getFileUrl(archivo.downloadUrl)
        .pipe(finalize(() => {
          if (this.totalFiles == binaryData.length) {
            this.zip.generateAsync({ type: "blob" })
              .then(function (binaryData) {
                saveAs(binaryData, nombreArchivo + ".zip");
              });
            this.banderaSpinnerDownload = false;
          }
        }))
        .subscribe((res: Blob) => {
          this.zip.file(archivo.name, res);
          binaryData.push(res);
          this.tamañoZip = this.tamañoZip + res.size;
        });
    });

  }


  generarArchivoDescargarDB(nombreEpigrafe) {
    this.zip = new JSZip();
    this.epigrafesSinData = [];

    if (nombreEpigrafe == "Inmuebles Urbanos") {
      this.banderaSpinnerDownloadDB=true;
      this._featureService.getInmueblesUrbanos(this.userInfo.municipioId).subscribe((data) => {
        if (data.result != null) {
          
          let keys: string[] = [];
          keys = Object.keys(data.result.datosBienes[1]);

          data.result.datosBienes.forEach((dato) => {
            keys.forEach(key => {
              if (dato[key] instanceof Object) {
                dato[key] = dato[key].nombre;
              }
            });
          });
          this.downloadFile(data.result.datosBienes, nombreEpigrafe, keys);
        } else {
          this.epigrafesSinData.push(nombreEpigrafe);
        }

      });

    } else if (nombreEpigrafe == "Inmuebles Rusticos") {
      this.banderaSpinnerDownloadDB=true;
      this._featureService.getDatosEpigrafe(this.userInfo.municipioId, 17).subscribe(data => {
        if (data.result != null) {
    
          let keys: string[] = [];
          keys = Object.keys(data.result.datosBienes[0]);
          data.result.datosBienes.forEach((dato) => {
            keys.forEach(key => {
              if (dato[key] instanceof Object) {
                dato[key] = dato[key].nombre;
              }
            });
          });

          this.downloadFile(data.result.datosBienes, nombreEpigrafe, keys);
        } else {
          this.epigrafesSinData.push(nombreEpigrafe);
        }

      });
    } else if (nombreEpigrafe == "Vias Publicas Urbanas") {
      this.banderaSpinnerDownloadDB=true;
      this._featureService.getDatosEpigrafe(this.userInfo.municipioId, 18).subscribe(data => {
        if (data.result != null) {
   
          let keys: string[] = [];
          keys = Object.keys(data.result.datosBienes[0]);
          data.result.datosBienes.forEach((dato) => {
            keys.forEach(key => {
              if (dato[key] instanceof Object) {
                dato[key] = dato[key].nombre;
              }
            });

          });
          this.downloadFile(data.result.datosBienes, nombreEpigrafe, keys);
        } else {
          this.epigrafesSinData.push(nombreEpigrafe);
        }

      });
    } else if (nombreEpigrafe == "Vias Publicas Rusticas") {
      this.banderaSpinnerDownloadDB=true;
      this._featureService.getDatosEpigrafe(this.userInfo.municipioId, 19).subscribe(data => {
        if (data.result != null) {
  
          let keys: string[] = [];
          keys = Object.keys(data.result.datosBienes[0]);


          data.result.datosBienes.forEach((dato) => {

            keys.forEach(key => {

              if (dato[key] instanceof Object) {
                dato[key] = dato[key].nombre;
              }
            });

          });
          this.downloadFile(data.result.datosBienes, nombreEpigrafe, keys);
        } else {
          this.epigrafesSinData.push(nombreEpigrafe);
        }

      });
    } else if (nombreEpigrafe == "Bienes Revertibles") {
      this.banderaSpinnerDownloadDB=true;
      this._featureService.getDatosEpigrafe(this.userInfo.municipioId, 4).subscribe(data => {
        if (data.result != null) {
  
          let keys: string[] = [];
          keys = Object.keys(data.result.datosBienes[0]);
          data.result.datosBienes.forEach((dato) => {
            keys.forEach(key => {

              if (dato[key] instanceof Object) {
                dato[key] = dato[key].nombre;
              }
            });

          });
          this.downloadFile(data.result.datosBienes, nombreEpigrafe, keys);
        } else {
          this.epigrafesSinData.push(nombreEpigrafe);
        }
      });
    } else if (nombreEpigrafe == "Patrimonio Suelo") {
      this.banderaSpinnerDownloadDB=true;
      this._featureService.getDatosEpigrafe(this.userInfo.municipioId, 20).subscribe(data => {

        if (data.result != null) {
   
          let keys: string[] = [];
          keys = Object.keys(data.result.datosBienes[0]);
          data.result.datosBienes.forEach((dato) => {
            keys.forEach(key => {

              if (dato[key] instanceof Object) {
                dato[key] = dato[key].nombre;
              }
            });
          });
          this.downloadFile(data.result.datosBienes, nombreEpigrafe, keys);
        } else {
          this.epigrafesSinData.push(nombreEpigrafe);
        }
      });
    }
  

  }


  downloadFile(data, filename = 'data', keys) {
    let csvData = this.ConvertToCSV(data, keys);

    let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
    if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
      dwldLink.setAttribute("target", "_blank");
    }
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", filename + ".csv");
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
    this.banderaSpinnerDownloadDB=false;
  }
  ConvertToCSV(objArray, headerList) {
    let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    let str = '';
    let row = '';
    for (let index in headerList) {
      row += headerList[index] + ';';
    }
    row = row.slice(0, -1);
    str += row + '\r\n';
    for (let i = 0; i < array.length; i++) {
      let line = '';
      for (let index in headerList) {
        let head = headerList[index];
        line += array[i][head] + ";";
      }
      str += line + '\r\n';
    }
    return str;
  }



  updateAllComplete() {
    this.allComplete = this.task.subtasks != null && this.task.subtasks.every(t => t.completed);
  }

  descargarBaseDeDatos() {
    this.task.subtasks.forEach(s => {

      if (s.completed) {
        if (s.label == "Inmuebles Urbanos") {
          this.generarArchivoDescargarDB(s.label);
        } else if (s.label == "Inmuebles Rusticos") {
          this.generarArchivoDescargarDB(s.label);
        } else if (s.label == "Vias Publicas Urbanas") {
          this.generarArchivoDescargarDB(s.label);
        }
        else if (s.label == "Vias Publicas Rusticas") {
          this.generarArchivoDescargarDB(s.label);
        } else if (s.label == "Bienes Revertibles") {
          this.generarArchivoDescargarDB(s.label);
        } else if (s.label == "Patrimonio Suelo") {
          this.generarArchivoDescargarDB(s.label);

        }
      }

    });
  }

  someComplete(): boolean {
    if (this.task.subtasks == null) {
      return false;
    }

    if (this.task.subtasks.filter(t => t.completed).length > 0) {
      this.showButtonDownloadBaseD = true;
    } else {
      this.showButtonDownloadBaseD = false;
      this.epigrafesSinData=[];
    }

  

    return this.task.subtasks.filter(t => t.completed).length > 0 && !this.allComplete;
  }

  setAll(completed: boolean) {
    this.allComplete = completed;
    if (this.task.subtasks == null) {
      return;
    }
    this.task.subtasks.forEach(t => (t.completed = completed));
  }
}




