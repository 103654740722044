import { TranslocoModule } from '@ngneat/transloco';
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { MatIconModule } from "@angular/material/icon";
import { MatTooltipModule } from "@angular/material/tooltip";
import { FuseDrawerModule } from "@fuse/components/drawer";
import { MatButtonModule } from "@angular/material/button";
import { DrawerMenuComponent } from "./drawer-menu.component";

@NgModule({
	declarations: [DrawerMenuComponent],
	imports: [
		CommonModule,
		RouterModule,
		MatIconModule,
		MatTooltipModule,
		FuseDrawerModule,
		MatButtonModule,
		TranslocoModule
	],
	exports: [DrawerMenuComponent],
})
export class DrawerMenuModule {}
