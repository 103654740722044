import { ScrapingService } from './../scraping/scraping.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Validators } from '@angular/forms';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FeaturesService } from './../services/features.service';
import { MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-datos-catastro',
  templateUrl: './datos-catastro.component.html',
  styleUrls: ['./datos-catastro.component.scss']
})
export class DatosCatastroComponent implements OnInit {
  provincias: any[];
  userInfo: any;
  form: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<DatosCatastroComponent>,
    private _featureSvc: FeaturesService,
    private _fb: FormBuilder,
    private _snackBar: MatSnackBar,
    private _scrapService: ScrapingService
  ) { }

  ngOnInit(): void {
    this._featureSvc.getProvincias().subscribe({
      next: (res) => this.provincias = res.result,
      error: (err) => console.log(err)
    });
    this.userInfo = JSON.parse(localStorage.getItem("user"));
    this.form = this._fb.group({
      usuario: ["", Validators.required],
      password: ["", Validators.required],
      provincia: ["", Validators.required]
    });
  }

  onClose(): void {
		this.dialogRef.close();
	}

  cargar() {
    if(this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }
    this._scrapService.getScrapingByProvincia([this.form.value.provincia], { 
      dni: this.form.value.usuario, 
      password: this.form.value.password 
    }).subscribe({
      next: (res: any) => {
        this._snackBar.open(res.msg, "Cerrar", {
          duration: 3000,
        });
      },
      error: (err) => {
        this._snackBar.open("Error al obtener los datos", "Cerrar", {
          duration: 3000,
        });
      }
    });
  }

}
