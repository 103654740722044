import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { catchError, Observable, of, switchMap, throwError, map } from "rxjs";
import { AuthUtils } from "app/core/auth/auth.utils";
import { UserService } from "app/core/user/user.service";

import { environment } from "environments/environment";
import { AuthMockApi } from "../../mock-api/common/auth/api";
import jwt_decode from "jwt-decode";

@Injectable()
export class AuthService {
	private _authenticated: boolean = false;
	urlApi = environment.urlApi2;
	urlOld = environment.urlApi;
	_infMunicipio: any;
	/**
	 * Constructor
	 */
	constructor(
		private _httpClient: HttpClient,
		private _userService: UserService,
		private mockApi: AuthMockApi
	) { }

	// -----------------------------------------------------------------------------------------------------
	// @ Accessors
	// -----------------------------------------------------------------------------------------------------

	/**
	 * Setter & getter for access token
	 */
	set accessToken(token: string) {
		localStorage.setItem("accessToken", token);
	}

	get accessToken(): string {
		return localStorage.getItem("accessToken") ?? "";
	}

	get usuario(): any {
		return JSON.parse(localStorage.getItem("user"));
	}

	set infMunicipio(data: any) {
		this._infMunicipio = data;
	}

	get infMunicipio(): any {
		return this._infMunicipio;
	}

	get pathMunicipio(): string {
		return `provincias/${this._infMunicipio.provincia.nombre}/${this._infMunicipio.nombre}/`;
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Public methods
	// -----------------------------------------------------------------------------------------------------

	/**
	 * Forgot password
	 *
	 * @param email
	 */
	forgotPassword(email: string): Observable<any> {
		return this._httpClient.post(`${this.urlApi}request-password-reset`, { email })
			.pipe(
				map((resp) => {
					console.log(resp);
					return resp;
				})
			);
	}

	/**
	 * Reset password
	 *
	 * @param password
	 */
	resetPassword(password: string, token: string): Observable<any> {
		return this._httpClient.post(`${this.urlApi}reset-password`, { password, token })
			.pipe(
				map((resp) => {
					console.log(resp);
					return resp;
				})
			);
	}

	/**
	 * Sign in
	 *
	 * @param credentials
	 */
	signIn(credentials: { email: string; password: string }): Observable<any> {
		// Throw error, if the user is already logged in
		if (this._authenticated) {
			return throwError("User is already logged in.");
		}

		// return this._httpClient.post("api/auth/sign-in", credentials).pipe(
		return this._httpClient.post(`${this.urlApi}login`, credentials).pipe(
			switchMap((response: any) => {
				// Store the access token in the local storage
				if (!response.statusLogin) return of(response);
				if (!response.result && !response.token) {
					throw new Error(response.msg);
				}
				// this.accessToken = response.accessToken;
				(this.accessToken = response.token),
					// Set the authenticated flag to true
					(this._authenticated = true);

				// Store the user on the user service
				const infoUser: any = jwt_decode(response.token);
				console.log(infoUser);
				this._userService.user = infoUser;
				localStorage.setItem("user", JSON.stringify(infoUser));




				// Return a new observable with the response
				return of(response);
			})
		);
	}

	/**
	 * Sign in
	 *
	 * @param credentials
	 */
	signInOld(credentials: { email: string; password: string }): Observable<any> {
		// Throw error, if the user is already logged in
		/* if (this._authenticated) {
			return throwError("User is already logged in.");
		} */

		// return this._httpClient.post("api/auth/sign-in", credentials).pipe(
		return this._httpClient.post(`${this.urlOld}login`, credentials).pipe(
			switchMap((response: any) => {
				// Store the access token in the local storage
				if (!response.result && !response.token) {
					throw new Error(response.msg);
				}
				// this.accessToken = response.accessToken;
				//(this.accessToken = response.token),
				// Set the authenticated flag to true
				this._authenticated = true;

				// Store the user on the user service
				//const infoUser: any = jwt_decode(response.token);
				//console.log(infoUser);
				//this._userService.user = infoUser;
				localStorage.setItem("tokenOld", response.token);

				// Return a new observable with the response
				return of(response);
			})
		);
	}

	/**
	 * Sign in using the access token
	 */
	signInUsingToken(): Observable<any> {
		// Sign in using the token
		return this._httpClient
			.post("api/auth/sign-in-with-token", {
				accessToken: this.accessToken,
			})
			.pipe(
				catchError(() =>
					// Return false
					of(false)
				),
				switchMap((response: any) => {
					// Replace the access token with the new one if it's available on
					// the response object.
					//
					// This is an added optional step for better security. Once you sign
					// in using the token, you should generate a new one on the server
					// side and attach it to the response object. Then the following
					// piece of code can replace the token with the refreshed one.
					if (response.accessToken) {
						this.accessToken = response.accessToken;
					}

					// Set the authenticated flag to true
					this._authenticated = true;

					// Store the user on the user service
					this._userService.user = response.user;

					// Return true
					return of(true);
				})
			);
	}

	/**
	 * Sign out
	 */
	signOut(): Observable<any> {
		// Remove the access token from the local storage
		localStorage.removeItem("accessToken");

		// Remove the information about the user from the local storage
		localStorage.removeItem("user");

		// remove Entidad local
		localStorage.removeItem("entidad_local");

		// remove Entidad local
		localStorage.removeItem("muni");

		// Set the authenticated flag to false
		this._authenticated = false;

		// Return the observable
		return of(true);
	}

	/**
	 * Sign up
	 *
	 * @param user
	 */
	signUp(user: {
		name: string;
		email: string;
		password: string;
		company: string;
	}): Observable<any> {
		return this._httpClient.post("api/auth/sign-up", user);
	}

	/**
	 * Unlock session
	 *
	 * @param credentials
	 */
	unlockSession(credentials: {
		email: string;
		password: string;
	}): Observable<any> {
		return this._httpClient.post("api/auth/unlock-session", credentials);
	}

	/**
	 * Check the authentication status
	 */
	check(): Observable<boolean> {
		// Check if the user is logged in
		if (this._authenticated) {
			return of(true);
		}

		// Check the access token availability
		if (!this.accessToken) {
			return of(false);
		}

		// Check the access token expire date
		if (AuthUtils.isTokenExpired(this.accessToken)) {
			return of(false);
		}

		// If the access token exists and it didn't expire, sign in using it
		return this.signInUsingToken();
	}
}
