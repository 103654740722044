import { Inject, Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { merge } from "lodash-es";
import {
	FUSE_APP_CONFIG,
	HOME_APP_CONFIG,
} from "@fuse/services/config/config.constants";

@Injectable({
	providedIn: "root",
})
export class FuseConfigService {
	private _config: BehaviorSubject<any>;
	private _isHome: BehaviorSubject<boolean>;
	private _showMenu: BehaviorSubject<boolean>;

	/**
	 * Constructor
	 */
	constructor(
		@Inject(FUSE_APP_CONFIG) config: any,
		@Inject(HOME_APP_CONFIG) isHome: any,
		@Inject(HOME_APP_CONFIG) showMenu: any
	) {
		// Private
		this._config = new BehaviorSubject(config);
		this._isHome = new BehaviorSubject(isHome);
		this._showMenu = new BehaviorSubject(showMenu);
		this.showMenu = true;
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Accessors
	// -----------------------------------------------------------------------------------------------------

	/**
	 * Setter & getter for config
	 */
	set config(value: any) {
		// Merge the new config over to the current config
		const config = merge({}, this._config.getValue(), value);

		// Execute the observable
		this._config.next(config);
	}

	// eslint-disable-next-line @typescript-eslint/member-ordering
	get config$(): Observable<any> {
		return this._config.asObservable();
	}

	/**
	 * Setter & getter for isHome
	 */
	set isHome(value: any) {
		// Merge the new config over to the current config
		const isHome = value;
		// Execute the observable
		this._isHome.next(isHome);
	}

	// eslint-disable-next-line @typescript-eslint/member-ordering
	get isHome$(): Observable<any> {
		return this._isHome.asObservable();
	}

	/**
	 * Setter & getter for showMenu
	 */
	set showMenu(value: any) {
		// Execute the observable
		this._showMenu.next(value);
	}

	// eslint-disable-next-line @typescript-eslint/member-ordering
	get showMenu$(): Observable<any> {
		return this._showMenu.asObservable();
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Public methods
	// -----------------------------------------------------------------------------------------------------

	/**
	 * Resets the config to the default
	 */
	reset(): void {
		// Set the config
		this._config.next(this.config);
		this._isHome.next(this.isHome);
	}
}
