import { Injectable } from "@angular/core";
import { PdfService } from "./pdf.service";
import { CurrencySpPipe } from "app/pipes/currency.pipe";
import { NumberPipe } from "app/pipes/number.pipe";
import { ProjectService } from "./project.service";
import { lastValueFrom } from "rxjs";
import { ValoracionPgcpService } from "../valoracion-pgcp/valoracion-pgcp.service";
import { ValoracionGeneralService } from "./../valoracion-general/valoracion-general.service";
import { FeaturesService } from "./features.service";
import { NewFeaturesService } from "../services/newFeatures.service";
import { Document, Paragraph, TextRun, Packer, SectionType, TableRow, TableCell, Table, WidthType, AlignmentType, UnderlineType } from "docx";
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { saveAs } from 'file-saver';
import { InfoProvinciaService } from './info-provincia.service';
import { Utils } from "../../utils/utils";



enum estilosPdf {
  TITULO = "titulo",
  BOLDCURSIVA = "boldCursiva",
  BOLD = "bold",
}

@Injectable({
  providedIn: "root",
})
export class ExportDocumentosPdfService {
  userInfo: any;
  image1 = null;
  imgMunicipio = null;
  infoProvincia: any
  imagenMunicipio: any
  municipioId: any
  year: any;
  customUnderline = {color: '#000000', type: UnderlineType.SINGLE};
  entidad_local:any;

  info: any
  constructor(
    private pdfService: PdfService,
    private currencySpPipe: CurrencySpPipe,
    private numberPipe: NumberPipe,
    private projectService: ProjectService,
    private _valoracionPgcpSvc: ValoracionPgcpService,
    private featureService: FeaturesService,
    private _valoracionGnrl: ValoracionGeneralService,
    private _projectSvc: ProjectService,
    private newFeaturesService: NewFeaturesService,
    private infoProvinciaService: InfoProvinciaService,
    private http: HttpClient, private sanitizer: DomSanitizer
  ) {
    this.userInfo = JSON.parse(localStorage.getItem("user"));
    this.entidad_local = JSON.parse(localStorage.getItem("entidad_local"));
    if (this.userInfo != null) {
      this.newFeaturesService.getInfoProvincia(this.userInfo.municipioId).subscribe({
        next: (res) => {
          this.infoProvincia = res.result
          this.newFeaturesService.getMunicipioImagen(this.infoProvincia.nombre, this.infoProvincia.nombre).subscribe({
            next: (res) => {
              const reader = new FileReader();
              reader.readAsDataURL(res);
              reader.onloadend = () => {
                this.imagenMunicipio = reader.result;
              };
            },
            error: (err) => console.log(err)
          });
        },
        error: (err) => console.log(err)
      });
    }

  }


  getImagenMunicipio(info: any) {
    this.newFeaturesService.getMunicipioImagen(info.provincia.nombre, info.nombre).subscribe({
      next: (res) => {
        const reader = new FileReader();
        reader.readAsDataURL(res);
        reader.onloadend = () => {
          this.imagenMunicipio = reader.result;
        };

      },
      error: (err) => console.log(err)
    });
  }


  invertirFecha(fecha) {
    if (!fecha) {
      return "NO CONSTA";
    }
    const [anio, mes, dia] = fecha.split("/");

    return `${dia}-${mes}-${anio}`;
  }

  getBase64ImageFromURL(url: any) {
    return new Promise((resolve, reject) => {
      var img = new Image();
      img.setAttribute("crossOrigin", "anonymous");

      img.onload = () => {
        var canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;

        var ctx = canvas.getContext("2d");
        ctx?.drawImage(img, 0, 0);

        var dataURL = canvas.toDataURL("image/png");

        resolve(dataURL);
      };

      img.onerror = (error) => {
        reject(error);
      };

      img.src = url;
    });
  }

  async informeActualizacionInventario() {
    const r$ = this._valoracionPgcpSvc.getValoracionPGCPNew(
      this.userInfo.municipioId
    );
    const resp = await lastValueFrom(r$);
    let total = 0;
    const data = resp.result;
    for (const e in data) {
      total += data[e];
    }

    const ab$ = this.projectService.getValoracion(this.userInfo.municipioId);
    const finalNumber = await lastValueFrom(ab$);
    const dataR = finalNumber.result;
    var dd = {
      content: [
        {
          text: `${this.entidad_local?.nombre ?? ''}`,
          fontSize: 24,
          style: "header",
        },
        {
          text: "INFORME DE RESULTADOS",
          style: "header",
        },
        {
          text: "DOCUMENTACIÓN REMITIDA",
          pageBreak: "before",
          style: "subheader",
        },
        "La empresa ha contado con la siguiente documentación para la realización de la actualización del Inventario de Bienes y Derechos de la entidad:",
        {
          ul: [
            "Inventarios de bienes antiguo del año  2009",
            "Listado de finca catastradas a nombre de la Entidad",
          ],
          margin: [20, 10],
        },
        // {
        // 	text: "INCIDENCIAS DETECTADAS",
        // 	style: "subheader",
        // },
        // "En la elaboración del documento de Inventario se han detectado abundantes incidencias consecuencia del mal estado de la cartografía y datos catastrales, y errores manifestados por la corporación en la elaboración de las Normas Urbanísticas que se encuentran aprobadas definitivamente. Igualmente se ha detectado que el deslinde del Término Municipal que consta en la cartografía catastral no coincide con el deslinde de 1911 fijado en la concordia de ese año con los términos colindantes.",

        // {
        // 	text: "Las incidencias detectadas son:",
        // 	style: "parrafo",
        // },

        // {
        // 	margin: [10, 0, 0, 0],
        // 	ol: [
        // 		{
        // 			style: "parrafo",
        // 			text: "Se ha detectado que parte de la finca catastral Pol 507 Parc 15398 parte de su superficie se encuentra dentro de casco urbano. Puede coincidir, según la información aportada por la corporación, con una antigua era privada. En el planeamiento esa superficie consta con fuera de alineación, es decir, viario público, y no como parcela urbana. Se ha procedido a incluir la finca rústica como está en catastro con la observación del error, a la espera de la apertura de expediente de regularización catastral, si se demuestra la titularidad privada de la finca, y modificación puntual del planeamiento para la modificación de alineación.",
        // 		},
        // 		{
        // 			style: "parrafo",
        // 			text: "Se ha detectado error de cabida en el finca Pol 508 Parc 158, sobre la que se sitúa el depósito municipal de agua. La cabida correcta aparece en el vuelo aéreo de 1956 (vuelo americano) Según la corporación, el error proviene del proceso de reorganización de la propiedad de Concentración Parcelaria. La cabida de 1956 era de unos 113 m2 sobre el que se construyó entorno a 1979 el depósito de agua. Se ha procedido a incluir en la cabida original indicando la regularización necesaria de cabida de la finca.",
        // 		},
        // 		{
        // 			style: "parrafo",
        // 			text: "Se ha detectado también la inclusión en catastro y finca enajenadas desde la última actualización del Inventario. En concreto son las fincas Pol 506 Parc 15124, Pol 507 Parc 5614 y Pol 509 Parc 5452. Todas sin documentar. La última de ellas fue baja por permuta por el molino municipal, permutado con D. Sebastián Espinosa. Se ha procedido a su baja pero recomendamos se proceda a su regularización catastral.",
        // 		},
        // 		{
        // 			style: "parrafo",
        // 			text: "Error similar se ha detectado en la adquisición del terreno sobre el que se ubica el repetidor de telecomunicaciones, la ref. catastral que ubica la infraestructura sigue a nombre del titular anterior. Recomendamos se proceda a su regularización catastral.",
        // 		},
        // 		{
        // 			style: "parrafo",
        // 			text: "Otra incidencia detectada afecta a la c/ Mayor y a los equipamientos públicos del frontón y merendero. Según la corporación el acceso a dichos inmuebles se ha realizado desde la prolongación de la c/ Mayor. Catastralmente sin embargo el acceso se realiza desde otra finca catastral de titularidad privada, cuya alineación en el planeamiento coincide con catastro. Se recomienda se inicie expediente de recuperación del dominio público, para proceder a incluir dicha terreno como viario público y se realice la correspondiente modificación puntual de alineación de vía en el planeamiento urbanístico.",
        // 		},
        // 		{
        // 			style: "parrafo",
        // 			text: "Otro error en la titularidad catastral se ha detectado en el solar de la antigua fragua. El solar municipal corresponde a la finca catastral c/ Del Rio 29-A, sin embargo la finca catastrada a nombre del Ayuntamiento es la c/Del Rio 29 y la 29-A se encuentra catastrado a nombre del particular titular de la finca 29. Recomendamos se proceda a su regularización catastral.",
        // 		},
        // 		{
        // 			style: "parrafo",
        // 			text: "Por último se ha detectado caminos con tramos desaparecidos, ocupados por fincas particulares y no recogido en catastro según su trazado original. Se ha procedido a incluir con su trazado original de vía pública a vía pública, y se recomienda se inicie expediente de recuperación de dominio público.",
        // 		},
        // 	],
        // },
        {
          text: "ALTAS, BAJAS y VARIACIONES",
          style: "subheader",
        },
        `Se procede a incluir como ALTAS en el epígrafe Inmuebles Urbanos el cementerio municipal, no catastrado a nombre del Ayuntamiento, el parque infantil construido sobre el solar del antiguo horno, y el subepígrafe Infraestructuras, las fuentes, el molino adquirido por permita, 3 captaciones y una depuradora nueva, y dos puentes.

                En cuanto a fincas rústicas se procedido al alta de Pol 502 Parc 5110, Pol 507 Parc 5092, 5384, 15402, Pol 508 Parc 158 y 5418, Pol 509 Parc 5454, y Pol 11 Parc 369, 407 y 422, actualmente catastradas a ,nombre de la entidad y no recogidas en el documento anterior. Igualmente se han incluidos los ribazos no incluidos previamente.
                
                Con respecto a las BAJAS, se procede a las bajas mencionadas en el apartado incidencias apartado 3.
                
                En el apartado de VARIACIONES,  se procede a actualizar la valoración de los bienes e incluir toda la información adicional suministrada.
                `,
        {
          text: "INFORME RESUMEN DE BIENES",
          style: "subheader",
        },
        `Los bienes del Ayuntamiento clasificado por epígrafes, según el Reglamento de  Bienes es el que se detalla a continuación.`,
        this.buildTabla({
          titulo: "INVENTARIO DE BIENES  Y DERECHOS",
          total: "1075",
          bodyTable: [
            ["EPIGRAFE", "TIPO DE BIEN", "TOTAL"],
            ["1.1.", "Inmuebles urbanos", "232"],
            ["1.1.1.", "Inmuebles urbanos", "232"],
            ["1.1.2.", "Parques y Jardines", "0"],
            ["1.1.3.", "Solares", "0"],
            ["1.1.4.", "Infraestructuras", "0"],
            ["1.2.", "Fincas rústicas", "7"],
            ["1.3.", "Vías públicas urbanas", "603"],
            ["1.4.", "Vías públicas rústicas", "0"],
            ["2", "Derechos reales", "0"],
            [
              "3",
              "Bienes muebles de carácter histórico, artístico o de considerable valor",
              "0",
            ],
            ["4", "Valores mobiliarios, créditos y derechos ", "0"],
            ["5", "Vehículos", "226"],
            ["6", "Semovientes", "0"],
            ["7", "Otros muebles", "0"],
            ["8", "Bienes y derechos revertibles ", "7"],
          ],
        }),

        // this.buildTabla,
        `Con respecto a los Inmuebles Urbanos, se han inventariado DOSCIENTOS TREINTA Y TRES. Divididos en 4 subepígrafes. Se han inventariado un total de 163 inmuebles urbanos, 15 solares, 7 infraestructuras y 48 parques y jardines. Si las consideramos según su naturaleza de dominio se dividen en Inmuebles de Dominio Público – Servicio Público, Inmuebles Patrimoniales e Inmuebles de Dominio Público – Uso Público. 

                Los primeros corresponden fundamentalmente a los edificios que albergan servicios municipales y a las infraestructuras. Su relación es la siguiente:
                `,
        this.buildTabla({
          titulo: "DOMINIO PÚBLICO – SERVICIO PÚBLICO",
          mostrarTotal: false,
          bodyTable: [
            ["EPIGRAFE", "NUM ORD", "DENOMINACIÓN"],
            ["1.1.1.", "1", "AYUNTAMIENTO Y TABERNA"],
            ["1.1.1.", "3", "CONSULTORIO y ANTIGUA CASA DEL MEDICO"],
            ["1.1.1.", "7", "CEMENTERIO"],
            ["1.1.4.", "9", "TORRE DE COMUNICACION"],
            ["1.1.4.", "10", "DEPOSITO"],
            ["1.1.4.", "11", "DEPOSITO REGULADOR DE CANTOBLANCO"],
            ["1.1.4.", "12", "CAPTACION GUINALDAYA"],
            ["1.1.4.", "13", "CAPTACION PARDOTE"],
            ["1.1.4.", "14", "DEPURADORA NUEVA"],
          ],
        }),
        `Los bienes de DP-UP corresponden a bienes cuya utilización es general y cuya conservación y policía son de competencia de la Entidad local. Su relación es la siguiente:`,
        this.buildTabla({
          titulo: "DOMINIO PÚBLICO –USO  PÚBLICO",
          mostrarTotal: false,
          bodyTable: [
            ["EPIGRAFE", "NUM ORD", "DENOMINACIÓN"],
            ["1.1.1.", "6", "FRONTON"],
            ["1.1.2.", "1", "PARQUE INFANTIL"],
            ["1.1.4.", "1", "FUENTE DE LA ESPERANZA"],
            ["1.1.4.", "2", "PILON-FUENTE LA TEJA"],
            ["1.1.4.", "3", "FUENTE LA DEHESA"],
            ["1.1.4.", "4", "FUENTE DE DIOS"],
            ["1.1.4.", "5", "FUENTE LOS COCINOS"],
            ["1.1.4.", "6", "FUENTE ARANA"],
            ["1.1.4.", "7", "LAVADERO"],
            ["1.1.4.", "8", "MOLINO"],
            ["1.1.4.", "15", "PUENTE SANTO"],
            ["1.1.4.", "16", "PUENTE DE LA ISLA"],
          ],
        }),
        `En cuanto a bienes PATRIMONIALES hemos inventariado un total de CUATRO, que corresponde a aquellos bien que no estén destinados a uso público ni afectados a algún servicio público y puedan constituir fuentes de ingresos para el erario de la Entidad.`,
        this.buildTabla({
          titulo: "PATRIMONIAL",
          mostrarTotal: false,
          bodyTable: [
            ["EPIGRAFE", "NUM ORD", "DENOMINACIÓN"],
            ["1.1.1.", "2", "ANTIGUA CASA DEL MAESTRO"],
            ["1.1.1.", "4", "CASA DEL PASTOR"],
            ["1.1.1.", "5", "ALMACEN Y MERENDERO"],
            ["1.1.3.", "1", "SOLAR C/ DEL RÍO 29 (A)"],
          ],
        }),
        {
          style: "parrafo",
          text: "En lo referente a Inmuebles Rústicos, epígrafe 1.2, el Ayuntamiento posee DOSCIENTOS VEINTITRES fincas rústicas, 11 de las cuales son fincas de reemplazo consecuencia de la reorganización de la propiedad por Concentración  Parcelaria, de carácter patrimonial, 5 de carácter inmemorial, y del resto se desconoce el título de propiedad.",
        },
        {
          style: "parrafo",
          text: "En cuanto a la naturaleza de dominio, hay dos Montes de Utilidad Pública, de uso público, 4 fincas de carácter comunal y el resto patrimoniales.",
        },
        {
          style: "parrafo",
          text: "En cuanto a Vías públicas urbanas, epígrafe 1.3, se han inventariado OCHO vías, todas ellas de Dominio  Público – Uso Público.",
        },
        {
          style: "parrafo",
          text: "Las Vías públicas rústicas o camino municipales, epígrafe 1.4, se han inventariado CINCUENTA Y UN caminos. Que supone un total de 40,45 km de red viaria y un ancho medio de 3,50 m. En estado de conservación bastante desigual.",
        },
        {
          style: "parrafo",
          text: "En cuanto a Derechos reales, epígrafe 2 del Reglamente de Bienes de las Entidades Locales, el Ayuntamiento no dispone en la actualidad de bienes de esta naturaleza.",
        },
        {
          style: "parrafo",
          text: "En lo referente a los Muebles de carácter histórico – artístico o de considerable valor económico, epígrafe 3, el Ayuntamiento no dispone en la actualidad de bienes de esta naturaleza.",
        },
        {
          style: "parrafo",
          text: "En relación a los Valores mobiliarios, créditos y derechos, epígrafe 4, el Ayuntamiento no dispone de valores mobiliarios.",
        },
        {
          style: "parrafo",
          text: "Los Vehículos, epígrafe 5, el Ayuntamiento no dispone en la actualidad de bienes de esta naturaleza.",
        },
        {
          style: "parrafo",
          text: "En lo que se refiere a Semovientes, epígrafe 6,  el Ayuntamiento no dispone en la actualidad de ganado en propiedad.",
        },
        {
          style: "parrafo",
          text: "Los muebles no comprendidos en apartados anteriores, epígrafe 7, no se han inventariado muebles en este apartado al no constarnos mobiliario en periodo de amortización.",
        },
        {
          style: "parrafo",
          text: "En Bienes y derechos revertibles, epígrafe 8, el Ayuntamiento no dispone en la actualidad de bienes cedidos o adscritos.",
        },
        {
          text: "VALORACIONES",
          style: "subheader",
        },
        `Los criterios de valoración aplicados a los bienes vienen recogidos en el documento Orden HAP/1781/2013, de 20 de septiembre, por la que se aprueba la Instrucción del modelo normal de contabilidad local, donde se pormenorizan y justifican los criterios empleados para valorar el patrimonio del Ayuntamiento

                Se valora según coste inicial menos amortización, en aquellos bienes que se dispone del dato de adquisición o construcción, y siguiendo el método de coste hallando el valor de reposición de los bienes para aquellos bienes que se desconoce dicho dato. Para la amortización se considera amortización lineal.`,

        this.buildTabla({
          titulo: "RESUMEN GENERAL DE BIENES VALORADOS POR EPIGRAFES",
          total: "0,00 €",
          bodyTable: [
            ["EPIGRAFE", "TIPO DE BIEN", "TOTAL/ €"],
            ["1.1.1.", "Inmuebles urbanos", "0,00 €"],
            ["1.1.2.", "Parques y Jardines", "0,00 €"],
            ["1.1.3.", "Solares", "0,00 €"],
            ["1.1.4.", "Infraestructuras", "0,00 €"],
            ["1.2.", "Fincas rústicas", "0,00 €"],
            ["1.3.", "Vías públicas urbanas", "0,00 €"],
            ["1.4.", "Vías públicas rústicas", "0,00 €"],
            ["2", "Derechos reales", "0,00 €"],
            [
              "3",
              "Bienes muebles de carácter histórico, artístico o de considerable valor",
              "0,00 €",
            ],
            ["4", "Valores mobiliarios, créditos y derechos", "0,00 €"],
            ["5", "Vehículos", "0,00 €"],
            ["6", "Semovientes", "0,00 €"],
            ["7", "Otros muebles", "0,00 €"],
            ["8", "Bienes y derechos revertibles", "0,00 €"],
          ],
        }),

        this.buildTabla({
          titulo: "DOMINIO PÚBLICO –USO  PÚBLICO",
          total: `${this.currencySpPipe.transform(total.toString())}`,
          bodyTable: [
            ["CTA PGCP", "TIPO DE BIEN", "TOTAL/€"],
            [
              "210",
              "Terrenos y bienes naturales",
              `${this.currencySpPipe.transform(data.terrenosBienesNaturales)}`,
            ],
            [
              "211",
              "Construcciones",
              `${this.currencySpPipe.transform(data.construcciones)}`,
            ],
            [
              "212",
              "Infraestructuras",
              `${this.currencySpPipe.transform(data.infraestructuras)}`,
            ],
            [
              "213",
              "Bienes del patrimonio histórico",
              `${this.currencySpPipe.transform(
                data.bienesPatrimonioHistorico
              )}`,
            ],
            [
              "214",
              "Maquinaria y utillaje",
              `${this.currencySpPipe.transform(data.maquinariaUtilaje)}`,
            ],
            [
              "215",
              "Instalaciones técnicas y otras instalaciones",
              `${this.currencySpPipe.transform(
                data.instalacionesTecnicasOtrasInstalaciones
              )}`,
            ],
            [
              "216",
              "Mobiliario",
              `${this.currencySpPipe.transform(data.mobiliario)}`,
            ],
            [
              "217",
              "Equipos para procesos de información",
              `${this.currencySpPipe.transform(
                data.equiposParaProcesosDeInformacion
              )}`,
            ],
            [
              "218",
              "Elementos de transporte",
              `${this.currencySpPipe.transform(data.elementosDeTransporte)}`,
            ],
            [
              "219",
              "Otro Inmovilizado Material",
              `${this.currencySpPipe.transform(data.otroInmovilizadoMaterial)}`,
            ],
          ],
        }),
        { margin: [0, 30], text: `En ___, a __ de __ de 2022` },
        {
          text: "Fdo. Marta Bueno Antúnez",
          alignment: "center",
          margin: [0, 50, 0, 0],
        },
        {
          text: "Arquitecto",
          alignment: "center",
        },
      ],
      defaultStyle: { aligment: "justify", margin: [0, 10] },
      styles: {
        header: {
          fontSize: 18,
          bold: true,
          margin: [0, 20],
        },
        subheader: {
          fontSize: 15,
          bold: true,
          color: "#3d85c6",
          margin: [0, 10],
        },
        parrafo: {
          margin: [0, 10],
        },
        quote: {
          italics: true,
          font: "TimesNewRoman",
        },
        small: {
          fontSize: 8,
        },
      },
    };

    this.pdfService.generatePdf(dd);
  }

  generateTable(data) {
    const table = data.map((rowData, index) => {
      const cells = rowData.map((cellData, cellIndex) => {
        return new TableCell({
          children: [
            new Paragraph({
              children: [
                new TextRun({
                  text: cellData,
                  bold: true,
                  size: 24,
                  font: "Calibri",
                }),
              ],
              alignment: cellIndex === rowData.length - 1 ? AlignmentType.RIGHT : AlignmentType.LEFT,
            }),
          ],
        });
      });

      return new TableRow({
        children: cells,
        tableHeader: index === 0,
      });
    });
    return table;
  }

  async informeActualizacionInventarioWord(info: any = null) {
    if (info == null) {
      info = this.infoProvincia
    }
    const r$ = this._valoracionPgcpSvc.getValoracionPGCPNew(
      info.id
    );
    const resp = await lastValueFrom(r$);

    let total = 0;
    const data = resp.result;
    for (const e in data) {
      total += data[e];
    }


    const ab$ = this.projectService.getValoracion(info.id);
    const finalNumber = await lastValueFrom(ab$);
    const dataR = finalNumber.result.valoracion;

    /** ------------------------------------------------
     * TABLA INVENTARIO DE BIENES Y DERECHOS
      ------------------------------------------------*/
    const resResumen = this._projectSvc.getResumen(info.id);
    const lastResumen = await lastValueFrom(resResumen);
    const bienesDerechosTabla = [["EPIGRAFE", "TIPO DE BIEN", "TOTAL"]];
    let totalBienesInmuebles = 0;
    let numSubepigrafesInmublesUrbanos = 0;
    if(Object.keys(lastResumen.result.totalDeBienes).length > 0){
      const generalBienes = lastResumen.result.totalDeBienes.GeneralBienes
      const generalDerechos = lastResumen.result.totalDeBienes.GeneralDerechos
      let totalBienesDerechos = 0
      
      if(generalBienes.length > 0) {
        generalBienes.forEach((lv1) => {
          if(lv1.id == 3){
            totalBienesInmuebles = lv1.total;
            numSubepigrafesInmublesUrbanos = lv1.subepigrafes.length;
          }
          if(!isNaN(lv1.codigo) || (lv1.total && lv1.total > 0)){
          bienesDerechosTabla.push([lv1.codigo, lv1.nombre, lv1.total]);
          totalBienesDerechos = totalBienesDerechos + parseInt(lv1.total)
          }
          if (lv1.codigo == "1") {
            lv1.subepigrafes.forEach(lv2 => {
              bienesDerechosTabla.push([lv2.codigo, lv2.nombre, lv2.total]);
              // totalBienesDerechos = totalBienesDerechos + parseInt(lv2.total)
              if (lv2.nombre.includes("Inmuebles urbanos")) {
                lv2.subepigrafes.forEach(lv3 => {
                  bienesDerechosTabla.push([lv3.codigo, `\t${lv3.nombre}`, lv3.total]);
                });
              }
            });
          }
          // bienesDerechosTabla.push([lv1.codigo, lv1.nombre, lv1.total]);
          // totalBienesDerechos = totalBienesDerechos + parseInt(lv1.total)
        });
      }
  
      if(generalBienes.length > 0) {
        generalDerechos.forEach((lv1) => {
          bienesDerechosTabla.push([lv1.codigo, lv1.nombre, lv1.total]);
        });
      }
  
      const bienesDerechosRows = this.generateTable(bienesDerechosTabla)
  
  
      /** ------------------------------------------------
       * SECCION JURIDICA
      ------------------------------------------------*/
  
  
  
      const resJuridica = this._valoracionPgcpSvc.getDatosJuridicav2(
        info.id
      );
  
      const datosJuridica = await lastValueFrom(resJuridica);
      console.log(datosJuridica)
  
      let datosPatrimonial: any
      let datosDPUP: any
      let datosDPSP: any
      let totalPatrimonial
  
      let tableServicioPublico = [["EPIGRAFE", "NUM ORD", "DENOMINACIÓN"]]
      let tableUsoPublico = [["EPIGRAFE", "NUM ORD", "DENOMINACIÓN"]]
      let tablePatrimonial = [["EPIGRAFE", "NUM ORD", "DENOMINACIÓN"]]
  
      console.log(datosJuridica.result.length)
  
      if (datosJuridica.result.length > 0) {
        datosPatrimonial = datosJuridica.result[1]
        datosDPUP = datosJuridica.result[2]
        datosDPSP = datosJuridica.result[0]
        datosDPSP.bienes.forEach(element => {
          if (element.nombre != null) {
            tableServicioPublico.push([
              element.epigrafe.codigo,
              element.n_orden.toString(),
              element.nombre
            ])
          }
        });
        datosDPUP.bienes.forEach(element => {
          if (element.nombre != null) {
            tableUsoPublico.push([
              element.epigrafe.codigo,
              element.n_orden.toString(),
              element.nombre
            ])
          }
        });
        datosPatrimonial.bienes.forEach(element => {
          if (element.nombre != null) {
            tablePatrimonial.push([
              element.epigrafe.codigo,
              element.n_orden.toString(),
              element.nombre
            ])
          }
        });
        totalPatrimonial = datosPatrimonial.bienes.length
  
      }
  
  
      const rowsServicioPublico = this.generateTable(tableServicioPublico);
      const rowsUsoPublico = this.generateTable(tableUsoPublico);
      const rowsPatrimonial = this.generateTable(tablePatrimonial);
      const rValoracion = this._valoracionGnrl.getValoracionGeneral(
        info.id
      );
      const respValoracion = await lastValueFrom(rValoracion);
  
      const tableResumen = [["EPIGRAFE", "TIPO DE BIEN", "TOTAL/ €"]];
      let totalValoracion = 0;
  
      respValoracion.result.valoracion.forEach((el) => {
        if (!isNaN(el.codigo) || (el.total && el.total > 0)) {
          if (el.codigo != "1") {
            tableResumen.push([
              el.codigo,
              el.nombre,
              `${this.currencySpPipe.transform(el.total)}`,
            ]);
            totalValoracion = totalValoracion + el.total;
          } else {
            if (el.subepigrafes) {
              el.subepigrafes.forEach((se) => {
                if (se.subepigrafes) {
                  se.subepigrafes.forEach((se2) => {
                    tableResumen.push([
                      se2.codigo,
                      se2.nombre,
                      `${this.currencySpPipe.transform(se2.total)}`,
                    ]);
                    totalValoracion = totalValoracion + se2.total;
                  });
                } else {
                  tableResumen.push([
                    se.codigo,
                    se.nombre,
                    `${this.currencySpPipe.transform(se.total)}`,
                  ]);
                  totalValoracion = totalValoracion + se.total;
                }
              });
            } else {
              tableResumen.push([
                el.codigo,
                el.nombre,
                `${this.currencySpPipe.transform(el.total)}`,
              ]);
              totalValoracion = totalValoracion + el.total;
            }
          }
        }
      });
      const rowsResumen = this.generateTable(tableResumen);
  
  
  
      const tableDominio = [["CTA PGCP", "TIPO DE BIEN", "TOTAL/€"]];
      let totalDominio = 0;
  
      data.forEach((el) => {
        if(isNaN(el.nombre) || (el.total && el.total > 0)){
        tableDominio.push([
          el.codigo,
          el.nombre,
          `${this.currencySpPipe.transform(el.total)}`,
        ]);
  
        totalDominio = totalDominio + el.total;
        }
      });
  
  
      const rowsDominio = this.generateTable(tableDominio);
  
      const doc = new Document({
        sections: [
          {
            properties: {
              type: SectionType.NEXT_PAGE,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: `${this.entidad_local?.nombre ?? ''}`,
                    bold: true,
                    font: "Calibri",
                    size: 48,
                  }),
                ],
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: "INFORME DE RESULTADOS",
                    bold: true,
                    font: "Calibri",
                  }),
                ],
              }),
            ],
          },
          {
            properties: {},
            children: [
              // new Paragraph({
              //   children: [
              //     new TextRun({
              //       text: "DOCUMENTACIÓN REMITIDA",
              //       bold: true,
              //       color: "#719ee2",
              //       font: "Calibri",
              //       size: 35,
              //     }),
              //   ],
              //   spacing: {
              //     before: 300,
              //     after: 100,
              //   },
              // }),
              // new Paragraph({
              //   children: [
              //     new TextRun({
              //       text: "La empresa ha contado con la siguiente documentación para la realización de la actualización del Inventario de Bienes y Derechos de la entidad:",
              //       size: 24,
              //       font: "Calibri",
              //     }),
              //   ],
              // }),
              // new Paragraph({
              //   children: [
              //     new TextRun({
              //       text: "• Inventarios de bienes antiguo del año 2009",
              //       size: 24,
              //       font: "Calibri",
              //     }),
              //   ],
              //   spacing: {
              //     before: 40,
              //     after: 10,
              //   },
              // }),
              // new Paragraph({
              //   children: [
              //     new TextRun({
              //       text: "• Listado de finca catastradas a nombre de la Entidad",
              //       size: 24,
              //       font: "Calibri",
              //     }),
              //   ],
              //   spacing: {
              //     before: 40,
              //     after: 10,
              //   },
              // }),
              // new Paragraph({
              //   children: [
              //     new TextRun({
              //       text: "DOCUMENTACIÓN REMITIDA",
              //       bold: true,
              //       color: "#719ee2",
              //       size: 35,
              //       font: "Calibri",
              //     }),
              //   ],
              //   spacing: {
              //     before: 300,
              //     after: 100,
              //   },
              // }),
              // new Paragraph({
              //   children: [
              //     new TextRun({
              //       text: "Se procede a incluir como ALTAS en el epígrafe Inmuebles Urbanos el cementerio municipal, no catastrado a nombre del Ayuntamiento, el parque infantil construido sobre el solar del antiguo horno, y el subepígrafe Infraestructuras, las fuentes, el molino adquirido por permita, 3 captaciones y una depuradora nueva, y dos puentes.",
              //       size: 24,
              //       font: "Calibri",
              //     }),
              //   ],
              //   spacing: {
              //     before: 300,
              //     after: 100,
              //   },
              // }),
              // new Paragraph({
              //   children: [
              //     new TextRun({
              //       text: "Se ha procedido al alta de las parcelas Pol 502 Parc 5110, Pol 507 Parc 5092, 5384, 15402, Pol 508 Parc 158 y 5418, Pol 509 Parc 5454 y Pol 11 Parc 369, 407 y 422, actualmente catastradas a nombre de la entidad y se han incluido los ribazos no incluidos anteriormente.",
              //       size: 24,
              //       font: "Calibri",
              //     }),
              //   ],
              //   spacing: {
              //     before: 300,
              //     after: 100,
              //   },
              // }),
              // new Paragraph({
              //   children: [
              //     new TextRun({
              //       text: "Con respecto a las BAJAS, se procede a las bajas mencionadas en el apartado incidencias apartado 3.",
              //       size: 24,
              //       font: "Calibri",
              //     }),
              //   ],
              //   spacing: {
              //     before: 300,
              //     after: 100,
              //   },
              // }),
              // new Paragraph({
              //   children: [
              //     new TextRun({
              //       text: "En el apartado de VARIACIONES, se procede a actualizar la valoración de los bienes e incluir toda la información adicional suministrada",
              //       size: 24,
              //       font: "Calibri",
              //     }),
              //   ],
              // }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: "INFORME RESUMEN DE BIENES",
                    bold: true,
                    color: "#719ee2",
                    size: 35,
                    font: "Calibri",
                  }),
                ],
                spacing: {
                  before: 300,
                  after: 100,
                },
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: "En el apartado de VARIACIONES, se procede a actualizar la valoración de los bienes e incluir toda la información adicional suministrada",
                    size: 24,
                    font: "Calibri",
                  }),
                ],
                spacing: {
                  before: 300,
                  after: 100,
                },
              }),
              new Table({
                width: { size: 100, type: WidthType.PERCENTAGE },
                rows: [
                  new TableRow({
                    children: [
                      new TableCell({
                        children: [
                          new Paragraph({
                            children: [
                              new TextRun({
                                text: "INVENTARIO DE BIENES Y DERECHOS",
                                bold: true,
                                font: "Calibri",
                                size: 28,
                              }),
                            ],
                            shading: {
                              fill: "00B0F0",
                            },
                            alignment: AlignmentType.CENTER,
                          }),
                        ],
                        columnSpan: 3,
                      }),
                    ],
                  }),
                  ...bienesDerechosRows,
                  new TableRow({
                    children: [
                      new TableCell({
                        children: [
                          new Paragraph({
                            children: [
                              new TextRun({
                                text: "TOTAL BIENES",
                                bold: true,
                                font: "Calibri",
                                size: 24,
                              }),
                            ],
                            shading: {
                              fill: "00B0F0",
                            },
                            alignment: AlignmentType.RIGHT,
                          }),
                        ],
                        columnSpan: 2,
                      }),
                      new TableCell({
                        children: [
                          new Paragraph({
                            children: [
                              new TextRun({
                                text: totalBienesDerechos.toString(),
                                bold: true,
                                font: "Calibri",
                                size: 24,
                              }),
                            ],
                            shading: {
                              fill: "00B0F0",
                            },
                            alignment: AlignmentType.CENTER,
                          }),
                        ],
                      }),
                    ],
                  }),
                ],
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: `Con respecto a los `,
                    size: 24,
                    font: "Calibri",
                  }),
                  new TextRun({
                    text: `Inmuebles Urbanos`,
                    size: 24,
                    bold: true,
                    underline: this.customUnderline,
                    font: "Calibri",
                  }),
                  new TextRun({
                    text: `, se han inventariado ${Utils.numeroEnLetras(totalBienesInmuebles).toLocaleUpperCase()}. Divididos en ${numSubepigrafesInmublesUrbanos} subepígrafes. Se han inventariado un total de ${generalBienes[0].subepigrafes[0].total} inmuebles urbanos, ${generalBienes[0].subepigrafes[0].subepigrafes[2].total} solares, ${generalBienes[0].subepigrafes[0].subepigrafes[3].total}  infraestructuras y ${generalBienes[0].subepigrafes[0].subepigrafes[1].total}  parques y jardines. Si las consideramos según su naturaleza de dominio se dividen en Inmuebles de Dominio Público – Servicio Público, Inmuebles Patrimoniales e Inmuebles de Dominio Público – Uso Público`,
                    size: 24,
                    font: "Calibri",
                  }),
                ],
                spacing: {
                  before: 300,
                  after: 100,
                },
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: "Los primeros corresponden fundamentalmente a los edifcios que albergan servicios municipales y a las infraestructuras. Su relación es la siguiente:",
                    size: 24,
                    font: "Calibri",
                  }),
                ],
                spacing: {
                  before: 300,
                  after: 100,
                },
              }),
              new Table({
                width: { size: 100, type: WidthType.PERCENTAGE },
                rows: [
                  new TableRow({
                    children: [
                      new TableCell({
                        children: [
                          new Paragraph({
                            children: [
                              new TextRun({
                                text: "DOMINIO PÚBLICO – SERVICIO PÚBLICO",
                                bold: true,
                                font: "Calibri",
                                size: 28,
                              }),
                            ],
                            shading: {
                              fill: "00B0F0",
                            },
                            alignment: AlignmentType.CENTER,
                          }),
                        ],
                        columnSpan: 3,
                      }),
                    ],
                  }),
                  ...rowsPatrimonial,
                ],
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: "Los bienes de DP-UP corresponden a bienes cuya utilización es general y cuya conservación y policía son de competencia de la Entidad local. Su relación es la siguiente:",
                    size: 24,
                    font: "Calibri",
                  }),
                ],
                spacing: {
                  before: 300,
                  after: 100,
                },
              }),
              new Table({
                width: { size: 100, type: WidthType.PERCENTAGE },
                rows: [
                  new TableRow({
                    children: [
                      new TableCell({
                        children: [
                          new Paragraph({
                            children: [
                              new TextRun({
                                text: "DOMINIO PÚBLICO – USO PÚBLICO",
                                bold: true,
                                font: "Calibri",
                                size: 28,
                              }),
                            ],
                            shading: {
                              fill: "00B0F0",
                            },
                            alignment: AlignmentType.CENTER,
                          }),
                        ],
                        columnSpan: 3,
                      }),
                    ],
                  }),
                  ...rowsUsoPublico,
                ],
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: `En cuanto a bienes PATRIMONIALES hemos inventariado un total de ${Utils.numeroEnLetras(totalPatrimonial).toLocaleUpperCase()}, que corresponde a aquellos bien que no estén destinados a uso público ni afectados a algún servicio público y puedan constituir fuentes de ingresos para el erario de la Entidad`,
                    size: 24,
                    font: "Calibri",
                  }),
                ],
                spacing: {
                  before: 300,
                  after: 100,
                },
              }),
              new Table({
                width: { size: 100, type: WidthType.PERCENTAGE },
                rows: [
                  new TableRow({
                    children: [
                      new TableCell({
                        children: [
                          new Paragraph({
                            children: [
                              new TextRun({
                                text: "PATRIMONIAL",
                                bold: true,
                                font: "Calibri",
                                size: 28,
                              }),
                            ],
                            shading: {
                              fill: "00B0F0",
                            },
                            alignment: AlignmentType.CENTER,
                          }),
                        ],
                        columnSpan: 3,
                      }),
                    ],
                  }),
                  ...rowsServicioPublico,
                ],
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: `En lo referente a `,
                    size: 24,
                    font: "Calibri",
                  }),
                  new TextRun({
                    text: `Inmuebles Rústicos`,
                    size: 24,
                    font: "Calibri",
                    bold: true,
                    underline: this.customUnderline,
                  }),
                  new TextRun({
                    text: `, epígrafe 1.2, el Ayuntamiento posee ${generalBienes[0].subepigrafes[1].total} fincas rústicas, de las cuales son fncas de reemplazo consecuencia de la reorganización de la propiedad por Concentración Parcelaria, de carácter patrimonial, [...] de carácter inmemorial, y del resto se desconoce el título de propiedad.`,
                    size: 24,
                    font: "Calibri",
                  }),
                ],
                spacing: {
                  before: 300,
                  after: 100,
                },
              }),



              new Paragraph({
                children: [
                  new TextRun({
                    text: "En cuanto a la naturaleza de dominio, hay [...] Montes de Utilidad Pública, de uso público, [...] fncas de carácter comunal y el resto patrimoniales.",
                    size: 24,
                    font: "Calibri",
                  }),
                ],
                spacing: {
                  before: 300,
                  after: 100,
                },
              }),

              

              new Paragraph({
                children: [
                  new TextRun({
                    text: `En cuanto a `,
                    size: 24,
                    font: "Calibri",
                  }),
                  new TextRun({
                    text: `Vías públicas urbanas`,
                    size: 24,
                    font: "Calibri",
                    bold: true,
                    underline: this.customUnderline,
                  }),
                  new TextRun({
                    text: `, epígrafe 1.3, se han inventariado ${generalBienes[0].subepigrafes[2].total} vías, [...] de Dominio Público – Servicio Público.`,
                    size: 24,
                    font: "Calibri",
                  }),
                ],
                spacing: {
                  before: 300,
                  after: 100,
                },
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: `Las `,
                    size: 24,
                    font: "Calibri",
                  }),
                  new TextRun({
                    text: `Vías públicas rústicas o camino municipales`,
                    size: 24,
                    font: "Calibri",
                    bold: true,
                    underline: this.customUnderline,
                  }),
                  new TextRun({
                    text: `, epígrafe 1.4, se han inventariado ${generalBienes[0].subepigrafes[3].total} caminos. Que supone un total de [...] km de red viaria y un ancho medio de [...] m. En estado de conservación bastante desigual.`,
                    size: 24,
                    font: "Calibri",
                  }),
                ],
                spacing: {
                  before: 300,
                  after: 100,
                },
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: `En cuanto a `,
                    size: 24,
                    font: "Calibri",
                  }),
                  new TextRun({
                    text: `Derechos reales`,
                    size: 24,
                    font: "Calibri",
                    bold: true,
                    underline: this.customUnderline,
                  }),
                  new TextRun({
                    text: `, epígrafe 2 del Reglamente de Bienes de las Entidades Locales, el Ayuntamiento dispone de ${generalDerechos[0].total}  bienes de esta naturaleza`,
                    size: 24,
                    font: "Calibri",
                  }),
                ],
                spacing: {
                  before: 300,
                  after: 100,
                },
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: `En lo referente a los `,
                    size: 24,
                    font: "Calibri",
                  }),
                  new TextRun({
                    text: `Muebles de carácter histórico – artístico o de considerable valor económico`,
                    size: 24,
                    font: "Calibri",
                    bold: true,
                    underline: this.customUnderline,
                  }),
                  new TextRun({
                    text: `, epígrafe 3, el Ayuntamiento ${generalBienes[1].total !=0 ? 'dispone en la actualidad de '+generalBienes[1].total +'bienes de esta naturaleza.' : 'no dispone en la actualidad de bienes.'}`,
                    size: 24,
                    font: "Calibri",
                  }),
                ],
                spacing: {
                  before: 300,
                  after: 100,
                },
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: `En relación a los `,
                    size: 24,
                    font: "Calibri",
                  }),
                  new TextRun({
                    text: `Valores mobiliarios, créditos y derechos`,
                    size: 24,
                    font: "Calibri",
                    bold: true,
                    underline: this.customUnderline,
                  }),
                  new TextRun({
                    text: `, epígrafe 4, el Ayuntamiento dispone de ${generalDerechos[1].total} valores mobiliarios.`,
                    size: 24,
                    font: "Calibri",
                  }),
                ],
                spacing: {
                  before: 300,
                  after: 100,
                },
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: `Los `,
                    size: 24,
                    font: "Calibri",
                  }),
                  new TextRun({
                    text: `Vehículos`,
                    size: 24,
                    font: "Calibri",
                    bold: true,
                    underline: this.customUnderline,
                  }),
                  new TextRun({
                    text: `, epígrafe 5, el Ayuntamiento dispone en la actualidad de ${generalBienes[2].total} bienes de esta naturaleza.`,
                    size: 24,
                    font: "Calibri",
                  }),
                ],
                spacing: {
                  before: 300,
                  after: 100,
                },
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: `En lo que se refiere a `,
                    size: 24,
                    font: "Calibri",
                  }),
                  new TextRun({
                    text: `Semovientes`,
                    size: 24,
                    font: "Calibri",
                    bold: true,
                    underline: this.customUnderline,
                  }),
                  new TextRun({
                    text: `, epígrafe 6,  el Ayuntamiento dispone en la actualidad de ${generalBienes[3].total} ganado en propiedad.`,
                    size: 24,
                    font: "Calibri",
                  }),
                ],
                spacing: {
                  before: 300,
                  after: 100,
                },
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: "Los ",
                    size: 24,
                    font: "Calibri",
                  }),
                  new TextRun({
                    text: "muebles no comprendidos en apartados anteriores",
                    size: 24,
                    font: "Calibri",
                    bold: true,
                    underline: this.customUnderline,
                  }),
                  new TextRun({
                    text: ", epígrafe 7, no se han inventariado muebles en este apartado al no constarnos mobiliario en periodo de amortización.",
                    size: 24,
                    font: "Calibri",
                  }),
                ],
                spacing: {
                  before: 300,
                  after: 100,
                },
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: `En `,
                    size: 24,
                    font: "Calibri",
                  }),
                  new TextRun({
                    text: `Bienes y derechos revertibles`,
                    size: 24,
                    font: "Calibri",
                    bold: true,
                    underline: this.customUnderline,
                  }),
                  new TextRun({
                    text: `, epígrafe 8, el Ayuntamiento dispone en la actualidad de ${generalDerechos[2].total} bienes cedidos o adscritos.`,
                    size: 24,
                    font: "Calibri",
                  }),
                ],
                spacing: {
                  before: 300,
                  after: 100,
                },
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: "VALORACIONES",
                    bold: true,
                    color: "#719ee2",
                    size: 35,
                    font: "Calibri",
                  }),
                ],
                spacing: {
                  before: 300,
                  after: 100,
                },
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: "Los criterios de valoración aplicados a los bienes vienen recogidos en el documento Orden HAP/1781/2013, de 20 de septiembre, por la que se aprueba la Instrucción del modelo normal de contabilidad local, donde se pormenorizan y justifican los criterios empleados para valorar el patrimonio del Ayuntamiento.",
                    size: 24,
                    font: "Calibri",
                  }),
                ],
                spacing: {
                  before: 300,
                  after: 100,
                },
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: "Se valora según coste inicial menos amortización, en aquellos bienes que se dispone del dato de adquisición o construcción, y siguiendo el método de coste hallando el valor de reposición de los bienes para aquellos bienes que se desconoce dicho dato. Para la amortización se considera amortización lineal.",
                    size: 24,
                    font: "Calibri",
                  }),
                ],
                spacing: {
                  before: 400,
                  after: 100,
                },
              }),
              new Table({
                width: { size: 100, type: WidthType.PERCENTAGE },
                rows: [
                  new TableRow({
                    children: [
                      new TableCell({
                        children: [
                          new Paragraph({
                            children: [
                              new TextRun({
                                text: "RESUMEN GENERAL DE BIENES VALORADOS POR EPIGRAFES",
                                bold: true,
                                font: "Calibri",
                                size: 28,
                              }),
                            ],
                            shading: {
                              fill: "00B0F0",
                            },
                            alignment: AlignmentType.CENTER,
                          }),
                        ],
                        columnSpan: 3,
                      }),
                    ],
                  }),
                  ...rowsResumen,
                  new TableRow({
                    children: [
                      new TableCell({
                        children: [
                          new Paragraph({
                            children: [
                              new TextRun({
                                text: "TOTAL BIENES",
                                bold: true,
                                font: "Calibri",
                                size: 24,
                              }),
                            ],
                            shading: {
                              fill: "00B0F0",
                            },
                            alignment: AlignmentType.RIGHT,
                          }),
                        ],
                        columnSpan: 2,
                      }),
                      new TableCell({
                        children: [
                          new Paragraph({
                            children: [
                              new TextRun({
                                text: `${this.currencySpPipe.transform(
                                  totalValoracion.toString()
                                )}`,
                                bold: true,
                                font: "Calibri",
                                size: 24,
                              }),
                            ],
                            shading: {
                              fill: "00B0F0",
                            },
                            alignment: AlignmentType.CENTER,
                          }),
                        ],
                      }),
                    ],
                  }),
                ],
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: "",
                  }),
                ],
                spacing: {
                  before: 300,
                  after: 100,
                },
              }),
  
              new Table({
                width: { size: 100, type: WidthType.PERCENTAGE },
                rows: [
                  new TableRow({
                    children: [
                      new TableCell({
                        children: [
                          new Paragraph({
                            children: [
                              new TextRun({
                                text: "RESUMEN GENERAL DE BIENES VALORADOS POR CUENTA PGCP",
                                bold: true,
                                font: "Calibri",
                                size: 28,
                              }),
                            ],
                            shading: {
                              fill: "00B0F0",
                            },
                            alignment: AlignmentType.CENTER,
                          }),
                        ],
                        columnSpan: 3,
                      }),
                    ],
                  }),
                  ...rowsDominio,
                  new TableRow({
                    children: [
                      new TableCell({
                        children: [
                          new Paragraph({
                            children: [
                              new TextRun({
                                text: "TOTAL BIENES",
                                bold: true,
                                font: "Calibri",
                                size: 24,
                              }),
                            ],
                            shading: {
                              fill: "00B0F0",
                            },
                            alignment: AlignmentType.RIGHT,
                          }),
                        ],
                        columnSpan: 2,
                      }),
                      new TableCell({
                        children: [
                          new Paragraph({
                            children: [
                              new TextRun({
                                text: `${this.currencySpPipe.transform(
                                  totalDominio.toString()
                                )}`,
                                bold: true,
                                font: "Calibri",
                                size: 24,
                              }),
                            ],
                            shading: {
                              fill: "00B0F0",
                            },
                            alignment: AlignmentType.CENTER,
                          }),
                        ],
                      }),
                    ],
                  }),
                ],
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: "En ___, a __ de __ de 2022",
                    size: 24,
                    font: "Calibri",
                  }),
                ],
                spacing: {
                  before: 600,
                  after: 100,
                },
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: "Fdo. Marta Bueno Antúnez",
                    size: 24,
                    font: "Calibri",
                  }),
                ],
                spacing: {
                  before: 100,
                  after: 100,
                },
                alignment: AlignmentType.CENTER,
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: "Arquitecto",
                    size: 24,
                    font: "Calibri",
                  }),
                ],
                spacing: {
                  before: 100,
                  after: 100,
                },
                alignment: AlignmentType.CENTER,
              }),
            ],
          },
        ],
      });
  
      Packer.toBlob(doc).then((blob) => {
        // saveAs from FileSaver will download the file
        saveAs(blob, "Informe-Resultados.docx");
      });
      return true

    }
  }

  buildTabla({
    titulo = "",
    total = "0",
    mostrarTotal = true,
    bodyTable = [],
  }) {
    return {
      columns: [
        { width: "*", text: "" },
        {
          style: "tableExample",
          alignment: "center",
          margin: [0, 20],
          width: "auto",
          table: {
            alignment: "center",
            body: [
              [
                {
                  text: titulo,
                  bold: true,
                  colSpan: 3,
                  alignment: "center",
                  fillColor: "#3a7dba",
                },
                {},
                {},
              ],
              ...bodyTable,
              !mostrarTotal
                ? [
                  {
                    text: "",
                    border: this.bordersType.top,
                  },
                  {
                    text: "",
                    border: this.bordersType.top,
                  },
                  {
                    text: "",
                    border: this.bordersType.top,
                  },
                ]
                : [
                  {
                    text: "TOTAL BIENES",
                    bold: true,
                    colSpan: 2,
                    alignment: "right",
                    fillColor: "#3a7dba",
                  },
                  {},
                  {
                    text: total,
                    bold: true,
                    alignment: "center",
                    fillColor: "#3a7dba",
                  },
                ],
            ],
          },
        },
        { width: "*", text: "" },
      ],
    };
  }


  async valoracionPGCP(info: any = null) {
    let municipioId
    if (info == null) {
      municipioId = this.userInfo.municipioId
    } else {
      this.infoProvincia = info.data
      this.imagenMunicipio = info.imagen
      municipioId = info.data.id
    }

    const r$ = this._valoracionPgcpSvc.getValoracionPGCPNew(
      municipioId
    );
    const resp = await lastValueFrom(r$);
    let total = 0;
    const data = resp.result;

    let cuentasPGCP = [];

    console.log(data)

    data.forEach((cta) => {
      total += cta.total;

      if(cta.codigo != '209' && cta.codigo != '250' ) {
        if (cta.codigo != '240') {
          cuentasPGCP.push([
            {},
            {
              text: `${cta.codigo} ${cta.nombre.toUpperCase()}`,
              margin: [30, 20, 0, 0],
              fontSize: 8,
            },
            {
              text: `${this.currencySpPipe.transform(
                cta.total != null ? cta.total.toString() : "0"
              )}`,
              alignment: "right",
              margin: [30, 20, 0, 0],
              fontSize: 9,
            },
            {},
          ]);
        } else {
          if (cta.codigo == '240' && cta.total != null) {
            if (cta.total.toString() != 0) {
              cuentasPGCP.push([
                {},
                {
                  text: `${cta.codigo} ${cta.nombre.toUpperCase()}`,
                  margin: [30, 20, 0, 0],
                  fontSize: 8,
                },
                {
                  text: `${this.currencySpPipe.transform(
                    cta.total != null ? cta.total.toString() : "0"
                  )}`,
                  alignment: "right",
                  margin: [30, 20, 0, 0],
                  fontSize: 9,
                },
                {},
              ]);
            }
          }
        }
      }
    });

    const encabezado = await this.encabezadoFichaValoracionGeneral(
      data,
      "VALORACIÓN SEGÚN PLAN GENERAL DE CONTABILIDAD PUBLICA",
      info,
    );

    let informe = {
      content: [
        ...encabezado,
        {
          style: "tableExample",
          layout: "noBorders",
          margin: [20, 20],
          table: {
            headerRows: 1,
            widths: ["10%", "55%", "25%", "10"],
            body: [
              [
                {},
                {
                  text: "CUENTAS DEL PGCP",
                  bold: true,
                  italics: true,
                  fontSize: 9,
                  font: "TimesNewRoman",
                },
                {
                  text: "VALORACIÓN TOTAL",
                  bold: true,
                  italics: true,
                  fontSize: 9,
                  font: "TimesNewRoman",
                  alignment: "right",
                },
                {},
              ],
              ...cuentasPGCP,
            ],
          },
        },
        {
          style: "tableExample",
          margin: [50, 20],
          table: {
            widths: ["50%", "50%"],
            body: [
              [
                {
                  text: "PATRIMONIO TOTAL ",
                  fontSize: 9,
                  style: "header",
                  bold: true,
                  italics: true,
                  font: "TimesNewRoman",
                  border: [true, true, false, false],
                  margin: [2, 2, 2, 2],
                },
                {
                  text: "VALORACIÓN TOTAL",
                  fontSize: 9,
                  style: "header",
                  alignment: "right",
                  italics: true,
                  font: "TimesNewRoman",
                  bold: true,
                  border: [false, true, true, false],
                  margin: [2, 2, 2, 2],
                },
              ],
              [
                {
                  text: `${this.entidad_local?.nombre ?? ''}`,
                  fontSize: 10,
                  style: "header",
                  border: [true, false, false, true],
                  bold: true,
                  margin: [2, 2, 2, 2],
                },
                {
                  text: `${this.currencySpPipe.transform(total.toString())}`,
                  fontSize: 9,
                  alignment: "right",
                  style: "header",
                  italics: true,
                  bold: true,
                  font: "TimesNewRoman",
                  border: [false, false, true, true],
                  margin: [2, 2, 2, 2],
                },
              ],
            ],
          },
        },
      ],
    };
    // this.pdfService.generatePdf(informe);
    return informe;
  }

  async valoracionPGCP_InformeInventarioBienes(info: any = null, entidad_local?:any,imagen_logo?:any) {
    let municipioId
    if (info == null) {
      municipioId = this.userInfo.municipioId
    } else {
      this.infoProvincia = info.data
     // this.imagenMunicipio = info.imagen
      municipioId = info.data.id
    }

    const r$ = this._valoracionPgcpSvc.getValoracionPGCPNew(
      municipioId
    );
    const resp = await lastValueFrom(r$);
    let total = 0;
    const data = resp.result;

    let cuentasPGCP = [];

    console.log(data)

    data.forEach((cta) => {
      total += cta.total;

      if(cta.codigo != '209' && cta.codigo != '250' ) {
        if (cta.codigo != '240') {
          cuentasPGCP.push([
            {},
            {
              text: `${cta.codigo} ${cta.nombre.toUpperCase()}`,
              margin: [30, 20, 0, 0],
              fontSize: 8,
            },
            {
              text: `${this.currencySpPipe.transform(
                cta.total != null ? cta.total.toString() : "0"
              )}`,
              alignment: "right",
              margin: [30, 20, 0, 0],
              fontSize: 9,
            },
            {},
          ]);
        } else {
          if (cta.codigo == '240' && cta.total != null) {
            if (cta.total.toString() != 0) {
              cuentasPGCP.push([
                {},
                {
                  text: `${cta.codigo} ${cta.nombre.toUpperCase()}`,
                  margin: [30, 20, 0, 0],
                  fontSize: 8,
                },
                {
                  text: `${this.currencySpPipe.transform(
                    cta.total != null ? cta.total.toString() : "0"
                  )}`,
                  alignment: "right",
                  margin: [30, 20, 0, 0],
                  fontSize: 9,
                },
                {},
              ]);
            }
          }
        }
      }
    });

    const encabezado = await this.encabezadoFichaValoracionGeneral(
      data,
      "VALORACIÓN SEGÚN PLAN GENERAL DE CONTABILIDAD PUBLICA",
      info,
      entidad_local,
      imagen_logo
    );

    let informe = {
      content: [
        ...encabezado,
        {
          style: "tableExample",
          layout: "noBorders",
          margin: [20, 20],
          table: {
            headerRows: 1,
            widths: ["10%", "55%", "25%", "10"],
            body: [
              [
                {},
                {
                  text: "CUENTAS DEL PGCP",
                  bold: true,
                  italics: true,
                  fontSize: 9,
                  font: "TimesNewRoman",
                },
                {
                  text: "VALORACIÓN TOTAL",
                  bold: true,
                  italics: true,
                  fontSize: 9,
                  font: "TimesNewRoman",
                  alignment: "right",
                },
                {},
              ],
              ...cuentasPGCP,
            ],
          },
        },
        {
          style: "tableExample",
          margin: [50, 20],
          table: {
            widths: ["50%", "50%"],
            body: [
              [
                {
                  text: "PATRIMONIO TOTAL ",
                  fontSize: 9,
                  style: "header",
                  bold: true,
                  italics: true,
                  font: "TimesNewRoman",
                  border: [true, true, false, false],
                  margin: [2, 2, 2, 2],
                },
                {
                  text: "VALORACIÓN TOTAL",
                  fontSize: 9,
                  style: "header",
                  alignment: "right",
                  italics: true,
                  font: "TimesNewRoman",
                  bold: true,
                  border: [false, true, true, false],
                  margin: [2, 2, 2, 2],
                },
              ],
              [
                {
                  text: `${entidad_local?.nombre ?? ''}`,
                  fontSize: 10,
                  style: "header",
                  border: [true, false, false, true],
                  bold: true,
                  margin: [2, 2, 2, 2],
                },
                {
                  text: `${this.currencySpPipe.transform(total.toString())}`,
                  fontSize: 9,
                  alignment: "right",
                  style: "header",
                  italics: true,
                  bold: true,
                  font: "TimesNewRoman",
                  border: [false, false, true, true],
                  margin: [2, 2, 2, 2],
                },
              ],
            ],
          },
        },
      ],
    };
    // this.pdfService.generatePdf(informe);
    return informe;
  }


  async encabezadoFichaValoracion(data, title, info: any = null, entidad_local:any=null) {

    let entidad=null;

    if(entidad_local !=null){
      entidad=entidad_local;

    }else{
      
      entidad=this.entidad_local;
    }

    let infoProvincia
    let imgMunicipio
    if (info != null) {
      infoProvincia = info.data
      imgMunicipio = info.image
    } else {
      infoProvincia = this.infoProvincia
      imgMunicipio = this.imagenMunicipio
    }


    if (imgMunicipio == null) {

      let prov = this.infoProvincia.nombre
      let url = `${prov}/${prov}?file=${prov.toLowerCase()}_escudo.png`
      imgMunicipio = await this.getImg(url)
    }

    let columnImage = {}
    if (imgMunicipio == null || imgMunicipio == 'not found') {
      columnImage = {
        text: '',
        width: 50,
        margin: [0, 10, 0, 0],
      }
    } else {
      columnImage = {
        image: imgMunicipio,
        width: 50,
        margin: [0, 10, 0, 0],
      }
    }

    // console.log(this.userInfo);
    const encabezado = [
      {
        margin: [0, 0, 0, 0],
        columns: [
          columnImage,
          {
            width: 100,
            margin: [10, 20, 10, 0],
            alignment: "center",
            fontSize: 10,
            text: `${entidad?.nombre ?? ''}`,
            italics: true,
            font: "TimesNewRoman",
            bold: true,
          },
          [
            {
              margin: [0, 0, 0, 0],
              text: "INVENTARIO DE BIENES Y DERECHOS",
              fontSize: 18,
              alignment: "right",
              italics: true,
              font: "TimesNewRoman",
              bold: true,
            },
            {
              margin: [0, 10, 0, 0],
              table: {
                widths: ["40%", "60%"],
                body: [
                  [
                    {
                      text: "ENTIDAD LOCAL ",
                      fontSize: 11,
                      fillColor: "#dbdbdb",
                      italics: true,
                      font: "TimesNewRoman",
                      bold: true,
                      style: estilosPdf.BOLDCURSIVA,
                      border: this.bordersType.none,
                    },
                    {
                      text: `${data.entidad_local && data.entidad_local != ''
                        ? data.entidad_local
                        : `${entidad?.nombre ?? ''}`
                        }`,
                      fontSize: 11,
                      border: this.bordersType.none,
                      fillColor: "#dbdbdb",
                    },
                  ],
                ],
              },
            },

            {
              margin: [0, 10, 0, 0],
              table: {
                widths: ["40%", "60%"],

                body: [
                  [
                    {
                      text: "LOCALIDAD",
                      fontSize: 11,
                      fillColor: "#dbdbdb",
                      style: estilosPdf.BOLDCURSIVA,
                      border: this.bordersType.none,
                      bold: true,
                      italics: true,
                      font: "TimesNewRoman",
                    },
                    {
                      text: `${this.infoProvincia.nombre}`,
                      fontSize: 11,
                      border: this.bordersType.none,
                      fillColor: "#dbdbdb",
                    },
                  ],
                ],
              },
            },
          ],
        ],
      },
      {
        margin: [0, 10, 0, 0],
        table: {
          widths: ["100%"],
          body: [
            [
              {
                text: title,
                fontSize: 11,
                fillColor: "#dbdbdb",
                border: this.bordersType.none,
                bold: true,
                italics: true,
                font: "TimesNewRoman",
                alignment: "center",
              },
            ],
          ],
        },
      },
    ];
    return encabezado;
  }

  async encabezadoFichaValoracionGeneral(data, title, info: any = null,entidad_local?:any,imagen_logo?:any) {
    let infoProvincia
    let imgMunicipio
    if (info != null) {
      infoProvincia = info.data
      //imgMunicipio = info.image
      imgMunicipio = imagen_logo;
    } else {
      infoProvincia = this.infoProvincia;
     // imgMunicipio = this.imagenMunicipio
     
    }


    if (imgMunicipio == null) {

      let prov = this.infoProvincia.nombre
      let url = `${prov}/${prov}?file=${prov.toLowerCase()}_escudo.png`
      imgMunicipio = await this.getImg(url)
    }

    let columnImage = {}
    if (imgMunicipio == null || imgMunicipio == 'not found') {
      columnImage = {
        text: '',
        width: 50,
        margin: [0, 10, 0, 0],
      }
    } else {
      columnImage = {
        image: imgMunicipio,
        width: 50,
        margin: [0, 10, 0, 0],
      }
    }

    // console.log(this.userInfo);
    const encabezado = [
      {
        margin: [0, 0, 0, 0],
        columns: [
          columnImage,
          {
            width: 100,
            margin: [10, 20, 10, 0],
            alignment: "center",
            fontSize: 10,
            text: `${entidad_local?.nombre ?? ''}`,
            italics: true,
            font: "TimesNewRoman",
            bold: true,
          },
          [
            {
              margin: [0, 0, 0, 0],
              text: "INVENTARIO DE BIENES Y DERECHOS",
              fontSize: 18,
              alignment: "right",
              italics: true,
              font: "TimesNewRoman",
              bold: true,
            },
            {
              margin: [0, 10, 0, 0],
              table: {
                widths: ["40%", "60%"],
                body: [
                  [
                    {
                      text: "ENTIDAD LOCAL ",
                      fontSize: 11,
                      fillColor: "#dbdbdb",
                      italics: true,
                      font: "TimesNewRoman",
                      bold: true,
                      style: estilosPdf.BOLDCURSIVA,
                      border: this.bordersType.none,
                    },
                    {
                      text: `${data.entidad_local && data.entidad_local != ''
                        ? data.entidad_local
                        : `${entidad_local?.nombre ?? ''}`
                        }`,
                      fontSize: 11,
                      border: this.bordersType.none,
                      fillColor: "#dbdbdb",
                    },
                  ],
                ],
              },
            },

            {
              margin: [0, 10, 0, 0],
              table: {
                widths: ["40%", "60%"],

                body: [
                  [
                    {
                      text: "LOCALIDAD",
                      fontSize: 11,
                      fillColor: "#dbdbdb",
                      style: estilosPdf.BOLDCURSIVA,
                      border: this.bordersType.none,
                      bold: true,
                      italics: true,
                      font: "TimesNewRoman",
                    },
                    {
                      text: `${this.infoProvincia.nombre}`,
                      fontSize: 11,
                      border: this.bordersType.none,
                      fillColor: "#dbdbdb",
                    },
                  ],
                ],
              },
            },
          ],
        ],
      },
      {
        margin: [0, 10, 0, 0],
        table: {
          widths: ["100%"],
          body: [
            [
              {
                text: title,
                fontSize: 11,
                fillColor: "#dbdbdb",
                border: this.bordersType.none,
                bold: true,
                italics: true,
                font: "TimesNewRoman",
                alignment: "center",
              },
            ],
          ],
        },
      },
    ];
    return encabezado;
  }

  getTotalByType(type: string, array: any) {
    const object = array.find((obj) => obj.nombre === type);
    return object ? object.total : "Tipo no encontrado";
  }

  async valoracionGeneralInventario(info: any = null, entidad_local?:any) {
    
   /* if(entidad_local != null){
			this.entidad_local=entidad_local;
		}*/

    let municipioId

    if (info == null) {
      municipioId = this.municipioId
    } else {
      this.infoProvincia = info.data
      this.imagenMunicipio = info.imagen
      municipioId = info.data.id
    }
    const ab$ = this.projectService.getValoracion(municipioId);

    const finalNumber = await lastValueFrom(ab$);

    let dataR = finalNumber.result.valoracion



    let bodyTable = [];
    let valoracionTotal = 0;
    let encabezado = []
    if (dataR != undefined) {
      console.log('tea=asasdas')
      console.log(dataR)

      dataR = dataR.filter(f => f.codigo != "Arbolado");
      dataR = dataR.filter(f => f.codigo != "PMS");
      dataR = dataR.filter(f => f.codigo != "Metalicos");

      dataR.forEach(ep => {
        let total = 0
        let dataInmueblesval = [];
        let subepigrafes = [];
        if (ep.nombre == "Inmuebles urbanos") {
          ep.subepigrafes.forEach((el) => {
            if (el.subepigrafes) {
              el.subepigrafes.forEach((subE) => {
                total += parseFloat(subE.total != null ? subE.total : 0);

                subepigrafes.push([
                  {},
                  {
                    text: `${subE.codigo} ${subE.nombre.toUpperCase()}`,
                    margin: [50, 10, 0, 0],
                    fontSize: 8,
                  },
                  {
                    text: `${this.currencySpPipe.transform(
                      subE.total != null ? subE.total.toString() : "0"
                    )}`,
                    alignment: "right",
                    margin: [0, 10, 0, 0],
                    fontSize: 8,
                  },
                  {},
                ]);
              });
            } else {
              dataInmueblesval.push([
                {},
                {
                  text: `${el.codigo} ${el.nombre.toUpperCase()}`,
                  margin: [30, 10, 0, 0],
                  font: "TimesNewRoman",
                  fontSize: 9,
                  bold: true,
                  italics: true,
                },
                {
                  text: `${this.currencySpPipe.transform(
                    el.total != null ? el.total.toString() : "0"
                  )}`,
                  alignment: "right",
                  margin: [0, 10, 0, 0],
                  bold: true,
                  font: "TimesNewRoman",
                  fontSize: 9,
                  italics: true,
                },
                {},
              ]);
            }
          });
        } else {
          if (ep.subepigrafes) {
            ep.subepigrafes.forEach((subE) => {
              total += parseFloat(subE.total != null ? subE.total : 0);

              subepigrafes.push([
                {},
                {
                  text: `${subE.codigo} ${subE.nombre.toUpperCase()}`,
                  margin: [50, 10, 0, 0],
                  fontSize: 8,
                },
                {
                  text: `${this.currencySpPipe.transform(
                    subE.total != null ? subE.total.toString() : "0"
                  )}`,
                  alignment: "right",
                  margin: [0, 10, 0, 0],
                  fontSize: 8,
                },
                {},
              ]);
            });
          }
        }

        total += ep.total != null ? ep.total : 0;

        let data = [
          {},
          {
            text: `${ep.codigo} ${ep.nombre.toUpperCase()}`,
            margin: [30, 10, 0, 0],
            font: "TimesNewRoman",
            fontSize: 9,
            bold: true,
            italics: true,
          },
          {
            text: `${this.currencySpPipe.transform(
              ep.total != null ? ep.total.toString() : "0"
            )}`,
            alignment: "right",
            margin: [0, 10, 0, 0],
            bold: true,
            font: "TimesNewRoman",
            fontSize: 9,
            italics: true,
          },
          {},
        ];

        //valoracionTotal += parseFloat(total.toString());
        valoracionTotal += Number(ep.total);

        bodyTable.push(data);
        if (subepigrafes.length > 0) {
          bodyTable.push(...subepigrafes);
        }
        if (data.length > 0) {
          bodyTable.push(...dataInmueblesval);
        }
      });
      encabezado = await this.encabezadoFichaValoracion(
        dataR,
        "VALORACIÓN GENERAL DEL INVENTARIO",
        info,
      );
    }

    let informe = {
      content: [
        ...encabezado,
        {
          style: "tableExample",
          layout: "noBorders",
          margin: [20, 20],
          table: {
            headerRows: 1,
            widths: ["10%", "45%", "35%", "10%"],
            body: [
              [
                {},
                {
                  text: "EPÍGRAFES",
                  bold: true,
                  italics: true,
                  font: "TimesNewRoman",
                  fontSize: 9,
                },

                {
                  text: "VALORACIÓN TOTAL",
                  bold: true,
                  italics: true,
                  alignment: "right",
                  font: "TimesNewRoman",
                  fontSize: 9,
                },

                {},
              ],
              ...bodyTable,
            ],
          },
        },
        {
          style: "tableExample",
          margin: [20, 20],
          table: {
            widths: ["50%", "50%"],
            body: [
              [
                {
                  text: "PATRIMONIO TOTAL ",
                  fontSize: 10,
                  style: "header",
                  bold: true,
                  italics: true,
                  font: "TimesNewRoman",
                  border: [true, true, false, false],
                  margin: [2, 2, 2, 2],
                },
                {
                  text: "VALORACIÓN TOTAL",
                  fontSize: 10,
                  style: "header",
                  alignment: "right",
                  italics: true,
                  font: "TimesNewRoman",
                  bold: true,
                  border: [false, true, true, false],
                  margin: [2, 2, 2, 2],
                },
              ],
              [
                {
                  text: `${this.entidad_local?.nombre ?? ''}`,
                  fontSize: 10,
                  style: "header",
                  border: [true, false, false, true],
                  bold: true,
                  margin: [2, 2, 2, 2],
                },
                {
                  text: `${this.currencySpPipe.transform(
                    valoracionTotal.toString() || "0"
                  )}`,
                  fontSize: 9,
                  alignment: "right",
                  style: "header",
                  italics: true,
                  font: "TimesNewRoman",
                  border: [false, false, true, true],
                  margin: [2, 2, 2, 2],
                },
              ],
            ],
          },
        },
      ],
    };

    //this.entidad_local = JSON.parse(localStorage.getItem("entidad_local"));

    return informe;
    // this.pdfService.generatePdf(informe);
  }

  async valoracionGeneralInventario_InformeInvetarioBienes(info: any = null, entidad_local?:any,imagen_logo?:any) {
    
    /* if(entidad_local != null){
       this.entidad_local=entidad_local;
     }*/
 
     let municipioId
 
     if (info == null) {
       municipioId = this.municipioId
     } else {
       this.infoProvincia = info.data
       municipioId = info.data.id
     }
     const ab$ = this.projectService.getValoracion(municipioId);
 
     const finalNumber = await lastValueFrom(ab$);
 
     let dataR = finalNumber.result.valoracion
 
 
 
     let bodyTable = [];
     let valoracionTotal = 0;
     let encabezado = []
     if (dataR != undefined) {
       console.log('tea=asasdas')
       console.log(dataR)
 
       dataR = dataR.filter(f => f.codigo != "Arbolado");
       dataR = dataR.filter(f => f.codigo != "PMS");
       dataR = dataR.filter(f => f.codigo != "Metalicos");
 
       dataR.forEach(ep => {
         let total = 0
         let dataInmueblesval = [];
         let subepigrafes = [];
         if (ep.nombre == "Inmuebles urbanos") {
           ep.subepigrafes.forEach((el) => {
             if (el.subepigrafes) {
               el.subepigrafes.forEach((subE) => {
                 total += parseFloat(subE.total != null ? subE.total : 0);
 
                 subepigrafes.push([
                   {},
                   {
                     text: `${subE.codigo} ${subE.nombre.toUpperCase()}`,
                     margin: [50, 10, 0, 0],
                     fontSize: 8,
                   },
                   {
                     text: `${this.currencySpPipe.transform(
                       subE.total != null ? subE.total.toString() : "0"
                     )}`,
                     alignment: "right",
                     margin: [0, 10, 0, 0],
                     fontSize: 8,
                   },
                   {},
                 ]);
               });
             } else {
               dataInmueblesval.push([
                 {},
                 {
                   text: `${el.codigo} ${el.nombre.toUpperCase()}`,
                   margin: [30, 10, 0, 0],
                   font: "TimesNewRoman",
                   fontSize: 9,
                   bold: true,
                   italics: true,
                 },
                 {
                   text: `${this.currencySpPipe.transform(
                     el.total != null ? el.total.toString() : "0"
                   )}`,
                   alignment: "right",
                   margin: [0, 10, 0, 0],
                   bold: true,
                   font: "TimesNewRoman",
                   fontSize: 9,
                   italics: true,
                 },
                 {},
               ]);
             }
           });
         } else {
           if (ep.subepigrafes) {
             ep.subepigrafes.forEach((subE) => {
               total += parseFloat(subE.total != null ? subE.total : 0);
 
               subepigrafes.push([
                 {},
                 {
                   text: `${subE.codigo} ${subE.nombre.toUpperCase()}`,
                   margin: [50, 10, 0, 0],
                   fontSize: 8,
                 },
                 {
                   text: `${this.currencySpPipe.transform(
                     subE.total != null ? subE.total.toString() : "0"
                   )}`,
                   alignment: "right",
                   margin: [0, 10, 0, 0],
                   fontSize: 8,
                 },
                 {},
               ]);
             });
           }
         }
 
         total += ep.total != null ? ep.total : 0;
 
         let data = [
           {},
           {
             text: `${ep.codigo} ${ep.nombre.toUpperCase()}`,
             margin: [30, 10, 0, 0],
             font: "TimesNewRoman",
             fontSize: 9,
             bold: true,
             italics: true,
           },
           {
             text: `${this.currencySpPipe.transform(
               ep.total != null ? ep.total.toString() : "0"
             )}`,
             alignment: "right",
             margin: [0, 10, 0, 0],
             bold: true,
             font: "TimesNewRoman",
             fontSize: 9,
             italics: true,
           },
           {},
         ];
 
         //valoracionTotal += parseFloat(total.toString());
         valoracionTotal += Number(ep.total);
 
         bodyTable.push(data);
         if (subepigrafes.length > 0) {
           bodyTable.push(...subepigrafes);
         }
         if (data.length > 0) {
           bodyTable.push(...dataInmueblesval);
         }
       });
       encabezado = await this.encabezadoFichaValoracionGeneral(
         dataR,
         "VALORACIÓN GENERAL DEL INVENTARIO",
         info,
         entidad_local,
         imagen_logo
       );
     }
 
     let informe = {
       content: [
         ...encabezado,
         {
           style: "tableExample",
           layout: "noBorders",
           margin: [20, 20],
           table: {
             headerRows: 1,
             widths: ["10%", "45%", "35%", "10%"],
             body: [
               [
                 {},
                 {
                   text: "EPÍGRAFES",
                   bold: true,
                   italics: true,
                   font: "TimesNewRoman",
                   fontSize: 9,
                 },
 
                 {
                   text: "VALORACIÓN TOTAL",
                   bold: true,
                   italics: true,
                   alignment: "right",
                   font: "TimesNewRoman",
                   fontSize: 9,
                 },
 
                 {},
               ],
               ...bodyTable,
             ],
           },
         },
         {
           style: "tableExample",
           margin: [20, 20],
           table: {
             widths: ["50%", "50%"],
             body: [
               [
                 {
                   text: "PATRIMONIO TOTAL ",
                   fontSize: 10,
                   style: "header",
                   bold: true,
                   italics: true,
                   font: "TimesNewRoman",
                   border: [true, true, false, false],
                   margin: [2, 2, 2, 2],
                 },
                 {
                   text: "VALORACIÓN TOTAL",
                   fontSize: 10,
                   style: "header",
                   alignment: "right",
                   italics: true,
                   font: "TimesNewRoman",
                   bold: true,
                   border: [false, true, true, false],
                   margin: [2, 2, 2, 2],
                 },
               ],
               [
                 {
                   text: `${entidad_local?.nombre ?? ''}`,
                   fontSize: 10,
                   style: "header",
                   border: [true, false, false, true],
                   bold: true,
                   margin: [2, 2, 2, 2],
                 },
                 {
                   text: `${this.currencySpPipe.transform(
                     valoracionTotal.toString() || "0"
                   )}`,
                   fontSize: 9,
                   alignment: "right",
                   style: "header",
                   italics: true,
                   font: "TimesNewRoman",
                   border: [false, false, true, true],
                   margin: [2, 2, 2, 2],
                 },
               ],
             ],
           },
         },
       ],
     };
 
     //this.entidad_local = JSON.parse(localStorage.getItem("entidad_local"));
 
     return informe;
     // this.pdfService.generatePdf(informe);
   }

  async getImg(pathUrl) {
    const r$ = this.featureService.getImage(pathUrl);
    try {
      const blob = await lastValueFrom(r$);
      const res = await this.blobToBase64(blob);
      return res
    } catch (error) {
      return null;
    }
  }
  blobToBase64(blob) {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }
  async comparativaValoracionGeneralInventario(municipioId:any) {


    const ab$ = this.projectService.getValoracionComparativa(
      municipioId,
      2022 //tener en cuenta los años que se envien, para futuro
    );
    const date = new Date();
    let actualyear = date.getFullYear();
    const ab = this.projectService.getValoracionComparativa(
      municipioId,
      actualyear
    );

    const finalNumber = await lastValueFrom(ab$);
    const finalValue = await lastValueFrom(ab$);


    let dataR = finalNumber.result.valoracion;
    const dataValue = finalValue.result.valoracion;

    let bodyTable = [];
    let valoracionTotal = 0;
    let valoracionTotalActual = 0;

    dataR = dataR.filter(f => f.codigo != "Arbolado");
    dataR = dataR.filter(f => f.codigo != "PMS");
    dataR = dataR.filter(f => f.codigo != "Metalicos");

    dataR.forEach((ep, i) => {
      let total = 0;
      let dataInmuebles = [];
      let totalActual = 0;
      let subepigrafes = [];
      if (ep.nombre == "Inmuebles urbanos") {
        if (ep.subepigrafes !=undefined) {
          ep.subepigrafes.forEach((el, j) => {
            if (el.subepigrafes !=undefined) {
              el.subepigrafes.forEach((subE, k) => {
                /* totalActual += parseFloat(
                  dataValue[i].subepigrafes[j].subepigrafes[k].total != null
                    ? dataValue[i].subepigrafes[j].subepigrafes[k].total
                    : 0
                ); */
                //total += parseFloat(subE.total != null ? subE.total : 0);

                subepigrafes.push([
                  {
                    text: subE.nombre.toUpperCase(),
                    margin: [20, 10, 0, 0],
                    fontSize: 8,
                  },
                  {
                    text: `${this.currencySpPipe.transform(
                      subE.total != null ? subE.total.toString() : "0"
                    )}`,
                    alignment: "right",
                    margin: [0, 10, 0, 0],
                    fontSize: 8,
                  },
                  {
                    text: `${this.currencySpPipe.transform(
                      dataValue[i].subepigrafes[j].subepigrafes[k].total != null
                        ? dataValue[i].subepigrafes[j].subepigrafes[
                          k
                        ].total.toString()
                        : "0"
                    )}`,
                    alignment: "right",
                    margin: [0, 10, 0, 0],
                    fontSize: 8,
                  },
                ]);
              });
            } else {
              dataInmuebles.push([
                {
                  text: el.nombre.toUpperCase(),
                  margin: [0, 10, 0, 0],
                  font: "TimesNewRoman",
                  fontSize: 9,
                  bold: true,
                  italics: true,
                },
                {
                  text: `${this.currencySpPipe.transform(
                    el.total != null ? el.total.toString() : "0"
                  )}`,
                  alignment: "right",
                  margin: [0, 10, 0, 0],
                  bold: true,
                  font: "TimesNewRoman",
                  fontSize: 9,
                  italics: true,
                },
                {
                  text: `${this.currencySpPipe.transform(
                    dataValue[i].subepigrafes[j].total != null
                      ? dataValue[i].subepigrafes[j].total.toString()
                      : "0"
                  )}`,
                  alignment: "right",
                  margin: [0, 10, 0, 0],
                  bold: true,
                  font: "TimesNewRoman",
                  fontSize: 9,
                  italics: true,
                },
              ]);
            }
          });
        }
      } else {
        if (ep.subepigrafes) {
          ep.subepigrafes.forEach((subE, j) => {
            /* totalActual += parseFloat(
              dataValue[i].subepigrafes[j].total != null
                ? dataValue[i].subepigrafes[j].total
                : 0
            ); */
            //total += parseFloat(subE.total != null ? subE.total : 0);

            subepigrafes.push([
              {
                text: subE.nombre.toUpperCase(),
                margin: [20, 10, 0, 0],
                fontSize: 8,
              },
              {
                text: `${this.currencySpPipe.transform(
                  subE.total != null ? subE.total.toString() : "0"
                )}`,
                alignment: "right",
                margin: [0, 10, 0, 0],
                fontSize: 8,
              },
              {
                text: `${this.currencySpPipe.transform(
                  dataValue[i].subepigrafes[j].total != null
                    ? dataValue[i].subepigrafes[j].total.toString()
                    : "0"
                )}`,
                alignment: "right",
                margin: [0, 10, 0, 0],
                fontSize: 8,
              },
            ]);
          });
        }
      }

      //total += ep.total != null ? ep.total : 0;
      total += Number(ep.total);
      //totalActual += dataValue[i].total != null ? dataValue[i].total : 0;
      totalActual += Number(dataValue[i].total);

      let data = [
        {
          text: ep.nombre.toUpperCase(),
          margin: [0, 10, 0, 0],
          font: "TimesNewRoman",
          fontSize: 9,
          bold: true,
          italics: true,
        },
        {
          text: `${this.currencySpPipe.transform(
            ep.total != null ? ep.total.toString() : "0"
          )}`,
          alignment: "right",
          margin: [0, 10, 0, 0],
          bold: true,
          font: "TimesNewRoman",
          fontSize: 9,
          italics: true,
        },
        {
          text: `${this.currencySpPipe.transform(
            dataValue[i].total != null ? dataValue[i].total.toString() : "0"
          )}`,
          alignment: "right",
          margin: [0, 10, 0, 0],
          bold: true,
          font: "TimesNewRoman",
          fontSize: 9,
          italics: true,
        },
      ];

      valoracionTotal += parseFloat(total.toString());
      valoracionTotalActual += parseFloat(total.toString());

      bodyTable.push(data);
      if (subepigrafes.length > 0) {
        bodyTable.push(...subepigrafes);
      }
      if (data.length > 0) {
        bodyTable.push(...dataInmuebles);
      }
    });

    const encabezado = await this.encabezadoFichaValoracion(
      dataR,
      "COMPARATIVA DE VALORACIÓN GENERAL DEL INVENTARIO"
    );
    let informe = {
      content: [
        ...encabezado,
        {
          style: "tableExample",
          layout: "noBorders",
          margin: [20, 20],
          table: {
            headerRows: 1,
            widths: ["40%", "30%", "30%"],
            body: [
              [
                {
                  text: "EPÍGRAFES",
                  bold: true,
                  italics: true,
                  font: "TimesNewRoman",
                  fontSize: 9,
                },

                {
                  text: "VALORACIÓN AÑO 2022",
                  bold: true,
                  italics: true,
                  alignment: "right",
                  font: "TimesNewRoman",
                  fontSize: 9,
                },

                {
                  text: "VALORACIÓN TOTAL ACTUAL",
                  bold: true,
                  italics: true,
                  alignment: "right",
                  font: "TimesNewRoman",
                  fontSize: 9,
                },
              ],
              ...bodyTable,
            ],
          },
        },
        {
          style: "tableExample",
          margin: [20, 20],
          table: {
            widths: ["40%", "30%", "30%"],
            body: [
              [
                {
                  text: "PATRIMONIO TOTAL ",
                  fontSize: 10,
                  style: "header",
                  bold: true,
                  italics: true,
                  font: "TimesNewRoman",
                  border: [true, true, false, false],
                  margin: [2, 2, 2, 2],
                },
                {
                  text: "VALORACIÓN TOTAL AÑO 2022",
                  fontSize: 10,
                  style: "header",
                  alignment: "right",
                  italics: true,
                  font: "TimesNewRoman",
                  bold: true,
                  border: [false, true, false, false],
                  margin: [2, 2, 2, 2],
                },
                {
                  text: "VALORACIÓN TOTAL ACTUAL",
                  fontSize: 10,
                  style: "header",
                  alignment: "right",
                  italics: true,
                  font: "TimesNewRoman",
                  bold: true,
                  border: [false, true, true, false],
                  margin: [2, 2, 2, 2],
                },
              ],
              [
                {
                  text: `${this.entidad_local?.nombre ?? ''}`,
                  fontSize: 10,
                  style: "header",
                  border: [true, false, false, true],
                  bold: true,
                  margin: [2, 2, 2, 2],
                },
                {
                  text: `${this.currencySpPipe.transform(
                    valoracionTotal.toString() || "0"
                  )}`,
                  fontSize: 9,
                  alignment: "right",
                  style: "header",
                  italics: true,
                  font: "TimesNewRoman",
                  border: [false, false, false, true],
                  margin: [2, 2, 2, 2],
                },
                {
                  text: `${this.currencySpPipe.transform(
                    valoracionTotal.toString() || "0"
                  )}`,
                  fontSize: 9,
                  alignment: "right",
                  style: "header",
                  italics: true,
                  font: "TimesNewRoman",
                  border: [false, false, true, true],
                  margin: [2, 2, 2, 2],
                },
              ],
            ],
          },
        },
      ],
    };
    return informe;
  }
  async imagenesFichaEpigrafe(data, epigrafe = '', planos = true, info: any = null,entidad_local?:any) {

    console.log("IMAGENEEEES MUNICIPIO fICHA EPIGRAFE");

    if (info == null) {
      info = {
        data: this.infoProvincia,
        image: this.imagenMunicipio
      }
    }
    const content = [];
    let encabezado = []
    if (epigrafe == '20') {
      encabezado = await this.encabezadoFicha({ data: data, codigo: epigrafe, epigrafe: false, title: 'INVENTARIO DE ARBOLADO URBANO', info },entidad_local);
    } else {
      encabezado = await this.encabezadoFicha({ data, codigo: epigrafe, epigrafe: true, info },entidad_local);
    }
    content.push(...encabezado);
    if(data.epigrafe && data.epigrafe.id == 20) {
      epigrafe = data.epigrafe.codigo
    }

    if (epigrafe.endsWith(".")) {
      epigrafe = epigrafe.substring(0, epigrafe.length - 1);
    }
    
    console.log(epigrafe)
    let auxNumOrden = data.n_orden || '';

    let imgPlano = await this.getImg(
      `${info.data.provincia.nombre.toUpperCase()}/${info.data.nombre.toUpperCase()}/planos?file=${epigrafe}-C-${auxNumOrden.toString()}.jpg`
    );
    let imgFoto = await this.getImg(
      `${info.data.provincia.nombre.toUpperCase()}/${info.data.nombre.toUpperCase()}/fotografias?file=${epigrafe}-F-${auxNumOrden.toString()}.jpg`
    );
    if (!imgFoto) {
      imgFoto = await this.getImg(
        `${info.data.provincia.nombre.toUpperCase()}/${info.data.nombre.toUpperCase()}/fotografias?file=${epigrafe}-F-${auxNumOrden
          .toString()
        }_1.jpg`
      );
    }
    if (!imgFoto) {
      imgFoto = await this.getImg(
        `${info.data.provincia.nombre.toUpperCase()}/${info.data.nombre.toUpperCase()}/fotografias?file=${epigrafe}-F-${auxNumOrden
          .toString()
        }_2.jpg`
      );
    }

    if (!imgFoto) {
      imgFoto = await this.getImg(
        `${info.data.provincia.nombre.toUpperCase()}/${info.data.nombre.toUpperCase()}/fotografias?file=${epigrafe}-F-1.jpg`
      );
    }

    if (!imgPlano) {
      imgFoto = await this.getImg(
        `${info.data.provincia.nombre.toUpperCase()}/${info.data.nombre.toUpperCase()}/planos?file=${epigrafe}-C-1.jpg`
      );
    }


    if (planos) {
      content.push({
        style: "tableExample",
        margin: [0, 10, 0, 15],
        table: {
          widths: ["*"],
          body: [
            [
              {
                text: `PLANO`,
                fontSize: 9,
                fillColor: "#cccccc",
                border: this.bordersType.none,
                bold: true,
                italics: true,
                font: "TimesNewRoman",
              },
            ],
            // imgPlano ? [{ image: imgPlano, width: 400 }] : [],
          ],
        },
      });
      if (imgPlano) {
        content.push({
          columns: [
            { width: "*", text: "" },
            {
              width: "auto",
              table: {
                body: [
                  [
                    {
                      image: imgPlano,
                      fit: [undefined, 240],
                      alignment: "center",
                    },
                  ],
                ],
              },
            },
            { width: "*", text: "" },
          ],
        });
      } else {
        content.push({
          columns: [
            { width: "*", text: "" },
            {
              width: "auto",
              table: {
                body: [
                  [
                    {
                      text: "No hay imágenes disponibles para este bien",
                      fontSize: 11,
                      bold: true,
                      width: 500,
                      margin: [80, 110, 80, 110],
                      alignment: "center",
                      font: "TimesNewRoman",
                    },
                  ],
                ],
              },
            },
            { width: "*", text: "" },
          ],
        });
      }
    }

    if (epigrafe != '1.2') {
      content.push({
        style: "tableExample",
        margin: [0, 10, 0, 15],
        table: {
          widths: ["*"],
          body: [
            [
              {
                text: `FOTO`,
                fontSize: 9,
                fillColor: "#cccccc",
                border: this.bordersType.none,
                bold: true,
                italics: true,
                font: "TimesNewRoman",
              },
            ],
          ],
        },
      });
      if (imgFoto) {
        content.push({
          columns: [
            { width: "*", text: "" },
            {
              width: "auto",
              table: {
                body: [
                  [
                    {
                      image: imgFoto,
                      fit: [undefined, 240],
                      alignment: "center",
                    },
                  ],
                ],
              },
            },
            { width: "*", text: "" },
          ],
        });
      } else {
        content.push({
          columns: [
            { width: "*", text: "" },
            {
              width: "auto",
              table: {
                body: [
                  [
                    {
                      text: "No hay imágenes disponibles para este bien",
                      fontSize: 11,
                      bold: true,
                      width: 500,
                      margin: [80, 110, 80, 110],
                      alignment: "center",
                      font: "TimesNewRoman",
                    },
                  ],
                ],
              },
            },
            { width: "*", text: "" },
          ],
        });
      }
    }
    let informe = {
      content,
      styles: {
        titulo: {
          // fontSize: 10,
          bold: true,
          italics: false,
          // margin: [0, 10, 0, 5],
        },
        cursiva: {
          // fontSize: 10,
          italics: true,
          font: "TimesNewRoman",
          // margin: [0, 10, 0, 5],
        },
        boldCursiva: {
          // fontSize: 10,
          italics: true,
          font: "TimesNewRoman",
          bold: true,
          // margin: [0, 10, 0, 5],
        },
      },
      defaultStyle: {
        italics: true,
        font: "TimesNewRoman",
      },
    };
    return informe;
  }

  async historialFichaEpigrafe(data, epigrafe = '', info: any = null,entidad_local?:any) {
    const content = [];
    let encabezado = []
    if (epigrafe == '20') {
      encabezado = await this.encabezadoFicha({ data: data, codigo: epigrafe, epigrafe: false, title: 'INVENTARIO DE ARBOLADO URBANO', info },entidad_local);
    } else {
      encabezado = await this.encabezadoFicha({ data, codigo: epigrafe, epigrafe: true, info },entidad_local);
    }
    content.push(...encabezado);
    //img1
    const tableBody2 = [];
    content.push({ text: "", margin: [0, 10, 0, 0] });
    tableBody2.push([
      {
        // colSpan: 8,
        text: "HISTORIAL DE LA FINCA",
        border: this.bordersType.noBottom,
        italics: true,
        font: "TimesNewRoman",
        // pageBreak: "before",
      },
    ]);
    tableBody2.push([
      {
        // colSpan: 8,
        text: data.historial ?? "",
        border: this.bordersType.noTop,
        style: estilosPdf.TITULO,
        bold: true,
      },
    ]);
    tableBody2.push([
      {
        // colSpan: 8,
        text: "OBSERVACIONES",
        border: this.bordersType.noBottom,
        italics: true,
        font: "TimesNewRoman",
      },
    ]);
    tableBody2.push([
      {
        // colSpan: 8,
        text: data.observaciones ?? " ",
        border: this.bordersType.noTop,
        style: estilosPdf.TITULO,
        bold: true,
      },
    ]);
    tableBody2.push([
      {
        // colSpan: 8,
        text: "RECOMENDACIONES",
        border: this.bordersType.noBottom,
        italics: true,
        font: "TimesNewRoman",
      },
    ]);
    tableBody2.push([
      {
        // colSpan: 8,
        text: data.recomendaciones ?? "",
        border: this.bordersType.noTop,
        style: estilosPdf.TITULO,
        bold: true,
      },
    ]);
    content.push({
      style: "tableExample",
      fontSize: 8,
      table: {
        heights: [15, 220, 15, 230, 15, 100],
        widths: ["*"],
        body: tableBody2,
      },
    });
    let informe = {
      content,
      styles: {
        titulo: {
          // fontSize: 10,
          bold: true,
          italics: false,
          // margin: [0, 10, 0, 5],
        },
        cursiva: {
          // fontSize: 10,
          italics: true,
          font: "TimesNewRoman",
          // margin: [0, 10, 0, 5],
        },
        boldCursiva: {
          // fontSize: 10,
          italics: true,
          font: "TimesNewRoman",
          bold: true,
          // margin: [0, 10, 0, 5],
        },
      },
      defaultStyle: {
        italics: true,
        font: "TimesNewRoman",
      },
    };
    return informe;
  }

  async encabezadoFicha(
    params: {
      data: any,
      codigo?: string,
      epigrafe?: Boolean,
      title?: any,
      info?: any,
    },
    entidad_local?:any
  ) {
    // comentariooooo

    const { data, codigo = '', epigrafe = true, title = 'INVENTARIO DE BIENES Y DERECHOS', info = null } = params;

    let entidad = null;
    if(entidad_local==null){
      entidad = this.entidad_local;
    }else{
      entidad = entidad_local;
    }

    let datosProvincia = null
    let imagen = null
    if (info == null) {
      datosProvincia = this.infoProvincia
      imagen = this.imagenMunicipio
    } else {
      datosProvincia = info.data
      imagen = info.image
    }


    let codigoEpigrafe = ''

    if (codigo != '') {
      codigoEpigrafe = codigo
    } else {
      if (data.sign_archivo != null) {
        codigoEpigrafe = data.sign_archivo.split("-")[0]
      }
    }
    let columns = []
    if (epigrafe) {
      columns = [
        {
          text: `CLASE DE BIEN:`,
          width: 100,
          style: estilosPdf.BOLDCURSIVA,
          italics: true,
          font: "TimesNewRoman",
          bold: true,
        },
        {
          text: "EPÍGRAFE ",
          width: "*",
          style: estilosPdf.BOLDCURSIVA,
          italics: true,
          font: "TimesNewRoman",
          bold: true,
          margin: [0, 0, 2, 0]
        },
        { text: codigoEpigrafe, width: "*" },
        {
          text: "Nº ORDEN: ",
          width: "*",
          style: estilosPdf.BOLDCURSIVA,
          italics: true,
          font: "TimesNewRoman",
          bold: true,
        },
        { text: data.n_orden, width: "*" },
        {
          text: "SIGN. ARCHIVO",
          width: 100,
          style: estilosPdf.BOLDCURSIVA,
          italics: true,
          font: "TimesNewRoman",
          bold: true,
        },
        { text: data.sign_archivo, width: "*" },
      ]
    } else {
      columns = [
        {
          text: "Nº ORDEN: ",
          width: "*",
          style: estilosPdf.BOLDCURSIVA,
          italics: true,
          font: "TimesNewRoman",
          bold: true,
        },
        { text: data.n_orden, width: "*" },
        {
          text: "SIGN. ARCHIVO",
          width: 100,
          style: estilosPdf.BOLDCURSIVA,
          italics: true,
          font: "TimesNewRoman",
          bold: true,
        },
        { text: data.sign_archivo, width: "*" },
      ]
    }

    let columnImagen

    if (imagen == null) {
      let prov = datosProvincia.provincia.nombre
       console.log("PROVINCIAA - PROVINCIAAAA");
       console.log(prov);
      let url = `${prov}/${prov}?file=${prov.toLowerCase()}_escudo.png`
      console.log(url)
      imagen = await this.getImg(url)
    }

    if (imagen != null) {
      columnImagen = {
        image: imagen,
        width: 50,
        margin: [0, 10, 0, 0],
      }
    } else {
      columnImagen = {
        text: '',
        width: 50,
        margin: [0, 10, 0, 0],
      }
    }


 // ------------------------------------------ ENCABEZADO INVENTARIO DE BIENES Y DERECHOS ----------------------------
    const encabezado = [
      {
        margin: [0, 0, 0, 0],
        columns: [
          columnImagen,
          {
            width: 100,
            margin: [10, 20, 10, 0],
            alignment: "center",
            fontSize: 10,
            text: `${entidad?.nombre ?? ''}`,
            italics: true,
            font: "TimesNewRoman",
            bold: true,
          },
          [
            {
              margin: [0, 0, 0, 0],
              text: title,
              fontSize: 18,
              alignment: "right",
              italics: true,
              font: "TimesNewRoman",
              style: estilosPdf.BOLDCURSIVA,
            },
            {
              margin: [0, 10, 0, 0],
              table: {
                widths: ["30%", "70%"],
                body: [
                  [
                    {
                      text: "ENTIDAD LOCAL ",
                      fontSize: 11,
                      fillColor: "#dbdbdb",
                      italics: true,
                      font: "TimesNewRoman",
                      bold: true,
                      style: estilosPdf.BOLDCURSIVA,
                      border: this.bordersType.none,
                    },
                    {
                      text: `${entidad?.nombre ?? ''}`,
                      fontSize: 11,
                      border: this.bordersType.none,
                      fillColor: "#dbdbdb",
                    },
                  ],
                ],
              },
            },

            {
              margin: [0, 10, 0, 0],
              table: {
                widths: ["30%", "70%"],

                body: [
                  [
                    {
                      text: "LOCALIDAD",
                      fontSize: 11,
                      fillColor: "#dbdbdb",
                      style: estilosPdf.BOLDCURSIVA,
                      border: this.bordersType.none,
                      bold: true,
                      italics: true,
                      font: "TimesNewRoman",
                    },
                    {
                      text: data.localidad ?? (data.municipio ? data.municipio.nombre : datosProvincia.nombre) ?? datosProvincia.nombre ?? '',
                      fontSize: 11,
                      border: this.bordersType.none,
                      fillColor: "#dbdbdb",
                    },
                  ],
                ],
              },
            },
          ],
        ],
      },
      {
        margin: [0, 10, 0, 0],
        table: {
          widths: ["*"],
          fontSize: 11,
          body: [
            [
              {
                fillColor: "#cccccc",
                fontSize: 10,
                border: this.bordersType.none,
                columns: columns
              },
            ],
            // [{ image: this.image1, width: 400 }],
          ],
        },
      },
    ];
    return encabezado;
  }

  async fichaDatosInmueblesUrbanos(data: any, epigrafe: any = { id: '' }, info: any = null, entidad_local?:any) {

   // this.entidad_local = JSON.parse(localStorage.getItem("entidad_local"));


    if (info == null) {
      info = {
        data: this.infoProvincia,
        image: this.imagenMunicipio
      }
    }

    let situacion = [];
    let linderos = [];
    let frutosRentas = []
    let valoracion = []

    if (epigrafe.id != 20) {
      frutosRentas = [
        [
          {
            rowSpan: 2,
            colSpan: 2,
            text: "FRUTOS Y RENTAS",
            border: [true, true, true, true],
            italics: true,
            font: "TimesNewRoman",
            bold: false,
            alignment: "center",
            margin: [0, 10, 0, 0],
          },
          "",
          ...this.columnBuild({
            texto: "CONCEPTO",
            colSpan: 4,
            borders: this.bordersType.topRight,
            italics: true,
            font: "TimesNewRoman",
            bold: false,
          }),
          ...this.columnBuild({
            texto: "VALOR (€)",
            colSpan: 2,
            borders: this.bordersType.topRight,
            italics: true,
            font: "TimesNewRoman",
            bold: false,
          }),
        ],
        [
          ...this.columnBuild({
            texto: "NO CONSTA",
            colSpan: 4,
            prevCol: 2,
            rowSpan: null,
            borders: this.bordersType.right,
            style: estilosPdf.TITULO,
          }),
          ...this.columnBuild({
            texto: `${this.currencySpPipe.transform(data.valor)}`,
            colSpan: 2,
            rowSpan: null,
            borders: this.bordersType.right,
            style: estilosPdf.TITULO,
          }),
        ]
      ]

      valoracion = [[
        {
          rowSpan: 2,
          colSpan: 2,
          text: "VALORACIÓN",
          border: this.bordersType.all,
          italics: true,
          font: "TimesNewRoman",
          bold: false,
          alignment: "center",
          margin: [0, 10, 0, 0],
        },
        "",
        ...this.columnBuild({
          texto: `VALOR DEL SUELO (€)`,
          colSpan: 2,
          borders: this.bordersType.topRight,
          italics: true,
          font: "TimesNewRoman",
          bold: false,
        }),
        ...this.columnBuild({
          texto: "VALOR CONSTRUCCIÓN (€) ",
          colSpan: 2,
          borders: this.bordersType.topRight,
          italics: true,
          font: "TimesNewRoman",
          bold: false,
        }),
        ...this.columnBuild({
          texto: "VALOR TOTAL (€)",
          colSpan: 2,
          borders: this.bordersType.topRight,
          italics: true,
          font: "TimesNewRoman",
          bold: false,
        }),
      ],
      [
        ...this.columnBuild({
          texto: `${this.currencySpPipe.transform(data.valor_suelo)}`,
          colSpan: 2,
          prevCol: 2,
          borders: this.bordersType.right,
          style: estilosPdf.TITULO,
        }),
        ...this.columnBuild({
          texto: `${this.currencySpPipe.transform(data.valor_construccion)}`,
          colSpan: 2,
          borders: this.bordersType.right,
          style: estilosPdf.TITULO,
        }),
        ...this.columnBuild({
          texto: `${this.currencySpPipe.transform(data.valor_total)}`,
          colSpan: 2,
          borders: this.bordersType.right,
          style: estilosPdf.TITULO,
        }),
      ]]
    } else {
      valoracion = [[
        ...this.columnBuild({
          texto: `VALORACION`,
          colSpan: 2,
          borders: this.bordersType.all,
          italics: true,
          font: "TimesNewRoman",
          bold: false,
        }),
        ...this.columnBuild({
          texto: `VALOR DEL SUELO (€)`,
          colSpan: 3,
          borders: this.bordersType.top,
          italics: true,
          font: "TimesNewRoman",
          bold: false,
        }),
        ...this.columnBuild({
          texto: `${this.currencySpPipe.transform(data.valor_suelo)}`,
          colSpan: 3,
          borders: this.bordersType.topRight,
          style: estilosPdf.TITULO,
        }),
      ]]
    }

    if (epigrafe.id == 17) {
      situacion.push(
        [
          {
            rowSpan: 2,
            border: this.bordersType.all,
            text: "SITUACIÓN",
            italics: true,
            font: "TimesNewRoman",
            verticalAlign: "middle",
            margin: [0, 17, 0, 0],
          },
          {
            colSpan: 2,
            border: this.bordersType.none,
            text: "REFERENCIA CATASTRAL",
            italics: true,
            font: "TimesNewRoman",
          },
          "",
          ...this.columnBuild({
            texto: data.referencia_catastral ?? "NO CONSTA",
            colSpan: 5,
            rowSpan: null,
            borders: this.bordersType.right,
            style: estilosPdf.TITULO,
          }),
        ],
        [
          "",
          ...this.columnBuild({
            texto: "POLÍGONO",
            colSpan: 2,
            rowSpan: null,
            borders: this.bordersType.bottom,
            italics: true,
            font: "TimesNewRoman",
            bold: false,
          }),
          ...this.columnBuild({
            texto: data.poligono ?? "NO PROCEDE",
            colSpan: 2,
            rowSpan: null,
            borders: this.bordersType.bottom,
            style: estilosPdf.TITULO,
          }),
          {
            borders: this.bordersType.bottom,
            text: "PARCELA",
            italics: true,
            font: "TimesNewRoman",
          },
          ...this.columnBuild({
            texto: data.parcela ?? "NO PROCEDE",
            colSpan: 2,
            rowSpan: null,
            borders: [false, false, true, true],
          }),
        ]
      );

      linderos.push(
        [
          {
            rowSpan: 4,
            border: this.bordersType.all,
            text: "LINDEROS",
            italics: true,
            font: "TimesNewRoman",
            margin: [0, 20, 0, 0],
          },
          {
            text: "LIND NORTE",
            rowSpan: null,
            border: this.bordersType.none,
            italics: true,
            font: "TimesNewRoman",
            bold: false,
          },
          {
            text: data.lindero_norte ?? "NO CONSTA",
            bold: true,
            colSpan: 6,
            fontSize: 8,
            rowSpan: null,
            border: this.bordersType.topRight,
            style: estilosPdf.TITULO,
          },
        ],
        [
          "",
          {
            border: this.bordersType.noRight,
            text: "LIND SUR",
            italics: true,
            font: "TimesNewRoman",
          },
          {
            text: data.lindero_sur ?? "NO CONSTA",
            colSpan: 6,
            bold: true,
            fontSize: 8,
            rowSpan: null,
            border: this.bordersType.topRight,
            style: estilosPdf.TITULO,
          },
        ],
        [
          "",
          {
            border: this.bordersType.vertical,
            text: "LIND ESTE",
            italics: true,
            font: "TimesNewRoman",
          },
          {
            text: data.lindero_este ?? "NO CONSTA",
            colSpan: 6,
            fontSize: 8,
            bold: true,
            rowSpan: null,
            border: this.bordersType.topRight,
            style: estilosPdf.TITULO,
          },
        ],
        [
          "",
          {
            border: this.bordersType.vertical,
            text: "LIND OESTE",
            italics: true,
            font: "TimesNewRoman",
          },
          {
            text: data.lindero_oeste ?? "NO CONSTA",
            colSpan: 6,
            bold: true,
            fontSize: 8,
            rowSpan: null,
            border: this.bordersType.topRight,
            style: estilosPdf.TITULO,
          },
        ]
      );
    } else {
      situacion.push(
        [
          {
            rowSpan: 3,
            border: this.bordersType.all,
            text: "SITUACIÓN",
            italics: true,
            font: "TimesNewRoman",
            verticalAlign: "middle",
            margin: [0, 17, 0, 0],
          },
          {
            colSpan: 2,
            border: this.bordersType.none,
            text: "REFERENCIA CATASTRAL",
            italics: true,
            font: "TimesNewRoman",
          },
          "",
          ...this.columnBuild({
            texto: data.referencia_catastral ?? "NO CONSTA",
            colSpan: 5,
            rowSpan: null,
            borders: this.bordersType.right,
            style: estilosPdf.TITULO,
          }),
        ],
        [
          "",
          {
            colSpan: 2,
            border: this.bordersType.none,
            text: "VÍA PÚBLICA AL FRENTE",
            italics: true,
            font: "TimesNewRoman",
          },
          "",
          ...this.columnBuild({
            texto: data.via_publica_al_frente ?? "NO CONSTA",
            colSpan: 3,
            rowSpan: null,
            borders: this.bordersType.none,
            style: estilosPdf.TITULO,
          }),
          {
            border: this.bordersType.none,
            text: "NÚMERO",
            italics: true,
            font: "TimesNewRoman",
          },
          {
            border: this.bordersType.right,
            text: data.numero ?? "NO CONSTA",
            style: estilosPdf.TITULO,
          },
        ],
        [
          "",
          ...this.columnBuild({
            texto: "POLÍGONO",
            colSpan: 2,
            rowSpan: null,
            borders: this.bordersType.bottom,
            italics: true,
            font: "TimesNewRoman",
            bold: false,
          }),
          ...this.columnBuild({
            texto: data.poligono ?? "NO PROCEDE",
            colSpan: 2,
            rowSpan: null,
            borders: this.bordersType.bottom,
            style: estilosPdf.TITULO,
          }),
          {
            borders: this.bordersType.bottom,
            text: "PARCELA",
            italics: true,
            font: "TimesNewRoman",
          },
          ...this.columnBuild({
            texto: data.parcela ?? "NO PROCEDE",
            colSpan: 2,
            rowSpan: null,
            borders: [false, false, true, true],
          }),
        ]
      );
      linderos.push(
        [
          {
            rowSpan: 4,
            border: this.bordersType.all,
            text: "LINDEROS",
            italics: true,
            font: "TimesNewRoman",
            margin: [0, 20, 0, 0],
          },

          ...this.columnBuild({
            texto: "LIND FRENTE",
            colSpan: 2,
            rowSpan: null,
            borders: this.bordersType.none,
            italics: true,
            font: "TimesNewRoman",
            bold: false,
          }),
          ...this.columnBuild({
            texto: data.lindero_frente ?? "NO CONSTA",
            colSpan: 5,
            rowSpan: null,
            borders: this.bordersType.topRight,
            style: estilosPdf.TITULO,
          }),
        ],
        [
          "",
          {
            colSpan: 2,
            border: this.bordersType.noRight,
            text: "LIND FONDO",
            italics: true,
            font: "TimesNewRoman",
          },
          "",
          ...this.columnBuild({
            texto: data.lindero_fondo ?? "NO CONSTA",
            colSpan: 5,
            rowSpan: null,
            borders: this.bordersType.topRight,
            style: estilosPdf.TITULO,
          }),
        ],
        [
          "",
          {
            colSpan: 2,
            border: this.bordersType.vertical,
            text: "LIND IZQUIERDO",
            italics: true,
            font: "TimesNewRoman",
          },
          "",
          ...this.columnBuild({
            texto: data.lindero_izquierdo ?? "NO CONSTA",
            colSpan: 5,
            rowSpan: null,
            borders: this.bordersType.topRight,
            style: estilosPdf.TITULO,
          }),
        ],
        [
          "",
          {
            colSpan: 2,
            border: this.bordersType.vertical,
            text: "LIND DERECHO",
            italics: true,
            font: "TimesNewRoman",
          },
          "",
          ...this.columnBuild({
            texto: data.lindero_derecho ?? "NO CONSTA",
            colSpan: 5,
            rowSpan: null,
            borders: this.bordersType.topRight,
            style: estilosPdf.TITULO,
          }),
        ]
      );
    }

    const tablaBody = [
      this.columnBuild({
        texto: "NOMBRE",
        colSpan: 8,
        rowSpan: null,
        borders: this.bordersType.noBottom,
        bold: false,
        italics: true,
        font: "TimesNewRoman",
      }),
      this.columnBuild({
        texto: data.nombre ?? "NO CONSTA",
        colSpan: 8,
        rowSpan: null,
        borders: this.bordersType.noTop,
        style: estilosPdf.TITULO,
      }),
      ...situacion,
      ...linderos,
      [
        {
          rowSpan: 2,
          border: this.bordersType.all,
          text: "SUPERFICIE",
          margin: [0, 20, 0, 0],
          italics: true,
          font: "TimesNewRoman",
        },
        {
          border: this.bordersType.all,
          text: "SUELO",
          margin: [0, 10, 0, 0],
          italics: true,
          fontSize: 8,
          font: "TimesNewRoman",
          alignment: "center",
        },
        {
          border: this.bordersType.noRight,
          text: "SUPERFICIE Registral (m2)",
          italics: true,
          fontSize: 8,
          font: "TimesNewRoman",
        },
        {
          border: this.bordersType.top,
          text: `${this.numberPipe.transform(data.s_sup_registral)}`,
          style: estilosPdf.TITULO,
          bold: true,
        },
        {
          border: this.bordersType.vertical,
          text: "SUPERFICIE Catastral (m2)",
          italics: true,
          fontSize: 8,
          font: "TimesNewRoman",
        },
        {
          border: this.bordersType.vertical,
          text: `${this.numberPipe.transform(data.s_sup_catastral)}`,
          style: estilosPdf.TITULO,
          bold: true,
        },
        {
          border: this.bordersType.vertical,
          text: "CALIFICACIÓN",
          italics: true,
          fontSize: 8,
          font: "TimesNewRoman",
        },
        {
          border: this.bordersType.topRight,
          text: data.calificacion ? data.calificacion.nombre : "NO CONSTA",
          style: estilosPdf.TITULO,
          fontSize: 8,
          bold: true,
        },
      ],
      [
        "",
        {
          border: this.bordersType.all,
          text: "EDIFICACIÓN",
          italics: true,
          fontSize: 8,
          margin: [0, 10, 0, 0],
          font: "TimesNewRoman",
        },
        {
          border: this.bordersType.noRight,
          text: "SUPERFICIE Registral (m2)",
          fontSize: 8,
          italics: true,
          font: "TimesNewRoman",
        },
        {
          border: this.bordersType.vertical,
          text: `${this.numberPipe.transform(data.c_sup_registral)}`,
          style: estilosPdf.TITULO,
          bold: true,
        },
        {
          border: this.bordersType.vertical,
          text: "SUPERFICIE Catastral (m2)",
          fontSize: 8,
          italics: true,
          font: "TimesNewRoman",
        },
        {
          border: this.bordersType.vertical,
          text: `${this.numberPipe.transform(data.c_sup_catastral)}`,
          style: estilosPdf.TITULO,
          bold: true,
        },
        {
          border: this.bordersType.vertical,
          text: "ESTADO DE Conservación",
          italics: true,
          fontSize: 8,
          font: "TimesNewRoman",
        },
        {
          border: this.bordersType.topRight,
          text: data.c_estado_conservacion ?? "NO PROCEDE",
          style: estilosPdf.TITULO,
          fontSize: 8,
          bold: true,
        },
      ],
      [
        ...this.columnBuild({
          texto: "APROVECHAMIENTO",
          colSpan: 3,
          rowSpan: null,
          borders: this.bordersType.noBottom,
          italics: true,
          font: "TimesNewRoman",
          bold: false,
        }),
        ...this.columnBuild({
          texto: "NATURALEZA DE DOMINIO",
          colSpan: 5,
          rowSpan: null,
          borders: this.bordersType.noBottom,
          italics: true,
          font: "TimesNewRoman",
          bold: false,
        }),
      ],
      [
        ...this.columnBuild({
          texto: data.aprovechamiento ?? "NO PROCEDE",
          colSpan: 3,
          rowSpan: null,
          borders: this.bordersType.noTop,
          style: estilosPdf.TITULO,
        }),
        ...this.columnBuild({
          texto: data.juridica.nombre ?? "NO PROCEDE",
          colSpan: 5,
          rowSpan: null,
          borders: this.bordersType.noTop,
          style: estilosPdf.TITULO,
        }),
      ],
      [
        ...this.columnBuild({
          texto: "TITULO DE PROPIEDAD",
          colSpan: 2,
          rowSpan: null,
          borders: this.bordersType.noBottom,
          italics: true,
          font: "TimesNewRoman",
          bold: false,
        }),
        ...this.columnBuild({
          texto: "INSCRIPCIÓN REGISTRAL",
          colSpan: 6,
          rowSpan: null,
          borders: this.bordersType.noBottom,
          italics: true,
          font: "TimesNewRoman",
          bold: false,
        }),
      ],
      [
        {
          colSpan: 2,
          rowSpan: 2,
          text: data.titulo_propiedad ?? "NO CONSTA",
          border: this.bordersType.noTop,
          style: estilosPdf.TITULO,
          bold: true,
        },
        "",
        {
          text: "Registro",
          border: this.bordersType.none,
          italics: true,
          font: "TimesNewRoman",
        },
        {
          text: "Tomo",
          border: this.bordersType.none,
          italics: true,
          font: "TimesNewRoman",
        },
        {
          text: "Libro",
          border: this.bordersType.none,
          italics: true,
          font: "TimesNewRoman",
        },
        {
          text: "Folio",
          border: this.bordersType.none,
          italics: true,
          font: "TimesNewRoman",
        },
        {
          text: "Finca",
          border: this.bordersType.none,
          italics: true,
          font: "TimesNewRoman",
        },
        {
          text: "Inscrip",
          border: this.bordersType.right,
          italics: true,
          font: "TimesNewRoman",
        },
      ],
      [
        "",
        "",
        {
          text: data.registro_propiedad ?? "NC",
          border: this.bordersType.bottom,
          style: estilosPdf.TITULO,
          bold: true,
        },
        {
          text: data.tomo ?? "NC",
          bold: true,
        },
        {
          text: `${data.libro ?? "NC"}`,
          bold: true,
        },
        {
          text: `${data.folio ?? "NC"}`,
          bold: true,
        },
        {
          text: data.finca ?? "NC",
          bold: true,
        },
        {
          text: data.inscripcion_registral ?? "NC",
          border: this.bordersType.right,
          style: estilosPdf.TITULO,
          bold: true,
        },
      ],
      [
        ...this.columnBuild({
          texto: "DESTINO",
          colSpan: 4,
          rowSpan: null,
          borders: this.bordersType.noBottom,
          italics: true,
          font: "TimesNewRoman",
          bold: false,
        }),
        ...this.columnBuild({
          texto: "ACUERDO",
          colSpan: 4,
          rowSpan: null,
          borders: this.bordersType.noBottom,
          italics: true,
          font: "TimesNewRoman",
          bold: false,
        }),
      ],
      [
        ...this.columnBuild({
          texto: data.destino ?? "NO CONSTA",
          colSpan: 4,
          rowSpan: null,
          borders: this.bordersType.noTop,
          style: estilosPdf.TITULO,
        }),
        ...this.columnBuild({
          texto: data.acuerdo ?? "NO CONSTA",
          colSpan: 4,
          rowSpan: null,
          borders: this.bordersType.noTop,
          style: estilosPdf.TITULO,
        }),
      ],
      [
        ...this.columnBuild({
          texto: "FECHA DE ADQUISICIÓN SUELO",
          colSpan: 3,
          rowSpan: null,
          borders: this.bordersType.noBottom,
          italics: true,
          font: "TimesNewRoman",
          bold: false,
        }),
        ...this.columnBuild({
          texto: "FECHA CONSTRUCCIÓN EDIFICACIÓN",
          colSpan: 5,
          rowSpan: null,
          borders: this.bordersType.noBottom,
          italics: true,
          font: "TimesNewRoman",
          bold: false,
        }),
      ],
      [
        ...this.columnBuild({
          texto: data.s_fecha_adquisicion ?? "NO CONSTA",
          colSpan: 3,
          rowSpan: null,
          borders: this.bordersType.noTop,
          style: estilosPdf.TITULO,
        }),
        ...this.columnBuild({
          texto: data.c_fecha_adquision ?? "NO PROCEDE",
          colSpan: 5,
          rowSpan: null,
          borders: this.bordersType.noTop,
          style: estilosPdf.TITULO,
        }),
      ],

      // ...this.row2("DERECHO REALES\nCONSTITUIDOS A FAVOR"),
      [
        ...this.columnBuild({
          texto: "DERECHO REALES\nCONSTITUIDOS A FAVOR",
          colSpan: 2,
          rowSpan: 3,
          borders: this.bordersType.all,
          italics: true,
          font: "TimesNewRoman",
          bold: false,
          alignment: "center",
          margin: [0, 15, 0, 0],
        }),
        ...this.columnBuild({
          texto: "No ORDEN INV.",
          rowSpan: null,
          borders: this.bordersType.vertical,
          italics: true,
          font: "TimesNewRoman",
          bold: false,
        }),
        ...this.columnBuild({
          texto: data.drcf_n_ord_inv ?? "NO CONSTA",
          colSpan: 2,
          rowSpan: null,
          borders: this.bordersType.vertical,
          style: estilosPdf.TITULO,
        }),
        ...this.columnBuild({
          texto: "NATURALEZA",
          rowSpan: null,
          borders: this.bordersType.vertical,
          italics: true,
          font: "TimesNewRoman",
          bold: false,
        }),
        ...this.columnBuild({
          texto: data.drcf_naturaleza ?? "NO CONSTA",
          rowSpan: null,
          colSpan: 2,
          borders: this.bordersType.topRight,
          style: estilosPdf.TITULO,
        }),
      ],
      [
        ...this.columnBuild({
          texto: "CONTENIDO",
          colSpan: 6,
          prevCol: 2,
          rowSpan: null,
          borders: this.bordersType.topRight,
          italics: true,
          font: "TimesNewRoman",
          bold: false,
        }),
      ],
      [
        ...this.columnBuild({
          texto: data.drcf_descripcion ?? "NO CONSTA",
          colSpan: 6,
          prevCol: 2,
          rowSpan: null,
          borders: this.bordersType.right,
          style: estilosPdf.TITULO,
        }),
      ],

      // ...this.row2("DERECHO REALES QUE LA\nGRAVAN"),

      [
        ...this.columnBuild({
          texto: "DERECHO REALES QUE LA\nGRAVAN",
          colSpan: 2,
          rowSpan: 3,
          borders: this.bordersType.all,
          italics: true,
          font: "TimesNewRoman",
          bold: false,
          alignment: "center",
          margin: [0, 15, 0, 0],
        }),
        ...this.columnBuild({
          texto: "No ORDEN INV.",
          rowSpan: null,
          borders: this.bordersType.vertical,
          italics: true,
          font: "TimesNewRoman",
          bold: false,
        }),
        ...this.columnBuild({
          texto: data.drg_n_ord_inv ?? "NO CONSTA",
          colSpan: 2,
          rowSpan: null,
          borders: this.bordersType.vertical,
          style: estilosPdf.TITULO,
        }),
        ...this.columnBuild({
          texto: "NATURALEZA",
          rowSpan: null,
          borders: this.bordersType.vertical,
          italics: true,
          font: "TimesNewRoman",
          bold: false,
        }),
        ...this.columnBuild({
          texto: data.drg_naturaleza ?? "NO CONSTA",
          colSpan: 2,
          rowSpan: null,
          borders: this.bordersType.topRight,
          style: estilosPdf.TITULO,
        }),
      ],
      [
        ...this.columnBuild({
          texto: "CONTENIDO",
          colSpan: 6,
          prevCol: 2,
          rowSpan: null,
          borders: this.bordersType.topRight,
          italics: true,
          font: "TimesNewRoman",
          bold: false,
        }),
      ],
      [
        ...this.columnBuild({
          texto: data.drg_descripcion ?? "NO CONSTA",
          colSpan: 6,
          prevCol: 2,
          rowSpan: null,
          borders: this.bordersType.right,
          style: estilosPdf.TITULO,
        }),
      ],

      // ...this.row2("DERECHO PERSONALES\nCONSTITUIDOS"),

      [
        ...this.columnBuild({
          texto: "DERECHO PERSONALES\nCONSTITUIDOS",
          colSpan: 2,
          rowSpan: 3,
          borders: this.bordersType.all,
          italics: true,
          font: "TimesNewRoman",
          bold: false,
          alignment: "center",
          margin: [0, 15, 0, 0],
        }),
        ...this.columnBuild({
          texto: "No ORDEN INV.",
          rowSpan: null,
          borders: this.bordersType.vertical,
          italics: true,
          font: "TimesNewRoman",
          bold: false,
        }),
        ...this.columnBuild({
          texto: data.drc_n_ord_inv ?? "NO CONSTA",
          colSpan: 2,
          rowSpan: null,
          borders: this.bordersType.vertical,
          style: estilosPdf.TITULO,
        }),
        ...this.columnBuild({
          texto: "NATURALEZA",
          rowSpan: null,
          borders: this.bordersType.vertical,
          italics: true,
          font: "TimesNewRoman",
          bold: false,
        }),
        ...this.columnBuild({
          texto: data.drc_naturaleza ?? "NO CONSTA",
          colSpan: 2,
          rowSpan: null,
          borders: this.bordersType.topRight,
          style: estilosPdf.TITULO,
        }),
      ],
      [
        ...this.columnBuild({
          texto: "CONTENIDO",
          colSpan: 6,
          prevCol: 2,
          rowSpan: null,
          borders: this.bordersType.topRight,
          italics: true,
          font: "TimesNewRoman",
          bold: false,
        }),
      ],
      [
        ...this.columnBuild({
          texto: data.drc_descripcion ?? "NO CONSTA",
          colSpan: 6,
          prevCol: 2,
          rowSpan: null,
          borders: this.bordersType.right,
          style: estilosPdf.TITULO,
        }),
      ],
      ///
      [
        {
          rowSpan: 3,
          colSpan: 2,
          text: "COSTO",
          border: this.bordersType.all,
          bold: false,
          font: "TimesNewRoman",
          alignment: "center",
          margin: [0, 15, 0, 0],
        },
        "",
        ...this.columnBuild({
          texto: "COSTO DE ADQUISICIÓN",
          colSpan: 3,
          rowSpan: null,
          borders: this.bordersType.top,
          italics: true,
          font: "TimesNewRoman",
          bold: false,
        }),
        ...this.columnBuild({
          texto: data.costo_adquisicion ? `${data.costo_adquisicion} ${data.moneda}` : "NO CONSTA",
          colSpan: 3,
          rowSpan: null,
          borders: this.bordersType.topRight,
          style: estilosPdf.TITULO,
        }),
      ],
      [
        ...this.columnBuild({
          texto: "Nº CUENTA (PGCP)",
          colSpan: 3,
          prevCol: 2,
          rowSpan: null,
          borders: this.bordersType.none,
          italics: true,
          font: "TimesNewRoman",
          bold: false,
        }),
        ...this.columnBuild({
          texto: data.contable.codigo ?? "NO CONSTA",
          // texto: data.contable ?? "NO CONSTA",
          colSpan: 3,
          rowSpan: null,
          borders: this.bordersType.right,
          style: estilosPdf.TITULO,
        }),
      ],
      [
        ...this.columnBuild({
          texto: "COSTOS INVERSIONES REALIZADAS",
          colSpan: 3,
          prevCol: 2,
          borders: this.bordersType.bottom,
          italics: true,
          font: "TimesNewRoman",
          bold: false,
        }),
        ...this.columnBuild({
          texto: data.costo_inversiones_realizadas ?? "NO CONSTA",
          colSpan: 3,
          borders: this.bordersType.right,
          style: estilosPdf.TITULO,
        }),
      ],
      ...valoracion,

      ...frutosRentas,

      [
        {
          colSpan: 8,
          text: "",
          border: this.bordersType.top,
        },
      ],
    ];
    let encabezado = []

    if (epigrafe.id == 20) {
      encabezado = await this.encabezadoFicha({ data: data, codigo: epigrafe, epigrafe: false, title: 'INVENTARIO DE BIENES DEL \n PATRIMONIO MUNICIPAL DE SUELO ', info: info },entidad_local);
    } else {
      encabezado = await this.encabezadoFicha({ data: data, codigo: epigrafe.codigo, epigrafe: true, info: info },entidad_local);
    }
    // const hor = await this.historialFichaEpigrafe(data);
    const content = [
      ...encabezado,
      {
        style: "tableExample",
        fontSize: 9,
        margin: [0, 10, 0, 0],
        table: {
          heights: 10.8,
          widths: [60, 55, 55, 55, 55, 55, 55, 55],
          body: tablaBody,
        },
        layout: {
          defaultBorder: false,
        },
        defaultStyle: {
          italics: true,
          font: "TimesNewRoman",
        },
      },
      // ...hor.content,
    ];

    let informe = {
      content,
      styles: {
        titulo: {
          // fontSize: 10,
          bold: true,
          italics: false,
          // margin: [0, 10, 0, 5],
        },
        cursiva: {
          // fontSize: 10,
          italics: true,
          font: "TimesNewRoman",
          // margin: [0, 10, 0, 5],
        },
        boldCursiva: {
          // fontSize: 10,
          italics: true,
          font: "TimesNewRoman",
          bold: true,
          // margin: [0, 10, 0, 5],
        },
      },
      defaultStyle: {
        italics: true,
        font: "TimesNewRoman",
      },
    };
    return informe;
    // this.pdfService.generatePdf(informe);
  }

  async fichaDatosVias(data: any, epigrafe: any = { id: '' }, info: any = null,entidad_local?:any) {


    let section = [];

    if (epigrafe.id == 18) {
      section = [
        [
          ...this.columnBuild({
            rowSpan: 2,
            colSpan: 2,
            borders: this.bordersType.all,
            texto: "SITUACIÓN",
            italics: true,
            font: "TimesNewRoman",
            alignment: "center",
            margin: [0, 8, 0, 0],
          }),
          ...this.columnBuild({
            colSpan: 2,
            borders: this.bordersType.none,
            texto: "ORIGEN",
            bold: false,
            italics: true,
            font: "TimesNewRoman",
          }),
          ...this.columnBuild({
            texto: data.origen ?? "NO CONSTA",
            colSpan: 4,
            rowSpan: null,
            borders: this.bordersType.right,
            style: estilosPdf.TITULO,
          }),
        ],
        [
          "",
          "",
          ...this.columnBuild({
            texto: "FINAL",
            colSpan: 2,
            rowSpan: null,
            borders: this.bordersType.bottom,
            italics: true,
            font: "TimesNewRoman",
            bold: false,
          }),
          ...this.columnBuild({
            texto: data.final ?? "NO CONSTA",
            colSpan: 4,
            rowSpan: null,
            borders: [false, false, true, true],
            style: estilosPdf.TITULO,
          }),
        ],

        [
          ...this.columnBuild({
            rowSpan: 2,
            colSpan: 2,
            borders: this.bordersType.all,
            texto: "SUPERFICIE",
            alignment: "center",
            italics: true,
            font: "TimesNewRoman",
            margin: [0, 8, 0, 0],
          }),
          ...this.columnBuild({
            colSpan: 2,
            borders: this.bordersType.none,
            texto: "LONGITUD (m) ",
            bold: false,
            italics: true,
            font: "TimesNewRoman",
          }),
          ...this.columnBuild({
            texto: data.s_sup_longitud ?? "NO CONSTA",
            colSpan: 1,
            rowSpan: null,
            borders: this.bordersType.none,
            style: estilosPdf.TITULO,
          }),
          ...this.columnBuild({
            colSpan: 2,
            borders: this.bordersType.none,
            texto: "ANCHURA (m) ",
            bold: false,
            italics: true,
            font: "TimesNewRoman",
          }),
          ...this.columnBuild({
            texto: data.s_sup_ancho ?? "NO CONSTA",
            colSpan: 1,
            rowSpan: null,
            borders: this.bordersType.right,
            style: estilosPdf.TITULO,
          }),
        ],
        [
          "",
          "",
          ...this.columnBuild({
            colSpan: 2,
            borders: this.bordersType.none,
            texto: "SUPERFICIE (m2)",
            bold: false,
            italics: true,
            font: "TimesNewRoman",
          }),
          ...this.columnBuild({
            texto: data.s_superficie ?? "NO CONSTA",
            colSpan: 1,
            rowSpan: null,
            borders: this.bordersType.none,
            style: estilosPdf.TITULO,
          }),
          ...this.columnBuild({
            colSpan: 2,
            borders: this.bordersType.none,
            texto: "SUP. REGISTRAL(m2)",
            bold: false,
            italics: true,
            font: "TimesNewRoman",
          }),
          ...this.columnBuild({
            texto: data.s_sup_registral ?? "NO CONSTA",
            colSpan: 1,
            rowSpan: null,
            borders: this.bordersType.right,
            style: estilosPdf.TITULO,
          }),
        ],

        [
          ...this.columnBuild({
            rowSpan: 3,
            colSpan: 2,
            borders: this.bordersType.all,
            texto: "CARACTERÍSTICAS",
            italics: true,
            font: "TimesNewRoman",
            alignment: "center",
            margin: [0, 30, 0, 0],
          }),
          ...this.columnBuild({
            colSpan: 2,
            borders: [false, true, false, true],
            texto: "TIPO DE PAVIMENTO",
            bold: false,
            italics: true,
            font: "TimesNewRoman",
          }),
          ...this.columnBuild({
            texto: data.caract_t_pavi ?? "NO CONSTA",
            colSpan: 4,
            rowSpan: null,
            borders: [false, true, true, true],
            style: estilosPdf.TITULO,
          }),
        ],
        [
          "",
          "",
          ...this.columnBuild({
            texto: "ESTADO DE CONSERVACIÓN",
            colSpan: 2,
            rowSpan: null,
            borders: [false, true, false, true],
            italics: true,
            font: "TimesNewRoman",
            bold: false,
          }),
          ...this.columnBuild({
            texto: data.caract_est_cons ?? "NO CONSTA",
            colSpan: 4,
            rowSpan: null,
            borders: [false, true, true, true],
            style: estilosPdf.TITULO,
          }),
        ],
        [
          "",
          "",
          ...this.columnBuild({
            texto: "INFRAESTRUCTURAS QUE SOPORTA",
            colSpan: 2,
            rowSpan: null,
            borders: this.bordersType.bottom,
            italics: true,
            font: "TimesNewRoman",
            bold: false,
          }),
          ...this.columnBuild({
            texto:
              "Alumbrado\nAlcantarillado\nTelecomunicación\nMobiliario Urbano",
            colSpan: 2,
            font: "TimesNewRoman",
            italics: true,
            bold: false,
            rowSpan: null,
            borders: [false, false, false, true],
            style: estilosPdf.TITULO,
          }),
          ...this.columnBuild({
            texto: `${data.alumbrado == 1 ? "Si" : "No"}\n${data.alcantarillado == 1 ? "Si" : "No"
              }\n${data.telecomunicacion == 1 ? "Si" : "No"}\n${data.mob_urbano == 1 ? "Si" : "No"
              }`,
            colSpan: 2,
            font: "TimesNewRoman",
            rowSpan: null,
            borders: [false, false, true, true],
            style: estilosPdf.TITULO,
          }),
        ],
      ];
    } else {
      section = [
        [
          ...this.columnBuild({
            rowSpan: 5,
            colSpan: 2,
            borders: this.bordersType.all,
            texto: "SITUACIÓN",
            italics: true,
            font: "TimesNewRoman",
            alignment: "center",
            margin: [0, 30, 0, 0],
          }),
          ...this.columnBuild({
            colSpan: 2,
            borders: [false, true, false, true],
            texto: "REF. CATASTRALES",
            bold: false,
            italics: true,
            font: "TimesNewRoman",
          }),
          ...this.columnBuild({
            texto: data.referencia_catastral ?? "NO CONSTA",
            colSpan: 4,
            rowSpan: null,
            borders: [false, true, true, true],
            style: estilosPdf.TITULO,
          }),
        ],
        [
          "",
          "",
          ...this.columnBuild({
            texto: "ORIGEN",
            colSpan: 2,
            rowSpan: null,
            borders: [false, true, false, true],
            italics: true,
            font: "TimesNewRoman",
            bold: false,
          }),
          ...this.columnBuild({
            texto: data.origen ?? "NO CONSTA",
            colSpan: 4,
            rowSpan: null,
            borders: [false, true, true, true],
            style: estilosPdf.TITULO,
          }),
        ],
        [
          "",
          "",
          ...this.columnBuild({
            texto: "FINAL",
            colSpan: 2,
            rowSpan: null,
            borders: this.bordersType.bottom,
            italics: true,
            font: "TimesNewRoman",
            bold: false,
          }),
          ...this.columnBuild({
            texto: data.final ?? "NO CONSTA",
            colSpan: 4,
            rowSpan: null,
            borders: [false, false, true, true],
            style: estilosPdf.TITULO,
          }),
        ],
        [
          "",
          "",
          ...this.columnBuild({
            texto: "IZQUIERDA",
            colSpan: 2,
            rowSpan: null,
            borders: this.bordersType.bottom,
            italics: true,
            font: "TimesNewRoman",
            bold: false,
          }),
          ...this.columnBuild({
            texto: data.izquierda ?? "NO CONSTA",
            colSpan: 4,
            rowSpan: null,
            borders: [false, false, true, true],
            style: estilosPdf.TITULO,
          }),
        ],
        [
          "",
          "",
          ...this.columnBuild({
            texto: "DERECHA",
            colSpan: 2,
            rowSpan: null,
            borders: this.bordersType.bottom,
            italics: true,
            font: "TimesNewRoman",
            bold: false,
          }),
          ...this.columnBuild({
            texto: data.derecha ?? "NO CONSTA",
            colSpan: 4,
            rowSpan: null,
            borders: [false, false, true, true],
            style: estilosPdf.TITULO,
          }),
        ],

        [
          ...this.columnBuild({
            rowSpan: 2,
            colSpan: 2,
            borders: this.bordersType.all,
            texto: "SUPERFICIE",
            alignment: "center",
            italics: true,
            font: "TimesNewRoman",
            margin: [0, 8, 0, 0],
          }),
          ...this.columnBuild({
            colSpan: 2,
            borders: this.bordersType.none,
            texto: "LONGITUD (m) ",
            bold: false,
            italics: true,
            font: "TimesNewRoman",
          }),
          ...this.columnBuild({
            texto: data.s_sup_longitud ?? "NO CONSTA",
            colSpan: 1,
            rowSpan: null,
            borders: this.bordersType.none,
            style: estilosPdf.TITULO,
          }),
          ...this.columnBuild({
            colSpan: 2,
            borders: this.bordersType.none,
            texto: "ANCHURA (m) ",
            bold: false,
            italics: true,
            font: "TimesNewRoman",
          }),
          ...this.columnBuild({
            texto: data.s_sup_ancho ?? "NO CONSTA",
            colSpan: 1,
            rowSpan: null,
            borders: this.bordersType.right,
            style: estilosPdf.TITULO,
          }),
        ],
        [
          "",
          "",
          ...this.columnBuild({
            colSpan: 2,
            borders: this.bordersType.none,
            texto: "SUPERFICIE (m2)",
            bold: false,
            italics: true,
            font: "TimesNewRoman",
          }),
          ...this.columnBuild({
            texto: data.s_superficie ?? "NO CONSTA",
            colSpan: 1,
            rowSpan: null,
            borders: this.bordersType.none,
            style: estilosPdf.TITULO,
          }),
          ...this.columnBuild({
            colSpan: 2,
            borders: this.bordersType.none,
            texto: "SUP. REGISTRAL(m2)",
            bold: false,
            italics: true,
            font: "TimesNewRoman",
          }),
          ...this.columnBuild({
            texto: data.s_sup_registral ?? "NO CONSTA",
            colSpan: 1,
            rowSpan: null,
            borders: this.bordersType.right,
            style: estilosPdf.TITULO,
          }),
        ],

        [
          ...this.columnBuild({
            rowSpan: 3,
            colSpan: 2,
            borders: this.bordersType.all,
            texto: "CARACTERÍSTICAS",
            italics: true,
            font: "TimesNewRoman",
            alignment: "center",
            margin: [0, 30, 0, 0],
          }),
          ...this.columnBuild({
            colSpan: 2,
            borders: [false, true, false, true],
            texto: "TIPO DE PAVIMENTO",
            bold: false,
            italics: true,
            font: "TimesNewRoman",
          }),
          ...this.columnBuild({
            texto: data.caract_t_pavi ?? "NO CONSTA",
            colSpan: 4,
            rowSpan: null,
            borders: [false, true, true, true],
            style: estilosPdf.TITULO,
          }),
        ],
        [
          "",
          "",
          ...this.columnBuild({
            texto: "ESTADO DE CONSERVACIÓN",
            colSpan: 2,
            rowSpan: null,
            borders: [false, true, false, true],
            italics: true,
            font: "TimesNewRoman",
            bold: false,
          }),
          ...this.columnBuild({
            texto: data.caract_est_cons ?? "NO CONSTA",
            colSpan: 4,
            rowSpan: null,
            borders: [false, true, true, true],
            style: estilosPdf.TITULO,
          }),
        ],
        [
          "",
          "",
          ...this.columnBuild({
            texto: "INFRAESTRUCTURAS QUE SOPORTA",
            colSpan: 2,
            rowSpan: null,
            borders: this.bordersType.bottom,
            italics: true,
            font: "TimesNewRoman",
            bold: false,
          }),
          ...this.columnBuild({
            texto:
              "Alumbrado\nAlcantarillado\nTelecomunicación\nMobiliario Urbano",
            colSpan: 2,
            font: "TimesNewRoman",
            italics: true,
            bold: false,
            rowSpan: null,
            borders: [false, false, false, true],
            style: estilosPdf.TITULO,
          }),
          ...this.columnBuild({
            texto: `${data.alumbrado == 1 ? "Si" : "No"}\n${data.alcantarillado == 1 ? "Si" : "No"
              }\n${data.telecomunicacion == 1 ? "Si" : "No"}\n${data.mob_urbano == 1 ? "Si" : "No"
              }`,
            colSpan: 2,
            font: "TimesNewRoman",
            rowSpan: null,
            borders: [false, false, true, true],
            style: estilosPdf.TITULO,
          }),
        ],
      ];
    }

    const tablaBody = [
      [
        ...this.columnBuild({
          texto: "TIPO DE VÍA",
          colSpan: 4,
          rowSpan: null,
          borders: this.bordersType.noBottom,
          bold: false,
          italics: true,
          font: "TimesNewRoman",
        }),
        ...this.columnBuild({
          texto: "NATURALEZA DE DOMINIO",
          colSpan: 4,
          rowSpan: null,
          borders: this.bordersType.noBottom,
          bold: false,
          italics: true,
          font: "TimesNewRoman",
        }),
      ],
      [
        ...this.columnBuild({
          texto: data.tipoVia ? data.tipoVia.nombre : "NO CONSTA",
          colSpan: 4,
          rowSpan: null,
          borders: this.bordersType.noTop,
          style: estilosPdf.TITULO,
        }),
        ...this.columnBuild({
          texto: data.juridica.nombre ?? "NO PROCEDE",
          colSpan: 4,
          rowSpan: null,
          borders: this.bordersType.noTop,
          style: estilosPdf.TITULO,
        }),
      ],
      [
        ...this.columnBuild({
          texto: "DENOMINACIÓN",
          colSpan: 8,
          rowSpan: null,
          borders: this.bordersType.noBottom,
          bold: false,
          italics: true,
          font: "TimesNewRoman",
        }),
      ],
      [
        ...this.columnBuild({
          texto: data.denominacion ?? "NO CONSTA",
          colSpan: 8,
          rowSpan: null,
          borders: this.bordersType.noTop,
        }),
      ],

      ...section,

      [
        ...this.columnBuild({
          texto: "TITULO DE PROPIEDAD",
          colSpan: 3,
          rowSpan: null,
          borders: this.bordersType.noBottom,
          italics: true,
          font: "TimesNewRoman",
          bold: false,
        }),
        ...this.columnBuild({
          texto: "INSCRIPCIÓN REGISTRAL",
          colSpan: 5,
          rowSpan: null,
          borders: this.bordersType.noBottom,
          italics: true,
          font: "TimesNewRoman",
          bold: false,
        }),
      ],
      [
        {
          colSpan: 3,
          rowSpan: 2,
          text: data.titulo_propiedad ?? "NO CONSTA",
          border: this.bordersType.noTop,
          style: estilosPdf.TITULO,
          bold: true,
        },
        "",
        "",
        {
          text: "Registro",
          border: this.bordersType.none,
          italics: true,
          font: "TimesNewRoman",
        },
        {
          text: "Tomo",
          border: this.bordersType.none,
          italics: true,
          font: "TimesNewRoman",
        },
        {
          text: "Libro  Folio",
          border: this.bordersType.none,
          italics: true,
          font: "TimesNewRoman",
        },
        {
          text: "Finca",
          border: this.bordersType.none,
          italics: true,
          font: "TimesNewRoman",
        },
        {
          text: "Inscrip",
          border: this.bordersType.right,
          italics: true,
          font: "TimesNewRoman",
        },
      ],
      [
        "",
        "",
        "",
        {
          text: data.registro_propiedad ?? "NO CONSTA",
          border: this.bordersType.bottom,
          style: estilosPdf.TITULO,
          bold: true,
        },
        {
          text: data.tomo ?? "NC",
          bold: true,
        },
        {
          text: `${data.libro ?? "NC"}   ${data.folio ?? "NC"}`,
          bold: true,
        },
        {
          text: data.finca ?? "NC",
          bold: true,
        },
        {
          text: data.inscripcion_registral ?? "NC",
          border: this.bordersType.right,
          style: estilosPdf.TITULO,
          bold: true,
        },
      ],
      [
        ...this.columnBuild({
          texto: "DESTINO",
          colSpan: 4,
          rowSpan: null,
          borders: this.bordersType.noBottom,
          italics: true,
          font: "TimesNewRoman",
          bold: false,
        }),
        ...this.columnBuild({
          texto: "ACUERDO",
          colSpan: 4,
          rowSpan: null,
          borders: this.bordersType.noBottom,
          italics: true,
          font: "TimesNewRoman",
          bold: false,
        }),
      ],
      [
        ...this.columnBuild({
          texto: data.destino ?? "NO CONSTA",
          colSpan: 4,
          rowSpan: null,
          borders: this.bordersType.noTop,
          style: estilosPdf.TITULO,
        }),
        ...this.columnBuild({
          texto: data.acuerdo ?? "NO CONSTA",
          colSpan: 4,
          rowSpan: null,
          borders: this.bordersType.noTop,
          style: estilosPdf.TITULO,
        }),
      ],

      // ...this.row2("DERECHO REALES\nCONSTITUIDOS A FAVOR"),
      [
        ...this.columnBuild({
          texto: "DERECHO REALES\nCONSTITUIDOS A FAVOR",
          colSpan: 2,
          rowSpan: 3,
          borders: this.bordersType.all,
          italics: true,
          font: "TimesNewRoman",
          bold: false,
          alignment: "center",
          margin: [0, 15, 0, 0],
        }),
        ...this.columnBuild({
          texto: "No ORDEN INV.",
          rowSpan: null,
          borders: this.bordersType.vertical,
          italics: true,
          font: "TimesNewRoman",
          bold: false,
        }),
        ...this.columnBuild({
          texto: data.drcf_n_ord_inv ?? "NO CONSTA",
          colSpan: 2,
          rowSpan: null,
          borders: this.bordersType.vertical,
          style: estilosPdf.TITULO,
        }),
        ...this.columnBuild({
          texto: "NATURALEZA",
          rowSpan: null,
          borders: this.bordersType.vertical,
          italics: true,
          font: "TimesNewRoman",
          bold: false,
        }),
        ...this.columnBuild({
          texto: data.drcf_naturaleza ?? "NO CONSTA",
          rowSpan: null,
          colSpan: 2,
          borders: this.bordersType.topRight,
          style: estilosPdf.TITULO,
        }),
      ],
      [
        ...this.columnBuild({
          texto: "CONTENIDO",
          colSpan: 6,
          prevCol: 2,
          rowSpan: null,
          borders: this.bordersType.topRight,
          italics: true,
          font: "TimesNewRoman",
          bold: false,
        }),
      ],
      [
        ...this.columnBuild({
          texto: data.drcf_descripcion ?? "NO CONSTA",
          colSpan: 6,
          prevCol: 2,
          rowSpan: null,
          borders: this.bordersType.right,
          style: estilosPdf.TITULO,
        }),
      ],

      // ...this.row2("DERECHO REALES QUE LA\nGRAVAN"),

      [
        ...this.columnBuild({
          texto: "DERECHO REALES QUE LA\nGRAVAN",
          colSpan: 2,
          rowSpan: 3,
          borders: this.bordersType.all,
          italics: true,
          font: "TimesNewRoman",
          bold: false,
          alignment: "center",
          margin: [0, 15, 0, 0],
        }),
        ...this.columnBuild({
          texto: "No ORDEN INV.",
          rowSpan: null,
          borders: this.bordersType.vertical,
          italics: true,
          font: "TimesNewRoman",
          bold: false,
        }),
        ...this.columnBuild({
          texto: data.drg_n_ord_inv ?? "NO CONSTA",
          colSpan: 2,
          rowSpan: null,
          borders: this.bordersType.vertical,
          style: estilosPdf.TITULO,
        }),
        ...this.columnBuild({
          texto: "NATURALEZA",
          rowSpan: null,
          borders: this.bordersType.vertical,
          italics: true,
          font: "TimesNewRoman",
          bold: false,
        }),
        ...this.columnBuild({
          texto: data.drg_naturaleza ?? "NO CONSTA",
          colSpan: 2,
          rowSpan: null,
          borders: this.bordersType.topRight,
          style: estilosPdf.TITULO,
        }),
      ],
      [
        ...this.columnBuild({
          texto: "CONTENIDO",
          colSpan: 6,
          prevCol: 2,
          rowSpan: null,
          borders: this.bordersType.topRight,
          italics: true,
          font: "TimesNewRoman",
          bold: false,
        }),
      ],
      [
        ...this.columnBuild({
          texto: data.drg_descripcion ?? "NO CONSTA",
          colSpan: 6,
          prevCol: 2,
          rowSpan: null,
          borders: this.bordersType.right,
          style: estilosPdf.TITULO,
        }),
      ],

      // ...this.row2("DERECHO PERSONALES\nCONSTITUIDOS"),

      [
        ...this.columnBuild({
          texto: "DERECHO PERSONALES\nCONSTITUIDOS",
          colSpan: 2,
          rowSpan: 3,
          borders: this.bordersType.all,
          italics: true,
          font: "TimesNewRoman",
          bold: false,
          alignment: "center",
          margin: [0, 15, 0, 0],
        }),
        ...this.columnBuild({
          texto: "No ORDEN INV.",
          rowSpan: null,
          borders: this.bordersType.vertical,
          italics: true,
          font: "TimesNewRoman",
          bold: false,
        }),
        ...this.columnBuild({
          texto: data.drc_n_ord_inv ?? "NO CONSTA",
          colSpan: 2,
          rowSpan: null,
          borders: this.bordersType.vertical,
          style: estilosPdf.TITULO,
        }),
        ...this.columnBuild({
          texto: "NATURALEZA",
          rowSpan: null,
          borders: this.bordersType.vertical,
          italics: true,
          font: "TimesNewRoman",
          bold: false,
        }),
        ...this.columnBuild({
          texto: data.drc_naturaleza ?? "NO CONSTA",
          colSpan: 2,
          rowSpan: null,
          borders: this.bordersType.topRight,
          style: estilosPdf.TITULO,
        }),
      ],
      [
        ...this.columnBuild({
          texto: "CONTENIDO",
          colSpan: 6,
          prevCol: 2,
          rowSpan: null,
          borders: this.bordersType.topRight,
          italics: true,
          font: "TimesNewRoman",
          bold: false,
        }),
      ],
      [
        ...this.columnBuild({
          texto: data.drc_descripcion ?? "NO CONSTA",
          colSpan: 6,
          prevCol: 2,
          rowSpan: null,
          borders: this.bordersType.right,
          style: estilosPdf.TITULO,
        }),
      ],
      ///
      [
        {
          rowSpan: 3,
          colSpan: 2,
          text: "COSTO",
          border: this.bordersType.all,
          bold: false,
          font: "TimesNewRoman",
          alignment: "center",
          margin: [0, 15, 0, 0],
        },
        "",
        ...this.columnBuild({
          texto: `COSTO DE LAS INVERSIONES Y MEJORAS EFECTUADAS (${data.moneda ?? "€"})`,
          colSpan: 4,
          rowSpan: null,
          borders: this.bordersType.top,
          italics: true,
          font: "TimesNewRoman",
          bold: false,
        }),
        ...this.columnBuild({
          texto: data.invers_realizadas ?? "NO CONSTA",
          colSpan: 2,
          rowSpan: null,
          borders: this.bordersType.topRight,
          style: estilosPdf.TITULO,
        }),
      ],
      [
        ...this.columnBuild({
          texto: "Nº CUENTA (PGCP)",
          colSpan: 3,
          prevCol: 2,
          rowSpan: null,
          borders: this.bordersType.none,
          italics: true,
          font: "TimesNewRoman",
          bold: false,
        }),
        ...this.columnBuild({
          texto: data.contable.codigo ?? "NO CONSTA",
          // texto: data.contable ?? "NO CONSTA",
          colSpan: 3,
          rowSpan: null,
          borders: this.bordersType.right,
          style: estilosPdf.TITULO,
        }),
      ],
      [
        ...this.columnBuild({
          texto: `VALORACIÓN TOTAL (${data.moneda ?? "€"})`,
          colSpan: 3,
          prevCol: 2,
          borders: this.bordersType.bottom,
          italics: true,
          font: "TimesNewRoman",
          bold: false,
        }),
        ...this.columnBuild({
          texto: `${this.currencySpPipe.transform(data.valor_total || "0")}`,
          colSpan: 3,
          borders: this.bordersType.right,
          style: estilosPdf.TITULO,
        }),
      ],
      [
        {
          colSpan: 8,
          text: "",
          border: this.bordersType.top,
        },
      ],
    ];
    const encabezado = await this.encabezadoFicha({ data, codigo: epigrafe.codigo, epigrafe: true, info },entidad_local);
    // const hor = await this.historialFichaEpigrafe(data);

    let heights: any = 14

    if (epigrafe.id == 18) {
      heights = 16
    }
    const content = [
      ...encabezado,
      {
        style: "tableExample",
        fontSize: 9,
        margin: [0, 10, 0, 0],
        table: {
          widths: "*",
          heights: heights,
          body: tablaBody,
        },
        layout: {
          defaultBorder: false,
        },
        defaultStyle: {
          italics: true,
          font: "TimesNewRoman",
        },
      },
      // ...hor.content,
    ];

    let informe = {
      content,
      styles: {
        titulo: {
          // fontSize: 10,
          bold: true,
          italics: false,
          // margin: [0, 10, 0, 5],
        },
        cursiva: {
          // fontSize: 10,
          italics: true,
          font: "TimesNewRoman",
          // margin: [0, 10, 0, 5],
        },
        boldCursiva: {
          // fontSize: 10,
          italics: true,
          font: "TimesNewRoman",
          bold: true,
          // margin: [0, 10, 0, 5],
        },
      },
      defaultStyle: {
        italics: true,
        font: "TimesNewRoman",
      },
    };
    return informe;
    // this.pdfService.generatePdf(informe);
  }

  bordersType = {
    none: [false, false, false, false],
    all: [true, true, true, true],
    left: [true, false, false, false],
    top: [false, true, false, false],
    right: [false, false, true, false],
    bottom: [false, false, false, true],
    vertical: [false, true, false, true],
    horizontal: [true, false, true, false],
    noLeft: [false, true, true, true],
    noTop: [true, false, true, true],
    noRight: [true, true, false, true],
    noBottom: [true, true, true, false],
    topRight: [false, true, true, false],
  };
  rowColumnsEmpy(col = 8, row = 1) {
    const resp = [];
    for (let i = 0; i < row; i++) {
      const er = [];
      for (let j = 0; j < col; j++) {
        er.push[""];
      }
      resp.push(er);
    }
    return resp;
  }
  rowBuild(texto, colSpan = 1) {
    let resp: any[] = [];
    resp.push({
      border: [true, false, true, true],
      text: texto,
      colSpan: colSpan,
    });
  }

  columnBuild({
    texto,
    colSpan = 0,
    rowSpan = 1,
    borders = [true, true, true, true],
    prevCol = 0,
    style = null,
    bold = true,
    italics = false,
    color = null,
    alignment = "left",
    margin = null,
    font = "Roboto",
    noWrap = false
  }) {
    let resp: any[] = [];
    if (prevCol) {
      for (let i = 0; i < prevCol; i++) {
        resp.push("");
      }
    }
    const colSpanAdd = colSpan ? colSpan - 1 : 0;
    resp.push({
      rowSpan: rowSpan,
      colSpan: colSpan,
      text: texto,
      border: borders,
      style: style,
      bold: bold,
      italics: italics,
      color: color,
      alignment: alignment,
      margin: margin,
      font: font,
      noWrap: noWrap
    });
    if (colSpanAdd) {
      for (let i = 0; i < colSpanAdd; i++) {
        resp.push("");
      }
    }
    return resp;
  }

  async listaBienes(
    data: any,
    titulo = "",
    tituloColumnas = ["N ORD.", "LOCALIDAD", "NOMBRE DE VIA", "NUM", "DESTINO"],
    valoresColumnnas = [
      "n_orden",
      "localidad",
      "tipovia",
      "via_publica_al_frente",
      "numero",
      "destino",
    ]
  ) {

    this.newFeaturesService.getInfoProvincia(this.userInfo.municipioId).subscribe({
      next: (res) => this.infoProvincia = res.result,
      error: (err) => console.log(err)
    });

    const body = [];
    data.forEach((element) => {
      body.push([
        ...this.columnBuild({
          texto: element[valoresColumnnas[0]] ?? "",
          borders: this.bordersType.none,
        }),
        ...this.columnBuild({
          texto: element[valoresColumnnas[1]] ?? "",
          borders: this.bordersType.none,
        }),
        ...this.columnBuild({
          texto: `${element[valoresColumnnas[2]] ? element[valoresColumnnas[2]] : ""
            } ${element[valoresColumnnas[3]] ?? ""}`,
          borders: this.bordersType.none,
        }),
        ...this.columnBuild({
          texto: element[valoresColumnnas[4]] ?? "",
          borders: this.bordersType.none,
        }),
        ...this.columnBuild({
          texto: element[valoresColumnnas[5]] ?? "",
          borders: this.bordersType.none,
        }),
      ]);
    });
    const content = [
      {
        text: `${titulo}`,
        style: [estilosPdf.TITULO],
        margin: [0, 0, 0, 15],
      },
      {
        style: "tableExample",
        margin: [0, 5, 0, 0],
        table: {
          widths: ["*"],
          body: [
            [
              {
                text: `EPIGRAFE ${titulo}`,
                alignment: "center",
                fillColor: "#43546A",
                color: "#ffffff",
                italics: false,
                bold: true,
              },
            ],
          ],
        },
      },
      {
        style: "tableExample",
        fontSize: 10,
        table: {
          widths: ["10%", "20%", "30%", "10%", "30%"],
          headerRows: 4,

          body: [
            [
              {
                text: "",
                border: this.bordersType.none,
                margin: [0, 0, 0, 0],
                fillColor: "#FFFFFF",
              },
              {
                text: "",
                border: this.bordersType.none,
                margin: [0, 0, 0, 0],
                fillColor: "#FFFFFF",
              },
              {
                text: "",
                border: this.bordersType.none,
                margin: [0, 0, 0, 0],
                fillColor: "#FFFFFF",
              },
              {
                text: "",
                border: this.bordersType.none,
                margin: [0, 0, 0, 0],
                fillColor: "#FFFFFF",
              },
              {
                text: "",
                border: this.bordersType.none,
                margin: [0, 0, 0, 0],
                fillColor: "#FFFFFF",
              },
            ],
            [
              {
                text: "",
                border: this.bordersType.none,
                margin: [0, 0, 0, 0],
              },
              {
                text: "",
                border: this.bordersType.none,
                margin: [0, 0, 0, 0],
              },
              {
                text: "",
                border: this.bordersType.none,
                margin: [0, 0, 0, 0],
              },
              {
                text: "",
                border: this.bordersType.none,
                margin: [0, 0, 0, 0],
              },
              {
                text: "",
                border: this.bordersType.none,
                margin: [0, 0, 0, 0],
              },
            ],
            [
              {
                text: tituloColumnas[0],
                fillColor: "#ABB9CA",
                alignment: "center",
                italics: false,
                bold: true,
              },
              {
                text: tituloColumnas[1],
                fillColor: "#ABB9CA",
                alignment: "center",
                italics: false,
                bold: true,
              },
              {
                text: tituloColumnas[2],
                fillColor: "#ABB9CA",
                alignment: "center",
                italics: false,
                bold: true,
              },
              {
                text: tituloColumnas[3],
                fillColor: "#ABB9CA",
                alignment: "center",
                italics: false,
                bold: true,
              },
              {
                text: tituloColumnas[4],
                fillColor: "#ABB9CA",
                alignment: "center",
                italics: false,
                bold: true,
              },
            ],
            [
              {
                text: "",
                border: this.bordersType.none,
                margin: [0, 0, 0, 10],
              },
              {
                text: "",
                border: this.bordersType.none,
                margin: [0, 0, 0, 10],
              },
              {
                text: "",
                border: this.bordersType.none,
                margin: [0, 0, 0, 10],
              },
              {
                text: "",
                border: this.bordersType.none,
                margin: [0, 0, 0, 10],
              },
              {
                text: "",
                border: this.bordersType.none,
                margin: [0, 0, 0, 10],
              },
            ],
            ...body,
          ],
        },
        layout: {
          fillColor: function (rowIndex, node, columnIndex) {
            return rowIndex % 2 === 0 ? "#CCCCCC" : null;
          },
        },
      },
    ];
    const headImg = {
      columns: [
        {
          image: this.imagenMunicipio,
          width: 50,
          margin: [0, 10, 0, 0],
        },
        {
          margin: [10, 45, 10, 0],
          alignment: "left",
          fontSize: 10,
          text: "INVENTARIO DE BIENES Y DERECHOS",
        },
      ],
      absolutePosition: {
        x: 65,
        y: 25,
      },
    };
    let informe = {
      footer: function (currentPage, pageCount) {
        return {
          margin: [0, 0, 45, 0],
          columns: [
            { width: "60%", text: `${this.entidad_local?.nombre ?? ''}`, alignment: "right" },
            { width: "40%", text: currentPage, alignment: "right" },
          ],
        };
      },
      pageOrientation: "landscape",
      content,
      styles: {
        titulo: {
          // fontSize: 10,
          bold: true,
          italics: false,
        },
        cursiva: {
          italics: true,
        },
        boldCursiva: {
          italics: true,
          bold: true,
        },
      },
      defaultStyle: {
        italics: false,
        bold: true,
      },
    };
    return informe;
    this.pdfService.generatePdf(informe);
  }
}