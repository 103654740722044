export class Utils {
    
    static normalizeString(str: string): string {
        return str.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase().trim();
    }

    static getInfoEpigrafeByCode(code: number): any {
        let infoEpigrafes = {
            6: { nombre: "Semovientes", epigrafe: "6.", url: "semovientes" },
            5: { nombre: "Otros Muebles", epigrafe: "7.", url: "otros-muebles" },
            11: { nombre: "Infraestructuras", epigrafe: "1.1.4.", url: "infraestructuras" },
            10: { nombre: "Solares", epigrafe: "1.1.3.", url: "solares" },
            9: { nombre: "Parques y Jardines", epigrafe: "1.1.2.", url: "parques-jardines" },
            8: { nombre: "Bienes Inmuebles", epigrafe: "1.1.1.", url: "inmuebles-urbanos" },
            17: { nombre: "Fincas Rústicas", epigrafe: "1.2.", url: "inmuebles-rusticos" },
            18: { nombre: "Vías Públicas Urbanas", epigrafe: "1.3.", url: "vias-publicas-urbanas" },
            19: { nombre: "Vías Públicas Rústicas", epigrafe: "1.4.", url: "vias-publicas-rusticas" },
            12: { nombre: "Derechos Reales", epigrafe: "2.", url: "derechos-reales" },
            13: { nombre: "Muebles de carácter Histórico-Artístico", epigrafe: "3.", url: "muebles-historicos" },
            15: { nombre: "Valores Mobiliarios", epigrafe: "4.1.", url: "valores-mobiliarios" },
            16: { nombre: "Créditos y Derechos", epigrafe: "4.2.", url: "creditos-derechos" },
            7: { nombre: "Vehículos", epigrafe: "5.", url: "vehiculos" },
            4: { nombre: "Bienes y Derechos Revertibles", epigrafe: "8.", url: "bienes-revertibles" },
            21: { nombre: "Arbolado Urbano", epigrafe: "Arbolado", url: "arbolado-urbano" }, // tener en cuenta que arbolado no tiene codigo
            20: { nombre: "Patrimonio Suelo - Inmueble", epigrafe: "Inmuebles", url: "patrimonio-municipal-suelo/inmuebles" } // tener en cuenta que no tenia codigo
        };
        return infoEpigrafes[code];
    }

    static getCodeByEpigrafe(epigrafe: string): number {
        let codes = {
            "6.": 6,
            "7.": 5,
            "1.1.4.": 11,
            "1.1.3.": 10,
            "1.1.2.": 9,
            "1.1.1.": 8,
            "1.2.": 17,
            "1.3.": 18,
            "1.4.": 19,
            "2.": 12,
            "3.": 13,
            "4.1.": 15,
            "4.2.": 16,
            "5.": 7,
            "8.": 4,
            "Arbolado":21,
            "Inmueble":20, //para patrimonio - inmueble
        };
        return codes[epigrafe];
    }

    static getInfoByTotalAssets(data: any): any {
        let object = [];
        for(let item of data) {
            if(item.nombre.startsWith("Inmuebles")) {
                for(let obj of item.subepigrafes) {
                    if(obj.nombre.startsWith("Inmuebles")) {
                        for(let x of obj.subepigrafes) {
                            object.push({
                                nombre: x.nombre,
                                total: Number(x.total)
                            });
                        }
                    } else {
                        object.push({
                            nombre: obj.nombre,
                            total: Number(obj.total)
                        });
                    }
                }
            } /* else if(item.nombre.startsWith("Valores")) {
                for(let obj of item.subepigrafes) {
                    object.push({
                        nombre: obj.nombre,
                        total: Number(obj.total)
                    });
                }
            } */ else {
                object.push({
                    nombre: item.nombre,
                    total: Number(item.total)
                });
            }
        }
        return object;
    }

    static getInfoResumenDerechos(data: any): any {
        let object = [];
        for(let item of data) {
            if(item.nombre.startsWith("Derechos")) {
                object.push({
                    nombre: item.nombre,
                    total: Number(item.total)
                });
            } else if(item.nombre.startsWith("Valores")) {
                for(let obj of item.subepigrafes) {
                    object.push({
                        nombre: obj.nombre,
                        total: Number(obj.total)
                    });
                }
            } else if(item.nombre.startsWith("Bienes")) {
                object.push({
                    nombre: item.nombre,
                    total: Number(item.total)
                });
            }
        }
        return object;
    }

    static getUrlByEpigrafeName(epigrafe: string): string {
        let url = "/app/";
        switch(epigrafe.toLowerCase()) {
            case "bienes inmuebles":
                url += "inmuebles-urbanos";
                break;
            case "parques y jardines":
                url += "parques-jardines";
                break;
            case "solares":
                url += "solares";
                break;
            case "infraestructuras":
                url += "infraestructuras";
                break;
            case "fincas rústicas":
                url += "inmuebles-rusticos";
                break;
            case "vías públicas urbanas":
                url += "vias-publicas-urbanas";
                break;
            case "vías públicas rústicas":
                url += "vias-publicas-rusticas";
                break;
            case "derechos reales":
                url += "derechos-reales";
                break;
            case "muebles de carácter histórico-artístico":
                url += "muebles-historicos";
                break;
            case "valores mobiliarios":
                url += "valores-mobiliarios";
                break;
            case "créditos y derechos":
                url += "creditos-derechos";
                break;
            case "vehiculos":
                url += "vehiculos";
                break;
            case "semovientes":
                url += "semovientes";
                break;
            case "otros muebles":
                url += "otros-muebles";
                break;
            case "bienes revertibles":
                url += "bienes-revertibles";
                break;
            default:
                break;
        }
        return url;
    }

    static numeroEnLetras(numero: number): string {
        const unidades = ['', 'uno', 'dos', 'tres', 'cuatro', 'cinco', 'seis', 'siete', 'ocho', 'nueve', 'diez', 'once', 'doce', 'trece', 'catorce', 'quince', 'dieciséis', 'diecisiete', 'dieciocho', 'diecinueve'];
        const decenas = ['', '', 'veinte', 'treinta', 'cuarenta', 'cincuenta', 'sesenta', 'setenta', 'ochenta', 'noventa'];
        const centenas = ['', 'ciento', 'doscientos', 'trescientos', 'cuatrocientos', 'quinientos', 'seiscientos', 'setecientos', 'ochocientos', 'novecientos'];
    
        if (numero < 0) {
          return 'menos ' + this.numeroEnLetras(Math.abs(numero));
        }
    
        if (numero < 20) {
          return unidades[numero];
        }
    
        if (numero < 100) {
          return decenas[Math.floor(numero / 10)] + ' ' + this.numeroEnLetras(numero % 10);
        }
    
        if (numero < 1000) {
          return centenas[Math.floor(numero / 100)] + ' ' + this.numeroEnLetras(numero % 100);
        }
    
        if (numero < 1000000) {
          return this.numeroEnLetras(Math.floor(numero / 1000)) + ' mil ' + this.numeroEnLetras(numero % 1000);
        }
    
        if (numero < 1000000000) {
          return this.numeroEnLetras(Math.floor(numero / 1000000)) + ' millones ' + this.numeroEnLetras(numero % 1000000);
        }
    
        return 'Número demasiado grande';
      }

      static entidadByEpigrafe(data: any){
        if([13, 7, 6, 5, 4, 14, 15, 16 ].includes[data.epigrafeId] ){
            return data.titulo_adquisicion ?? 'NA';
        }else {
            return data.titulo_propiedad ?? 'NA';
        }
      }

    static generateUUID(): string {
        let uuid = '';
        const characters = 'abcdef0123456789';
        for (let i = 0; i < 36; i++) {
          if (i === 8 || i === 13 || i === 18 || i === 23) {
            uuid += '-';
          } else if (i === 14) {
            uuid += '4';
          } else if (i === 19) {
            uuid += characters.charAt(Math.floor(Math.random() * 4) + 8);
          } else {
            uuid += characters.charAt(Math.floor(Math.random() * 16));
          }
        }
        return uuid;
    }

}