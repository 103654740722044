import { DateSpPipe } from './date.pipe';
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CurrencySpPipe } from "./currency.pipe";
import { SafePipe } from './safe.pipe';
import { NumberPipe } from './number.pipe';

@NgModule({
	declarations: [CurrencySpPipe, SafePipe, DateSpPipe, NumberPipe],
	imports: [CommonModule],
	exports: [CurrencySpPipe, SafePipe, DateSpPipe, NumberPipe],
	providers: [CurrencySpPipe, SafePipe, DateSpPipe, NumberPipe],
})
export class PipesModule {}
