import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { switchMap, of, Observable, ReplaySubject, take, map } from "rxjs";
import { environment } from "environments/environment";

@Injectable({
	providedIn: "root",
})
export class BienesRevertiblesService {
	urlApi = environment.urlApi;
	urlNew = environment.urlApi2;
	_urlEpigrafe = "bienes-revertibles";
	private _item: any;
	private _notifications: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
	constructor(private httpClient: HttpClient) {}

	public set item(val: any) {
		this._item = val;
	}
	public get item() {
		return this._item;
	}

	/**
	 * Getter for notifications
	 */
	get notifications$(): Observable<Notification[]> {
		return this._notifications.asObservable();
	}

	getBienesRevertibles(municipioId: number) {
		return this.httpClient.get(`${this.urlNew}datos-bienes?municipioId=${municipioId}&epigrafeId=4&limit=500`).pipe(
			switchMap((response: any) => {
				console.log(response);
				return of(response);
			})
		);
	}

	getByID(id: number): Observable<any> {
		return this.httpClient
			.get<any>(`${this.urlNew}datos-bienes/${id}`)
			.pipe(
				map((resp) => {
					console.log(resp);
					return resp;
				})
			);
	}

	solicitarBaja(data: any): Observable<any> {
		let headers = new HttpHeaders();
		headers = headers.set("Authorization", "Bearer " + localStorage.getItem("tokenOld"));
		return this.httpClient
			.put<any>(
				`${this.urlApi}${this._urlEpigrafe}/${data.id}/solicitar-baja/`,
				data,
				{ headers }
			)
			.pipe(
				map((resp) => {
					console.log(resp);
					return resp;
				})
			);
	}

	getPendientes(): Observable<any> {
		let headers = new HttpHeaders();
		headers = headers.set("Authorization", "Bearer " + localStorage.getItem("tokenOld"));
		return this.httpClient
			.get<any>(`${this.urlApi}${this._urlEpigrafe}/pendientes`, { headers })
			.pipe(
				map((resp) => {
					return resp;
				})
			);
	}

	keysToUpperCase(obj) {
		Object.keys(obj).forEach(function (key) {
			var k = key.toUpperCase();

			if (k !== key) {
				obj[k] = obj[key];
				delete obj[key];
			}
		});
		return obj;
	}
}
