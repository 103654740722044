import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class InfoProvinciaService {
  public infoProvincia: any = null;
  public escudoImagen: any = null;
  public nombreLocalidad:any="";
  constructor() { }
}
