import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numbersp'
})
export class NumberPipe implements PipeTransform {

  transform(valor: string, ...args: unknown[]) {
    if (!valor) return 0;
    if(Number.isNaN(Number(valor))) return valor;
		const rounded = Number(valor).toFixed(2);
		const [entero, decimal] = rounded.split(".");
		let formatInt = entero.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
		return decimal ? `${formatInt},${decimal}` : formatInt;
  }

}
