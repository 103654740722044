import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "environments/environment";
import { catchError, map, Observable, of } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class NewFeaturesService {
  constructor(private http: HttpClient) { }
  capitalize(text) {
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
  }
  capitalizeWords(text) {
    const words = text.split(' ');
    const capitalizedWords = words.map(word => this.capitalize(word));
    return capitalizedWords.join(' ');
  }

  getInfoProvincia(municipioId: string | number): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', "Bearer " + localStorage.getItem("accessToken"));
    return this.http.get(`${environment.urlApi2}municipios/${municipioId}`, { headers });
  }


  uploadData(municipioId: string | number, data: any, force: any = false): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', "Bearer " + localStorage.getItem("accessToken"));
    if (force) {
      return this.http.post(`${environment.urlApi2}etl/${municipioId}?force=true`, data, { headers });
    } else {
      return this.http.post(`${environment.urlApi2}etl/${municipioId}`, data, { headers });
    }
  }

  
  getMunicipioImagen(provincia: string, municipio: string): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', "Bearer " + localStorage.getItem("accessToken"));

    let prov = provincia.toLocaleUpperCase();
    let mun = municipio.toLocaleUpperCase();

    const url = `${environment.urlApi2}file-manager/file/shields-images/${prov}?file=${mun}.png`;

    return this.http.get(url, { headers, responseType: 'blob' }).pipe(
      map(response => {
        return response;
      }),
      catchError(error => {
        if (error.status === 404) {
          console.log("La imagen no existe. Usando imagen predeterminada.");
          return this.getDefaultImage(); // Retorna la imagen predeterminada
        } else {
          console.error('Error al obtener la imagen:', error);
          throw error; // Lanza el error para que pueda ser manejado en el componente llamante
        }
      })
    );
  }

  private getDefaultImage(): Observable<any> {
    return this.http.get('assets/arrecifeBN.png', { responseType: 'blob' }).pipe(
      map(response => {
        return response;
      })
    );
  }

  getDataInformeCatastro(tipoReg: string | number, codProvincia: string | number, pageSize: number, currentPage: number) {
    const headers = new HttpHeaders().set('Authorization', "Bearer " + localStorage.getItem("accessToken"));
    return this.http.get(`${environment.urlApi2}datos-cat?tipo_reg=${tipoReg}&page=${currentPage}&limit=${pageSize}&municipioId=${codProvincia}`, { headers });
    // return this.getInfoProvincia(municipioId).pipe(
    //   switchMap(res => {
    //     console.log(res);

    //     // return this.http.get(`${environment.urlApi2}datos-cat?tipo_reg=${tipoReg}&page=${currentPage}&limit=${pageSize}&municipioId=21001`, { headers });
    //   })
    // );
  }

  getDataTotalesCatastro(municipioId: string | number, provinciaId: string | number): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', "Bearer " + localStorage.getItem("accessToken"));
    return this.http.get(`${environment.urlApi2}informes/scraping/totales?provinciaId=${provinciaId}`, { headers });
    // return this.getInfoProvincia(municipioId).pipe(
    //   switchMap(res => {
    //     console.log(res);
    //     // return this.http.get(`${environment.urlApi2}informes/scraping/totales?provinciaId=21`, { headers });
    //   })
    // );

  }


}
