import { switchMap, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'environments/environment';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ValoracionGeneralService {
  urlApi = environment.urlApi;
  urlNew = environment.urlApi2;

  constructor(private httpClient: HttpClient) { }

  getValoracionGeneralFilter(filter: string, id_municipio: number) {
    return this.httpClient
      .get(
        `${this.urlNew}informes/valoracion?division=${filter}&municipioId=${id_municipio}`
      )
      .pipe(
        switchMap((response: any) => {
          return of(response);
        })
      );
	}
	
	getValoracionGeneral(id_municipio: number) {
		return this.httpClient.get(`${this.urlNew}informes/valoracion?municipioId=${id_municipio}`).pipe(
			switchMap((response: any) => {
				return of(response);
			})
		);
	}

}
