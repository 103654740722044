import { FeaturesService } from '../services/features.service';
import { Validators } from '@angular/forms';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';
import { LoadingModalComponent } from "../../../../modules/components/loading-modal/loading-modal.component";
import { MatDialog } from "@angular/material/dialog";
import { NewFeaturesService } from "../services/newFeatures.service";
import { ExportDocumentosPdfService } from "../services/export-pdf.service";

@Component({
  selector: 'app-modal-informe-resultados',
  templateUrl: './modal-informe-resultados.component.html',
  styleUrls: ['./modal-informe-resultados.component.scss']
})
export class ModalInformeResultadosComponent implements OnInit {
  form: FormGroup;
  formIncompleto: boolean = false;
  provincias: any[] = [];
  municipios: any[] = [];
  roles: any[] = [];
  entidades_locales: any[] = [];
  userInfo : any
  infoProvincia: any
  imagenMunicipio: any
  constructor(
    public dialogRef: MatDialogRef<ModalInformeResultadosComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private fb: FormBuilder,
    private _featureSvc: FeaturesService,
    private _matDialog: MatDialog,
    private newFeaturesService: NewFeaturesService,
    private _pdfDocService: ExportDocumentosPdfService,
  ) { }

  
  ngOnInit(): void {
    this.userInfo = JSON.parse(localStorage.getItem("user"));
    this._featureSvc.getEntidadesLocales().subscribe({
      next: (resp: any) => this.entidades_locales = resp.result,
      error: (err) => console.log(err)
    });
    this.form = this.fb.group({
      id_provincia:  ["", Validators.required],
      municipioId:  ["", Validators.required],
    });
    this._featureSvc.getProvincias().subscribe({
      next: (res) => this.provincias = res.result,
      error: (err) => console.log(err)
    });
  }


  onChangeProvincia(event: any) {
    if(!event.value) return;
    this._featureSvc.getProvinciasById(event.value).subscribe({
      next: (res) => this.municipios = res.result.municipios,
      error: (err) => console.log(err)
    });
  }


  onClose(): void {
		this.dialogRef.close();
	}

  async guardar() {

    if(this.form.invalid) {
      this.form.markAllAsTouched();
      this.formIncompleto = true;
      return;
    }
    this.dialogRef.close()
    this.formIncompleto = false;
    this.newFeaturesService.getInfoProvincia(this.form.value.municipioId).toPromise().then(
			(res) => {
        this._pdfDocService.informeActualizacionInventarioWord(res.result)
			},
			(err) => console.log(err)
		);

  }
}
