import { TranslocoService } from '@ngneat/transloco';
import { SubmenuPatrimonioSueloComponent } from "./../../../../modules/admin/features/submenu-patrimonio-suelo/submenu-patrimonio-suelo.component";
import { IntegracionComponent } from "./../../../../modules/admin/features/integracion/integracion.component";
import { ExportarFicherosComponent } from "./../../../../modules/admin/features/exportar-ficheros/exportar-ficheros.component";
import { AmortizacionComponent } from "./../../../../modules/admin/features/amortizacion/amortizacion.component";
import { SubmenuAltaBienComponent } from "./../../../../modules/admin/features/submenu-alta-bien/submenu-alta-bien.component";
import { MatDialog } from "@angular/material/dialog";
import { Component, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Subject, takeUntil } from "rxjs";
import { DrawerMenuComponent } from "app/layout/common/drawer-menu/drawer-menu.component";
import { FuseMediaWatcherService } from "@fuse/services/media-watcher";
import {
	FuseNavigationService,
	FuseVerticalNavigationComponent,
} from "@fuse/components/navigation";
import { Navigation } from "app/core/navigation/navigation.types";
import { NavigationService } from "app/core/navigation/navigation.service";
import { FuseConfigService } from "../../../../../@fuse/services/config/config.service";
import { SharedService } from "../../../../shared/shared.service";
import { InvArboladoUrbanoService } from 'app/modules/admin/features/inv-arbolado-urbano/inv-arbolado-urbano.service';
import { MenuEnable } from 'app/modules/admin/features/services/menu-enable.service';

@Component({
	selector: "modern-layout",
	templateUrl: "./modern.component.html",
	encapsulation: ViewEncapsulation.None,
	styles: [
		`
			modern-layout #component-container > *:first-child {
				position: relative;
				display: flex;
				flex-direction: column;
				flex: 1 1 auto;
			}
		`,
	],
})
export class ModernLayoutComponent implements OnInit, OnDestroy {
	isScreenSmall: boolean;
	smallMenuOpen: boolean;
	navigation: Navigation;
	private _unsubscribeAll: Subject<any> = new Subject<any>();
	showMenu = true;
	userInfo: any;

	dataArbolado: any[] = [];

	banderaMenuExportacion=this._navigationService.banderaMenuExportacion;

	/**
	 * Constructor
	 */
	constructor(
		private _activatedRoute: ActivatedRoute,
		private _router: Router,
		private _navigationService: NavigationService,
		private _fuseMediaWatcherService: FuseMediaWatcherService,
		private _fuseNavigationService: FuseNavigationService,
		private _matDialog: MatDialog,
		private _fuseConfigService: FuseConfigService,
		private _sharedService: SharedService,
		private _translocoService: TranslocoService,
		private arbUrbanoService: InvArboladoUrbanoService,
		public serviceMenuExportacion:MenuEnable
	) {
		this.userInfo = JSON.parse(localStorage.getItem("user"));
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Accessors
	// -----------------------------------------------------------------------------------------------------

	/**
	 * Getter for current year
	 */
	get currentYear(): number {
		return new Date().getFullYear();
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Lifecycle hooks
	// -----------------------------------------------------------------------------------------------------

	/**
	 * On init
	 */
	ngOnInit(): void {
		this.arbUrbanoService.get(this.userInfo.municipioId).subscribe((data) => {
			this.dataArbolado = data.result ? data.result.datosBienes : [];
		});
		// Subscribe to navigation data
		this._navigationService.navigation$
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe((navigation: Navigation) => {
				this.navigation = navigation;
			});

		// Subscribe to media changes
		this._fuseMediaWatcherService.onMediaChange$
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe(({ matchingAliases }) => {
				// Check if the screen is small
				this.isScreenSmall = !matchingAliases.includes("md");
			});

		this._fuseConfigService.showMenu$
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe((showMenu: any) => {
				// Store the config
				this.showMenu = showMenu;

				// Update the layout
				// this._updateLayout();
			});
	}

	/**
	 * On destroy
	 */
	ngOnDestroy(): void {
		// Unsubscribe from all subscriptions
		this._unsubscribeAll.next(null);
		this._unsubscribeAll.complete();
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Public methods
	// -----------------------------------------------------------------------------------------------------

	/**
	 * Toggle navigation
	 *
	 * @param name
	 */
	toggleNavigation(name: string): void {
		// Get the navigation
		const navigation =
			this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(
				name
			);

		if (navigation) {
			// Toggle the opened status
			navigation.toggle();
		}
	}

	changeLanguage(lang: string): void {
		this._translocoService.setActiveLang(lang);
	}

	openDialogAltaBien(): void {
		this._matDialog.open(SubmenuAltaBienComponent, {
			autoFocus: false,
		});
	}

	openDialogAmortizacion(): void {
		this._matDialog.open(AmortizacionComponent, {
			autoFocus: false,
		});
	}

	onOpenModalExportarFicheros(): void {
		this._matDialog.open(ExportarFicherosComponent, {
			autoFocus: false,
		});
	}

	onOpenModalIntegracion(): void {
		this._matDialog.open(IntegracionComponent, {
			autoFocus: false,
			width: "450px",
		});
	}

	onOpenModalSubmenuPatrimonioSuelo(): void {
		this._matDialog.open(SubmenuPatrimonioSueloComponent, {
			autoFocus: false,
		});
	}

	toggleMenu() {
		this._sharedService.emitData();
	}
}
