import { SubmenuPatrimonioSueloComponent } from './../../../modules/admin/features/submenu-patrimonio-suelo/submenu-patrimonio-suelo.component';
import { DatosCatastroComponent } from "./../../../modules/admin/features/datos-catastro/datos-catastro.component";
import { SubmenuInventariosComponent } from "./../../../modules/admin/features/submenu-inventarios/submenu-inventarios.component";
import { ModalInformeBienesComponent } from "./../../../modules/admin/features/modal-informe-bienes/modal-informe-bienes.component";
import { ModalInformeResultadosComponent } from "./../../../modules/admin/features/modal-informe-resultados/modal-informe-resultados.component";
import { ConfiguracionCertificadosComponent } from "./../../../modules/admin/features/configuracion-certificados/configuracion-certificados.component";
import { ModalCargarDatosComponent } from 'app/modules/admin/features/modal-cargar-datos/modal-cargar-datos.component';
import { MatDialog } from "@angular/material/dialog";
import {
	Component,
	Input,
	OnDestroy,
	OnInit,
	ViewEncapsulation,
} from "@angular/core";
import { NavigationStart, Router } from "@angular/router";
import { Subject, takeUntil } from "rxjs";
import { FuseConfigService } from "@fuse/services/config";
import { AppConfig, Scheme, Theme, Themes } from "app/core/config/app.config";
import { Layout } from "app/layout/layout.types";
import { FuseMediaWatcherService } from "../../../../@fuse/services/media-watcher/media-watcher.service";
import { ExportDocumentosPdfService } from "../../../modules/admin/features/services/export-pdf.service";
import { CertificadosPdfService } from "../../../modules/admin/features/services/certificados-pdf.service";
import { PdfService } from "../../../modules/admin/features/services/pdf.service";
import { FeaturesService } from "../../../modules/admin/features/services/features.service";
// import { saveAs } from "file-saver";
import { SharedService } from "../../../shared/shared.service";
import { ViewChild, ElementRef } from "@angular/core";
import { LoadingModalComponent } from "../../../modules/components/loading-modal/loading-modal.component";
import { environment } from 'environments/environment';

@Component({
	selector: "drawer-menu",
	templateUrl: "./drawer-menu.component.html",
	styles: [
		`
			drawer-menu {
				position: static;
				display: block;
				flex: none;
				width: auto;
			}

			@media (screen and min-width: 1280px) {
				empty-layout + drawer-menu .settings-cog {
					left: 0 !important;
				}
			}
		`,
	],
	encapsulation: ViewEncapsulation.None,
})
export class DrawerMenuComponent implements OnInit, OnDestroy {
	smallMenuOpen: boolean;
	config: AppConfig;
	layout: Layout;
	scheme: "dark" | "light";
	theme: string;
	themes: Themes;
	isScreenSmall: boolean;
	private _unsubscribeAll: Subject<any> = new Subject<any>();
	userInfo: any;
	@ViewChild("settingsDrawer") settingsDrawer: any;

	/**
	 * Constructor
	 */
	constructor(
		private _router: Router,
		private _fuseConfigService: FuseConfigService,
		private _fuseMediaWatcherService: FuseMediaWatcherService,
		private _matDialog: MatDialog,
		private _pdfDocService: ExportDocumentosPdfService,
		private _pdfService: PdfService,
		private _certificadoService: CertificadosPdfService,
		private featureService: FeaturesService,
		private _sharedService: SharedService,
	) {
		_router.events.forEach((event) => {
			if (event instanceof NavigationStart) {
				this.settingsDrawer.close();
			}
		});
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Lifecycle hooks
	// -----------------------------------------------------------------------------------------------------

	/**
	 * On init
	 */
	ngOnInit(): void {
		this._sharedService.toggleStateMenu$
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe((data) => {
				this.settingsDrawer.toggle();
			});
		// Subscribe to config changes
		this._fuseConfigService.config$
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe((config: AppConfig) => {
				// Store the config
				this.config = config;
			});

		// Subscribe to media changes
		this._fuseMediaWatcherService.onMediaChange$
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe(({ matchingAliases }) => {
				// Check if the screen is small
				this.isScreenSmall = !matchingAliases.includes("md");
			});

		this.userInfo = JSON.parse(localStorage.getItem("user"));
	}

	/**
	 * On destroy
	 */
	ngOnDestroy(): void {
		// Unsubscribe from all subscriptions
		this._unsubscribeAll.next(null);
		this._unsubscribeAll.complete();
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Public methods
	// -----------------------------------------------------------------------------------------------------

	/**
	 * Set the layout on the config
	 *
	 * @param layout
	 */
	setLayout(layout: string): void {
		// Clear the 'layout' query param to allow layout changes
		this._router
			.navigate([], {
				queryParams: {
					layout: null,
				},
				queryParamsHandling: "merge",
			})
			.then(() => {
				// Set the config
				this._fuseConfigService.config = { layout };
			});
	}

	/**
	 * Set the scheme on the config
	 *
	 * @param scheme
	 */
	setScheme(scheme: Scheme): void {
		this._fuseConfigService.config = { scheme };
	}

	/**
	 * Set the theme on the config
	 *
	 * @param theme
	 */
	setTheme(theme: Theme): void {
		this._fuseConfigService.config = { theme };
	}

	onOpenModalCertificadosConfiguracion(): void {
		this._matDialog.open(ConfiguracionCertificadosComponent, {
			autoFocus: false,
		});
	}

	onOpenModalDatosCatastro(): void {
		this._matDialog.open(DatosCatastroComponent, {
			autoFocus: false,
		});
	}

	onOpenModalSubmenuInventarioBienes(): void {
		this._matDialog.open(SubmenuInventariosComponent, {
			autoFocus: false,
		});
	}

	onOpenModalPatrimonioSuelo(): void {
		this._matDialog.open(SubmenuPatrimonioSueloComponent, {
			autoFocus: false
		});
	}

	onOpenModalCargarDatos() {
		this._matDialog.open(ModalCargarDatosComponent, {
			autoFocus: false,
		});
	}

	informeResultados() {
		this._pdfDocService.informeActualizacionInventarioWord();
	}

	async showModalInventarioBienes() {
		
		
		if(this.userInfo.rol != 'ADMIN') {
			this._matDialog.open(ModalInformeBienesComponent, {
				autoFocus: false,
			});
		} else {
			const dialogRef = this._matDialog.open(LoadingModalComponent, {
				autoFocus: true,
			});
			
			await this._certificadoService.inventarioBienesDerechos();

			dialogRef.close()
		}
	}

	async showModalResultados() {
		
		
		if(this.userInfo.rol != 'ADMIN') {
			this._matDialog.open(ModalInformeResultadosComponent, {
				autoFocus: false,
			});
		} else {
			const dialogRef = this._matDialog.open(LoadingModalComponent, {
				autoFocus: true,
			});
			
			this._pdfDocService.informeActualizacionInventarioWord();

			dialogRef.close()
		}
	}

	async inventarioBienes() {
		
		// await this._certificadoService.inventarioBienesDerechos();

		// await this._createPdfService.informeBienesInventario();
		

		//close modal here

		// let url = 'https://backend.dev.patrimoniogest.serprofes.com/api/file-manager2/file2/docs?file=informe01.pdf'
		// let url = `${environment.urlApi}file-manager2/file2/docs?file=informe01.pdf`;
		// window.open(url, "_blank");
		// this.featureService
		// 	.getPdf("docs?file=informe01.pdf")
		// 	.subscribe((data) => {
		// 		// let blob: Blob = data.body as Blob;
		// 		// saveAs(blob, "informe01.pdf");
		// 		const url = window.URL.createObjectURL(data);
		// 		window.open(url);
		// 	});
	}
}
