import { Component, OnInit } from "@angular/core";
import { FeaturesService } from "../../admin/features/services/features.service";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
	selector: "app-loading-modal",
	templateUrl: "./loading-modal.component.html",
	styles: [""],
})
export class LoadingModalComponent implements OnInit {
	constructor(
		private featureService: FeaturesService,
		public dialogRef: MatDialogRef<LoadingModalComponent>
	) {}

	ngOnInit(): void {}

	ngAfterViewInit(): void {
		// this.featureService
		// 	.getPdf("docs?file=informe01.pdf")
		// 	.subscribe((data) => {
		// 		// let blob: Blob = data.body as Blob;
		// 		// saveAs(blob, "informe01.pdf");
		// 		const url = window.URL.createObjectURL(data);
		// 		window.open(url);
		// 		this.dialogRef.close();
		// 	});

		// this.dialogRef.close();
	}
	ngOnDestroy(): void {}
}
