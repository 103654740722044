import { SubmenuAltaBienComponent } from './../submenu-alta-bien.component';
import { SubmenuInventariosComponent } from './../../submenu-inventarios/submenu-inventarios.component';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';

@Component({
  selector: 'app-submenu-mobiliarios-creditos',
  templateUrl: './submenu-mobiliarios-creditos.component.html',
  styleUrls: ['./submenu-mobiliarios-creditos.component.scss']
})
export class SubmenuMobiliariosCreditosComponent implements OnInit {
  lista: any[] = [
		{ name: "valores_mobiliarios", color: "#333B63", epigrafe: "4.1.", url: "valores-mobiliarios" },
		{ 
			name: "creditos", 
			color: "#F8BE5C", 
			epigrafe: "4.2.",
      url: "creditos-derechos"
		},
	];

  constructor(
    public dialogRef: MatDialogRef<SubmenuMobiliariosCreditosComponent>,
		private _matDialog: MatDialog,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private _router: Router
  ) { }

  ngOnInit(): void {
  }

  onClose(): void {
		this.dialogRef.close();
	}

  onClick(item: any) {
    let url = this.data.isAlta ? `/app/alta-bien/${item.url}` : `app/${item.url}`;
    this._router
				.navigateByUrl(url, {
					state: { name: item.name, epigrafe: item.epigrafe },
				})
				.then(() => {
					this.dialogRef.close();
				});
  }

  onPrevSubmenu(): void {
		this.dialogRef.close();
    if(this.data.isAlta) {
      this._matDialog.open(SubmenuAltaBienComponent, {
        autoFocus: false
      })
    } else {
      this._matDialog.open(SubmenuInventariosComponent, {
        autoFocus: false
      })
    }
	}

}
