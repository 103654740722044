import { FeaturesService } from '../services/features.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Validators } from '@angular/forms';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';
import { LoadingModalComponent } from "../../../../modules/components/loading-modal/loading-modal.component";
import { MatDialog } from "@angular/material/dialog";
import { CertificadosPdfService } from "../services/certificados-pdf.service";
import { NewFeaturesService } from "../services/newFeatures.service";
@Component({
  selector: 'app-modal-informe-bienes',
  templateUrl: './modal-informe-bienes.component.html',
  styleUrls: ['./modal-informe-bienes.component.scss']
})
export class ModalInformeBienesComponent implements OnInit {
  form: FormGroup;
  formIncompleto: boolean = false;
  provincias: any[] = [];
  municipios: any[] = [];
  roles: any[] = [];
  entidades_locales: any[] = [];
  userInfo : any
  infoProvincia: any
  imagenMunicipio: any
  infoEntidad:any;

  constructor(
    public dialogRef: MatDialogRef<ModalInformeBienesComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private fb: FormBuilder,
    private _snackBar: MatSnackBar,
    private _featureSvc: FeaturesService,
    private _matDialog: MatDialog,
    private _certificadoService: CertificadosPdfService,
    private newFeaturesService: NewFeaturesService
  ) { }

  ngOnInit(): void {
    this.userInfo = JSON.parse(localStorage.getItem("user"));
    this._featureSvc.getEntidadesLocales().subscribe({
      next: (resp: any) => this.entidades_locales = resp.result,
      error: (err) => console.log(err)
    });
    this.form = this.fb.group({
      entidadLocalId:  ["", Validators.required],
      id_provincia:  ["", Validators.required],
      municipioId:  ["", Validators.required],
    });
    this._featureSvc.getProvincias().subscribe({
      next: (res) => this.provincias = res.result,
      error: (err) => console.log(err)
    });
  }


  onChangeProvincia(event: any) {
    if(!event.value) return;
    this._featureSvc.getProvinciasById(event.value).subscribe({
      next: (res) => this.municipios = res.result.municipios,
      error: (err) => console.log(err)
    });
  }


  onClose(): void {
		this.dialogRef.close();
	}

  async guardar() {

    if(this.form.invalid) {
      this.form.markAllAsTouched();
      this.formIncompleto = true;
      return;
    }
    this.dialogRef.close()
    const dialogRefLoading = this._matDialog.open(LoadingModalComponent, {
      autoFocus: true,
    });

    this.formIncompleto = false;
    this.newFeaturesService.getInfoProvincia(this.form.value.municipioId).toPromise().then(
			(res) => {
				this.infoProvincia = res.result
      
        // Esto se acaba de añadir
        this._featureSvc.getEntidadLocalByMunicipio(this.infoProvincia.id).subscribe((resp)=>{
          this.infoEntidad = resp.result;
        });
				this.newFeaturesService.getMunicipioImagen(this.infoProvincia.provincia.nombre, this.infoProvincia.nombre).toPromise().then(
					(res) => {
						const reader = new FileReader();
						reader.readAsDataURL(res);
						reader.onloadend = () => {
							this.imagenMunicipio = reader.result;


  
              this._certificadoService.inventarioBienesDerechos({
                data: this.infoProvincia,
                image: this.imagenMunicipio
              },this.infoEntidad,this.imagenMunicipio).then(() => {
                dialogRefLoading.close()
              });
                  
						};
					},
					(err) => {
            console.log(this.infoProvincia)
            this._certificadoService.inventarioBienesDerechos({
              data: this.infoProvincia,
              image: null
            },this.infoEntidad,null).then(() => {
                // this.dialogRef.close();
                dialogRefLoading.close()
            });
          }
				);
			},
			(err) => console.log(err)
		);

  }

}
