<div class="flex flex-col flex-auto -m-6">
    <div class="flex flex-row sm:flex-row items-center justify-between bg-[#333B63] text-[#FBFCFD] py-4 px-9">
        <div class="text-xl font-bold tracking-tight leading-6 truncate">{{ "modales.ejecutarIntegracion.title" | transloco }}</div>
        <button class="mt-3 sm:mt-0 sm:ml-2" (click)="onClose()">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <div class="grid grid-cols-1 grid-flow-row gap-6 w-full mt-4 sm:mt-4 px-8 py-4">
        <div class="font-bold text-lg leading-5 text-[#333B63]">{{ "modales.ejecutarIntegracion.text" | transloco }}:</div>
        <mat-radio-group class="grid grid-cols-1 gap-5 my-2 text-lg leading-6" style="color: rgba(0, 0, 0, 0.6);
        ">
            <mat-radio-button value="Gema">GEMA (TAO)</mat-radio-button>
            <mat-radio-button value="Urbamap">URBAMAP (TAO)</mat-radio-button>
            <mat-radio-button value="Sicalwin">SICALWIN</mat-radio-button>
        </mat-radio-group>
        <div class="flex justify-between md:justify-end lg:justify-end gap-4">
            <button class="border-[#333B63] border rounded font-medium text-base leading-8 py-1 px-3" (click)="onClose()">{{ "modales.ejecutarIntegracion.buttons.cancelar" | transloco | uppercase }}</button>
            <button class="bg-[#333B63] rounded font-medium text-base leading-8 text-white py-1 px-4">{{ "modales.ejecutarIntegracion.buttons.aceptar" | transloco | uppercase }}</button>
        </div>
    </div>
</div>