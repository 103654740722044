<h1 mat-dialog-title>
    {{'mensaggess.mens_moda_inform_result'| transloco}}
</h1>
<div mat-dialog-content>
    <form [formGroup]="form">
        <div class="sm:col-span-3">
            <label>{{ "usuarios.modales.create.provincia.label" | transloco }}</label>
            <mat-form-field appearance="fill" class="w-full mt-2">
                <mat-select [placeholder]="'usuarios.modales.create.provincia.placeholder' | transloco" [formControlName]="'id_provincia'" (selectionChange)="onChangeProvincia($event)">
                    <mat-option *ngFor="let provincia of provincias" [value]="provincia.id">
                        {{provincia.nombre}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
  
    
        <div class="sm:col-span-3">
            <label>{{ "usuarios.modales.create.municipio.label" | transloco }}</label>
            <mat-form-field appearance="fill" class="w-full mt-2">
                <mat-select [placeholder]="'usuarios.modales.create.municipio.placeholder' | transloco" [formControlName]="'municipioId'">
                    <mat-option *ngFor="let municipio of municipios" [value]="municipio.id">
                        {{municipio.nombre}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="sm:col-span-3">
            <label>{{ "usuarios.modales.create.entity.label" | transloco }}</label>
            <mat-form-field appearance="fill" class="w-full mt-2">
                <mat-select [placeholder]="'usuarios.modales.create.entity.placeholder' | transloco" [formControlName]="'entidadLocalId'">
                    <mat-option *ngFor="let item of entidades_locales" [value]="item.id">
                        {{item.nombre}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </form>
</div>
<div mat-dialog-actions>
    <div class="mt-3 flex justify-end gap-4">
        <button class="border-[#333B63] border rounded font-medium text-base leading-8 py-1 px-3" (click)="onClose()">{{ "usuarios.modales.create.buttons.cancelar" | transloco | uppercase }}</button>
        <button class="bg-[#333B63] rounded font-medium text-base leading-8 text-white py-1 px-4" (click)="guardar()">{{ "usuarios.modales.create.buttons.aceptar" | transloco | uppercase }}</button>
    </div>
</div>