import { MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-integracion',
  templateUrl: './integracion.component.html',
  styleUrls: ['./integracion.component.scss']
})
export class IntegracionComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<IntegracionComponent>
  ) { }

  ngOnInit(): void {
  }

  onClose(): void {
		this.dialogRef.close();
	}


}
