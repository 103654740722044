import { Pipe } from "@angular/core";

@Pipe({
	name: "currencysp",
})
export class CurrencySpPipe {
	transform(val: string) {
		//if(!val) return null;
		return new Intl.NumberFormat("de-DE", {
			style: "currency",
			currency: "EUR",
			minimumFractionDigits: 2,
		}).format(Number(val));
	}
}
