import { Subject } from "rxjs";
import { Injectable } from "@angular/core";

@Injectable({
	providedIn: "root",
})
export class SharedService {
	private toggleStateMenu = new Subject();
	public toggleStateMenu$ = this.toggleStateMenu.asObservable();
	private toggleMenuVal = false;

	emitData() {
		this.toggleMenuVal = !this.toggleMenuVal;
		this.toggleStateMenu.next(this.toggleMenuVal);
	}
}
