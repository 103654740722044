<div class="flex flex-col flex-auto md:w-120 md:min-w-120 -m-6">
    <div class="flex flex-col sm:flex-row items-start justify-between bg-[#333B63] text-[#FBFCFD] py-4 px-9">
        <div class="text-xl font-bold tracking-tight leading-6 truncate">{{ "modales.obtenerCatastro.title" | transloco }}</div>
        <button class="mt-3 sm:mt-0 sm:ml-2" (click)="onClose()">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <div class="grid grid-cols-1 grid-flow-row gap-4 w-full mt-4 sm:mt-4 px-8 py-4">
        <div class="font-bold text-lg leading-5 text-[#333B63]">{{ "modales.obtenerCatastro.text" | transloco }}</div>
        <form [formGroup]="form" class="flex flex-col gap-4">
            <div>
                <div class="bg-[#102A63] py-2 px-3 w-20 text-center font-bold text-xl leading-6 text-[#FBFCFD]">{{ "modales.obtenerCatastro.datos.name" | transloco }}</div>
                <div class="border-[#333B63] border px-3 pt-2 bg-[#F4F6F8] flex-auto" style="color: rgba(0, 0, 0, 0.87);">
                    <div>
                        <label>{{ "modales.obtenerCatastro.datos.usuario.label" | transloco }}</label>
                        <mat-form-field appearance="fill" class="w-full mt-2">
                            <input matInput type="text" placeholder="{{'modales.obtenerCatastro.datos.usuario.placeholder' | transloco}}"
                            [formControlName]="'usuario'">
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="border-[#333B63] border px-3 pt-2 bg-[#F4F6F8] flex-auto" style="color: rgba(0, 0, 0, 0.87);">
                <div>
                    <label>{{ "modales.obtenerCatastro.datos.password.label" | transloco }}</label>
                    <mat-form-field appearance="fill" class="w-full mt-2">
                        <input matInput type="password" placeholder="{{'modales.obtenerCatastro.datos.password.placeholder' | transloco}}"
                        [formControlName]="'password'">
                    </mat-form-field>
                </div>
            </div>
            <div>
                <div class="bg-[#102A63] py-2 px-3 w-26 text-center font-bold text-xl leading-6 text-[#FBFCFD]">{{ "modales.obtenerCatastro.provincia.name" | transloco }}</div>
                <div class="border-[#333B63] border px-3 pt-2 bg-[#F4F6F8] flex-auto" style="color: rgba(0, 0, 0, 0.87);">
                    <div>
                        <label>{{ "modales.obtenerCatastro.provincia.provincia.label" | transloco }}</label>
                        <mat-form-field appearance="fill" class="w-full mt-2">
                            <mat-select placeholder="{{'modales.obtenerCatastro.provincia.provincia.placeholder' | transloco}}" [formControlName]="'provincia'">
                                <mat-option *ngFor="let provincia of provincias" [value]="provincia.nombre">
                                    {{provincia.nombre}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="flex justify-end gap-4">
                <button class="border-[#333B63] border rounded font-medium text-base leading-8 px-3" (click)="onClose()">{{ "modales.obtenerCatastro.buttons.cancelar" | transloco | uppercase }}</button>
                <button class="bg-[#333B63] rounded font-medium text-base leading-8 text-white px-4" (click)="cargar()">{{ "modales.obtenerCatastro.buttons.aceptar" | transloco | uppercase }}</button>
            </div>
        </form>
    </div>
</div>