<div class="flex flex-col flex-auto md:w-160 md:min-w-160 -m-6 p-8">
    <div class="flex flex-row sm:flex-row items-center justify-between">
        <div class="text-lg font-medium tracking-tight leading-6 truncate">{{ "modales.seleccionSubepigrafes.altaBien" | transloco }}</div>
        <button class="mt-3 sm:mt-0 sm:ml-2" (click)="onClose()">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <div class="grid grid-cols-1 grid-flow-row gap-6 w-full mt-8 sm:mt-4">

        <div class="flex flex-col">
            <div class="font-medium text-secondary">{{ "modales.seleccionSubepigrafes.seleccione" | transloco }}</div>
            <div class="flex-auto grid grid-cols-4 gap-4 mt-6">
                <button
                    class="col-span-2 sm:col-span-1 flex flex-col items-center justify-center py-8 px-1 rounded-2xl text-white dark:bg-white dark:bg-opacity-5"
                    *ngFor="let item of lista" matDialogClose [style.backgroundColor]="item.color"
                    (click)="onOpenSubmenu(item)">
                    <div class="mt-1 text-xl font-medium text-center">{{ item.name | transloco }}</div>
                </button>
            </div>
        </div>
    </div>
</div>