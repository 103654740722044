import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialog } from "@angular/material/dialog";
import { NewFeaturesService } from "../services/newFeatures.service";
import { FeaturesService } from '../services/features.service';
import { ExportDocumentosPdfService } from "../services/export-pdf.service";
import { FormGroup, FormBuilder } from '@angular/forms';
import { Validators } from '@angular/forms';
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
  selector: 'app-modal-cargar-datos',
  templateUrl: './modal-cargar-datos.component.html',
  styleUrls: ['./modal-cargar-datos.component.scss']
})
export class ModalCargarDatosComponent implements OnInit {
  userInfo: any;
  form: FormGroup;
  file: any
  provincias: any[] = [];
  municipios: any[] = [];
  entidades_locales: any[] = [];
  infoProvincia: any
  municipioId: any

  loading: any = false
  forceMessage: any = false


  constructor(
    public dialogRef: MatDialogRef<ModalCargarDatosComponent>,
    private NewFeaturesService: NewFeaturesService,
    private _featureSvc: FeaturesService,
    private _matDialog: MatDialog,
    private fb: FormBuilder,
    private newFeaturesService: NewFeaturesService,
    private _pdfDocService: ExportDocumentosPdfService,
    private _snackBar: MatSnackBar,
  ) { }

  ngOnInit(): void {
    this.userInfo = JSON.parse(localStorage.getItem("user"));
    this._featureSvc.getEntidadesLocales().subscribe({
      next: (resp: any) => this.entidades_locales = resp.result,
      error: (err) => console.log(err)
    });
    this._featureSvc.getProvincias().subscribe({
      next: (res) => this.provincias = res.result,
      error: (err) => console.log(err)
    });

    this.form = this.fb.group({
      id_provincia:  ["", Validators.required],
      municipioId:  ["", Validators.required],
    });
  }

  @ViewChild('fileInput') fileInput!: ElementRef<HTMLInputElement>;
  @ViewChild('fileInputContainer') fileInputContainer!: ElementRef<HTMLDivElement>;

  fileName: string | null = null;

  handleFileInputClick() {
    this.fileInput.nativeElement.click();
  }

  handleFileInputChange(event: Event) {
    const file = (event.target as HTMLInputElement).files?.[0];

    if (file && this.isValidFileType(file)) {
      this.fileName = file.name;

      this.file = file
    }
  }

  onChangeProvincia(event: any) {
    this.form.get('municipioId').setValue(null);
    if(!event.value) return;
    this._featureSvc.getProvinciasById(event.value).subscribe({
      next: (res) => this.municipios = res.result.municipios,
      error: (err) => console.log(err)
    });
  }

  uploadFile(force) {
    this.loading = true
    if(this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    if(this.file == undefined) {
      this._snackBar.open(
        "Debe agregar un archivo.",
        "Cerrar",
        {
          duration: 2000,
        }
      );
    } else {
      const formData = new FormData();
      const reader = new FileReader();
      reader.readAsArrayBuffer(this.file);
      reader.onload = () => {
        const arrayBuffer = reader.result as ArrayBuffer;
        const blob = new Blob([arrayBuffer], { type: 'application/vnd.ms-excel' });

        formData.append('document', blob, this.file.name);
        this.NewFeaturesService.uploadData(this.form.value.municipioId, formData, force).subscribe(
          response => {
            console.log(response)
            this._snackBar.open(
              "Datos cargados con éxito.",
              "Cerrar",
              {
                duration: 2000,
              }
            );
            this.loading = false

            this.dialogRef.close()
          },
          error => {
            console.error(error.error)
            if(error?.error?.msg == "Municipio already has data") {
              this.forceMessage = true
            }
            this.loading = false
          }
        );
      };

    }

  }
  
  onClose(): void {
		this.dialogRef.close();
	}


  private isValidFileType(file: File): boolean {
    const allowedExtensions = ['.xls', '.xlsx', '.csv'];
    const fileExtension = file.name.split('.').pop()?.toLowerCase();
    return allowedExtensions.includes(`.${fileExtension}`);
  }

}
