import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { switchMap, of, Observable, ReplaySubject, take, map } from "rxjs";
import { environment } from "environments/environment";

@Injectable({
	providedIn: "root",
})
export class ConfiguracionCertificadosService {
	urlApi = environment.urlApi2;
	_urlEpigrafe = "configuraciones";
	private _item: any;
	private _notifications: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
	constructor(private httpClient: HttpClient) {}

	public set item(val: any) {
		this._item = val;
	}
	public get item() {
		return this._item;
	}

	/**
	 * Getter for notifications
	 */
	get notifications$(): Observable<Notification[]> {
		return this._notifications.asObservable();
	}

	getConfiguracion() {
		let userInfo = JSON.parse(localStorage.getItem("user"));
		return this.httpClient
			.get(`${this.urlApi}${this._urlEpigrafe}/${userInfo.idUser}`)
			.pipe(
				switchMap((response: any) => {
					return of(response);
				})
			);
	}

	create(data: any): Observable<any> {
		console.log(data);

		return this.httpClient
			.post<any>(`${this.urlApi}${this._urlEpigrafe}`, data)
			.pipe(
				map((resp) => {
					console.log(resp);
					return resp;
				})
			);
	}

	update(data: any, id: number): Observable<any> {
		console.log(data);

		return this.httpClient
			.put<any>(`${this.urlApi}${this._urlEpigrafe}/${id}`, data)
			.pipe(
				map((resp) => {
					console.log(resp);
					return resp;
				})
			);
	}

	getByID(id: number): Observable<any> {
		return this.httpClient
			.get<any>(`${this.urlApi}${this._urlEpigrafe}/${id}`)
			.pipe(
				map((resp) => {
					console.log(resp);
					return resp;
				})
			);
	}
}
