import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { NewFeaturesService } from "../services/newFeatures.service";

import {
  switchMap,
  of,
  Observable,
  ReplaySubject,
  take,
  map,
  catchError,
} from "rxjs";
import { environment } from "environments/environment";

@Injectable({
  providedIn: "root",
})
export class FeaturesService {
  urlApi = environment.urlApi;
  //urlApi2 = "http://82.223.217.238:3000/api/";
  urlApiNuevoBackend = environment.urlApi2;
  urlApiFiles = this.urlApiNuevoBackend;
  infoProvincia: any
  userInfo: any;

  private _item: any;
  private _notifications: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  constructor(
    private httpClient: HttpClient,
    private newFeaturesService: NewFeaturesService,
  ) {

    this.userInfo = JSON.parse(localStorage.getItem("user"));
    if (this.userInfo != null) {
      this.newFeaturesService.getInfoProvincia(this.userInfo.municipioId).subscribe({
        next: (res) => {
          this.infoProvincia = res.result
        },
        error: (err) => console.log(err)
      });
    }
  }


  public set item(val: any) {
    this._item = val;
  }
  public get item() {
    return this._item;
  }

  /**
   * Getter for notifications
   */
  get notifications$(): Observable<Notification[]> {
    return this._notifications.asObservable();
  }

  getSolicitudesAprobadas(municipioId: number) {
    return this.httpClient
      .get(`${this.urlApiNuevoBackend}datos-bienes/aprobados?municipioId=${municipioId}&limit=2000`)
      .pipe(
        switchMap((response: any) => {
          return of(response);
        })
      );
  }

  getSolicitudesDenegadas(municipioId: number) {
    return this.httpClient
      .get(`${this.urlApiNuevoBackend}datos-bienes/denegados?municipioId=${municipioId}&limit=2000`)
      .pipe(
        switchMap((response: any) => {
          return of(response);
        })
      );
  }



  getContable() {
    return this.httpClient
      .get(`${this.urlApiNuevoBackend}contable`)
      .pipe(
        switchMap((response: any) => {
          if (response.result) {
            response.result = response.result.sort((a, b) => a.codigo - b.codigo);
          }
          return of(response);
        })
      );
  }

  getJuridica() {
    return this.httpClient
      .get(`${this.urlApiNuevoBackend}juridicas`)
      .pipe(
        switchMap((response: any) => {
          return of(response);
        })
      );
  }

  getFuncional() {
    return this.httpClient
      .get(`${this.urlApiNuevoBackend}funcionales`)
      .pipe(
        switchMap((response: any) => {
          return of(response);
        })
      );
  }

  getCalificaciones() {
    return this.httpClient
      .get(`${this.urlApiNuevoBackend}calificaciones`)
      .pipe(
        switchMap((response: any) => {
          return of(response);
        })
      );
  }

  getPendientes(epigrafe: string) {
    let headers = new HttpHeaders();
    headers = headers.set(
      "Authorization",
      "Bearer " + localStorage.getItem("tokenOld")
    );
    return this.httpClient
      .get(`${this.urlApi}${epigrafe}/pendientes`, { headers })
      .pipe(
        switchMap((response: any) => {
          return of(response);
        })
      );
  }

  getInmueblesUrbanos(municipioId: number) {


    return this.httpClient
      .get(
        `${this.urlApiNuevoBackend}datos-bienes?municipioId=${municipioId}&epigrafeId=8&limit=500`
      )
      .pipe(
        switchMap((response: any) => {
          return of(response);
        })
      );
  }

  getInmueblesPatrimonioSueloInmuebles(municipioId: number) {
    return this.httpClient
      .get(`${this.urlApiNuevoBackend}datos-bienes?municipioId=${municipioId}&epigrafeId=20&limit=500`)
      .pipe(
        switchMap((response: any) => {
          console.log(response);
          return of(response);
        })
      );
  }

  getInmueblesPatrimonioSuelo(municipioId: number) {
    return this.httpClient
      .get(`${this.urlApiNuevoBackend}datos-bienes?municipioId=${municipioId}&epigrafeId=21&limit=500`)
      .pipe(
        switchMap((response: any) => {
          console.log(response);
          return of(response);
        })
      );
  }

  getInmueblePatrimonioSueloById(id: number) {
    return this.httpClient
      .get(`${this.urlApiNuevoBackend}datos-bienes/${id}`)
      .pipe(
        switchMap((response: any) => {
          console.log(response);
          return of(response);
        })
      );
  }

  getInmueblesUrbanosById(id: number, municipioId: number) {
    return this.httpClient
      .get(
        `${this.urlApiNuevoBackend}datos-bienes/${id}?municipioId=${municipioId}`
      )
      .pipe(
        switchMap((response: any) => {
          console.log(response);
          return of(response);
        })
      );
  }

  updatePatrimonioSuelo(data: any, id: number): Observable<any> {
    console.log(data);
    let headers = new HttpHeaders();
    headers = headers.set(
      "Authorization",
      "Bearer " + localStorage.getItem("tokenOld")
    );
    return this.httpClient
      .put<any>(`${this.urlApi}patrimonio-suelo/${id}`, data, { headers })
      .pipe(
        map((resp) => {
          console.log(resp);
          return resp;
        })
      );
  }

  getListaEpigrafes(url: String) {
    let headers = new HttpHeaders();
    headers = headers.set(
      "Authorization",
      "Bearer " + localStorage.getItem("tokenOld")
    );
    return this.httpClient.get(`${this.urlApi}${url}`, { headers }).pipe(
      switchMap((response: any) => {
        return of(response);
      })
    );
  }

  getProvincias() {
    return this.httpClient
      .get(`${this.urlApiNuevoBackend}provincias`)
      .pipe(
        switchMap((response: any) => {
          return of(response);
        })
      );
  }

  getProvinciasById(id: number) {
    return this.httpClient
      .get(`${this.urlApiNuevoBackend}provincias/${id}`)
      .pipe(
        switchMap((response: any) => {
          return of(response);
        })
      );
  }

  update(data: any, id: number): Observable<any> {
    /* let urlEpigrafe = "";
    console.log(data);
    switch (data.epigrafe) {
      case "1.1.1.":
        urlEpigrafe = "inmuebles-urbanos";
        break;
      case "1.1.2.":
        urlEpigrafe = "parques-jardines";
        break;
      case "1.1.3.":
        urlEpigrafe = "solares";
        break;
      case "1.1.4.":
        urlEpigrafe = "infraestructuras";
        break;
      default:
        urlEpigrafe = "";
        break;
    }
    if (!urlEpigrafe) {
      throw new Error("URL no válida");
    }
    if (data.epigrafe != "1.1.1.") {
      data = this.keysToUpperCase(data);
    } */

     // Para registrar le fecha de adquisicion por defecto
    //Para fijar una fecha por defecto
    if (data.s_fecha_adquisicion == null || data.s_fecha_adquisicion == "NO CONSTA") {
      data.s_fecha_adquisicion = new Date('9999-01-01T00:00:00.000Z');
    }


    return this.httpClient
      .put<any>(`${this.urlApiNuevoBackend}datos-bienes/${id}`, data)
      .pipe(
        map((resp) => {
          console.log(resp);
          return resp;
        })
      );
  }

  create(data: any): Observable<any> {
    /* let urlEpigrafe = "";
    console.log(data);
    switch (data.epigrafe) {
      case "1.1.1.":
        urlEpigrafe = "inmuebles-urbanos";
        break;
      case "1.1.2.":
        urlEpigrafe = "parques-jardines";
        break;
      case "1.1.3.":
        urlEpigrafe = "solares";
        break;
      case "1.1.4.":
        urlEpigrafe = "infraestructuras";
        break;
      default:
        urlEpigrafe = "";
        break;
    }
    console.log(data);
    if (!urlEpigrafe) {
      throw new Error("URL no válida");
    }
    if (data.epigrafe != "1.1.1.") {
      data = this.keysToUpperCase(data);
    } */

    //Para guardar el id de la entidad Local
    let entidad_local = JSON.parse(localStorage.getItem("entidad_local"));
    data.entidadLocalId = entidad_local?.id;

    // Para registrar le fecha de adquisicion por defecto
    //Para fijar una fecha por defecto
    if (data.s_fecha_adquisicion == null || data.s_fecha_adquisicion == "NO CONSTA") {
      data.s_fecha_adquisicion = new Date('9999-01-01T00:00:00.000Z');
    }



    console.log(" ---- ENTIDAAD LOCAL ----");

    console.log(data.entidadLocalId);

    return this.httpClient
      .post<any>(`${this.urlApiNuevoBackend}datos-bienes`, data)
      .pipe(
        map((resp) => {
          console.log(resp);
          return resp;
        })
      );
  }

  solicitarSolicitud(data: any): Observable<any> {
    console.log(data.id);
    let code = parseInt(data.id);
    return this.httpClient
      .put<any>(
        `${this.urlApiNuevoBackend}datos-bienes/${code}/solicitar-baja/`,
        data
      )
      .pipe(
        map((resp) => {
          console.log(resp);
          return resp;
        })
      );
  }

  aceptarSolicitud(data: any): Observable<any> {
    return this.httpClient
      .put<any>(
        `${this.urlApiNuevoBackend}datos-bienes/${data.id}/aceptar-solicitud/`,
        data
      )
      .pipe(
        map((resp) => {
          console.log(resp);
          return resp;
        })
      );
  }

  denegarSolicitud(data: any): Observable<any> {
    return this.httpClient
      .put<any>(
        `${this.urlApiNuevoBackend}datos-bienes/${data.id}/denegar-solicitud/`,
        data
      )
      .pipe(
        map((resp) => {
          console.log(resp);
          return resp;
        })
      );
  }

  getEpigrafes() {
    return this.httpClient
      .get(`${this.urlApiNuevoBackend}epigrafes`)
      .pipe(
        switchMap((response: any) => {
          return of(response);
        })
      );
  }

  updateEpigrafe(data: any) {
    return this.httpClient
      .put<any>(`${this.urlApiNuevoBackend}epigrafes/${data.id}`, {
        codigo: data.codigo,
        descripcion: data.descripcion,
        nombre: data.nombre
      })
      .pipe(
        map((resp) => {
          console.log(resp);
          return resp;
        })
      );
  }

  getPendientesByEpigrafe(municipioId: number, epigrafeId: number) {
    return this.httpClient
      .get(`${this.urlApiNuevoBackend}datos-bienes/pendientes?municipioId=${municipioId}&epigrafeId=${epigrafeId}&limit=200&page=1`)
      .pipe(
        switchMap((response: any) => {
          return of(response);
        })
      );
  }

  getTiposVias() {
    return this.httpClient.get(`${this.urlApiNuevoBackend}tipos-vias`).pipe(
      switchMap((response: any) => {
        return of(response);
      })
    );
  }

  denegarModificacion(data: any): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set(
      "Authorization",
      "Bearer " + localStorage.getItem("tokenOld")
    );
    let urlEpigrafe = this.getLinkByCode(data.epigrafe);
    return this.httpClient
      .put<any>(
        `${this.urlApi}${urlEpigrafe}/${data.id}/denegar-actualizacion/`,
        data,
        { headers }
      )
      .pipe(
        map((resp) => {
          console.log(resp);
          return resp;
        })
      );
  }

  keysToUpperCase(obj) {
    Object.keys(obj).forEach(function (key) {
      var k = key.toUpperCase();

      if (k !== key) {
        obj[k] = obj[key];
        delete obj[key];
      }
    });
    return obj;
  }

  keysToLowerCase(obj) {
    Object.keys(obj).forEach(function (key) {
      var k = key.toLowerCase();

      if (k !== key) {
        obj[k] = obj[key];
        delete obj[key];
      }
    });
    return obj;
  }

  // ---- Guardar Imagenes
  postImage(file: File) {
    var formData = new FormData();
    formData.append('file', file);

    return this.httpClient.post(`${this.urlApiFiles}file-manager/file/provincias/VALLADOLID/SAN PELAYO/fotografias`, formData)
      .subscribe(event => {
        console.log('done')
      })

    /*return (
      this.httpClient
        
        .post(`${this.urlApiFiles}file-manager/file/provincias/${path}` )
        .pipe(
          switchMap((response: any) => {
            return of(response);
          })
        )
    );*/
  }

  getImage(path) {
    return (
      this.httpClient
        // .get(`http://82.223.217.238:8082/api/file-manager/file/${path}`, {
        .get(`${this.urlApiFiles}file-manager/file/provincias/${path}`, {
          responseType: "blob"
        })
        .pipe(
          switchMap((response: any) => {
            return of(response);
          })
        )
    );
  }



  getFileUrl(path) {
    return this.httpClient
      .get(`${path}`, {
        responseType: "blob"
      })
      .pipe(
        switchMap((response: any) => {
          return of(response);
        })
      );
  }

  getPdf(path) {
    let headers = new HttpHeaders();
    //headers = headers.set("Accept", "application/pdf");
    return this.httpClient
      .get(`${this.urlApi}file-manager/file/${path}`, {
        // headers: headers,
        // observe: "response",
        responseType: "blob",
        headers,
      })
      .pipe(
        switchMap((response: any) => {
          return of(response);
        })
      );
  }

  getPathFiles(tipo, epigrafe, nOrden) {
    const urlBack = this.urlApi.split("/api")[0];
    // const urlBack = this.urlApiFiles.split("/api")[0];
    let folder = "fotografias";
    let tipoArchivo = "FOTOGRAFIA";
    let codigoEpigrafe = epigrafe
    codigoEpigrafe = codigoEpigrafe.replace(/\./g, "");
    switch (tipo) {
      case "c":
        folder = "planos";
        tipoArchivo = "CROQUIS";
        break;
      case "d":
        folder = "docs";
        tipoArchivo = "DOCUMENTO";
        break;
      default:
        break;
    }


    if (this.infoProvincia == null) {
      this.infoProvincia = JSON.parse(localStorage.getItem("muni"));
      console.log(this.infoProvincia);
    }




    return (
      this.httpClient
        .get(`${this.urlApiFiles}file-manager/folder/provincias/${this.infoProvincia.provincia.nombre.toUpperCase()}/${this.infoProvincia.nombre.toUpperCase()}/${folder}`)
        .pipe(
          switchMap((response: any) => {
            const resp = [];
            response.forEach((element) => {
              try {
                const i = element.name.lastIndexOf(".");
                let nombreArchivo = element.name.substring(0, i);
                nombreArchivo = nombreArchivo.replaceAll('.', '')
                const splitNombre = nombreArchivo.split("-");
                const codigo = splitNombre[0].replace(/\./g, "");
                console.log(codigoEpigrafe)
                if (
                  codigoEpigrafe == codigo &&
                  splitNombre[1].toLowerCase() == tipo.toLowerCase()
                ) {
                  const splitArchivos = splitNombre[2].split(".");
                  const numOrden = Number.parseInt(splitArchivos[0]);
                  if (nOrden == numOrden) {
                    element.src = `${urlBack}${element.downloadUrl}`;
                    element.tipo = tipoArchivo;
                    resp.push(element);
                  }
                }
              } catch (error) { }
            });
            return of(resp);
          })
        )
    );
  }

  getDataImage(url: string): Observable<string> {
    return this.httpClient
      .get(url, { responseType: "blob" })
      .pipe(switchMap((response) => this.readFile(response)));
  }

  private readFile(blob: Blob): Observable<string> {
    return Observable.create((obs) => {
      const reader = new FileReader();

      reader.onerror = (err) => obs.error(err);
      reader.onabort = (err) => obs.error(err);
      reader.onload = () => obs.next(reader.result);
      reader.onloadend = () => obs.complete();

      return reader.readAsDataURL(blob);
    });
  }

  getLinkByCode(epigrafe: string): string {
    if (!epigrafe.endsWith(".")) epigrafe += ".";
    let urlEpigrafe = "";
    switch (epigrafe) {
      case "1.1.1.":
        urlEpigrafe = "inmuebles-urbanos";
        break;
      case "1.1.2.":
        urlEpigrafe = "parques-jardines";
        break;
      case "1.1.3.":
        urlEpigrafe = "solares";
        break;
      case "1.1.4.":
        urlEpigrafe = "infraestructuras";
        break;
      case "1.2.":
        urlEpigrafe = "inmuebles-rusticos";
        break;
      case "1.3.":
        urlEpigrafe = "vias-publicas-urbanas";
        break;
      case "1.4.":
        urlEpigrafe = "vias-publicas-rusticas";
        break;
      case "2.":
        urlEpigrafe = "derechos-reales";
        break;
      case "3.":
        urlEpigrafe = "muebles-caracter-ha";
        break;
      case "4.1.":
        urlEpigrafe = "valores-mobiliarios";
        break;
      case "4.2.":
        urlEpigrafe = "creditos-derechos";
        break;
      case "5.":
        urlEpigrafe = "vehiculos";
        break;
      case "6.":
        urlEpigrafe = "semovientes";
        break;
      case "7.":
        urlEpigrafe = "otros-muebles";
        break;
      case "8.":
        urlEpigrafe = "bienes-revertibles";
        break;
      case "Inmuebles":
        urlEpigrafe = "patrimonio-suelo";
      default:
        urlEpigrafe = "";
        break;
    }
    return urlEpigrafe;
  }

  getPathInfo(path) {
    return (
      this.httpClient
        // .get(`${this.urlApi}/file-manager/folder/${path}`)
        .get(`${this.urlApiFiles}/file-manager/folder/${path}`)
        .pipe(
          switchMap((response: any) => {
            const resp = response;
            let url = this.urlApiFiles.replace("/api/", "");
            resp.map((e) => {
              if (e.downloadUrl) {
                e.downloadUrl = url + e.downloadUrl;
              }
            });
            return of(resp);
          })
        )
    );
  }





  // Enpoints Informe Epigrafe
  getDatosEpigrafe(municipioId: number, epigrafeId: number) {
    return this.httpClient
      .get(
        `${this.urlApiNuevoBackend}datos-bienes?municipioId=${municipioId}&epigrafeId=${epigrafeId}&limit=9999`
      )
      .pipe(
        switchMap((response: any) => {
          console.log(response);
          return of(response);
        })
      );
  }

  createFolder(path) {
    return this.httpClient
      .post(`${this.urlApiFiles}/file-manager/folder/${path}`, {})
      .pipe(
        switchMap((response: any) => {
          return of(response);
        })
      );
  }

  setCertifiedData(data) {
    return this.httpClient
      .post(`${this.urlApiNuevoBackend}/configuraciones`, {
        nombreSecretario: data.secretario,
        lugar: data.lugar,
        nombreAlcalde: data.alcalde,
        usuarioId: data.usuarioId,
        municipioId: data.municipioId,
        fecha: data.fecha,
        fechaInventario: data.fechaInventario
      })
      .pipe(
        switchMap((response: any) => {
          return of(response);
        })
      );
  }



  // ------------- ENTIDADES LCOALES --------------

  getEntidadesLocales() {
    return this.httpClient
      .get(`${this.urlApiNuevoBackend}entidades-locales`)
      .pipe(
        switchMap((response: any) => {
          return of(response);
        })
      );
  }

  getEntidadLocalByMunicipio(id: number) {
    return this.httpClient
      .get(`${this.urlApiNuevoBackend}entidades-locales/municipio/${id}`)
      .pipe(
        switchMap((response: any) => {
          return of(response);
        })
      );
  }


  // --- Amortizaciones ---

  getAmortizaciones(municipioId: number) {
    return this.httpClient
      .get(`${this.urlApi}amortizaciones/${municipioId}`)
      .pipe(
        switchMap((response: any) => {
          console.log(response);
          return of(response);
        })
      );
  }

}
