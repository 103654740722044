<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<!-- Navigation -->
<ng-container *ngIf="isScreenSmall">
    <drawer-menu [smallMenuOpen]="smallMenu"></drawer-menu>

    <!-- <fuse-vertical-navigation class="dark bg-gray-900 print:hidden" [mode]="'over'" [name]="'mainNavigation'"
        [navigation]="navigation.default" [opened]="smallMenuOpen" > -->
    <!-- Navigation header hook -->

    <!-- <ng-container *ngIf="isScreenSmall">
            <button mat-icon-button (click)="settingsDrawer?.toggle()">
                <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>
            </button>
        </ng-container>
        <ng-container DrawerMenuComponent>
     
        </ng-container>
    </fuse-vertical-navigation> -->
</ng-container>

<!-- Wrapper -->
<div class="  flex flex-col flex-auto w-full min-w-0">

    <!-- Header -->
    <div
        class="fixed  flex flex-0 items-center w-full h-16 sm:h-15 px-4 md:px-6 z-49 shadow dark:shadow-none dark:border-b bg-[#111927] dark:bg-transparent print:hidden">
        <ng-container *ngIf="!isScreenSmall">
            <!-- Logo -->
            <div class="flex items-center mx-2 lg:mr-8">
                <a routerLink="app" class="hidden lg:flex">
                    <img class="dark:hidden w-24" src="assets/images/logo/logo_colores.png">
                    <img class="hidden dark:flex w-24" src="assets/images/logo/logo_colores.png">
                </a>
                <a routerLink="app" class="flex lg:hidden w-24">
                    <img src="assets/images/logo/logo_colores.png">
                </a>
            </div>

        </ng-container>
        <!-- Navigation toggle button -->
        <ng-container *ngIf="isScreenSmall">
            <button mat-icon-button (click)="toggleMenu()">
                <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>
            </button>

        </ng-container>
        <!-- Components -->
        <div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">
            <!-- <button class="lg:hidden" mat-icon-button (click)="quickChat.toggle()">
                <mat-icon [svgIcon]="'heroicons_outline:chat-alt-2'"></mat-icon>
            </button> -->
            <div class="mx-4" *ngIf="userInfo.rol != 'TECNICO'">
                <button class="text-white mr-16" mat-icon-button [matMenuTriggerFor]="menuOpciones">
                    <span class="relative sm: text-sm px-1">{{ "navbar.opciones.name" | transloco | uppercase }}
                    </span>
                    <mat-icon [svgIcon]="'heroicons_outline:chevron-down'"></mat-icon>
                </button>

                <mat-menu [xPosition]="'before'" #menuOpciones="matMenu" class="sm:mx-o pt-0 w-full">
                    <div *ngIf="userInfo?.rol != 'CONSULTOR'"
                        class="font-bold leading-4 text-[#333B63] bg-[#DAE1E7] py-1 text-end">{{
                        "navbar.opciones.opcionesGenerales.name" | transloco }}</div>
                    <ng-container *ngIf="userInfo?.rol != 'CONSULTOR'" class="sm:mx-0 pt-0 w-full">
                        <button mat-menu-item (click)="openDialogAltaBien()"
                            *ngIf="['GESTOR', 'SUPER ADMIN','SECRETARIO'].includes(userInfo?.rol)">
                            <span>{{ "navbar.opciones.opcionesGenerales.altaBien" | transloco }}</span>
                        </button>
                        <a mat-menu-item routerLink="app/edicion-subepigrafes" *ngIf="userInfo?.rol == 'SUPER ADMIN'">
                            <span>{{ "navbar.opciones.opcionesGenerales.edicionSubepigrafes" | transloco }}</span>
                        </a>
                    </ng-container>
                    <div class="font-bold leading-4 text-[#333B63] bg-[#DAE1E7] py-1 px-2 text-end">{{
                        "navbar.opciones.opcionesEconomicas.name" | transloco }}
                    </div>
                    <a routerLink="app/valoracion-pgcp" mat-menu-item>
                        <span>{{ "navbar.opciones.opcionesEconomicas.valoracionPgcp" | transloco }}</span>
                    </a>
                    <a routerLink="/app/valoracion-general-epigrafes" mat-menu-item>
                        <span>{{ "navbar.opciones.opcionesEconomicas.valoracionBienes" | transloco }}</span>
                    </a>
                    <a routerLink="app/valoracion-general" mat-menu-item>
                        <span>{{ "navbar.opciones.opcionesEconomicas.comparativaValoracion" | transloco }}</span>
                    </a>
                    <button mat-menu-item (click)="openDialogAmortizacion()"
                        *ngIf="['SECRETARIO', 'SUPER ADMIN'].includes(userInfo?.rol)">
                        <span>{{ "navbar.opciones.opcionesEconomicas.amortizacion" | transloco }}</span>
                    </button>
                    <div class="font-bold leading-4 text-[#333B63] bg-[#DAE1E7] py-1 px-2 text-end">{{
                        "navbar.opciones.cartografia.name" | transloco }}</div>
                    <a routerLink="/app/mapa" mat-menu-item>
                        <span>{{ "navbar.opciones.cartografia.consultarPlanimetria" | transloco }}</span>
                    </a>
                    <a routerLink="/app/mapa" mat-menu-item *ngIf="['GESTOR', 'SUPER ADMIN','SECRETARIO'].includes(userInfo?.rol)">
                        <span>{{ "navbar.opciones.cartografia.edicionPlanimetria" | transloco }}</span>
                    </a>
                    <!-- <a routerLink="app/scraping" mat-menu-item *ngIf="userInfo?.rol != 'CONSULTOR'">
                        <span>Scraping</span>
                    </a> -->

                </mat-menu>
            </div>

            <!--  ******  EXPORTACIONES ****-->
            <div *ngIf="serviceMenuExportacion.activarMenuExportacion">
                <div class="mx-4" *ngIf="userInfo.rol != 'TECNICO' && userInfo.rol != 'CONSULTOR'">
                    <button class="text-white mr-14 flex " mat-icon-button [matMenuTriggerFor]="menuOpcionesEdicion">
                        <span class="relative sm: text-sm px-1">{{ "navbar.exportacion.name" | transloco | uppercase }}
                        </span>
                        <mat-icon [svgIcon]="'heroicons_outline:chevron-down'"></mat-icon>
                    </button>

                    <mat-menu [xPosition]="'before'" #menuOpcionesEdicion="matMenu">

                        <button mat-menu-item (click)="onOpenModalExportarFicheros()">
                            <span>{{ "navbar.exportacion.ficheros" | transloco }}</span>
                        </button>
                        <!--
                        <button mat-menu-item (click)="onOpenModalIntegracion()">
                            <span>{{ "navbar.exportacion.integracion" | transloco }}</span>
                        </button>
                        -->

                    </mat-menu>
                </div>
            </div>

            <!-- FIN EXPORTACIONES-->

            <div class="mx-4" *ngIf="!['CONSULTOR', 'TECNICO'].includes(userInfo.rol)">
                <a routerLink="/app/notificaciones" class="flex  items-center ">
                    <mat-icon [svgIcon]="'heroicons_outline:bell'"></mat-icon>
                    <!-- <span class="bg-[#DC2626] rounded-full px-1 text-white">1</span> -->
                </a>
            </div>

            <!-- <div class="mx-6" [matMenuTriggerFor]="menuLanguages">
                <button class="flex items-center">
                    <mat-icon>language</mat-icon>
                </button>
                <mat-menu [xPosition]="'before'" #menuLanguages="matMenu">
                    <button mat-menu-item (click)="changeLanguage('es')">
                        <span>{{ "navbar.lenguajes.es" | transloco }}</span>
                    </button>
                    <button mat-menu-item (click)="changeLanguage('en')">
                        <span>{{ "navbar.lenguajes.en" | transloco }}</span>
                    </button>
                </mat-menu>
            </div> -->

            <div
                class="w-full text-white whitespace-nowrap text-ellipsis overflow-hidden text-center leading-normal font-medium">
                {{ userInfo?.name }}
            </div>
            <user></user>
        </div>

    </div>
    <!-- <div class="flex flex-0  mt-14 w-full h-10 bg-[#F9FBFB] px-16"> -->
    <div *ngIf="showMenu && userInfo.rol != 'TECNICO'">

        <nav class="fixed z-50 w-full mt-14 bg-[#F9FBFB] border-gray-200 px-2 sm:px-4 shadow-lg">
            <div class="container flex flex-wrap items-center justify-between mx-auto">

                <div class="w-full hidden md:block md:w-auto" id="navbar-default">
                    <ul
                        class="flex flex-col p-4 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium md:border-0 md:bg-white">
                        <li>
                            <button [matMenuTriggerFor]="inventario_bienes"
                                class="block py-2 pl-3 pr-4 text-[#2563EB] rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-800 md:p-0 font-medium leading-8">
                                {{ "home.inventarioBienes" | transloco | uppercase }}</button>
                            <mat-menu #inventario_bienes="matMenu">
                                <button [matMenuTriggerFor]="inmuebles_urbanos" mat-menu-item>{{ "inmUrbanos" |
                                    transloco }}</button>
                                <a routerLink="/app/derechos-reales" mat-menu-item>{{ "derechos_reales" | transloco
                                    }}</a>
                                <a routerLink="/app/muebles-historicos" mat-menu-item>{{ "muebles_ha" | transloco }}</a>
                                <button [matMenuTriggerFor]="mobiliarios_creditos" mat-menu-item class="">
                                    <p class="text-clip overflow-hidden">{{ "valores_mobiliarios" | transloco }}/{{
                                        "creditos" | transloco }}</p>
                                </button>
                                <a routerLink="/app/vehiculos" mat-menu-item>{{ "vehiculos" | transloco }}</a>
                                <a routerLink="/app/semovientes" mat-menu-item>{{ "semovientes" | transloco }}</a>
                                <a routerLink="/app/otros-muebles" mat-menu-item>{{ "otros_muebles" | transloco }}</a>
                                <a routerLink="/app/bienes-revertibles" mat-menu-item>{{ "bienes_revertibles" |
                                    transloco }}</a>
                                <a routerLink="/app/valoracion-general-epigrafes" mat-menu-item>{{ "val_gnrl" |
                                    transloco }}</a>
                            </mat-menu>
                            <mat-menu #mobiliarios_creditos="matMenu">
                                <a routerLink="/app/valores-mobiliarios" mat-menu-item>{{ "valores_mobiliarios" |
                                    transloco }}</a>
                                <a routerLink="/app/creditos-derechos" mat-menu-item>{{ "creditos" | transloco }}</a>
                            </mat-menu>
                            <mat-menu #inmuebles_urbanos="matMenu">
                                <button [matMenuTriggerFor]="inmuebles" mat-menu-item>{{ "inmUrbanos" | transloco
                                    }}</button>
                                <a routerLink="/app/inmuebles-rusticos" mat-menu-item>{{ "fincas" | transloco }}</a>
                                <a routerLink="/app/vias-publicas-urbanas" mat-menu-item>{{ "vias_urbanas" | transloco
                                    }}</a>
                                <a routerLink="/app/vias-publicas-rusticas" mat-menu-item>{{ "vias_rusticas" | transloco
                                    }}</a>
                            </mat-menu>
                            <mat-menu #inmuebles="matMenu">
                                <a routerLink="/app/inmuebles-urbanos" mat-menu-item>{{ "inmuebles" | transloco }}</a>
                                <a routerLink="/app/parques-jardines" mat-menu-item>{{ "parques" | transloco }}</a>
                                <a routerLink="/app/solares" mat-menu-item>{{ "solares" | transloco }}</a>
                                <a routerLink="/app/infraestructuras" mat-menu-item>{{ "infraestructuras" | transloco
                                    }}</a>
                            </mat-menu>
                        </li>
                        <li>
                            <button (click)="onOpenModalSubmenuPatrimonioSuelo()"
                                class="block py-2 pl-3 pr-4 text-[#2563EB] rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-800 md:p-0 font-medium leading-8">{{
                                "home.patrimonioSuelo" | transloco | uppercase }}</button>
                        </li>
                        <li *ngIf="dataArbolado.length > 0">
                            <a routerLink="/app/arbolado-urbano"
                                class="block py-2 pl-3 pr-4 text-[#2563EB] rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-800 md:p-0 font-medium leading-8">
                            {{'home.inventario_a_u'| transloco | uppercase}}</a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>

        <!-- <ng-container class="ml-10">
            <button class="text-primary ml-10" mat-icon-button>
                <span class="relative">Inventario de bienes y derechos
                </span>
            </button>
        </ng-container>
        <ng-container class="ml-10">
            <button class="text-primary ml-10" mat-icon-button>
                <span class="relative">Inventario de bienes y derechos
                </span>
            </button>
        </ng-container>
        <ng-container>
            <button class="text-primary ml-10" mat-icon-button>
                <span class="relative">Inventario de bienes y derechos
                </span>
            </button>
        </ng-container> -->
    </div>
    <!-- Content -->
    <div class="flex flex-col flex-auto w-full">
        <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
        <router-outlet *ngIf="true"></router-outlet>
    </div>

    <!-- Footer -->
    <!-- <div class="relative flex justify-center w-full z-49 print:hidden">
        <div class="flex items-center w-full h-14 sm:h-20 px-6 md:px-8 sm:shadow-xl border-t bg-card dark:bg-default">
            <span class="font-medium text-secondary">Desarrollado por <a class="text-blue-700" target="_blank" href="https://www.chvconsulting.es/">CHVCONSULTING</a> &copy; {{currentYear}}</span>
        </div>
    </div> -->






</div>

<!-- Quick chat -->
<!-- <quick-chat #quickChat="quickChat"></quick-chat> -->