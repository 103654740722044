<div class="flex flex-col flex-auto md:w-160 md:min-w-160 -m-6">
    <div class="flex flex-row sm:flex-row items-center justify-between bg-[#333B63] text-[#FBFCFD] py-4 px-9">
        <div class="text-xl font-bold tracking-tight leading-6 truncate">{{ "modales.exportarFicheros.title" | transloco
            }}</div>
        <button class="mt-3 sm:mt-0 sm:ml-2" (click)="onClose()">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <div class="grid grid-cols-1 grid-flow-row gap-6 w-full mt-4 sm:mt-4 px-8 py-4">
        <div class="font-bold text-lg leading-5 text-[#333B63]">{{ "modales.exportarFicheros.seccion.text" | transloco
            }}:</div>
        <mat-radio-group class="grid grid-cols-1 gap-5 mt-3 mb-2 text-lg leading-6 md:grid-cols-2 lg:grid-cols-2 "
            style="color: rgba(0, 0, 0, 0.6);
        ">
            <mat-radio-button (change)="selectRadioOption($event)" value="Base de datos">{{
                "modales.exportarFicheros.seccion.opciones.bbdd" | transloco }}</mat-radio-button>
            <mat-radio-button (change)="selectRadioOption($event)" value="Documentacion">{{
                "modales.exportarFicheros.seccion.opciones.documentacion" | transloco }}</mat-radio-button>
            <mat-radio-button (change)="selectRadioOption($event)" value="Planos">{{
                "modales.exportarFicheros.seccion.opciones.planos" | transloco }}</mat-radio-button>
            <mat-radio-button (change)="selectRadioOption($event)" value="Fotografias">{{
                "modales.exportarFicheros.seccion.opciones.fotografias" | transloco }}</mat-radio-button>
            <mat-radio-button (change)="selectRadioOption($event)" value="Geometrias">{{
                "modales.exportarFicheros.seccion.opciones.geometrias" | transloco }}</mat-radio-button>
        </mat-radio-group>


        <div *ngIf="banderaSpinner" style="display: flex;justify-content: center;">
            <mat-spinner></mat-spinner>
        </div>


        <div *ngIf="showSubMenuBaseD" style="margin-top: -40px;">
            <hr>
            <div style="background: #D9F3E1; border-radius: 10px; padding: 10px">
                <h3 style="text-align: center;"><b>{{'modales.exportarFicheros.seccion.opciones.bbdd'| transloco | uppercase}} :</b> {{'mensaggess.sele_ep'| transloco}}</h3>
                <span class="example-list-section">
                    <mat-checkbox style="padding: 5px;" class="example-margin" [checked]="allComplete"
                        [color]="task.color" [indeterminate]="someComplete()" (change)="setAll($event.checked)">
                        <b> {{task.name}}</b>
                    </mat-checkbox>
                </span>
                <span class="example-list-section">
                    <ul class="grid grid-cols-3">

                        <li *ngFor="let subtask of task.subtasks">

                            <mat-checkbox style="padding: 5px;" [(ngModel)]="subtask.completed" [color]="subtask.color"
                                (ngModelChange)="updateAllComplete()">
                                {{subtask.name}}
                            </mat-checkbox>

                        </li>

                    </ul>
                </span>

                <div style="display: flex;justify-content: center;margin-top: 10px;">
                    <button *ngIf="showButtonDownloadBaseD" mat-raised-button (click)="descargarBaseDeDatos()">
                        <mat-spinner *ngIf="banderaSpinnerDownloadDB" [diameter]="20"></mat-spinner>
                        <mat-icon *ngIf="!banderaSpinnerDownloadDB">cloud_download</mat-icon>
                        <span style="margin-left: 4px;"> {{'notifications.mensa19'| transloco}}</span>
                    </button>
                </div>

                <div style="display: flex;justify-content: center;margin-top: 15px;">
                    <div style="background: #E04A5B;color:white;padding: 10px;border-radius: 10px;" *ngIf="epigrafesSinData.length > 0">
                        <p style="text-align: center">{{'notifications.mensa20'| transloco}}</p>
                        <ul>
                            <li *ngFor="let epigrafe  of epigrafesSinData ">
                                <b>{{epigrafe}} :</b> {{'notifications.mensa21'| transloco}}
                            </li>
                        </ul>

                    </div>
                </div>

                <div *ngIf="!showButtonDownloadBaseD"
                    style="border-radius: 10px ;background: #F5E17D;padding: 15px;text-align: center; margin-top: 10px;color: black">
                    {{'notifications.mensa22'| transloco}}
                </div>




            </div>


        </div>

        <div *ngIf="bandera" [style.background-color]="totalFiles > 0 ? '#23DBAF' : '#DB776B'" class="b"
            style=" padding:10px;border-radius: 10px; text-align: center; color:white">



            <div *ngIf="totalFiles>0">
                <div style="text-align: center;">
                    Número de Archivos:
                </div>

                <p style="margin: 10px;text-align: center;">
                    <span style="padding: 10px;font-size: 25px;font-weight: bold;">
                        {{totalFiles}}
                    </span>
                </p>

                <div>
                    <button mat-raised-button style="margin:10px" (click)="descargarZip()">
                        <mat-spinner *ngIf="banderaSpinnerDownload" [diameter]="20"></mat-spinner>
                        <mat-icon *ngIf="!banderaSpinnerDownload">cloud_download</mat-icon>
                        <span style="margin-left: 4px;"> Descargar .zip</span>
                    </button>
                </div>
            </div>

            <div *ngIf="totalFiles == 0">
                No existen archivos
            </div>
        </div>


        <!--
        <div class="font-bold text-lg leading-5 text-[#333B63]">{{ "modales.exportarFicheros.formato" | transloco }}:</div>
     
       <mat-radio-group class="grid grid-cols-2 gap-5 mt-3 mb-2 text-lg leading-6 md:grid-cols-3 lg:grid-cols-3" style="color: rgba(0, 0, 0, 0.6);
        ">
            <mat-radio-button value="csv" [disabled]="radioCartografia.checked">CSV</mat-radio-button>
            <mat-radio-button value="xml" [disabled]="radioCartografia.checked">XML</mat-radio-button>
            <mat-radio-button value="txt" [disabled]="radioCartografia.checked">TXT</mat-radio-button>
            <mat-radio-button value="html" [disabled]="radioCartografia.checked">HTML</mat-radio-button>
            <mat-radio-button value="shape" [disabled]="!radioCartografia.checked">Shape</mat-radio-button>
            <mat-radio-button value="dwg" [disabled]="!radioCartografia.checked">DWG</mat-radio-button>
        </mat-radio-group>
        -->
        <div class="flex justify-between gap-4 md:justify-end lg:justify-end ">
            <button fontIcon="user" mat-flat-button color="primary" (click)="onClose()"> <span
                    class="fas fa-user"></span>
                {{ "modales.exportarFicheros.buttons.cancelar" | transloco | uppercase
                }}</button>
        </div>
    </div>
</div>