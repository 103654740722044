import { switchMap, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from "environments/environment";

@Injectable({
  providedIn: "root",
})
export class ValoracionPgcpService {
  urlApi = environment.urlApi;
  urlNew = environment.urlApi2;

  constructor(private httpClient: HttpClient) {}

  //   getValoracionPGCP() {
  // 	let headers = new HttpHeaders();
  // 	headers = headers.set(
  //     "Authorization",
  //     "Bearer " + localStorage.getItem("accessToken")
  //   );
  //   return this.httpClient
  //     .get(`${this.urlNew}resumenes/valoracion-pgcp`, { headers })
  //     .pipe(
  //       switchMap((response: any) => {
  //         return of(response);
  //       })
  //     );
  // 	}

  getValoracionPGCPNew(municipioId: number) {
    return this.httpClient
      .get(
        `${this.urlNew}resumenes/valoracion-pgcp?municipioId=${municipioId}`
      )
      .pipe(
        switchMap((response: any) => {
          return of(response);
        })
      );
  }

  getDatosJuridica(municipioId: number) {
    return this.httpClient
      .get(
        `${this.urlNew}informes/bienes/juridica?municipioId=${municipioId}`
      )
      .pipe(
        switchMap((response: any) => {
          return of(response);
        })
      );
  }

  getDatosJuridicav2(municipioId: number) {
    return this.httpClient
      .get(
        `${this.urlNew}informes/bienes/juridica?municipioId=${municipioId}&filterByEpigrafeCodigo=1.1.%`
      )
      .pipe(
        switchMap((response: any) => {
          return of(response);
        })
      );
  }

}
