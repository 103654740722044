import { MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-amortizacion',
  templateUrl: './amortizacion.component.html',
  styleUrls: ['./amortizacion.component.scss']
})
export class AmortizacionComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<AmortizacionComponent>
  ) { }

  ngOnInit(): void {
  }

  onClose(): void {
		this.dialogRef.close();
	}


}
