import { MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-submenu-patrimonio-suelo',
  templateUrl: './submenu-patrimonio-suelo.component.html',
  styleUrls: ['./submenu-patrimonio-suelo.component.scss']
})
export class SubmenuPatrimonioSueloComponent implements OnInit {
  lista: any[] = [
		{ 
      name: "inmuebles", 
      url: "app/patrimonio-municipal-suelo/inmuebles", 
      color: '#333B63' 
    },
		{
			name: "fondos_metalico",
			url: "app/patrimonio-municipal-suelo/fondos-metalico",
			color: '#F8BE5C'
		},
	];

  constructor(
    public dialogRef: MatDialogRef<SubmenuPatrimonioSueloComponent>
  ) { }

  ngOnInit(): void {
  }

  onClose(): void {
		this.dialogRef.close();
	}

}
