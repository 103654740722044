import { Pipe } from "@angular/core";

@Pipe({
	name: "datesp",
})
export class DateSpPipe {
	transform(value: string) {
		if(!value) return "NO CONSTA";
		if(value.split("-").length != 3) return value;
		if(value.includes("T")) {
			value = value.split("T")[0];
		}
        const [ year, month, day ] = value.toString().split("-");
		return [day, month, year].join("-");
		//return new Date(Number(year), Number(month) - 1, Number(day)).toLocaleDateString(); 
	}
}