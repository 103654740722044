import { SubmenuMobiliariosCreditosComponent } from './../submenu-alta-bien/submenu-mobiliarios-creditos/submenu-mobiliarios-creditos.component';
import { Router } from '@angular/router';
import { SubmenuInmueblesComponent } from './../submenu-alta-bien/submenu-inmuebles/submenu-inmuebles.component';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { Component, OnInit } from "@angular/core";

@Component({
	selector: "app-submenu-inventarios", 
	templateUrl: "./submenu-inventarios.component.html",
	styles: [""],
})
export class SubmenuInventariosComponent implements OnInit {
	lista: any[] = [
		{ name: "inmuebles", url: "app/inmuebles-urbanos", color: '#5D85A1' },
		{ name: "derechos_reales", url: "/app/derechos-reales", color: '#4DAF4A' },
		{
			name: "muebles_ha",
			url: "app/muebles-historicos",
			color: '#9C3836'
		},
		{ name: "val_creditos2", url: "/app", color: '#FACC00' },
		{ name: "vehiculos", url: "/app/vehiculos", color: '#CDCC00' },
		{ name: "semovientes", url: "/app/semovientes", color: '#61C0E9' },
		{ name: "otros_muebles", url: "/app/otros-muebles", color: '#70440F' },
		{ name: "bienes_revertibles",url: "/app/bienes-revertibles", color: '#F78E10' },
	];
	constructor(
		public dialogRef: MatDialogRef<SubmenuInventariosComponent>,
		private _matDialog: MatDialog,
		private _router: Router
	) {}

	ngOnInit(): void {}

	openSubmenuInmueblesUrbanos(item: any): void {
		if(item.name == 'inmuebles') {
			this._matDialog.open(SubmenuInmueblesComponent, {
				autoFocus: false,
				data: { 
					isAlta: false,
					ref: this.dialogRef,
				}
			});
		} else if(item.name == 'val_creditos2') {
			this._matDialog.open(SubmenuMobiliariosCreditosComponent, {
				autoFocus: false,
				data: { 
					isAlta: false,
					ref: this.dialogRef,
				}
			});
		} else {
			let epigrafe = "";
			switch(item.name) {
				case "Inmuebles Rústicos": 
					epigrafe = "fincas-rusticas";
					break;
				case "muebles_ha":
					epigrafe = "muebles-historicos";
					break;
				case "vehiculos":
					epigrafe = "vehiculos";
					break;
				case "semovientes":
					epigrafe = "semovientes";
					break;
				case "otros_muebles":
					epigrafe = "otros-muebles";
					break;
				case "derechos_reales":
					epigrafe = "derechos-reales";
					break;
				case "bienes_revertibles":
					epigrafe = "bienes-revertibles";
					break;
				default:
					break;
			}
			this._router
				.navigateByUrl(`/app/${epigrafe}`, {
					state: { name: item.name, epigrafe: item.epigrafe },
				})
				.then(() => {
					this.dialogRef.close();
				});
		}
	}

	onClose(): void {
		this.dialogRef.close();
	}

}
