import { Injectable } from "@angular/core";
import { PdfService } from "./pdf.service";
import { CurrencySpPipe } from "app/pipes/currency.pipe";
import { CertificadoUtils } from "./certificados.utils";
import { FeaturesService } from "./features.service";
import { lastValueFrom } from "rxjs";
import { ExportDocumentosPdfService } from "./export-pdf.service";
import { FichasDatosPdfService } from './ficha-datos.service';
import { InvArboladoUrbanoService } from "../inv-arbolado-urbano/inv-arbolado-urbano.service";
import { NewFeaturesService } from "../services/newFeatures.service";
import { ConfiguracionCertificadosService } from "../configuracion-certificados/configuracion-certificados.service";
import { CreateTableService } from './create-table.service';
import { InfoProvinciaService } from './info-provincia.service';
import { MatSnackBar } from "@angular/material/snack-bar";

@Injectable({
	providedIn: "root",
})
export class CertificadosPdfService {
	userInfo: any;
	infoProvincia: any
	datosCertificado: any;
	formatedDate: any
	imagenMunicipio: any;
	entidad_local:any;
	contenidoCertificado:any={
		datosGenerales:"",
		cuerpo:[
		
		]
	}


	constructor(
		private pdfService: PdfService,
		private currencySpPipe: CurrencySpPipe,
		private _featureService: FeaturesService,
		private _pdfDocService: ExportDocumentosPdfService,
		private fichaDatosService: FichasDatosPdfService,
		private createTableService: CreateTableService,
		private newFeaturesService: NewFeaturesService,
		private configuracionService: ConfiguracionCertificadosService,
		private arbUrbanoService: InvArboladoUrbanoService,
		private infoProvinciaService: InfoProvinciaService,
		private _snackBar: MatSnackBar,

	) {
		this.userInfo = JSON.parse(localStorage.getItem("user"));
		this.entidad_local = JSON.parse(localStorage.getItem("entidad_local"));
		this.configuracionService.getConfiguracion().subscribe(
			(data) => {
				this.datosCertificado = data.result || {};
				let date = new Date(this.datosCertificado.fecha);
				this.formatedDate = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
			},
			(err) => {
			}
		);
	}

	updateDatosCertificado(){
		this.configuracionService.getConfiguracion().subscribe(
			(data) => {
				this.datosCertificado = data.result || {};
				let date = new Date(this.datosCertificado.fecha);
				this.formatedDate = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
			},
			(err) => {
			}
		);
	}

	getProvinciaInformation(municipiopId: any) {
		this.newFeaturesService.getInfoProvincia(municipiopId).subscribe({
			next: (res) => {
				return res.result
			},
			error: (err) => console.log(err)
		});
	}

	getImagenMunicipio(info) {
		this.newFeaturesService.getMunicipioImagen(info.provincia.nombre, info.nombre).subscribe({
			next: (res) => {
				const reader = new FileReader();
				reader.readAsDataURL(res);
				reader.onloadend = () => {
					return reader.result;
				};

			},
			error: (err) => {
				this.imagenMunicipio = null;
				console.log(err)
			}
		});
	}

	async certificadoBienes(data: any) {

		if (!this.datosCertificado) {
			this._snackBar.open("Solicite al administrador que agregue los datos del alcalde y secretario", "Cerrar", {
				duration: 7000
			});
			return
		}


		this.newFeaturesService.getInfoProvincia(this.userInfo.municipioId).subscribe({
			next: (res) => {
				this.newFeaturesService.getMunicipioImagen(res.result.provincia.nombre, res.result.nombre).subscribe({
					next: (res) => {
						const reader = new FileReader();
						reader.readAsDataURL(res);
						reader.onloadend = async () => {
							this.imagenMunicipio = reader.result;
							const content = await CertificadoUtils.generarCertificadoBienes(data, this.datosCertificado, this.imagenMunicipio);
							let informe = {
								pageMargins: [80, 30],
								content: content,
								styles: {
									titulo: {
										fontSize: 11,
										alignment: "center",
										decoration: "underline",
									},
									cursiva: {
										italics: true,
									},
									boldCursiva: {
										italics: true,
										bold: true,
									},
								},
								defaultStyle: {
									italics: true,
									lineHeight: 1.5,

								},
							};
							this.pdfService.generatePdf(informe);
						};
					},
					error: (err) => console.log(err)
				});
			},
			error: (err) => console.log(err)
		});



	}

	async certificadoBienesPersonalizado(estructuraCertificado:any, data: any, tipoInventario?:any) {

		if (!this.datosCertificado) {
			this._snackBar.open("Solicite al administrador que agregue los datos del alcalde y secretario", "Cerrar", {
				duration: 7000
			});
			return
		}


		this.newFeaturesService.getInfoProvincia(this.userInfo.municipioId).subscribe({
			next: (res) => {
				this.newFeaturesService.getMunicipioImagen(res.result.provincia.nombre, res.result.nombre).subscribe({
					next: (res) => {
						const reader = new FileReader();
						reader.readAsDataURL(res);
						reader.onloadend = async () => {
							this.imagenMunicipio = reader.result;
							const content = await CertificadoUtils.generarCertificadoBienesPersonalizado(estructuraCertificado,data, this.datosCertificado, this.imagenMunicipio,tipoInventario);
							let informe = {
								pageMargins: [70, 20],
								content: content,
								styles: {
									titulo: {
										fontSize: 11,
										alignment: "center",
										decoration: "underline",
									},
									cursiva: {
										italics: true,
									},
									boldCursiva: {
										italics: true,
										bold: true,
									},
								},
								defaultStyle: {
									italics: true,
									lineHeight: 1.5,

								},
							};
							this.pdfService.generatePdf(informe);
						};
					},
					error: (err) => {console.log(err);
						
					}
				});
			},
			error: (err) => console.log(err)
		});



	}

	certificadoRusticas(data: any) {
		CertificadoUtils.certificadoRusticas();
	}

	getBase64ImageFromURL(url: any) {
		return new Promise((resolve, reject) => {
			var img = new Image();
			img.setAttribute("crossOrigin", "anonymous");

			img.onload = () => {
				var canvas = document.createElement("canvas");
				canvas.width = 100;
				canvas.height = 100;

				var ctx = canvas.getContext("2d");
				ctx?.drawImage(img, 0, 0);

				var dataURL = canvas.toDataURL("image/png");

				resolve(dataURL);
			};

			img.onerror = (error) => {
				reject(error);
			};

			img.src = url;
		});
	}

	buildTituloPage({ titulo = "", fontSize = 25, saltoPagina = true, orientation = 'portrait', info = null },entidad_local:any) {
		if (info == null) {
			info = {
				data: this.infoProvincia,
				image: this.imagenMunicipio
			}
		}
		let contenido = [];
		contenido.push(
			{
				table: {
					widths: ['100%'],
					heights: [670],
					body: [
						[{
							border: [true, true, true, true],
							text: titulo,
							fontSize: fontSize,
							margin: [0, 300, 0, 0],
							alignment: "center",
							bold: true,
							valign: 'middle',
						}],
						[{
							border: [false, false, false, true],
							text: "",
							fontSize: 1,
							margin: [0, 30, 0, 0],
							alignment: "center",
							bold: true,
						}],
						[{
							border: [false, true, false, false],
							text: `${entidad_local?.nombre}\n ${info.data.provincia.nombre}`,
							fontSize: 15,
							alignment: "center",
							bold: true,
						}]
					]
				}
			}
		);
		if (saltoPagina) contenido.push({ text: "", pageBreak: "after", pageOrientation: orientation });
		return contenido;
	}

	async inventarioBienesDerechos(info: any = null, entidad_local? : any, imagen_logo?:any) {

		if (info == null) {
			info = {
				data: this.infoProvincia,
				image: this.imagenMunicipio
			}
		}


		let contenido = [];
		//let image = await this.getBase64ImageFromURL("./assets/images/1.1.1.-C-007.jpeg")

		/**-------Portada Informe------- */
		contenido.push({
			table: {
				widths: ['100%'],
				heights: [650],
				body: [
					[{
						border: [true, true, true, true],
						text: "INVENTARIO\nDE\nBIENES\nY\nDERECHOS",
						margin: [0, 200, 0, 0],
						fontSize: 45,
						alignment: "center",
						bold: true,
						valign: 'middle',
					},
					],
					[{
						border: [false, false, false, true],
						text: "",
						fontSize: 1,
						margin: [0, 30, 0, 0],
						alignment: "center",
						bold: true,
					}],
					[{
						border: [false, true, false, false],
						text: `${entidad_local?.nombre ?? ''}`,
						fontSize: 15,
						alignment: "center",
						bold: true,
					}]
				]
			}
		});
		contenido.push({ text: "", pageBreak: "after" });

		/**-------Seccion Valoracion General------- */
		const respValoracionGeneral = await this._pdfDocService.valoracionGeneralInventario_InformeInvetarioBienes(info,entidad_local,imagen_logo);
		contenido.push(respValoracionGeneral.content);
		contenido.push({ text: "", pageBreak: "after" });

		/**-------Seccion Valoracion Contable------- */
		const valoracionPGCP = await this._pdfDocService.valoracionPGCP_InformeInventarioBienes(info,entidad_local,imagen_logo);
		contenido.push(valoracionPGCP.content);

		/**-------Descargar Informe Valoracion------- */
		let valoracionPdf = {
			pageMargins: [40, 40, 40, 40],
			info: {
				title: `Inventario Bienes y Derechos`,
				author: "SerProfes",
			},
			content: contenido,
			defaultStyle: {
				fontSize: 7,
			},
		};
		this.pdfService.downloadEpigrafePdf(valoracionPdf, 'Valoracion');


		let EPIGRAFES: any = [
			// {nombre: 'BIENES INMUEBLES', id: 8, codigo: '1.1.1'},
			// {nombre: 'PARQUES Y JARDINES', id: 9, codigo: '1.1.2'},
			// {nombre: 'SOLARES', id: 10, codigo: '1.1.3'},
			// {nombre: 'INFRAESTRUCTURA', id: 11, codigo: '1.1.4'},
			// {nombre: 'FINCAS RUSTICAS', id: 17, codigo: '1.2'},
			// {nombre: 'VIAS PUBLICAS URBANAS', id: 18, codigo: '1.3'},
			// {nombre: 'VIAS PUBLICAS RUSTICAS', id: 19, codigo: '1.4'},
			// {nombre: 'DERECHOS REALES', id: 12, codigo: '2'},
			// {nombre: 'MUEBLES DE CARACTER HISTORICO-ARTISTICO', id: 13, codigo: "3"},
			// {nombre: 'VALORES MOBILIARIOS', id: 15, codigo: '4'},
			// {nombre: 'CREDITOS Y DERECHOS', id: 16, codigo: '4'},
			// {nombre: 'VEHÍCULOS', id: 7, codigo: '5'},
			// {nombre: 'SEMOVIENTES', id: 6, codigo: '6'},
			// {nombre: 'OTROS MUEBLES', id: 5, codigo: '7'},
			// {nombre: 'BIENES Y DERECHOS REVERTIBLES', id: 4, codigo: '8'},
			// {nombre: 'ARBOLADO URBANO', id: 21, codigo: ''},
			// {nombre: 'INMUEBLES', id: 20, codigo: ''},
			// {nombre: 'FONDOS EN METÁLICO', id: 22, codigo: ''}
		]

		if (info && info.data && info.data.epigrafes && info.data.epigrafes.length > 0) {
			EPIGRAFES = info.data.epigrafes;
		}
		EPIGRAFES.map(el => {
			el.nombre = el.nombre.toUpperCase()
		})



		for (const epi of EPIGRAFES) {
			contenido = [];

			if (epi.id == 20) {
				contenido.push({
					table: {
						widths: ['100%'],
						heights: [650],
						body: [
							[{
								border: [true, true, true, true],
								text: "INVENTARIO\nDE\nPATRIMONIO\nY\nSUELOS",
								margin: [0, 200, 0, 0],
								fontSize: 45,
								alignment: "center",
								bold: true,
								valign: 'middle',
							},
							],
							[{
								border: [false, false, false, true],
								text: "",
								fontSize: 1,
								margin: [0, 30, 0, 0],
								alignment: "center",
								bold: true,
							}],
							[{
								border: [false, true, false, false],
								text: `${info.data.nombre ?? ''}`,
								fontSize: 15,
								alignment: "center",
								bold: true,
							}]
						]
					}
				});
				contenido.push({ text: "", pageBreak: "after" });
			}



			try {
				let data = null
				if (epi.id != 1 && epi.id != 3) {
					data = await lastValueFrom(this._featureService.getDatosEpigrafe(info.data.id, epi.id));

					if (data.result == null || data.result.datosBienes.length == 0) {
						// Titulo de Epigrafe
						contenido.push(
							this.buildTituloPage({
								titulo: epi.nombre,
								saltoPagina: true,
								info: info
							}, entidad_local)
						);
						contenido.push(
							this.buildTituloPage({
								titulo: "NO CONSTAN BIENES\nEN ESTE EPIGRAFE",
								saltoPagina: false,
								info: info
							},entidad_local)
						);

					} else {
						contenido.push(
							this.buildTituloPage({
								titulo: epi.nombre,
								saltoPagina: true,
								orientation: "landscape",
								info: info
							},entidad_local)
						);


						let datosBienes = data.result.datosBienes;
						const total = data.result.total;

						let table = await this.construirTablaEpigrafe(datosBienes, epi.id, epi.nombre, info);
						contenido.push(table);
						contenido.push({
							text: "",
							pageBreak: "after",
							pageOrientation: "portrait",
						});

						for (const element of datosBienes) {

							let respValoracionGeneral: any = {};
							if (epi.id == 7) {
								respValoracionGeneral =
									await this.fichaDatosService.fichaDatosVehiculos(
										element,
										epi,
										info,
										entidad_local
									);
							} else if(epi.id == 5){
								respValoracionGeneral =
								await this.fichaDatosService.fichaOtrosInmuebles(
									element,
									epi, 
									info,
									entidad_local
								);
							} else if (epi.id == 13) {
								respValoracionGeneral =
									await this.fichaDatosService.fichaDatosMueblesHistoricos(
										element,
										epi,
										info,
										entidad_local

									);
							} else if (epi.id == 15) {
								respValoracionGeneral =
									await this.fichaDatosService.fichaDatosValoresInmobiliarios(
										element,
										epi,
										info,
										entidad_local
									);
							} else if (epi.id == 16) {
								respValoracionGeneral =
									await this.fichaDatosService.fichaDatosCreditos(
										element,
										epi,
										info,
										entidad_local
									);
							} else if (epi.id == 6) {
								respValoracionGeneral =
									await this.fichaDatosService.fichaSemovientes(
										element,
										epi,
										info,
										entidad_local
									);
							} else if (epi.id == 12) {
								respValoracionGeneral =
									await this.fichaDatosService.fichaDerechosReales(
										element,
										epi,
										info,
										entidad_local
									);
							} else if (epi.id == 4) {
								respValoracionGeneral =
									await this.fichaDatosService.fichaDatosBienesRevertibles(
										element,
										epi,
										info,
										entidad_local
									);
							} else if (epi.id == 18 || epi.id == 19) {
								respValoracionGeneral =
									await this._pdfDocService.fichaDatosVias(
										element,
										epi,
										info,
										entidad_local
									);
							} else if (epi.id == 21) {
								respValoracionGeneral =
									await this.fichaDatosService.fichaArbolado(
										element,
										epi,
										info,
										entidad_local
									);
							} else if (epi.id == 22) {
								respValoracionGeneral =
									await this.fichaDatosService.fichaFondosMetalicos(
										element,
										epi,
										info,
										entidad_local
									);
							} else {
								respValoracionGeneral =
									await this._pdfDocService.fichaDatosInmueblesUrbanos(
										element,
										epi,
										info,
										entidad_local
									);
							}

							contenido.push(respValoracionGeneral.content);
							contenido.push({
								text: "",
								pageBreak: "after",
							});

							const hor = await this._pdfDocService.historialFichaEpigrafe(
								element,
								epi.codigo,
								info,
								entidad_local
							);
							contenido.push(hor.content);
							contenido.push({
								text: "",
								pageBreak: "after",
							});

							if (epi.id != 15 && epi.id != 16) {
								let imagenes;
								if (epi.id == 7 || epi.id == 13 || epi.id == 6) {
									imagenes = await this._pdfDocService.imagenesFichaEpigrafe(element, epi.codigo, false, info,entidad_local);
								} else {
									imagenes = await this._pdfDocService.imagenesFichaEpigrafe(element, epi.codigo, true, info,entidad_local);
								}
								contenido.push(imagenes.content);
							}


							contenido.push({
								text: "",
								pageBreak: "after",
							});

						}
					}
					let epigrafePdf = {
						pageMargins: [40, 40, 40, 40],
						info: {
							title: `Inventario Bienes y Derechos`,
							author: "SerProfes",
						},
						content: contenido,
						defaultStyle: {
							fontSize: 7,
						},
					};

					this.pdfService.downloadEpigrafePdf(epigrafePdf, epi.nombre);
				}




			} catch (error) {
				console.log(error);
			}
		}

	}

	async construirTablaEpigrafe(data, id, title, info: any = null) {
		let table
		if (id == 7) {
			//Vehiculos
			table = await this.createTableService.createTable(
				data,
				title,
				["N ORD.", "TIPO DE VEHICULO", "MATRICULA", "DESTINO"],
				["n_orden", "tipo_vehiculo", "matricula", "destino"],
				["10%", "40%", "20%", "30%"],
				null,
				info
			);
		} else if (id == 5) {
			table = await this.createTableService.createTable(
				data,
				title,
				["N ORD.", "LOCALIDAD", "DESCRIPCIÓN", "DESTINO", "EDIFICIO"],
				["n_orden", "localidad", "descripcion", "destino", "edificio"],
				["10%", "20%", "30%", "20%", "20%"],
				null,
				info
			);
		} else if (id == 6) {
			table = await this.createTableService.createTable(
				data,
				title,
				["N ORD.", "LOCALIDAD", "ESPECIE", "NUM", "RAZA"],
				["n_orden", "localidad", "especie", "num_cabezas", "raza"],
				["10%", "30%", "20%", "10%", "30%"],
				null,
				info
			);
		} else if (id == 12) {
			table = await this.createTableService.createTable(
				data,
				title,
				["N ORD.", "LOCALIDAD", "NOMBRE VIA", "TITULO ADQUISICION"],
				["n_orden", "localidad", ["tipoVia.abreviatura", "via_publica_al_frente"], "titulo_propiedad"],
				["10%", "20%", "40%", "30%"],
				null,
				info
			);
		} else if (id == 13) {
			table = await this.createTableService.createTable(
				data,
				title,
				["N ORD.", "DESCRIPCION", "UBICACION", "EDIFICIO"],
				["n_orden", "descripcion", "ubicacion_del_bien", "edificio"],
				["10%", "40%", "30%", "20%"],
				null,
				info
			);
		} else if (id == 15) {
			table = await this.createTableService.createTable(
				data,
				title,
				["N ORD.", "CLASE", "NUM TITULOS", "ENTIDAD EMISORA", "LUGAR"],
				["n_orden", "clase", "num_titulos", "org_entidad_emi", "lugar_depositado"],
				["10%", "20%", "10%", "30%", "30%"],
				null,
				info
			);
		} else if (id == 16) {
			table = await this.createTableService.createTable(
				data,
				title,
				["N ORD.", "NOMBRE DEUDOR", "ESTADO", "VALOR", "CUENTA"],
				["n_orden", "nombre_deudor", "estado_mueble", "valor", "n_cuenta"],
				["10%", "30%", "20%", "10%", "30%"],
				null,
				info
			);
		} else if (id == 4) {
			//Bienes Revertibles
			table = await this.createTableService.createTable(
				data,
				title,
				["N ORD.", "TITULAR", "NOMBRE VIA", "NUM", "DESCRIPCION"],
				["n_orden", "titular", "via_publica", "numero", "descripcion"],
				["10%", "30%", "20%", "10%", "30%"],
				null,
				info
			);
		} else if (id == 18 || id == 19) {
			//Bienes Revertibles
			table = await this.createTableService.createTable(
				data,
				title,
				["N ORD.", "LOCALIDAD", "DENOMINACION", "ORIGEN", "FINAL"],
				["n_orden", "localidad", ["tipoVia.abreviatura", "denominacion"], "origen", "final"],
				["10%", "20%", "30%", "20%", "20%"],
				null,
				info
			);
		} else if (id == 20) {
			//Bienes Revertibles
			table = await this.createTableService.createTable(
				data,
				title,
				["N ORD.", "LOCALIDAD", "NOMBRE VIA", "NUM", "DESCRIPCION"],
				["n_orden", "localidad", ["tipoVia.abreviatura", "via"], "numero", "nombre"],
				["10%", "30%", "20%", "10%", "30%"],
				'INVENTARIO DEL PATRIMONIO MUNICIPAL DE SUELO',
				info
			);
		} else if (id == 21) {
			//Bienes Revertibles
			table = await this.createTableService.createTable(
				data,
				title,
				["N ORD.", "ESPECIE", "No PIES", "LOCALIZACION", "Nº ORD INV GRAL", "TRAMO AGRUPACION", "ALINEACION", "DISTANCIA ENTRE PIES"],
				["n_orden", "especie", "n_pies", "localizacion", "n_ord_inv_gral", "tramo_agrupacion", "alineacion", "dist_entre_pies"],
				["10%", "20%", "10%", "20%", "10%", "10%", "10%", "10%"],
				'INVENTARIO DE ARBOLADO URBANO',
				info
			);
		} else if (id == 22) {
			//Bienes Revertibles
			table = await this.createTableService.createTable(
				data,
				title,
				["N ORD.", "CONCEPTO", "REMITENTE", "IMPORTE", "SALDO"],
				["n_orden", "concepto", "remitente", "importe", "saldo"],
				["10%", "30%", "40%", "10%", "10%"],
				'INVENTARIO DEL PATRIMONIO MUNICIPAL DE SUELO',
				info
			);
		} else {
			table = await this.createTableService.createTableInmuebles(
				data,
				title,
				'',
				id,
				info
			);
		}
		return table.content
	}


	// async buildChild(g) {
	// 	const contenido = [];
	// 	const a$ = this._featureService.getListaEpigrafes(g.url);
	// 	let finalNumber = { result: [] };
	// 	try {
	// 		finalNumber = await lastValueFrom(a$);
	// 	} catch (error) {
	// 		// console.log(finalNumber);
	// 	}
	// 	const data = [];
	// 	if (finalNumber.result) {
	// 		if (finalNumber.result.length > 25) {
	// 			finalNumber.result = finalNumber.result.slice(0, 1);
	// 		}
	// 		for (const item of finalNumber.result) {
	// 			data.push(this._featureService.keysToLowerCase(item));
	// 		}
	// 	}

	// 	if (data) {
	// 		if (data.length > 0) {
	// 			let listaInforme: any = []
	// 			if(g.url == 'vehiculos'){
	// 				listaInforme = await this.createTableService.createTable(
	// 					data,
	// 					g.item,
	// 					[ "N ORD.", "TIPO DE VEHICULO", "MATRICULA", "DESTINO"],
	// 					[ "n_orden", "tipo_vehiculo", "matricula", "destino"],
	// 					["10%", "40%", "20%", "30%"]
	// 				);
	// 			} else if(g.url == 'muebles-caracter-ha'){
	// 				listaInforme = await this.createTableService.createTable(
	// 					data,
	// 					g.item,
	// 					["N ORD.", "DESCRIPCION", "UBICACION", "EDIFICIO"],
	// 					["num_ord", "descripcion", "ubicacion_del_bien","edificio"],
	// 					["10%", "40%", "30%", "20%"]
	// 				);
	// 			} else if(g.url == 'bienes-revertibles'){
	// 				listaInforme = await this.createTableService.createTable(
	// 					data,
	// 					g.item,
	// 					["N ORD.", "TITULAR", "NOMBRE VIA", "NUM", "DESCRIPCION"],
	// 					["n_orden", "titular", "via_publica", "n_cuenta", "descripcion"],
	// 					["10%", "30%", "20%", "10%", "30%"]
	// 				);
	// 			} else {
	// 				listaInforme = await this.createTableService.createTableInmuebles(
	// 					data,
	// 					g.item,
	// 					g.url,
	// 					0
	// 				);
	// 			}
	// 			console.log(listaInforme)
	// 			contenido.push({
	// 				text: "",
	// 				pageBreak: false,
	// 				pageOrientation: "landscape",
	// 			});
	// 			contenido.push( listaInforme.content);
	// 			contenido.push({
	// 				text: "",
	// 				pageBreak: "after",
	// 				pageOrientation: "portrait",
	// 			});
	// 		} else {
	// 			contenido.push(
	// 				this.buildTituloPage({
	// 					titulo: "NO CONSTAN BIENES\nEN ESTE EPIGRAFE",
	// 					saltoPagina: false,
	// 				})
	// 			);
	// 		}
	// 		for (const element of data) {
	// 			let respValoracionGeneral:any = {};
	// 			console.log(element)
	// 			if(g.url == 'vehiculos'){
	// 				respValoracionGeneral =
	// 				await this.fichaDatosService.fichaDatosVehiculos(
	// 					element, epigrafe: any
	// 				);
	// 			}else if(g.url == 'muebles-caracter-ha'){
	// 				console.log(g)
	// 				respValoracionGeneral =
	// 				await this.fichaDatosService.fichaDatosMueblesHistoricos(
	// 					element, epigrafe: any
	// 				);
	// 			} else if(g.url == 'bienes-revertibles'){
	// 				respValoracionGeneral =
	// 				await this.fichaDatosService.fichaDatosBienesRevertibles(
	// 					element, epi
	// 				);
	// 			} else if(g.url == 'vias-publicas-urbanas' || g.url == 'vias-publicas-rusticas') {
	// 				respValoracionGeneral =
	// 				await this._pdfDocService.fichaDatosVias(
	// 					element,
	// 					g.url
	// 				);
	// 			} else {
	// 				respValoracionGeneral =
	// 				await this._pdfDocService.fichaDatosInmueblesUrbanos(
	// 					element,
	// 					g.url
	// 				);
	// 			}

	// 			contenido.push(respValoracionGeneral.content);
	// 			contenido.push({
	// 				text: "",
	// 				pageBreak: "after",
	// 			});

	// 			const hor = await this._pdfDocService.historialFichaEpigrafe(
	// 				element
	// 			);
	// 			contenido.push(hor.content);
	// 			contenido.push({
	// 				text: "",
	// 				pageBreak: "after",
	// 			});

	// 			const imagenes =
	// 				await this._pdfDocService.imagenesFichaEpigrafe(element);
	// 			contenido.push(imagenes.content);


	// 			contenido.push({
	// 				text: "",
	// 				pageBreak: "after",
	// 			});
	// 		}
	// 	}
	// 	return contenido;
	// }
}
