import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ImageBaseUrlService {

  constructor() { }

  getBaseImageFromURL(url: any) {
		return new Promise((resolve, reject) => {
			var img = new Image();
			img.setAttribute("crossOrigin", "anonymous");

			img.onload = () => {
				var canvas = document.createElement("canvas");
				canvas.width = img.width;
				canvas.height = img.height;

				var ctx = canvas.getContext("2d");
				ctx?.drawImage(img, 0, 0);

				var dataURL = canvas.toDataURL("image/png");

				resolve(dataURL);
			};

			img.onerror = (error) => {
				reject(error);
			};

			img.src = url;
		});
	}
}
